<template>
	<div class="xeUsageChart heightMAX rounded" style="overflow: hidden;" :style="{backgroundColor: '#ff0000'}">
		<div class="heightMAX" style="padding: 0">
			<!-- <div class="jvectormap-container " style="background-color: transparent; position:relative"> -->
			<drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX kbet-custom-reset">
				<div class="rounded kbet-widget-energy heightMAX" style="display: block;">
					{{$t('에너지 실시간 자립률')}}
				</div>
				<!--// kbet-card-info -->
			</drop>
		</div>

		<b-modal ref="modalSetting" size="sm" :title="`${$t('설정')} - ${$t('실시간 자립률')}`" bodyClass="p-t-0 p-b-0" hide-footer>
      {{$t('설정')}}
			<!-- <self-rate-new-setting :initSetting="setting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" /> -->
		</b-modal>
	</div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";

	export default {
		props: ["showGrid", "widget", "cellSize", "on-remove-widget"],
		extends: xeBaseWidget,
		components: {
	},
		created() {
			if (!this.widget.bgIcon) {
				this.widget.bgIcon = "material flash_on";
			}
			if (!this.widget.bldImage) {
				this.widget.bldImage = "bg-icon03.png";
			}

			if (!this.widget.zoneIdx) {
				this.widget.zoneIdx = 1;
			}

			// console.log("xeSelfRateNew widget created");
		},
		mounted() {
			// console.log("xeSelfRateNew widget mounted");
		},
		data() {
			return {
			
			};
		},
		computed: {
			setting() {
				return JSON.parse(JSON.stringify(this.widget));
			},

		},
		methods: {
			initSetting() {},
			applyData() {
				// console.log("xeSelfRateNew widget methods applyData : " + JSON.stringify(data));


			},
		},
	};
</script>

<style scoped></style>
