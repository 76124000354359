<template>
  <div class="xeChartPie heightMAX">
    <panel
      ref="panel"
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-5 chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div v-if="isShow" class="heightMAX">
          <pie-chart :chartData="chartJs.data" class="heightMAX" :options="chartJs.options" :style="{ height: chartHeight }"></pie-chart>
        </div>
      </drop>
    </panel>
    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div v-if="isShow" class="m-b-20 heightMAX" :class="[inverseBg]">
        <h4>{{ $t(widget.title) }}</h4>
        <pie-chart :chartData="chartJs.data" :options="chartJs.options" :style="{ height: chartHeight }"></pie-chart>
      </div>
    </drop>
    <b-modal ref="modalSetting" size="lg" title="설정 - Pie 차트" bodyClass="p-t-0 p-b-0" hide-footer>
      <chart-pie-setting :initSetting="chartPieSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import PieChart from "@src/components/vue-chartjs/PieChart.js";
import chartPieSetting from "@src/views/widget/v1/setting/xeChartPieSetting.vue";
import utils from "./xeBaseUtils.js";

// TODO: 데이터가 없을때 뜨기도하고 안뜨기도 함.....

export default {
  name: "xeChartPie",
  extends: xeBaseWidget,
  components: {
    PieChart,
    chartPieSetting,
  },
  data() {
    return {
      chartJs: {
        type: "pie",
        data: {},
        options: {},
      },
    };
  },
  computed: {
    chartPieSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  created() {
    // 초기값 설정
    if (!this.widget.series) this.widget.series = [{ label: "Default", point: "", backgroundColor: "#348fe2", compare: false }];
    if (!this.widget.addition) this.widget.addition = { afterAction: "", afterBind: "", isPercentage: true, fixed: 0, legend: { display: true, position: "bottom" } };
    if (!this.widget.self) this.widget.self = { baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" }, compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" } };
  },
  mounted(){
    let pies = document.getElementsByClassName('xeChartPie');

    for(let i = 0; i < pies.length; i++){
      pies[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.chartJs.options = this.createChartOptions();
      this.chartJs.data = this.createChartData();
    },
    // 차트 데이터 기본값 설정
    createChartData() {
      let points = this.widget.series.map((v) => {
        return { label: v.label, point: v.point, compare: v.compare };
      });

      let backgroundColor = this.widget.series
        .filter((v) => !v.compare || this.isCompare)
        .map((v) => {
          return v.backgroundColor;
        });

      let isPercentage = this.widget.addition && this.widget.addition.isPercentage;

      let labels = points
        ? points.map((item) => {
            return item.label;
          })
        : ["Default"];

      let data = points.map(() => {
        return (100.0 / points.length).toFixed(1);
      });

      return {
        labels: labels,
        points: points,
        isPercentage: isPercentage,
        datasets: [
          {
            backgroundColor: backgroundColor,
            borderWidth: 2,
            data: data,
          },
        ],
      };
    },
    // 차트 기본 옵션값 설정
    createChartOptions() {
      let defaultV = {
        responsive: true,
        maintainAspectRatio: false,
        radiusBackground: { color: "#d1d1d1" },
        legend: { display: this.widget.addition.legend.display, position: this.widget.addition.legend.position, labels: { usePointStyle: true, boxWidth: 9 } },
        elements: { point: { radius: 0.3 } },
        layout: { padding: { left: 15, right: 15, top: 0, bottom: 0 } },
        title: { display: false },
        tooltips: {
          mode: "index",
          intersect: true,
          callbacks: {
            label: (tooltipItem, data) => {
              let totalSum = data.datasets[tooltipItem.datasetIndex].data.sum();
              let label = data.labels[tooltipItem.index];
              let currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              let currentPer = null;

              if (data.isPercentage) {
                if (totalSum == 0) {
                  currentPer = (100.0 / data.datasets[tooltipItem.datasetIndex].data.length).toFixed(this.widget.addition.fixed);
                } else {
                  currentPer = (currentValue / totalSum) * 100.0;
                }

                currentPer = this.comma(currentPer, this.widget.addition.fixed);

                currentValue = this.comma(currentValue, this.widget.addition.fixed);

                return `${label}: ${currentPer}%, ${currentValue}`;
              } else {
                return `${label}: ${currentValue}`;
              }
            },
          },
        },
      };

      // TODO: chart의 기본속성을 그대로 사용할 수 있도록 하는 기능임. 예전에 사용하다가 사용하지 않고 있음.. 사용해야 함.
      if (this.widget.chart) return this.merge(defaultV, this.widget.chart);

      return defaultV;
    },
    // 차트 데이터 바인딩
    applyData(widgetLogs) {
      // 데이터 선별 작업 진행
      let chartData = this.createChartData();

      // 기존 로그 복사하여 데이터 변형 작업 진행
      let copyLogs = JSON.parse(JSON.stringify(widgetLogs));
      // afterAction 처리
      copyLogs = utils.onAfterAction2(chartData.points, copyLogs, this.widget.addition.afterAction);

      // 데이터 초기화
      chartData.datasets[0].data = chartData.points.map((item) => {
        let log = copyLogs[item.compare ? 1 : 0];

        // 조회해서 가지고 온 포인트값의 배열
        let ptVals = log && log.points ? log.points[item.point] : [];

        // 후처리 방식에 따라 데이터 바꿔서 차트 데이터에 바인딩
        let val = utils.onAfterBind(item.point, ptVals, this.widget.addition.afterBind); // 값반환

        val = utils.fixedValue(val, this.widget.addition.fixed);

        return val;
      });

      this.chartJs.data = chartData;
    },
    // dnd
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "label": "습구온도", "point": "084000001_0000_0000_VLI_00000001"}

        points.map((point) => {
          if (that.widget.series.filter((v) => v.point === point.ptAddr).length == 0) {
            // 중복 체크
            that.widget.series.push({ label: point.title, point: point.ptAddr, backgroundColor: that.getRandomColor() });
          }
        });
      }

      let defaultObj = { label: "Default", point: "", backgroundColor: "#348fe2", compare: false };

      if (JSON.stringify(this.widget.series.slice(0, 1)) == JSON.stringify([defaultObj])) this.widget.series = [];

      append(transfer.item.type === this.CODE.Drag.PointGroup ? transfer.item.points : [transfer.item]);
    },
  },
};
</script>
