<template>
	<div class="xeBizInfo heightMAX rounded">
		<div class="valign-middle heightMAX">
			<div class="heightMAX" style="padding: 0">
				<!-- <div class="jvectormap-container " style="background-color: transparent; position:relative"> -->

				<drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX kbet-custom-reset">
					<div class="rounded kbet-widget-infoCard heightMAX" :style="cssVars">
						<div class="weather-title">
							<strong class="title">
								{{ $t(widget.title) }}
							</strong>
							<div class="weather-area-v1">
								<div class="weather-temperature">
									<i class="xe-weather-icon" :class="'owm-' + widget.weather.icon"></i>
									<div class="divider-line">
										<span class="hidden">{{ $t("구분선") }}</span>
									</div>
									<dl>
										<dt>{{ Math.round(widget.weather.temp) }}℃</dt>
										<dd>{{ widget.weather.city }}</dd>
									</dl>
								</div>
							</div>
						</div>
						<!-- <p class="title">
                {{ $t(widget.title) }}
              </p>
                      <i
                class="xe-weather-icon"
                :class="'owm-' + widget.weather.icon"
              ></i>
              <div class="divider-line"><span class="hidden">구분선</span></div>
              <span>{{ widget.weather.city }}</span> -->

						<div class="info-area">
							<div v-if="widget.info.address != ''" class="info-list">
								<i class="material-icons list-icon">place</i>
								<ul class="list-add">
									<li>
										{{ $t(widget.info.address) }}
									</li>
								</ul>
							</div>
							<!--//info-list-->
							<div v-if="widget.info.area != ''" class="info-list">
								<i class="material-icons list-icon">location_city</i>
								<ul class="list-add">
									<li>
										{{ widget.info.area == "" ? "" : comma(widget.info.area) + " ㎡" }}
									</li>
								</ul>
							</div>
							<!--//info-list-->
							<div v-if="widget.info.product != ''" class="info-list">
								<i class="material-icons list-icon">category</i>
								<ul class="list-add">
									<li>{{ $t(widget.info.product) }}</li>
								</ul>
							</div>
							<!--//info-list-->
						</div>
						<!--// info-area -->
					</div>
					<!--// kbet-card-info -->
				</drop>
			</div>
		</div>

		<!-- 세팅 모달 -->
		<b-modal ref="modalSetting" size="lg" :title="`${$t('설정')} - ${$t('건물정보')}`" body-class="p-t-0 p-b-0" hide-footer>
			<biz-info-setting :initSetting="bizInfoSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
		</b-modal>
	</div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
	import Resource from "@plugins/Resource.js";
	import bizInfoSetting from "./setting/xeBizInfoSetting.vue";
	import weatherCitys from "./common/weather_city.json";

	export default {
		name: "xeBizInfo",
		props: ["showGrid", "widget", "cellSize", "on-remove-widget"],
		extends: xeBaseWidget,
		components: {
			bizInfoSetting,
		},
		created() {
			if (!this.widget.info) {
				this.widget.info = {
					address: "",
					product: "",
					area: "",
				};
			}

			if (!this.widget.weather) {
				this.widget.weather = {
					id: 0,
					main: "",
					description: "",
					temp: 0,
					temp_min: 0,
					temp_max: 0,
					icon: "",
					city: "Seoul",
					humidity: 0,
				};
			}

			//var imageURL = ''

			// if(!this.widget.src){
			//   this.imageUrl = this.$store.getters.master.company.imagePath + this.$store.getters.master.company.emptyImage
			// }
			// if(!this.$store.getters.master.company.imageName){
			//   this.imageUrl = this.$store.getters.master.company.imagePath + this.$store.getters.master.company.emptyImage
			// }
			// else{
			//   this.imageUrl = this.$store.getters.master
			// }
		},
		mounted() {
			let biz = document.getElementsByClassName("xeBizInfo");

			for (let i = 0; i < biz.length; i++) {
				biz[i].parentNode.style.height = "100%";
			}
		},
		data() {
			return {
				weatherCitys: weatherCitys,
				selected: "",
				imageUrl: "",
				masterInfo: {},
				customInfo: this.widget.customInfo !== undefined ? [] : this.widget.customInfo,

				// citys: [], // { countryCode: '', cityCode: '', cityName: '' }
				currentCity: {}, // Resouce.js에서 일반도시명으로 변경 함...
				mainWeather: {
					forecastDt: new Date().format("MM-dd HH") + ":00",
					temp: 0,
					humidity: 0,
					windSpeed: 0,
				},

				sparkTemperature: {
					data: [],
					length: 12,
					lineStyle: {
						stroke: "#ff1b30",
						strokeWidth: 2,
					},
				},
				sparkRain: {
					data: [],
					length: 12,
					lineStyle: {
						stroke: "#04c5c5",
						strokeWidth: 2,
					},
				},
				resource: Resource,
				forecastDt: new Date().format("yyyyMMdd"),
			};
		},
		computed: {
			localCity() {
				return localStorage && localStorage.currentCity ? JSON.parse(localStorage.currentCity) : "";
			},
			cssVars() {
				return {
					"--bg-image": this.widget.src === undefined ? "url()" : "url(/assets/xems/common/widget/bizInfo/" + this.widget.src + ")",
				};
			},
			bizInfoSetting() {
				return JSON.parse(JSON.stringify(this.widget));
			},
			master() {
				return this.$store.getters.master;
			},
			// masteCountryCode(){
			//   return this.master ? this.master.countryCode : '';
			// },
			citys() {
				return this.master.cityCodes || [];
			},
			masteCityCode() {
				if (this.master && Array.isArray(this.master.cityCode) && this.master.cityCode.length > 0) {
					return this.master.cityCode[0];
				}

				return this.master ? this.master.cityCode : "";
			},
			fixedHeight() {
				// 41은 패널해더의 높이...  5는 dndgrid 마진값

				if (!this.isEmpty(this.widget)) {
					let margin = 0;
					if (this.cellSize && !this.isEmpty(this.cellSize.margin)) margin = this.cellSize.margin;

					let panelHeaderHeight = this.widget.panel && this.widget.panel.display ? 41 : 0; // 41은 패널헤더가 표실될때의 높이값

					return this.widget.position.h * (this.cellSize.h + margin) - margin - panelHeaderHeight;
				} else return null;
			},
			imageHeight() {
				return this.fixedHeight + "px";
			},
		},

		methods: {
			initSetting() {
				// console.log('xeBizInfo.vue.initSetting()... called');
			},
			onPanelRemove() {
				this.isDelete = true;
				this.$emit("on-remove-widget");
			},

			applyData(data) {
				this.widget.weather.id = data.id;
				this.widget.weather.main = data.main;
				this.widget.weather.description = data.description;
				this.widget.weather.icon = data.weatherIcon;
				this.widget.weather.temp = data.temp;
				this.widget.weather.temp_min = data.temp_min;
				this.widget.weather.temp_max = data.temp_max;
				this.widget.weather.humidity = data.humidity;
			},

			getCurrentWeather() {
				let apiKey = "23cb0490dcd8c8f3e5fb667cb1fa6eee";
				let city = this.widget.weather.city;

				var url = "https://api.openweathermap.org/data/2.5/forecast";
				var params = "?units=metric&mode=json&apiKey=" + apiKey + "&q=" + city;

				this.$http
					.get(url + params)
					.then((response) => {
						return response.json();
					})
					.then((data) => {
						let currentVal = data.list[0];

						this.widget.weather.id = currentVal.weather[0].id;
						this.widget.weather.main = currentVal.weather[0].main;
						this.widget.weather.description = currentVal.weather[0].description;
						this.widget.weather.icon = currentVal.weather[0].icon;

						this.widget.weather.temp = currentVal.main.temp;
						this.widget.weather.temp_min = currentVal.main.temp_min;
						this.widget.weather.temp_max = currentVal.main.temp_max;
						this.widget.weather.humidity = currentVal.main.humidity;
					});
			},
		},
	};
</script>

<style scoped>
	.kbet-widget-infoCard {
		background: linear-gradient(180deg, #282736ff 1%, 40%, rgba(40, 39, 54, 0.4) 100%), var(--bg-image);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		color: #fff;
		padding: 0 25px !important;
	}

	.xe-weather-icon {
		font-family: "weathericons";
		font-style: normal;
		font-weight: normal;
	}
</style>
