<template>
  <div class="xeChartDonut heightMAX">
    <panel
      ref="panel"
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="inverseMode ? 'inverse' : panelVariant"
      :bodyClass="'p-5 chartHeightMax ' + inverseBg"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer heightMAX" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)" class="heightMAX">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop i-am-drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div class="donut heightMAX" :id="donutID">
          <div class="area">
            <template v-for="(donut, i) in donuts">
              <xc-donut-card class="heightMAX" :card="donut" :inverseMode="inverseMode" :key="i" v-b-tooltip.top :title="`${comma(donut.baseline.val, 1)} = ${comma(donut.baseline.org, 1)} / ${comma(donut.baseline.max, 1)} * 100.0`" />
            </template>
          </div>
        </div>
      </drop>
    </panel>

    <drop i-am-drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div class="m-b-20 heightMAX" :class="[inverseBg]">
        <h4>{{ $t(widget.title) }}</h4>
        <div class="donut m-t-10 heightMAX" :id="donutID">
          <div class="area">
            <template v-for="(donut, i) in donuts">
              <xc-donut-card :card="donut" :inverseMode="inverseMode" :key="i" class="heightMAX"/>
            </template>
          </div>
        </div>
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" title="설정 - Donut Chart" body-class="p-t-0 p-b-0" hide-footer>
      <chart-donut-setting :initSetting="chartDonutSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<style scoped>
.donut {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  /* background: #101010; */
  /* background: #fff; */
}
.area {
  position: relative;
  min-width: 100px;
  /* width: 900px; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
</style>

<script>
import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import xcDonutCard from "@src/views/widget/v1/xcDonutCard.vue";
import chartDonutSetting from "@src/views/widget/v1/setting/xeChartDonutSetting.vue";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";

export default {
  name: "xeChartDonut",
  extends: xeBaseWidget,
  components: {
    xcDonutCard,
    chartDonutSetting,
  },
  data() {
    return {
      // {
      //   "type": "xeChartDonut",
      //   "title": "Average Load Ratio",
      //   "panel": { "display": true },
      //   "id": "W100-007",
      //   "hidden": false,
      //   "pinned": false,
      //   "position": { "x": 1, "y": 2, "w": 38, "h": 35 },
      //   "series": [
      //     {
      //       "label": "Load Ratio",
      //       "point": "VLI_CH1_PLR",
      //       "maxPt": "",
      //       "maxVal": null,
      //       "style": { "color": "#009999" }
      //     }
      //   ],
      //   "addition": { "afterAction": "", "afterBind": "", "fixed": 0 }
      // }

      donuts: [],

      donutID: this.getRandomId("donut"),
      donutEl: null,
      areaEl: null,
    };
  },
  computed: {
    chartDonutSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  watch: {
    fixedHeight() {
      requestAnimationFrame(this.draw);
    },
    "widget.position.w"() {
      requestAnimationFrame(this.draw);
    },
    inverseMode() {
      requestAnimationFrame(this.draw);
    },
  },
  created() {
    // 초기값 설정
    if (!this.widget.series) this.widget.series = [{ label: "Default", point: "", maxPt: "", maxVal: null, style: { color: "" } }];
    if (!this.widget.addition) this.widget.addition = { afterAction: "", afterBind: "", fixed: 0 };
    if (!this.widget.self) this.widget.self = { baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" }, compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" } };
  },
  mounted(){
    let donuts = document.getElementsByClassName('xeChartDonut');

    for(let i = 0; i < donuts.length; i++){
      donuts[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.donuts = [];
      this.widget.series.forEach((v) => {
        this.donuts.push({
          label: this.$t(v.label),
          point: v.point,
          maxPt: v.maxPt,
          maxVal: v.maxVal,
          isCompare: v.compare,
          baseline: { val: 0, org: 0, max: 0 },
          compare: { val: 0, org: 0, max: 0 },
          style: { color: v.style.color, width: 0, height: 0 },
          fixed: this.widget.addition.fixed,
        });
      });

      this.donutEl = document.getElementById(this.donutID);
      this.areaEl = this.donutEl.getElementsByClassName("area")[0];

      this.$nextTick(this.draw);
    },
    // 차트 데이터 바인딩
    applyData(logs) {
      let that = this;

      this.debug.logs = logs;

      function onAfter(ptAddr, logs, isCompare) {
        let vals = ptAddr ? logs[isCompare ? 1 : 0].points[ptAddr] : [] || undefined;
        vals = utils.onAfterAction(ptAddr, vals, that.widget.addition.afterAction); // 배열반환
        return utils.onAfterBind(ptAddr, vals, that.widget.addition.afterBind); // 값반환
      }

      function onCalcMax(ptAddr, logs, maxPt, maxVal, isCompare) {
        let org = onAfter(ptAddr, logs, isCompare);

        let max = maxPt ? onAfter(maxPt, logs, isCompare) : maxVal || 0;

        let val = "";
        if (max !== 0) {
          val = (org / max) * 100.0;
        }

        return { val, org, max };
      }

      // "series": [{
      //   "label": "Load Ratio",
      //   "point": "VLI_CH1_PLR",
      //    "maxPt": "", // maxVal보다 우선함..
      //    "maxVal": null
      // }]

      this.donuts.forEach((donut) => {
        donut.baseline = onCalcMax(donut.point, logs, donut.maxPt, donut.maxVal, false);

        if (this.isCompare) {
          donut.compare = onCalcMax(donut.point, logs, donut.maxPt, donut.maxVal, true);
        }
      });
    },
    onPanelExpand() {
      this.isPanelExpand = !this.isPanelExpand;

      this.$nextTick(() => {
        let changePanelHeight = this.$refs.panel.$el.clientHeight;

        if (!this.isEmpty(this.$refs.panel) && !this.isEmpty(this.$refs.panel.$el)) {
          document.getElementById(this.donutID).style.zoom = (changePanelHeight / this.basePanelHeight) * 1.5;
          if (this.isPanelExpand == false) document.getElementById(this.donutID).style.zoom = 1.5;
        }
      });
    },
    //dnd
    handleDropPoint(transfer) {
      let that = this;

      this.widget.series.map((v) => v.style.color);

      function append(points) {
        points.map((point) => {
          if (that.widget.series.filter((v) => v.point === point.ptAddr).length == 0) {
            // 중복 체크
            that.widget.series.push({ label: point.title, point: point.ptAddr, maxPt: "", maxVal: null, style: { color: that.getRandomColor() } });
          }
        });
      }

      let defaultObj = { label: "Default", point: "", maxPt: "", maxVal: null, style: { color: "" } };

      if (JSON.stringify(this.widget.series.slice(0, 1)) == JSON.stringify([defaultObj])) this.widget.series = [];
      append(transfer.item.type === this.CODE.Drag.PointGroup ? transfer.item.points : [transfer.item]);
    },
    draw() {
      // 전체넓이 / 카드수 = 카드한장의 width
      let w = this.areaEl.offsetWidth / this.donuts.length;
      let h = this.fixedHeight;

      if (w < 50) w = 50;

      this.donuts.forEach((card) => {
        card.style.width = w - 20; // 50 = 카드간 마진..
        card.style.height = h - 38;
      });

      if (this.inverseMode) {
        this.donutEl.style.backgroundColor = "#101010";

        this.donutEl.querySelectorAll(".area .card:before").forEach(function(elm) {
          elm.style.background = "rgba(255,255,255, .03)";
        });

        // 빼기 linear-gradient
        this.donutEl.querySelectorAll(".area .card").forEach(function(elm) {
          elm.style.background = "linear-gradient(0deg, #1b1b1b, #222, #1b1b1b)";
        });

        this.donutEl.querySelectorAll(".percent").forEach(function(elm) {
          elm.style["box-shadow"] = "inset 0 0 50px #000";
          elm.style["background"] = "#222";
        });

        this.donutEl.querySelectorAll(".percent .number h2").forEach(function(elm) {
          elm.style["color"] = "#777";
        });

        this.donutEl.querySelectorAll(".percent .number h2 span").forEach(function(elm) {
          elm.style["color"] = "#777";
        });

        this.donutEl.querySelectorAll(".text").forEach(function(elm) {
          elm.style["color"] = "#777";
        });
      } else {
        this.donutEl.style.backgroundColor = "rgba(255,255,255, 0)";

        this.donutEl.querySelectorAll(".area .card:before").forEach(function(elm) {
          elm.style.background = "rgba(0,0,0, .03)";
        });

        // 빼기 linear-gradient
        this.donutEl.querySelectorAll(".area .card").forEach(function(elm) {
          elm.style.background = "#fff";
        });

        this.donutEl.querySelectorAll(".percent").forEach(function(elm) {
          elm.style["box-shadow"] = "inset 0 0 50px #fff";
          elm.style["background"] = "#fff";
        });

        this.donutEl.querySelectorAll(".percent .number h2").forEach(function(elm) {
          elm.style["color"] = "#888";
        });

        this.donutEl.querySelectorAll(".percent .number h2 span").forEach(function(elm) {
          elm.style["color"] = "#888";
        });

        this.donutEl.querySelectorAll(".text").forEach(function(elm) {
          elm.style["color"] = "#888";
        });
      }
    },
  },
};
</script>
