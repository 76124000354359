<template>
	<div>
		<div class="row form-group m-t-10">
			<div class="col-md-12">
				<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }"><trans>일반</trans></a>
				<a href="javascript:;" class="xe-tab-underline" @click.prevent="toggleTabs('point')" :class="{ 'xe-tab-line-orange': tabs.point }"> <trans>관제점</trans></a>
				<a href="javascript:;" class="xe-tab-underline" @click.prevent="toggleTabs('processing')" :class="{ 'xe-tab-line-orange': tabs.processing }"> <trans>데이터처리</trans></a>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-md-12">
				<div class="tab-content">
					<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.general }">
						<div class="row form-group">
							<div class="col-10">
								<label> <trans>제목</trans> </label>
								<input type="text" class="form-control" v-model="copySetting.title" />
							</div>
							<div class="col-2">
								<label> <trans>패널</trans> </label>
								<div class="checkbox checkbox-css">
									<input type="checkbox" :id="'donutchart_panel'" v-model="copySetting.panel.display" />
									<label :for="'donutchart_panel'">{{ copySetting.panel.display ? "ON" : "OFF" }}</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<h5><trans>차트</trans></h5>
							</div>
						</div>
						<div class="row form-group">
							<div class="col-md-2">
								<label> <trans>소수점자리</trans> </label>
								<valid-input
									:inputType="'number'"
									:inputValue.sync="copySetting.addition.fixed"
									:classList="'form-control'"
									:NonStatus="false"
									:errorMessage="validMessage.WIDGET_DECIMAL_AND_FONTSIZE_LENGTH_VALID_MESSAGE"
									:rules="rules.DASHBOARD_WIDGET_DECIMAL_RULE"
                  >
								</valid-input>
							</div>
						</div>
						<hr />
						<div class="row">
							<div class="col-12">
								<h5><trans>조회기간 덮어쓰기</trans></h5>
							</div>
						</div>
						<xe-modal-self :copySetting="copySetting" :isCompare="true" />
					</div>

					<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.point }">
						<div v-for="(series, i) in copySetting.series" :key="i">
							<div class="row form-group">
								<div class="col-md-3">
									<label><trans>표시명</trans> {{ i + 1 }}</label>
									<div class="input-group">
										<valid-input :inputType="'text'" :inputValue.sync="series.label" :rules="rules.DASHBOARD_WIDGET_VIEW_NAME_RULE" :NonStatus="true" :classList="'form-control'"> </valid-input>
										<span class="input-group-append">
											<color-picker :idx="i" :current="series.style.color" @change-color="onChangeColor"></color-picker>
										</span>
									</div>
								</div>
								<div class="col-md-3">
									<label><trans>관제점</trans></label>
									<div :style="{ fontWeight: 'normal' }">
										<tree-select
											class="col-12 pr-0 pl-0"
											v-model="series.point"
											:options="searchPointInfoOptions"
											:disable-branch-nodes="true"											
											:open-on-focus="true"
											:placeholder="$t(controlMessages.POINT_TREE_PLACEHOLDER_MESSAGE)"
											:noChildrenText="$t(controlMessages.POINT_TREE_NOCHILDERN_MESSAGE)"
											:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
										/>
									</div>
								</div>
								<div class="col-md-3">
									<label><trans>최대값 (관제점)</trans></label>
									<input type="text" class="form-control" v-model="series.maxPt" :disabled="!isEmpty(series.maxVal)" />
								</div>
								<div class="col-md-2">
									<label><trans>최대값 (소수)</trans></label>
									<input type="text" class="form-control" v-model="series.maxVal" :disabled="!isEmpty(series.maxPt)" />
								</div>
								<div class="col-md-1">
									<label>&nbsp;</label>
									<b-dropdown text="" variant="default" toggle-class="btn-white" class="pull-left" style="margin-top:25px;">
										<b-dropdown-item @click="deleteSeries(i)"> <trans>삭제</trans> </b-dropdown-item>
									</b-dropdown>
								</div>
							</div>
							<legend></legend>
						</div>
						<div class="row form-group m-0">
							<button class="btn btn-white m-auto" @click="insertSeries()"><i class="fa fa-plus width-100"></i></button>
						</div>
					</div>

					<div class="tab-pane fade" :class="{ 'active show': tabs.processing }">
						<div class="row form-group">
							<div class="col-md-12">
								<label><trans>후처리</trans></label>
								<valid-textarea
									:inputType="'text'"
									:inputValue.sync="copySetting.addition.afterAction"
									:classList="'form-control'"
									:rows="5"
									:NonStatus="true"
									:rules="rules.DASHBOARD_WIDGET_POSTPROCESS_RULE"
								>
								</valid-textarea>
							</div>
						</div>
						<label><trans>취합방법</trans></label>
						<div class="row form-group">
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'donutchart_afterBind_Last' + copySetting.id" value="Last" v-model="copySetting.addition.afterBind" />
									<label :for="'donutchart_afterBind_Last' + copySetting.id">{{$t('마지막(Last)')}}</label>
								</div>
							</div>
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'donutchart_afterBind_Min' + copySetting.id" value="Min" v-model="copySetting.addition.afterBind" />
									<label :for="'donutchart_afterBind_Min' + copySetting.id">{{$t('최소(Min)')}}</label>
								</div>
							</div>
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'donutchart_afterBind_Max' + copySetting.id" value="Max" v-model="copySetting.addition.afterBind" />
									<label :for="'donutchart_afterBind_Max' + copySetting.id">{{$t('최대(Max)')}}</label>
								</div>
							</div>
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'donutchart_afterBind_Sum' + copySetting.id" value="Sum" v-model="copySetting.addition.afterBind" />
									<label :for="'donutchart_afterBind_Sum' + copySetting.id">{{$t('합계(Sum)')}}</label>
								</div>
							</div>
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'donutchart_afterBind_Avg' + copySetting.id" value="Avg" v-model="copySetting.addition.afterBind" />
									<label :for="'donutchart_afterBind_Avg' + copySetting.id">{{$t('평균(Avg)')}}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-12 p-0">
				<div slot="modal-footer" class="w-100">
					<button class="btn btn-primary m-r-5 pull-right" @click="onModalOK()"><trans>확인</trans></button>
					<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()"><trans>취소</trans></button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@src/api/backEndApi";
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	export default {
		name: "xeChartDonutSetting",
		extends: xeBaseSetting,
		data() {
			return {
				tabs: {
					general: true,
					processing: false,
					point: false,
				},
				searchPoint: null,
				searchPointInfoOptions: [],
				rules,
				validMessage,
				controlMessages
			};
		},
		mounted() {
			this.getSearchPoint();
		},
		methods: {
			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.point = false;
					this.tabs.processing = false;
				}
				if (tab == "processing") {
					this.tabs.general = false;
					this.tabs.point = false;
					this.tabs.processing = true;
				} else if (tab == "point") {
					this.tabs.general = false;
					this.tabs.point = true;
					this.tabs.processing = false;
				}
			},
			insertSeries() {
				this.copySetting.series.push({ label: "", point: "", maxPt: "", maxVal: null, style: { color: "" } });
			},
			deleteSeries(idx) {
				this.copySetting.series.splice(idx, 1);
			},
			onChangeColor(idx, color) {
				this.copySetting.series[idx].style.color = color;
			},
			getSearchPoint() {
				this.searchPointInfoOptions = [];

				backEndApi.pointInfo.getpointByGroup().then(({ data }) => {
					this.searchPoint = data;

					this.searchPoint.map((point) => {
						const pointList = point.pointList;
						const groupObject = {
							id: point.groupCode,
							label: point.groupCodeName,
							children: [],
						};

						for (let i = 0; i < pointList.length; i++) {
							groupObject.children.push({
								id: pointList[i].ptAddr,
								label: pointList[i].ptName,
							});
						}

						this.searchPointInfoOptions.push(groupObject);
					});

					this.searchPointInfoOptions.sort((a, b) => {
						return a.label.localeCompare(b.label);
					});

					this.searchPointInfoOptions.unshift({
						id: "",
						label: "전체",
					});
				});
			},
		},
	};
</script>
