<template>
	<div>
		<vvo v-slot="v">
			<div class="row form-group m-t-10">
				<div class="col-md-12">
					<a href="javascript:;" class="xe-tab-underline xe-tab-line-orange"> <trans>일반</trans></a>
				</div>
			</div>
			<br />
			<div class="row form-group">
				<div class="col-md-12">
					<div class="form-group row">
						<div class="col-4">
							<label>
								<h6><trans>사업자명</trans></h6>
							</label>
							<valid-input
								:vid="'사업자명'"
								:inputType="'text'"
								:inputValue.sync="copySetting.title"
								:classList="'form-control'"
								:placeholder="$t('사업장 이름을 입력하세요.')"
								:rules="rules.DASHBOARD_BIZ_OWNER_RULE"
                :errorMessage="validMessage.COMMON_OVER_LENGTH_VALID_MESSAGE(30)"
								:NonStatus="true"
							>
							</valid-input>
						</div>
						<div class="col-8">
							<label>
								<h6><trans>배경 이미지</trans></h6>
							</label>
							<select class="form-control" v-model="copySetting.src">
								<option value="biz_img01.png">{{$t('빌딩')}}</option>
								<option value="biz_img02.png">{{$t('숲')}}</option>
							</select>
						</div>
					</div>
				</div>

				<div class="col-md-12">
					<div class="form-group row">
						<div class="col-4">
							<label>
								<h6><trans>주소</trans></h6>
							</label>
							<valid-input
								:vid="'주소'"
								:inputType="'text'"
								:inputValue.sync="copySetting.info.address"
								:classList="'form-control'"
								:placeholder="$t('사업장 주소를 입력하세요.')"
								:rules="rules.DASHBOARD_BIZ_OWNER_RULE"
                :errorMessage="validMessage.COMMON_OVER_LENGTH_VALID_MESSAGE(30)"
								:NonStatus="true"
							>
							</valid-input>
						</div>
						<div class="col-4">
							<label>
								<h6><trans>면적</trans></h6>
							</label>
							<valid-input
                :vid="'면적'"
								:inputType="'number'"
								:inputValue.sync="copySetting.info.area"
								:classList="'form-control'"
								:rules="rules.DASHBOARD_BIZ_AREA_RULE"
								:errorMessage="validMessage.COMMON_DECIMAL_NUMBER_LENGTH_VALID_MESSAGE(8)"
								:NonStatus="true"
							>
							</valid-input>
						</div>
						<div class="col-4">
							<label>
								<h6><trans>주요 생산품</trans></h6>
							</label>
							<valid-input
                :vid="'주요 생산품'"
								:inputType="'text'"
								:inputValue.sync="copySetting.info.product"
								:classList="'form-control'"
								:rules="rules.DASHBOARD_BIZ_KEY_CONTENTS_RULE"
								:errorMessage="validMessage.COMMON_OVER_LENGTH_VALID_MESSAGE(30)"
								:NonStatus="true"
							>
							</valid-input>
						</div>
					</div>
					<hr />
				</div>
				<div class="col-md-12">
					<div class="form-group row">
						<div class="col-2">
							<label>
								<h6><trans>날씨설정</trans></h6>
							</label>
							<select class="form-control" v-model="copySetting.weather.city">
								<option value="Seoul"> {{ $t("서울") }} </option>
								<option value="Incheon"> {{ $t("인천") }} </option>
								<option value="Daejeon"> {{ $t("대전") }} </option>
								<option value="Daegu"> {{ $t("대구") }} </option>
								<option value="Busan"> {{ $t("부산") }} </option>
								<option value="Ulsan"> {{ $t("울산") }} </option>
								<option value="Gwangju"> {{ $t("광주") }} </option>
								<option value="Gyeonggi-do"> {{ $t("경기도") }} </option>
								<option value="Gangwon-do"> {{ $t("강원도") }} </option>
								<option value="Gyeongsangnam-do"> {{ $t("경상남도") }} </option>
								<option value="Gyeongsangbuk-do"> {{ $t("경상북도") }} </option>
								<option value="Chungcheongnam-do"> {{ $t("충청남도") }} </option>
								<option value="Chungcheongbuk-do"> {{ $t("충청북도") }} </option>
								<option value="Jeollanam-do"> {{ $t("전라남도") }} </option>
								<option value="Jeollabuk-do"> {{ $t("전라북도") }} </option>
								<option value="Jeju-do"> {{ $t("제주도") }} </option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<br />

			<div class="row form-group">
				<div class="col-md-12 p-0">
					<div slot="modal-footer" class="w-100">
						<button class="btn btn-primary m-r-5 pull-right" @click="v.invalid ? validFailed(v.errors) : onModalOK()">
							<trans>확인</trans>
						</button>
						<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()">
							<trans>취소</trans>
						</button>
					</div>
				</div>
			</div>
		</vvo>
	</div>
</template>

<script>
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	// import xeScatchColorPicker from "../common/xeScatchColorPicker.vue";

	export default {
		extends: xeBaseSetting,
		// components:{
		//   xeScatchColorPicker
		// },
		data() {
			return {
				rules,
				validMessage
			};
		},
		methods: {},
	};
</script>
@src/views/widget/v1/common/xeBaseSetting.js