import VueChartJs from "vue-chartjs";
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.unregister(ChartDataLabels);

export default {
  extends: VueChartJs.Pie,
  props: ["data", "options"],
  mounted() {
    // console.log("3-1");
    if(this.options["plugins"]){
      if(this.options.plugins.datalabels){    
          this.addPlugin(ChartDataLabels);
      }
    }
    this.renderChart(this.data, this.options);
  },
  watch: {
    data() {
      this.renderChart(this.data, this.options);
    },
  },
};
