<template>
  <div class="xeChartLine heightMAX">
    <panel
      ref="panel"
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="inverseMode ? 'inverse' : panelVariant"
      :bodyClass="'chartHeightMax ' + inverseBg"
      hideExpand="true"
      hideReload="false"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-reload="onPanelReload"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag
          class="cursor-pointer"
          :transfer-data="{ item: widget }"
        >
          <drop
            @dragover="handleTitleDragOver(...arguments)"
            @drop="handleTitleDrop(...arguments)"
          >
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>

      <template slot="button">
        <button
          class="btn btn-xs btn-icon btn-circle btn-primary"
          v-on:click="viewMode = 'Chart'"
          :disabled="viewMode === 'Chart'"
        ><i class="fa fa-chart-line"></i> </button>
        <button
          class="btn btn-xs btn-icon btn-circle btn-lime"
          v-on:click="viewMode = 'Grid'"
          :disabled="viewMode === 'Grid'"
        ><i class="fa fa-list"></i> </button>
      </template>

      <drop
        @dragover="handleBodyDragOver(...arguments)"
        @drop="handleBodyDrop(...arguments)"
        class="heightMAX"
      >
        <div
          v-if="isShow"
          class="heightMAX"
        >
          <!-- {{widgetLogs ? widgetLogs[0].conditions[0].points['082000020_0000_0041_NLI_22200001'] : '33' }} -->
          <mix-chart
            class="heightMAX"
            v-show="viewMode === 'Chart'"
            :chartData="chartJs.data"
            :options="chartJs.options"
          ></mix-chart>
          <xc-grid-table
            v-show="viewMode === 'Grid'"
            :gridTable="gridTable"
            :isGroup="isGridGroup"
            :isPagination="isPagination"
            :isLineNumber="isLineNumber"
          ></xc-grid-table>
        </div>
      </drop>
    </panel>

    <drop
      v-else
      @dragover="handleBodyDragOver(...arguments)"
      @drop="handleBodyDrop(...arguments)"
      class="heightMAX"
    >
      <div
        v-if="isShow"
        class="heightMAX"
        :class="[inverseBg]"
      >
        <h4>{{ $t(widget.title) }}</h4>
        <mix-chart
          v-show="viewMode === 'Chart'"
          :chartData="chartJs.data"
          :options="chartJs.options"
        ></mix-chart>
        <xc-grid-table
          v-show="viewMode === 'Grid'"
          :gridTable="gridTable"
          :isGroup="isGridGroup"
          :isPagination="isPagination"
        ></xc-grid-table>
      </div>
    </drop>

    <b-modal
      ref="modalSetting"
      size="lg"
      :title="`${$t('설정')} - Line ${$t('차트')}`"
      bodyClass="p-t-0 p-b-0"
      hide-footer
    >
      <chart-line-setting
        :initSetting="chartSetting"
        @on-modal-cancel="onModalCancel"
        @on-modal-ok="onModalOK"
      />
    </b-modal>
  </div>
</template>

<script>
import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import MixChart from "@src/components/vue-chartjs/MixChart.js";
import chartLineSetting from "@src/views/widget/v1/setting/xeChartLineSetting.vue";
import xcGridTable from "@src/views/widget/v1/controls/xcGridTable.vue";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";

export default {
  name: "xeChartLine",
  extends: xeBaseWidget,
  components: {
    MixChart,
    chartLineSetting,
    xcGridTable,
  },
  data() {
    return {
      chartJs: {
        data: {},
        options: {},
      },
      viewMode: "Chart", // 'Chart', 'Grid'

      // grid
      isGridGroup: false,
      isPagination: false,
      isLineNumber: true,
      gridTable: {
        columns: [],
        rows: [],
      },
      gridSetting: {},
    };
  },
  computed: {
    chartSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  created() {
    // 초기값 설정
    if (!this.widget.series)
      this.widget.series = [
        {
          type: "spline",
          label: "Default",
          point: "",
          position: "Left",
          backgroundColor: "rgba(52, 143, 226, 0.3)",
          fill: false,
          compare: false,
        },
      ];
    if (!this.widget.yAxes)
      this.widget.yAxes = {
        left: { label: "Unit1", min: 0, max: 0, stacked: false, display: true },
        right: {
          label: "Unit2",
          min: 0,
          max: 0,
          stacked: false,
          display: false,
        },
      };
    if (!this.widget.addition)
      this.widget.addition = {
        afterAction: "",
        fixed: 0,
        legend: { display: true, position: "bottom" },
      };
    if (!this.widget.self)
      this.widget.self = {
        baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
        compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
      };
  },
  mounted() {
    let lines = document.getElementsByClassName("xeChartLine");

    for (let i = 0; i < lines.length; i++) {
      lines[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.chartJs.options = this.createChartOptions();
      this.chartJs.data = {
        labels: this.defaultLabels(this.searchBoxCond.type),
        datasets: this.defaultDatasets(),
      };
    },
    defaultLabels(timeType) {
      let now = new Date();
      let labels = [];

      if (timeType == 1)
        labels = [now.addYear(-1).getFullYear() + "Y", now.getFullYear() + "Y"]; // 년
      if (timeType == 2)
        labels = [...Array(13).keys()]
          .filter((v) => v !== 0)
          .map((v) => {
            return v + "M";
          }); // 월
      if (timeType == 3)
        labels = [...Array(31).keys()]
          .filter((v) => v !== 0)
          .map((v) => {
            return v + "D";
          }); // 일
      if (timeType == 4)
        labels = [...Array(24).keys()].map((v) => {
          return v + "H";
        }); // 시
      if (timeType == 5) {
        // 15분
        for (let i = 1; i < 25; i++) {
          if (i == 1) labels.push(["0m", now.getDate() + "D"]);
          else labels.push("0m");
          labels.push("15m");
          labels.push("30m");
          labels.push("45m");
        }
      }

      return labels;
    },
    createLabels(logs) {
      let labels = [];
      let y,
        m,
        d,
        h,
        M = "";

      for (let i = 0; i < logs.length; i++) {
        let firstRows = logs[i].rows[0];

        for (let j = 0; j < logs[i].rows.length; j++) {
          let str = logs[i].rows[j];
          if (/^(\d){4}$/.test(str)) {
            y = str.substr(0, 4);
            // logs[i].rows[j] = y + "-" + m + "-" + d;
            logs[i].rows[j] = y + "년";
          } else if (/^(\d){6}$/.test(str)) {
            y = str.substr(0, 4);
            m = str.substr(4, 2);
            // logs[i].rows[j] = y + "-" + m + "-" + d;
            logs[i].rows[j] = m + "월";
          } else if (/^(\d){8}$/.test(str)) {
            y = str.substr(0, 4);
            m = str.substr(4, 2);
            d = str.substr(6, 2);
            if (Number(firstRows.substr(4, 2)) === Number(m)) {
              logs[i].rows[j] = d + "일";
            } else {
              logs[i].rows.splice(j, logs[i].rows.length - j);
              break;
            }
            // logs[i].rows[j] = y + "-" + m + "-" + d;
          } else if (/^(\d){10}$/.test(str)) {
            y = str.substr(0, 4);
            m = str.substr(4, 2);
            d = str.substr(6, 2);
            h = str.substr(8, 2);
            // logs[i].rows[j] = y + "-" + m + "-" + d + " " + h;\
            logs[i].rows[j] = h + "시";
          } else if (/^(\d){12}$/.test(str)) {
            y = str.substr(0, 4);
            m = str.substr(4, 2);
            d = str.substr(6, 2);
            h = str.substr(8, 2);
            M = str.substr(10, 2);
            // logs[i].rows[j] = y + "-" + m + "-" + d + " " + h + ":" + M;
            logs[i].rows[j] = h + ":" + M;
          }
        }
      }

      if (logs.length === 1) {
        // baseline 조회시
        labels = logs[0].rows;
      } else if (logs.length === 2) {
        // compare 조회시
        let base = logs[0];
        let comp = logs[1];

        // 구간조회이기 때문에, 일자의 갯수가 base와 comp가 서로 다를 수 있다.
        // 그래서, 일자 기간을 더 길게 조회한 쪽을 기준으로 for문을 돌아준다.

        if (base.rows.length >= comp.rows.length) {
          labels = base.rows.map((dt) => {
            return [
              dt,
              // comp.dates[i] ? comp.dates[i][1] + " " + comp.dates[i][0] : "",
            ];
          });
        } else {
          labels = comp.rows.map((dt) => {
            return [
              dt,
              // base.dates[i] ? base.dates[i][1] + " " + base.dates[i][0] : "",
            ];
          });
        }
      }

      console.log("xeArea Logs ==============>", labels);
      return labels;
    },
    defaultDatasets() {
      return this.widget.series
        .filter((item) => !item.compare || this.isCompare)
        .map((item) => {
          item.type = item.type.toLowerCase();
          let isStep = item.type == "step" ? true : false;
          let isSpline = item.type == "spline" ? true : false;
          let repType = item.type;
          if (isStep || isSpline) repType = "line";

          return {
            steppedLine: isStep,
            lineTension: isSpline ? 0.5 : 0.0,
            type: repType,
            label: item.label,
            backgroundColor: item.backgroundColor,
            borderColor: item.backgroundColor,
            yAxisID: item.position === "Left" ? "Left_yAxis" : "Right_yAxis",
            fill: item.fill,

            point: item.point,
            compare: item.compare ? item.compare : false,
            // timeType: timeType,
            // sDt: self?"":"",
            // eDt: self?"":""
          };
        });
    },
    createDatasets(logs) {
      let datasets = this.defaultDatasets();

      // 기존 로그 복사하여 데이터 변형 작업 진행
      let copyLogs = JSON.parse(JSON.stringify(logs));
      // afterAction 처리
      copyLogs = utils.onAfterAction2(
        datasets,
        copyLogs,
        this.widget.addition.afterAction
      );

      // 기존 로직 중 fixedValue살림
      return datasets.map((dataset) => {
        let log = dataset.compare ? copyLogs[1] : copyLogs[0];
        let ptVals = log && log.points ? log.points[dataset.point] : [];

        dataset.data = ptVals;

        return dataset;
      });
    },
    createChartOptions() {
      let leftAxis = this.widget.yAxes.left || {
        label: "",
        min: 0,
        max: 0,
        stacked: false,
        display: false,
      };
      let rightAxis = this.widget.yAxes.right || {
        label: "",
        min: 0,
        max: 0,
        stacked: false,
        display: false,
      };
      let options = {
        responsive: true,
        maintainAspectRatio: false,
        hoverMode: "index",
        legend: {
          display: this.widget.addition.legend.display,
          position: this.widget.addition.legend.position,
          labels: { usePointStyle: true, boxWidth: 5 },
        },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: -5 } },
        tooltips: {
          mode: "index",
          intersect: true,
          callbacks: {
            label: (tooltipItem, data) => {
              let value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              value = this.comma(value, this.widget.addition.fixed);

              let label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }

              return label + value;

              // let label = data.datasets[tooltipItem.datasetIndex].label || '';

              // if (label) { label += ': ' }
              // if(!data.datasets[tooltipItem.datasetIndex].compare){ label += tooltipItem.yLabel; }

              // return label;
            },
          },
        },
        elements: {
          point: {
            // radius: 3, // 라인차트에 점크기 기본값 3
          },
        },
        scales: {
          xAxes: [
            {
              stacked: leftAxis.stacked || rightAxis.stacked ? true : false,
              ticks: {
                     autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 20,
              },
            },
          ],
          yAxes: [
            {
              display: leftAxis.display,
              position: "left",
              id: "Left_yAxis",
              scaleLabel: {
                display: true,
                labelString: leftAxis.label,
                fontColor: this.inverseMode ? "white" : "black",
              },
              ticks: {
                     autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 20,
                suggestedMin: leftAxis.min,
                suggestedMax: leftAxis.max,
                beginAtZero: true,
                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
              stacked: leftAxis.stacked,
            },
            {
              display: rightAxis.display,
              position: "right",
              id: "Right_yAxis",
              scaleLabel: {
                display: true,
                labelString: rightAxis.label,
                fontColor: this.inverseMode ? "white" : "black",
              },
              ticks: {
                     autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 20,
                suggestedMin: rightAxis.min,
                suggestedMax: rightAxis.max,
                beginAtZero: true,
                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
              stacked: rightAxis.stacked,
              gridLines: { drawOnChartArea: false },
            },
          ],
        },
      };

      return options;
    },
    // virtual
    applyData(widgetLogs) {
      this.chartJs.data = {
        labels: this.createLabels(widgetLogs),
        datasets: this.createDatasets(widgetLogs),
      };
      this.widgetLogs = widgetLogs;
      this.bindGridData(widgetLogs);
    },
    // special
    bindGridData(newLogs) {
      let that = this;
      try {
        let _setting = this.widget;

        if (this.isEmpty(_setting.series)) return;

        let comparePostFix = "_C";

        // eslint-disable-next-line
        function _bindGridData(logs) {
          // console.log("xeGridTable bindGridData ", ptVals);

          try {
            // TODO: 후처리....
            // afterAction
            // A데이터와 B데이터의 상호 비율..
            // A데이터+B데이터를 합쳐서 새로운 데이터를 생성..
            // 여름에는 냉수를 표시하고, 겨울에는 온수를 표시하는 로직
            // afterBind
            // 전체데이터뿌리고 맨마지막중에 합산ROW를 추가...
            // 시간을 순서로 대체..

            // 컨디션별 데이터 생성작업
            // 이중배열 : compare인경우 두개의 배열이 반환됨. 첫번째 배열은 baseline, 두번째 배열은 compare

            // TODO: 장새롬 2019-10-24 - 오류처리해 주세요..
            // xeChartLine.vue?8c48:349 xeChartLine Grid _bindGridData TypeError: Cannot read property '0' of undefined

            let doubleRows = logs.map((condition, i) => {
              return condition.dates.map((dateTimes, j) => {
                if (dateTimes.length > 1)
                  dateTimes = `${dateTimes[1] || ""} ${dateTimes[0] || ""}`;

                //Points
                let obj = {};
                obj[i == 1 ? "Comparison" : "BaseLine"] = dateTimes;
                obj[i == 1 ? "hiddenComparison" : "hiddenBaseLine"] =
                  condition.rows[j];
                Object.keys(condition.points).map((point) => {
                  obj[point + (i == 1 ? comparePostFix : "")] =
                    Array.isArray(condition.points[point]) &&
                    condition.points[point].length > 0
                      ? condition.points[point][j]
                      : null;
                });
                return obj;
              });
            });

            // BaseLine 조회일 경우
            if (doubleRows.length <= 1) {
              //console.log("xeGridTable bindGridData BaseLine 조회일 경우 :", doubleRows);
              return doubleRows[0] || [];
            }

            // 두개의 배열길이 다른경우, 더 긴 배열에 길이만큼 for문이 돌아서 row가 생성되어야 함.
            let baseRows = doubleRows[0];
            let compareRows = doubleRows[1];

            if (baseRows.length > compareRows.length) {
              return baseRows.map((row, idx) => {
                return Object.assign(baseRows[idx], compareRows[idx]);
              });
            } else {
              return compareRows.map((row, idx) => {
                return Object.assign(compareRows[idx], baseRows[idx]);
              });
            }
          } catch (err) {
            console.error("xeChartLine Grid _bindGridData", err);
          }
          return [];
        }
        // eslint-disable-next-line
        function _onAfterAction(newLogs, afterAction) {
          return newLogs;

          //const totalLog = (newLogs && newLogs.length > 0 ? newLogs[0] : null);
          // if (that.isEmpty(newLogs)) return [];
          // if (that.isEmpty(afterAction)) return newLogs;

          // newLogs.map(condition => {
          //   Object.keys(condition.points).map(point => {
          //     try {
          //       let ptVals = condition.points[point];
          //       let fn = new Function("ptVals", afterAction);
          //       let rtn = fn(ptVals);
          //       condition.points[point] = rtn;
          //     } catch (err) {
          //       console.error("xeChartLine Grid _onAfterAction error", err);
          //     }
          //   });
          // });

          // return newLogs;
        }
        // eslint-disable-next-line
        function _createGridColumns(column, isCompare) {
          // BaseLine, Comparison 시간 Sort 함수 추가
          function customSortFn(x, y, col, rowX, rowY) {
            let target = "hidden" + col.field;
            return rowX[target] > rowY[target]
              ? -1
              : rowX[target] < rowY[target]
              ? 1
              : 0;
          }

          function newColumn(col) {
            return {
              label: col.label, // + " " + column.point,
              field: col.compare ? `${col.point}${comparePostFix}` : col.point, //포인트정보
              type: "decimal",
              // width: "" + Math.round(100 / that.widget.columns.length + (that.isCompare ? 2 : 1)) + "%",
              tdClass:
                "text-right f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
              thClass: "text-center text-nowrap",
              compare: col.compare == true,
            };
          }

          let rtnColumns = [
            {
              label: "BaseLine",
              field: "BaseLine",
              tdClass: "text-right",
              thClass: "text-center text-nowrap",
              compare: false,
              sortable: true,
              sortFn: customSortFn,
            },
          ];
          rtnColumns.push({
            label: "hiddenBaseLine",
            field: "hiddenBaseLine",
            tdClass: "text-right",
            thClass: "text-center text-nowrap",
            compare: false,
            hidden: true,
          });
          rtnColumns = rtnColumns.concat(
            column.filter((v) => !v.compare).map((col) => newColumn(col))
          );

          if (isCompare) {
            rtnColumns.push({
              label: "Comprison",
              field: "Comparison",
              tdClass: "text-right",
              thClass: "text-center text-nowrap",
              compare: true,
              sortFn: customSortFn,
            });
            rtnColumns.push({
              label: "hiddenComparison",
              field: "hiddenComparison",
              tdClass: "text-right",
              thClass: "text-center text-nowrap",
              compare: true,
              hidden: true,
            });
            rtnColumns = rtnColumns.concat(
              column.filter((v) => v.compare).map((col) => newColumn(col))
            );
          }

          return rtnColumns;
        }

        let _columns = _setting.series.filter((v) =>
          this.isCompare ? true : !v.compare
        );

        let cols = [
          {
            label: "BaseLine",
            point: "regDt",
            width: "20%",
            tdClass: "text-center",
            thClass: "text-center text-nowrap",
          },
        ];
        if (_columns.length > 0)
          cols = _createGridColumns(_columns, that.isCompare);

        // // afterAction
        // let logs = _onAfterAction(newLogs, _setting.addition.afterAction);

        // afterAction에서 사용할 오브젝트 생성
        let copyLogs = JSON.parse(JSON.stringify(newLogs));

        // 오브젝트 처리 및 afterAction 처리
        // let fnObj = utils.logsToObj(_setting.series, copyLogs);

        // afterAction 처리
        // let afterObj = utils.onAfterAction2(JSON.parse(JSON.stringify(fnObj)), _setting.addition.afterAction); // 오브젝트 반환

        // 처리된 obj 다시 logs 로 변환및 바인딩
        // utils.objToLogs(_setting.series, copyLogs, afterObj);

        let rtnRows = _bindGridData(copyLogs);

        // afterBind
        if (_setting.addition && _setting.addition.afterBind) {
          let groupRows = [];

          _setting.addition.afterBind.forEach((after) => {
            // 상단 row 생성
            let afterRow = {
              BaseLine: "" + after,
              Comparison: "Comparison",
              children: [{}],
            };

            // afterRow에 point를 key로 하여 value에 afterBind를 함.
            _setting.columns.forEach((column) => {
              // compare시
              if (that.isCompare && column.compare) {
                let value = that.onAfterBindForValue(
                  rtnRows.map((row) => row[column.point + "_C"]),
                  after
                );

                if (
                  _setting.addition.fixed != null &&
                  _setting.addition.fixed != undefined
                ) {
                  value = parseFloat(value).toFixed(_setting.addition.fixed);
                }

                afterRow[column.point + "_C"] = value;
              }
              // compare아닐때..

              let value = that.onAfterBindForValue(
                rtnRows.map((row) => row[column.point]),
                after
              );

              if (!that.isEmpty(_setting.addition.fixed)) {
                value = parseFloat(value).toFixed(_setting.addition.fixed);
              }

              afterRow[column.point] = value;
            });

            groupRows.push(afterRow);
          });

          if (groupRows.length > 0)
            groupRows[groupRows.length - 1] = Object.assign(
              groupRows[groupRows.length - 1],
              { children: rtnRows }
            );

          this.isGridGroup = true;
          this.gridTable = { columns: cols, rows: groupRows };
        } else {
          this.isGridGroup = false;
          this.gridTable = { columns: cols, rows: rtnRows };
        }
      } catch (err) {
        console.error(`${this.$options.name}`, err);
      }
    },
    //dnd - virtual
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "type": "bar", "label": "입구온도", "point": "084000001_0000_0000_VLI_00000001", "position": "Left", "backgroundColor": "#5499C7", "fill": false}

        points.map((point) => {
          if (
            that.widget.series.filter((v) => v.point === point.ptAddr).length ==
            0
          ) {
            // 중복 체크
            that.widget.series.push({
              type: "spline",
              label: point.title,
              point: point.ptAddr,
              position: "Left",
              backgroundColor: that.getRandomColor(),
              fill: false,
            });
          }
        });
      }

      let defaultObj = {
        type: "spline",
        label: "Default",
        point: "",
        position: "Left",
        backgroundColor: "rgba(52, 143, 226, 0.3)",
        fill: false,
        compare: false,
      };

      if (
        JSON.stringify(this.widget.series.slice(0, 1)) ==
        JSON.stringify([defaultObj])
      )
        this.widget.series = [];

      append(
        transfer.item.type === this.CODE.Drag.PointGroup
          ? transfer.item.points
          : [transfer.item]
      );
    },
  },
};
</script>
