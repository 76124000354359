<template>
  <div class="xeChartHorizontalBar heightMAX">
    <panel
      ref="panel"
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-5 chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop
            @dragover="handleTitleDragOver(...arguments)"
            @drop="handleTitleDrop(...arguments)"
          >
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop
        @dragover="handleBodyDragOver(...arguments)"
        @drop="handleBodyDrop(...arguments)"
        class="heightMAX"
      >
        <div v-if="isShow" class="heightMAX">
          <horizontal-bar-chart
            :chartData="horizonralBar.data"
            :options="horizonralBar.options"
            class="heightMAX"
            style="padding-right: '40px'"
          />
        </div>
      </drop>
    </panel>

    <drop
      v-else
      @dragover="handleBodyDragOver(...arguments)"
      @drop="handleBodyDrop(...arguments)"
      class="heightMAX"
    >
      <div v-if="isShow" class="m-b-20 heightMAX" :class="[inverseBg]">
        <h4>{{ $t(widget.title) }}</h4>
        <horizontal-bar-chart
          :chartData="horizonralBar.data"
          :options="horizonralBar.options"
          class="heightMAX"
        />
      </div>
    </drop>

    <b-modal
      ref="modalSetting"
      size="lg"
      title="설정 - Horizontal Bar 차트"
      bodyClass="p-t-0 p-b-0"
      hide-footer
    >
      <chart-horizontal-bar-setting
        :initSetting="chartSetting"
        @on-modal-cancel="onModalCancel"
        @on-modal-ok="onModalOK"
      />
    </b-modal>
  </div>
</template>

<script>
import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import HorizontalBarChart from "@src/components/vue-chartjs/HorizontalBarChart";
import chartHorizontalBarSetting from "@src/views/widget/v1/setting/xeChartHorizontalBarSetting.vue";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";

export default {
  name: "xeChartHorizontalBar",
  extends: xeBaseWidget,
  components: {
    HorizontalBarChart,
    chartHorizontalBarSetting,
  },
  data() {
    return {
      horizonralBar: {
        data: {},
        options: {},
      },
    };
  },
  computed: {
    chartSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  created() {
    if (!this.widget.series)
      this.widget.series = [
        { label: "Default", point: "", backgroundColor: "#00acac" },
        //        { label: "Default", point: "", backgroundColor: "#348fe2" },
 
      ];
    if (!this.widget.yAxes)
      this.widget.yAxes = { left: { label: "", stacked: false } };
    if (!this.widget.addition)
      this.widget.addition = {
        xAxes: { label: "", min: 0, max: 0, stacked: false },
        afterAction: "",
        fixed: 0,
        legend: { display: true, position: "bottom" },
      };
    if (!this.widget.self)
      this.widget.self = {
        baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
        compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
      };
  },
  mounted() {
    let horizontals = document.getElementsByClassName("xeChartHorizontalBar");

    for (let i = 0; i < horizontals.length; i++) {
      horizontals[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.horizonralBar.options = this.createChartOptions();

      this.horizonralBar.data = {
        labels: this.defaultLabels(),
        datasets: this.defaultDatasets(),
      };
    },
    defaultLabels() {
      return this.widget.series.map((item) => {
        return item.label;
      });
    },
    //chart data 기본값설정
    defaultDatasets() {
      return this.widget.series.map((item) => {
        return {
          label: item.label,
          backgroundColor: item.backgroundColor,
          yAxisID: "Left_yAxis",
          point: item.point,
        };
      });
    },
    //차트 상세 옵션 설정
    createChartOptions() {
      let leftAxis = this.widget.yAxes
        ? this.widget.yAxes.left
          ? this.widget.yAxes.left
          : ""
        : "";
      let xAxes =
        this.widget.addition && this.widget.addition.xAxes
          ? this.widget.addition.xAxes
          : "";
      // let rightAxis = this.widget.yAxes ? (this.widget.yAxes.right ? this.widget.yAxes.right : "") : "";

      leftAxis = leftAxis ? leftAxis : { label: "value1", stacked: false };
      // rightAxis = rightAxis ? rightAxis : { label: "value2", min: 0, max: 0, stacked: false, display: false };

      return {
        responsive: true,
        animation: { duration: 0 }, // 빠른 데이터 전환시 animation 오류 발생 (transition of null)
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: true,
          callbacks: {
            title: function() {
              return "";
            },
            label: (tooltipItem, data) => {
              let label = data.labels[tooltipItem.index];
              let currentValue =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              currentValue = this.comma(
                currentValue,
                this.widget.addition.fixed
              );

              if (!data.isPercentage) return `${label}: ${currentValue}`;
              return `${label}: ${currentValue}%`;
            },
          },
        },
        legend: {
          display: this.widget.addition.legend.display,
          position: this.widget.addition.legend.position,
        },
        scales: {
          xAxes: [
            {
              position: "bottom",
              scaleLabel: { display: true, labelString: xAxes.label || "" },
              ticks: {
                suggestedMin: xAxes.min,
                suggestedMax: xAxes.max,
                beginAtZero: true,
                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
              stacked: xAxes.stacked,
            },
          ],
          yAxes: [
            {
              // display: leftAxis.display,
              position: "left",
              id: "Left_yAxis",
              scaleLabel: {
                display: true,
                labelString: leftAxis.label,
                fontColor: "black",
              },
              stacked: leftAxis.stacked,
              mirror: true,
            },
            // {
            //   display: rightAxis.display,
            //   position: "right",
            //   id: "Right_yAxis",
            //   scaleLabel: { display: true, labelString: rightAxis.label, fontColor: "black" },
            //   stacked: rightAxis.stacked,
            //   gridLines: { drawOnChartArea: false }
            // }
          ],
        },
      };
    },
    // virtual
    applyData(widgetLogs) {
      this.isShow = false;

      this.horizonralBar.data.datasets = this.createDatasets(widgetLogs);
      this.widgetLogs = widgetLogs;

      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    //데이터 바인딩
    createDatasets(logs) {
      try {
        let datasets = this.defaultDatasets();
        // let log = logs[0];

        let datas = [];
        let backgroundColors = [];

        // 기존 로그 복사하여 데이터 변형 작업 진행
        let copyLogs = JSON.parse(JSON.stringify(logs));
        // afterAction 처리
        copyLogs = utils.onAfterAction2(
          datasets,
          copyLogs,
          this.widget.addition.afterAction
        );

        datasets.forEach((dataset) => {
          let ptVals = [];
          let ptVal = null;

          // compare 모드가 없어서 copyLogs[0]으로 고정함
          ptVals = copyLogs[0].points[dataset.point] || [];

          ptVals = this.isEmpty(ptVals) ? 0 : ptVals.sum();

          ptVal = utils.fixedValue(ptVals, this.widget.addition.fixed);
          datas.push(ptVal);
          backgroundColors.push(dataset.backgroundColor);
        });

        datasets = [{ data: datas, backgroundColor: backgroundColors }];

        return datasets;
      } catch (err) {
        console.error(`${this.$options.name} bindChartData`, err);
      }
    },
    // dnd
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "label": "냉각탑냉각능력(kcal/h)", "point": "084000001_0000_0000_VLI_00000001", "position": "Left", "backgroundColor": "#DAF7A6", "fill": false },

        points.map((point) => {
          if (
            that.widget.series.filter((v) => v.point === point.ptAddr).length ==
            0
          ) {
            // 중복 체크
            that.widget.series.push({
              label: point.title,
              point: point.ptAddr,
              position: "Left",
              backgroundColor: that.getRandomColor(),
            });
          }
        });
      }

      let defaultObj = {
        label: "Default",
        point: "",
        position: "Left",
        backgroundColor: "#348fe2",
      };

      // 기본 default이 series의 첫번재 값과 같으면 series를 초기화함
      if (
        JSON.stringify(this.widget.series.slice(0, 1)) ==
        JSON.stringify([defaultObj])
      )
        this.widget.series = [];
      append(
        transfer.item.type === this.CODE.Drag.PointGroup
          ? transfer.item.points
          : [transfer.item]
      );
    },
  },
};
</script>
