<template>
	<div>
		<div class="row form-group m-t-10">
			<div class="col-md-12">
				<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('general')"
					:class="{ 'xe-tab-line-orange': tabs.general }">
					<trans>일반</trans>
				</a>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-12">
				<div class="tab-content">
					<div class="tab-pane fade" :class="{ 'active show': tabs.general }">
						<label>&nbsp;</label>
						<div class="row form-group">
							<div class="col-12">
								<label>
									<h6>
										<i class="fa fa-check"></i>
										<trans>항목명</trans>
									</h6>
								</label>

								<div class="row form-group align-items-center">
									<div class="col-md-12">
										<valid-input :inputType="'text'" :inputValue.sync="copySetting.title"
											:rules="rules.DASHBOARD_WIDGET_ITEM_NAME_RULE" :classList="'form-control'" :NonStatus="true">
										</valid-input>
									</div>
								</div>
							</div>
							<div class="col-12">
								<label>
									<h6>
										<i class="fa fa-check"></i>
										<trans>기기일련번호</trans>
									</h6>
								</label>

								<div class="row form-group align-items-center">
									<div class="col-md-12">
										<select class="form-control" v-model="copySetting.equipIdx">
											<option v-for="(equipInfo, index) in equipInfoList" :key="index" :value="equipInfo.equipIdx">
												{{ equipInfo.equipName }}
											</option>
										</select>
									</div>
								</div>
							</div>
							<!-- <div class="col-12">
                <label>
                  <h6><i class="fa fa-check"></i>
                    <trans>설비 유형 코드</trans>
                  </h6>
                </label>

                <div class="row form-group align-items-center">
                  <div class="col-md-12">
                    <select class="form-control" v-model="copySetting.equipTypeCode">
                      <option v-for="(equipType, index) in equipTypeList" :key="index" :value="equipType.equipTypeCode">
                        {{ equipType.equipTypeCodeName }}
                      </option>
                    </select>
                  </div>
                  
                </div>
              </div> -->
						</div>
						<hr />
						<xe-modal-self :copySetting="copySetting" :isCompare="true" />
					</div>
				</div>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-md-12 p-0">
				<div slot="modal-footer" class="w-100">
					<button class="btn btn-primary m-r-5 pull-right" @click="onModalOK()">
						<trans>확인</trans>
					</button>
					<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()">
						<trans>취소</trans>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
import AppErrorHandler from "@src/appErrorHandler";
import * as rules from "@src/consts/ruleConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
	extends: xeBaseSetting,
	data() {
		return {
			tabs: {
				general: true,
			},
			// define the default value
			zoneIdx: null,
			// define options
			options: null,
			equipInfoList: null,
			rules,
			popupMessages
		};
	},
	components: {},
	async mounted() {
		this.equipInfoList = await this.getEquipInfoList();
	},
	methods: {
		toggleTabs(tab) {
			if (tab == "general") {
				this.tabs.general = true;
				this.tabs.point = false;
				this.tabs.processing = false;
				this.tabs.chartOptions = false;
			}
		},
		async getEquipInfoList() {
			let param = {
				equipTypeCode: "MSN",
				enabled: "Y",
			};

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.equipInfo.getEquipInfoList(param);
				return result.data;
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}
		},
	},
};
</script>
@src/views/widget/v1/common/xeBaseSetting.js