// import { Radar } from 'vue-chartjs'

// export default {
//   extends: Radar,
//   props: ['data', 'options'],
//   beforeDestroy() {
//     try{
//     if(this.$data._chart.destroy) this.$data._chart.destroy(); // where this.chart is a reference to an instance of a ChartJS object
//     }catch(err){
//       console.log("Radar destroy failed.")
//     }
//   },
//   mounted () {
//     this.renderChart(this.data, this.options)
//   }
// }


import VueChartJS from "vue-chartjs";

export default {
  extends: VueChartJS.Radar,
  mixins: [VueChartJS.mixins.reactiveProp],
  props: ['chartData', 'options'],
  computed:{
  },
  watch: {
    chartData: function(newData){
      // 깜빡임 없는 live 업데이트
      // https://stackoverflow.com/questions/43728332/vue-chart-js-chart-is-not-updating-when-data-is-changing
      this.$data._chart.data.datasets[0].data = newData;
      this.$data._chart.update();
    },
  },
  beforeDestroy() {
    if(this.$data._chart && this.$data._chart.destroy) this.$data._chart.destroy(); // where this.chart is a reference to an instance of a ChartJS object
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    render(){
      this.renderChart(this.chartData, this.options);
    },
  }
}