<template>
    <vue-gauge
      ref="ttest"
      :refid="gaugeID"
      :options="newOptions"
    ></vue-gauge>
</template>

<script>
import VueGauge from "./vueGauge/vueGague";
import uniqid from "uniqid";

export default {
  components: { VueGauge },
  props: ["needleData", "maxData"],
  data() {
    return {
      gaugeID: uniqid("gauge-"),
    };
  },
  computed: {
    newOptions() {
      let needleValue = Number(this.needleData);
      let maxData = Number(this.maxData.replace(/,/g, ""));

      return {
        needleValue: ((100 * needleValue) / maxData).toFixed(0),
        needleColor: "rgb(0,0,0)",
        arcDelimiters: [16, 33, 50, 67, 84], // NOTE - 최대 값에 따라 값이 변경되어야 함
        arcColors: [
          "#C5C5C5",
          "#C3A8A8",
          "#C38281",
          "#C55957",
          "#C12926",
          "#8D0400",
        ],
        rangeLabel: "",
      };
    },
  },
  created() {},
  mounted() {},
};
</script>
