<template>
  <div class="xePivotTable heightMAX">
    <panel
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-0 chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div v-if="isShow" class="heightMAX">
          <table class="table table-responsive table-bordered m-b-0" :style="{ height: chartHeight }">
            <tbody>
              <tr class="text-right" v-for="(row, i) in pivotTable.rows" :key="'r' + i">
                <td v-for="(column, j) in row" :key="'c' + j" class="width-200" :class="[column.css, j == 0 ? 'bg-silver text-center' : '']">{{ column.value }}</td>
              </tr>
              <tr class="text-right" v-if="totals.find((v) => v.sum)">
                <th v-for="(total, k) in totals" :key="'k' + k" :class="[total.css, k == 0 ? 'bg-silver text-center' : '']">
                  {{ total.sum }}
                </th>
              </tr>
              <tr class="text-right" v-if="totals.find((v) => v.avg)">
                <th v-for="(total, k) in totals" :key="'k' + k" :class="[total.css, k == 0 ? 'bg-silver text-center' : '']">
                  {{ total.avg }}
                </th>
              </tr>
              <tr class="text-right" v-if="totals.find((v) => v.min)">
                <th v-for="(total, k) in totals" :key="'k' + k" :class="[total.css, k == 0 ? 'bg-silver text-center' : '']">
                  {{ total.min }}
                </th>
              </tr>
              <tr class="text-right" v-if="totals.find((v) => v.max)">
                <th v-for="(total, k) in totals" :key="'k' + k" :class="[total.css, k == 0 ? 'bg-silver text-center' : '']">
                  {{ total.max }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </drop>
    </panel>

    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)">
      <div v-if="isShow" :class="[inverseBg]" class="m-b-20">
        <h4 v-if="widget.title">{{ $t(widget.title) }}</h4>

        <table class="table table-responsive table-bordered m-b-0 heightMAX" :style="{ height: chartHeight }">
          <tbody>
            <tr class="text-right" v-for="(row, i) in pivotTable.rows" :key="'r' + i">
              <td v-for="(column, j) in row" :key="'c' + j" :class="[column.css, j == 0 ? 'bg-silver text-center' : '']">{{ column.value || "&nbsp;" }}</td>
            </tr>
            <tr class="text-right" v-if="totals.find((v) => v.sum)">
              <th v-for="(total, k) in totals" :key="'k' + k" :class="[total.css, k == 0 ? 'bg-silver text-center' : '']">
                {{ total.sum }}
              </th>
            </tr>
            <tr class="text-right" v-if="totals.find((v) => v.avg)">
              <th v-for="(total, k) in totals" :key="'k' + k" :class="[total.css, k == 0 ? 'bg-silver text-center' : '']">
                {{ total.avg }}
              </th>
            </tr>
            <tr class="text-right" v-if="totals.find((v) => v.min)">
              <th v-for="(total, k) in totals" :key="'k' + k" :class="[total.css, k == 0 ? 'bg-silver text-center' : '']">
                {{ total.min }}
              </th>
            </tr>
            <tr class="text-right" v-if="totals.find((v) => v.max)">
              <th v-for="(total, k) in totals" :key="'k' + k" :class="[total.css, k == 0 ? 'bg-silver text-center' : '']">
                {{ total.max }}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" :title="`${$t('설정')} - PivotTable`" body-class="p-t-0 p-b-0" hide-footer>
      <pivot-table-setting :initSetting="pivotTableSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<style scoped>
tr td:nth-last-child(1) {
  font-weight: bold;
}
</style>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import pivotTableSetting from "@src/views/widget/v1/setting/xePivotTableSetting.vue";
import utils from "./xeBaseUtils.js";

export default {
  name: "xePivotTable",
  extends: xeBaseWidget,
  components: {
    pivotTableSetting,
  },
  data() {
    return {
      pivotTable: {
        rows: [],
      },
      totals: [],
    };
  },
  computed: {
    pivotTableSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  created() {
    if (!this.widget.columns) this.widget.columns = [{ label: "Default", point: "", compare: false }];
    if (!this.widget.addition) this.widget.addition = { afterAction: "", afterBind: [], fixed: 0 };
    if (!this.widget.self) this.widget.self = { baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" }, compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" } };
  },
  mounted(){
    let pivots = document.getElementsByClassName('xePivotTable');

    for(let i = 0; i < pivots.length; i++){
      pivots[i].parentNode.style.height = '100%';
    }
  },
  methods: {
    initSetting() {
      this.pivotTable.rows = [];
      this.pivotTable.rows = this.defaultPivotRows();
    },
    defaultPivotRows() {
      let rows = [];

      // column이 없을때 default값 생성
      if (this.widget.columns) {
        this.widget.columns
          .filter((v) => !v.compare || this.isCompare)
          .map((v) => {
            let row = [{ value: v.label }].concat({ value: "" }); // Empty Cell 내용없음.. 초기화값..
            rows.push(row);
          });
      } else {
        rows.push([{ value: "No Data" }]);
      }

      return rows;
    },
    applyData(widgetLogs) {
      this.pivotTable.rows = [];
      this.widgetLogs = widgetLogs;
      this.createPivotTableRows(widgetLogs);
    },
    createPivotTableRows(logs) {
      try {
        //condition으로 compare 비교 후 row데이터 생성
        logs.map((log, idx) => {
          let baselineRow = [{ css: "bg-silver", value: idx == 0 ? "Baseline" : "Comparison" }].concat(
            log.dates.map((v) => {
              return { css: "bg-silver", value: `${v[0] || ""} ${v[1] || ""}` };
            })
          );

          baselineRow.push({ css: "bg-silver font-weight-bold", value: "Total" });
          this.pivotTable.rows.push(baselineRow);

          this.widget.columns
            .filter((v) => {
              return idx == 0 ? !v.compare : v.compare;
            })
            .map((v) => {
              // 제목줄 추가
              let row = [{ value: v.label }];

              // 데이터 추가 - 후처리 afterAction
              let vals = [];
              let afterActionValues = utils.onAfterAction(v.point, log.points[v.point], this.widget.addition.afterAction);
              if (!this.isEmpty(afterActionValues)) {
                vals = afterActionValues.map((v) => {
                  if (this.widget.addition.fixed != null && this.widget.addition.fixed != undefined && v != null) {
                    v = this.comma(parseFloat(v), this.widget.addition.fixed);
                  }
                  return { value: v };
                });
              }
              // else
              // {
              //   console.log("xePivotTable afterActionValues=", afterActionValues);
              // }
              row = row.concat(vals);

              // 합산ROW 추가
              let sumRow = 0;
              for (let idx = 1; idx < row.length; idx++) {
                if (!this.isEmpty(row[idx].value)) sumRow += parseFloat(row[idx].value);
              }
              row.push({ value: sumRow });

              this.pivotTable.rows.push(row);
            });
        });

        this.totals = [];
        for (let i = 1; i < this.pivotTable.rows.length; i++) {
          let row = this.pivotTable.rows[i];

          // totals배열 초기화
          if (this.totals.length == 0) {
            let headerObject = { css: "bg-silver" };
            this.widget.addition.afterBind.map((v) => {
              headerObject[v.toLowerCase()] = v;
            });
            this.totals.push(headerObject); // 이름컬럼

            // afterBind row
            for (let j = 1; j < row.length; j++) {
              if (row[j].css) continue;
              let columnObject = { vals: [] };
              this.widget.addition.afterBind.map((v) => {
                columnObject[v.toLowerCase()] = 0;
              });

              //console.log("columnObject", columnObject);
              this.totals.push(columnObject);
            }
          }

          // 데이터를 컬럼별로 배열에 담아둔다.
          for (let j = 1; j < row.length; j++) {
            if (row[j].css) continue;
            let ptVal = row[j].value;

            // console.log("ptVal", ptVal);
            this.totals[j].vals.push(ptVal);
            //console.log("this.totals[j]", this.totals[j])
          }
        }

        //console.log('this.pivotTable.rows',  this.totals, this.totals.length, JSON.stringify(this.pivotTable.rows));

        // 각 컬럼별 통계를 실행한다.
        if (this.widget.addition && this.widget.addition.afterBind) {
          for (let i = 1; i < this.totals.length; i++) {
            this.widget.addition.afterBind.map((type) => {
              let value = utils.onAfterBind("", this.totals[i].vals, type);

              if (this.widget.addition.fixed != null && this.widget.addition.fixed != undefined) {
                value = parseFloat(value).toFixed(this.widget.addition.fixed);
              }

              this.totals[i][type.toLowerCase()] = value;
            });
          }
        }
      } catch (err) {
        console.error(`${this.$options.name} bindData`, err);
      }
    },
    // dnd
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "label": "냉난방 사용량", "point": "084000001_0000_0000_VLI_00000001" },

        points.map((point) => {
          if (that.widget.columns.filter((v) => v.point === point.ptAddr).length == 0) {
            // 중복 체크
            that.widget.columns.push({ label: point.title, point: point.ptAddr });
          }
        });
      }

      let defaultObj = { label: "Default", point: "", compare: false };

      if (JSON.stringify(this.widget.columns.slice(0, 1)) == JSON.stringify([defaultObj])) this.widget.columns = [];
      append(transfer.item.type === this.CODE.Drag.PointGroup ? transfer.item.points : [transfer.item]);
    },
  },
};
</script>
