
<template>
  <!-- class="heightMAX" -->
  <div
    class="xePeekCard kbet-custom-reset heightMAX "
    :style="{ overflow: 'hidden'}"
  >
    <drop
      @dragover="handleBodyDragOver(...arguments)"
      @drop="handleBodyDrop(...arguments)"
      class="heightMAX"
    >

      <div class="kbet-custom-reset heightMAX">
        <div class="rounded kbet-widget-energy v1 heightMAX" ><!-- :style="{backgroundColor: this.widget.card.backgroundColor}" -->
          <p class="title rounded-top" >
            {{$t(widget.title)}}
          </p>
          <div
            class="energy-usage-area"
            :style="{ height: '100%' }"
          >
            <div
              class="real-time"
              :style="{ position: 'relative', height: '100%', display: 'inline-grid'}"
            >
              <div :style="{ alignSelf: 'center', marginTop: '38%' }" >
                <div class="graph-area">
                  <gauge-chart
                    class="svg-box"
                    :needleData="peakData.currentPeak[0]"
                    :maxData="peakData.contractPeak[0]"
                  >
                  </gauge-chart>
                </div>
                <dl class="real-time-data" >
                  <dd ><span>{{$t('현재')}} :</span> <strong>{{ Number(peakData.currentPeak[0]).toLocaleString() }}</strong><span :style="{ fontSize: '16px', lineHeight: '23px'}">{{ peakData.currentPeak[1] }}</span></dd>
                </dl>
              </div>

            </div>
            <div class="divider-line"><span class="hidden">{{$t('구분선')}}</span></div>
            <div class="today-peak" >
              <dl class="real-time-data peak-v1">
                <dt :style="{fontSize: '12px'}">{{$t('오늘의 피크')}} ({{ peakData.maxPeakTime }})</dt>
                <dd>
                  <strong>{{ Number(peakData.maxPeak[0]).toLocaleString() }}</strong>
                  <span>{{ peakData.maxPeak[1] }}</span>
                </dd>
              </dl>
              <ul class="peak-v2">
                <li>
                  <dl :style="{fontSize: '12px'}"> 
                    <dt>{{$t('계약 전력 ')}}:</dt>
                    <dd>
                      <strong> {{ Number(peakData.contractPeak[0]).toLocaleString() }}</strong>
                      <span> {{ peakData.contractPeak[1] }}</span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl :style="{fontSize: '12px'}" >
                    <dt>{{$t('올해 피크')}} :</dt>
                    <dd>
                      <strong>{{ Number(peakData.maxPeakCurrentYear[0]).toLocaleString() }}</strong>
                      <span>{{ " " +peakData.maxPeakCurrentYear[1] }}</span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl :style="{fontSize: '12px'}" >
                    <dt>{{$t('작년 피크')}} :</dt>
                    <dd>
                      <strong> {{ Number(peakData.maxPeakPrevYear[0]).toLocaleString() }}
                      </strong>
                      <span> {{ peakData.maxPeakPrevYear[1] }}</span>
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </drop>

    <b-modal ref="modalSetting" size="lg" :title="`${$t('설정')} - ${$t('실시간 전력현황')}`" body-class="p-t-0 p-b-0" hide-footer >
      <peek-card-setting
        :initSetting="peekCardSetting"
        @on-modal-cancel="onModalCancel"
        @on-modal-ok="onModalOK"
      />
    </b-modal>
  </div>
</template>


<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import GaugeChart from "@components/vue-chartjs/Charts/gaugeChart.vue";
import PeekCardSetting from "./setting/xePeekSetting";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";

export default {
  name: "xePeekCard",
  extends: xeBaseWidget,
  components: {
    GaugeChart,
    PeekCardSetting,
  },
  data() {
    return {
      peakData: {
        currentPeak: ["1", ""],
        maxPeak: ["1", ""],
        maxPeakTime: "1",
        contractPeak: ["1", ""],
        maxPeakPrevYear: ["1", ""],
        maxPeakCurrentYear: ["1", ""],
      },
      testPeak: 100,
      themeColor : global.xe.parseBool(localStorage.contentInverse) ? require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark : require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light
    };
  },
  computed: {
    peekCardSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    master() {
      return this.$store.getters.master;
    },
  },
  created() {
    if (!this.widget.bgIcon) {
      this.widget.bgIcon = "material flash_on";
    }
    if (!this.widget.moved) {
      this.widget.moved = false;
    }
    if (!this.widget.zoneIdx) {
      this.widget.zoneIdx = 1;
    }
    if (!this.widget.objects) {
      this.widget.objects = {
        main: {
          label: "",
          addition: {
            afterAction: "",
            afterBind: "",
            isPercentage: true,
            fixed: 0,
          },
          tail: "",
          point: "",
          value: 0,
          self: { type: 0, sTi: "", eTi: "" },
        },
        bottom: {
          label: "",
          addition: {
            afterAction: "",
            afterBind: "",
            isPercentage: false,
            fixed: 0,
          },
          tail: "",
          point: "",
          value: 0,
          self: { type: 0, sTi: "", eTi: "" },
        },
        series: { backgroundColor: "" },
      };
    }
    if (!this.widget.card) {
      this.widget.card = {
        colorOpens: false,
        titleColor: "#000000FF",
        titleFontSize: 14,
        backgroundColor: "#3F3F3F",
        style: {
          main: {
            fontSize: "30",
            fontWeight: "600",
            fontColor: "#000000FF",
            unitSize: "15",
            unitColor: "#000000FF",
            iconColor: "#FFFFFFFF",
            textAlign: "center",
          },
          bottom: {
            fontSize: "15",
            fontWeight: "400",
            fontColor: "#000000FF",
            unitSize: "15",
            unitColor: "#000000FF",
            textAlign: "left",
          },
        },
      };
    }
    if (!this.widget.title) {
      this.widget.title="실시간 전력피크"
    }

  },
  mounted() {
    let lodCard = document.getElementsByClassName("xePeekCard");

    for (let i = 0; i < lodCard.length; i++) {
      lodCard[i].parentNode.style.height = "100%";
    }

    let svgs = document.getElementsByClassName("vue-gauge-item");

    for (let i = 0; i < svgs.length; i++) {
      if (svgs[i].childNodes[0] && svgs[i].childNodes[0].className.baseVal !== "svg-box-content") {
        svgs[i].childNodes[0].className.baseVal =
          svgs[i].childNodes[0].className.baseVal + "svg-box-content";
      }
    }

    let svgAttri = document.getElementsByTagName("svg");
    for (let i = 0; i < svgAttri.length; i++) {
      // if (svgAttri[i].width !== "100%" && svgAttri[i].height !== "100%") {
      //   svgAttri[i].removeAttribute("width");
      //   svgAttri[i].removeAttribute("height");
      //   svgAttri[i].setAttribute("width", "100%");
      //   svgAttri[i].setAttribute("height", "100%");
      //   svgAttri[i].setAttribute("viewBox", "0, 0, 250, 170");
      // }
    }
  },
  methods: {
    initSetting() {
      this.isPanelExpand = false;
      
    },
    applyData(data) {
      // this.peakData = data;
      // data = {
      //   contractPeak: "50 kw",
      //   currentPeak: "123456789.0 kW",
      //   maxPeakTime: "07:15",
      //   maxPeak: "4 kW",
      //   maxPeakPrevYear: "56.6 kW",
      //   maxPeakCurrentYear: "33.6 kW",
      // }

      // index[0] : value
      // index[1] : unit (kw)
      this.peakData = {
        currentPeak: data.currentPeak !== null ? data.currentPeak.split(" ") : ["-", ""],
        maxPeak: data.maxPeak !== null ? data.maxPeak.split(" ") : ["-", ""],
        maxPeakTime: data.maxPeakTime,
        contractPeak: data.contractPeak !== null ? data.contractPeak.split(" ") : ["-", ""],
        maxPeakPrevYear: data.maxPeakPrevYear !== null ? data.maxPeakPrevYear.split(" ") : ["-", ""],
        maxPeakCurrentYear: data.maxPeakCurrentYear !== null ? data.maxPeakCurrentYear.split(" ") : ["-", ""],
      };

      // this.peakData.maxPeakPrevYear = "342 kw";
      // this.peakData.maxPeakCurrentYear = "2,3312 kw";
      this.data = data;
    },
    getCond(logs, cond) {
      let rtnLogs = null;

      // 일자형식 스크립트 실행
      function runTi(type, ti) {
        let fn = new Function(
          "now",
          "baseline",
          "compare",
          "type",
          "return " + ti
        );
        let dt = fn(new Date(), type);

        return dt.normalize(type);
      }

      rtnLogs = logs.filter(
        (log) =>
          log.type == cond.type &&
          log.sDt == runTi(cond.type, cond.sTi) &&
          log.eDt == runTi(cond.type, cond.eTi) &&
          log.points[cond.point]
      );

      return rtnLogs;
    },
    bindCard(log, card) {
      let ptVals = [];

      if (Array.isArray(log)) ptVals = log[0].points[card.point];
      else ptVals = log.points[card.point];

      ptVals = utils.onAfterAction(
        card.point,
        ptVals,
        card.addition.afterAction
      ); // 배열반환

      let val = utils.onAfterBind(
        card.point,
        ptVals,
        card.addition ? card.addition.afterBind : null
      ); // 값반환

      card.value = val;
    },
    handleDropPoint(label, arg) {
      this.widget.title = arg.item.title;
      if (label == "main") {
        this.widget.title = arg.item.title;
        this.widget.objects.main = {
          label: "",
          addition: { afterAction: "", afterBind: "Last", isPercentage: false },
          tail: "",
          point: arg.item.ptAddr,
          value: 0,
        };

        // "main"  : { "label":"", "afterBind": "Last", "tail": "kWh", "point": "084000001_0000_0000_VLI_00000001" },
      } else {
        // bottom
        this.widget.title = arg.item.title;
        this.widget.objects.bottom = {
          label: "",
          addition: { afterAction: "", afterBind: "Last", isPercentage: false },
          tail: "",
          point: arg.item.ptAddr,
          value: 0,
        };
      }
    },

    // 드래그
    // handleDropPoint(transfer) {
    //   if (this.$err(transfer)) return;

    //   this.widget.title = transfer.item.title;
    //   this.widget.objects.main = { afterBind: "Last", tail: "", point: transfer.item.ptAddr, value: 0 };
    // },
  },
};
</script>

<style scoped>
/* .svg-box {
  width: 100%;
  height: 100%;
  position: relative;
} */

.svg-box-content {
  top: 0;
  left: 0;
}
</style>