<template>
  <div class="xeIndoorEnvInfo heightMAX rounded" style="overflow: hidden;">
    <div class="heightMAX">
      <!-- <div class="jvectormap-container " style="background-color: transparent; position:relative"> -->
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX kbet-custom-reset">
        <div class="rounded kbet-widget-energy monitoring heightMAX" style="display: block;">
          <p class="title">{{ $t(widget.title) }}</p>
          <div class="monitoring_wrap">
            <ul class="info">
              <li v-for="(data, i) in equipInfoList" :key="`info${i}`" :class="`info${i + 1 < 10 ? '0' + (i + 1) : i + 1}`">
                <dl>
                  <dt :style="{ wordBreak: 'keep-all' }">
                    {{ i === 0 ? equipInfo.equipName : data.name }}
                  </dt>
                  <dd>
                    {{ `${data.value} ${data.unit}` }}
                  </dd>
                </dl>
              </li>
              
              <!-- <li class="info01">에너지
                <dl>
                  <dt :style="{ wordBreak: 'keep-all' }">{{ equipInfo.equipName }}</dt>
                  <dd>{{ equipInfo.TEMP }}℃</dd>
                </dl>
              </li>
              <li class="info02">
                <dl>
                  <dt>습도</dt>
                  <dd>{{ equipInfo.HUMI }}%</dd>
                </dl>
              </li>
              <li class="info03">
                <dl>
                  <dt>CO2</dt>
                  <dd>{{ equipInfo.CO2 }}</dd>
                </dl>
              </li>
              <li class="info04">
                <dl>
                  <dt>미세먼지</dt>
                  <dd>{{ equipInfo.PM10 }}</dd>
                </dl>
              </li>
              <li class="info05">
                <dl>
                  <dt>초미세먼지</dt>
                  <dd>{{ equipInfo.PM20 }}</dd>
                </dl>
              </li> -->
            </ul>
          </div>
        </div>
      </drop>
    </div>

    <b-modal ref="modalSetting" size="sm" :title="`${$t('설정')} - ${$t('실내환경정보')}`" bodyClass="p-t-0 p-b-0" hide-footer>
      <indoor-env-info-setting :initSetting="setting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import indoorEnvInfoSetting from "./setting/xeIndoorEnvInfoSetting.vue";

export default {
  name: "xeBizInfo",
  props: ["showGrid", "widget", "cellSize", "on-remove-widget"],
  extends: xeBaseWidget,
  components: {
    indoorEnvInfoSetting,
  },
  created() {
    // console.log("xeIndoorEnvInfo widget created");
    if (!this.widget.equipIdx) {
      this.widget.equipIdx = 1;
    }

    if (!this.widget.equipTypeCode) {
      this.widget.equipTypeCode = "EHPO";
    }
  },
  mounted() {
    // console.log("xeIndoorEnvInfo widget mounted");
  },
  data() {
    return {
      equipInfoList: [],
      equipPropList: [],
      equipInfo: {
        HUMI: 0,
        PM10: 0,
        PM20: 0,
        CO2: 0,
        TEMP: 0,
        equipIdx: 0,
        equipName: "",
      },

      themeColor: global.xe.parseBool(localStorage.contentInverse)
        ? require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").dark
        : require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").light,
    };
  },
  computed: {
    setting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  methods: {
    applyData(data) {
      // console.log("xeIndoorEnvInfo widget methods applyData : " + data);
      this.equipPropList = data.propList;
      this.equipInfo = data.equipInfo;
      this.setDatas();
    },
    setDatas() {
      this.equipInfoList = [];

      for (let i = 0; i < this.equipPropList.length; i++) {
        this.equipInfoList.push({
          key: this.equipPropList[i].equipPropCode,
          name: this.equipPropList[i].equipPropName,
          unit: this.equipPropList[i].unitSmallCodeName,
        });
      }

      for (let i = 0; i < this.equipInfoList.length; i++) {
        this.equipInfoList[i]["value"] = this.equipInfo[this.equipInfoList[i].key];
      }
    },
  },
};
</script>

<style scoped></style>
