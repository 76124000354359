<template>
	<div>
			<div class="form-group row m-t-10">
				<div class="col-md-12">
					<a href="javascript:;" class="xe-tab-underline" v-on:click.prevent="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }"> <trans>일반</trans> </a>
				</div>
			</div>

			<div class="form-group row">
				<div class="col-md-12">
					<div class="tab-content m-0 p-0">
						<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.general }">
							<label>&nbsp;</label>

							<div class="form-group row">
								<div class="col-12">
									<label>
										<h6><i class="fa fa-check"></i><trans>항목명</trans></h6>
									</label>
									<valid-input :inputType="'text'" :inputValue.sync="copySetting.title" :rules="rules.DASHBOARD_WIDGET_ITEM_NAME_RULE" :classList="'form-control'" :NonStatus="true">
									</valid-input>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-12">
									<label>
										<h6><i class="fa fa-check"></i><trans>구역설정</trans></h6>
									</label>
									<tree-select
										v-model="copySetting.zoneIdx"
										:load-options="loadOptions"
										:placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
										:noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
										:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
										:multiple="false"
										:options="options" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group row">
				<div class="col-md-12 p-0">
					<div slot="modal-footer" class="w-100">
						<button type="button" class="btn btn-primary m-r-5 pull-right" @click="onModalOK()"  ><trans>확인</trans></button>
						<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()"><trans>취소</trans></button>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	export default {
		extends: xeBaseSetting,
		components: {},
		data() {
			return {
				tabs: {
					general: true,
					card: false,
					main: false,
					Sub: false,
				},
				// define the default value
				zoneIdx: null,
				// define options
				options: null,
				rules,
				popupMessages,
				controlMessages
			};
		},
		mounted() {
			this.getZoneListHierarchi();
		},
		methods: {
			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.card = false;
					this.tabs.main = false;
					this.tabs.Sub = false;
				} else if (tab == "card") {
					this.tabs.general = false;
					this.tabs.card = true;
					this.tabs.main = false;
					this.tabs.Sub = false;
				} else if (tab == "main") {
					this.tabs.general = false;
					this.tabs.card = false;
					this.tabs.main = true;
					this.tabs.Sub = false;
				} else if (tab == "Sub") {
					this.tabs.general = false;
					this.tabs.card = false;
					this.tabs.main = false;
					this.tabs.Sub = true;
				}
			},
			togglePercentage(card) {
				if (card == "main") {
					this.copySetting.objects.main.addition.isPercentage = !this.copySetting.objects.main.addition.isPercentage;
				} else {
					this.copySetting.objects.bottom.addition.isPercentage = !this.copySetting.objects.bottom.addition.isPercentage;
				}
			},
			onChangeIcon(value) {
				if (!this.isEmpty(value)) this.copySetting.bgIcon = value.icon;
				else this.copySetting.bgIcon = "";
			},
			onChangeProgressColor(idx, color) {
				//this.copySetting.bgColor = color;
				this.copySetting.card.style.progress.color = color;
			},
			// onChangeProgressBarColor(idx, color) {
			//   this.copySetting.pgbgColor = color;
			// },
			// onChangeColor(idx, color) {
			// },
			getZoneListHierarchi() {
				backEndApi.zoneInfo.getZoneListHierarchi().then(({ data }) => {
					this.options = [];

					let temp = [];
					temp.push(data);
					this.options = this.setZoneTreeOption(temp);
				});
			},
			setZoneTreeOption(resultData) {
				resultData.forEach((data) => {
					data.id = data.zoneIdx;
					data.label = data.zoneName;

					if (data["children"]) {
						return this.setZoneTreeOption(data["children"]);
					} else {
						return data;
					}
				});

				return resultData;
			},
		},
	};
</script>
@src/views/widget/v1/common/xeBaseSetting.js