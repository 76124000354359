<template>
	<div>
		<div class="row form-group m-t-10">
			<div class="col-md-12">
				<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }"><trans>일반</trans></a>
				<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('chartOptions')" :class="{ 'xe-tab-line-orange': tabs.chartOptions }"><trans>차트 옵션</trans></a>
				<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('point')" :class="{ 'xe-tab-line-orange': tabs.point }"><trans>관제점</trans></a>
				<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('processing')" :class="{ 'xe-tab-line-orange': tabs.processing }"><trans>데이터처리</trans></a>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-12">
				<div class="tab-content">
					<div class="tab-pane fade" :class="{ 'active show': tabs.general }">
						<label>&nbsp;</label>
						<div class="row form-group">
							<div class="col-12">
								<label>
									<h6><i class="fa fa-check"></i><trans>항목명</trans></h6>
								</label>

								<div class="row form-group align-items-center">
									<div class="col-md-6">
										<valid-input :inputType="'text'" :inputValue.sync="copySetting.title" :rules="rules.DASHBOARD_WIDGET_ITEM_NAME_RULE" :classList="'form-control'" :NonStatus="true">
										</valid-input>
									</div>
									<!-- <div class="col-md-3">
											<xe-scatch-color-picker
												:color="copySetting.panel.backgroundColor"
												:isOpen="copySetting.panel.colorOpens"
												:inputForm="'hex'"
												@input="copySetting.panel.backgroundColor = $event.hex8"
											>
											</xe-scatch-color-picker>
										</div>
										<div class="col-md-3">
											<xe-scatch-color-picker :color="copySetting.panel.fontColor" :isOpen="copySetting.panel.colorOpens" :inputForm="'hex'" @input="copySetting.panel.fontColor = $event.hex8">
											</xe-scatch-color-picker>
										</div> -->
								</div>
							</div>
						</div>
						<hr />
						<div class="row">
							<div class="col-12">
								<label>
									<h6><i class="fa fa-check"></i><trans>조회기간</trans></h6>
								</label>
							</div>
						</div>
						<xe-modal-self :copySetting="copySetting" :isCompare="true" />
						<hr />
					</div>

					<div class="tab-pane fade" :class="{ 'active show': tabs.chartOptions }">
						<label>&nbsp;</label>
						<div class="row form-group">
							<div class="col-12">
								<h6><i class="fa fa-check"></i><trans>차트 옵션</trans></h6>
							</div>
						</div>

						<!-- 공통 설정 -->
						<div class="row form-group">
							<div class="col-md-2">
								<label
									><b><trans>공통</trans></b></label
								>
							</div>
							<div class="col-md-10">
								<div class="row form-group align-items-center">
									<div class="col-md-5">
										<label><trans>단위</trans></label>
										<valid-input :inputType="'text'" :inputValue.sync="copySetting.chart.options.unit" :rules="rules.DASHBOARD_WIDGET_UNIT_RULE" :NonStatus="true" :classList="'form-control'">
										</valid-input>
									</div>
									<div class="col-md-5">
										<label><trans>배경 색상</trans></label>
										<xe-scatch-color-picker
											:color="copySetting.chart.backgroundColor"
											:isOpen="copySetting.chart.colorOpens"
											:inputForm="'hex'"
											@input="copySetting.chart.backgroundColor = $event.hex8"
										>
										</xe-scatch-color-picker>
									</div>
									<div class="col-md-5">
										<label><trans>폰트 색상</trans></label>
										<xe-scatch-color-picker
											:color="copySetting.chart.options.fontColor"
											:isOpen="copySetting.chart.colorOpens"
											:inputForm="'hex'"
											@input="copySetting.chart.options.fontColor = $event.hex8"
										>
										</xe-scatch-color-picker>
									</div>
									<div class="col-md-5 m-t-10">
										<label><trans>소수점자리</trans></label>
										<valid-input
											:inputType="'number'"
											:inputValue.sync="copySetting.addition.fixed"
											:classList="'form-control'"
											:NonStatus="false"
											:errorMessage="validMessage.WIDGET_DECIMAL_AND_FONTSIZE_LENGTH_VALID_MESSAGE"
											:rules="rules.DASHBOARD_WIDGET_DECIMAL_RULE"
										>
										</valid-input>
									</div>
									<div class="col-md-2 m-t-10">
										<label>&nbsp;</label>
										<div class="checkbox checkbox-css">
											<input type="checkbox" :id="'chart_isPercentage' + copySetting.id" v-model="copySetting.chart.options.isPercentage" />
											<label :for="'chart_isPercentage' + copySetting.id"><trans>백분율</trans></label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- //공통 설정 -->

						<!-- 범례 설정 -->
						<div class="row form-group">
							<div class="col-md-2">
								<label
									><b><trans>범례</trans></b></label
								>
							</div>
							<div class="col-md-10">
								<div class="row form-group align-items-center">
									<div class="col-md-2">
										<label><trans>위치</trans></label>
										<select class="form-control" v-model="copySetting.chart.options.legend.position">
											<option value="top">{{ $t("상단") }}</option>
											<option value="bottom">{{ $t("하단") }}</option>
											<option value="left">{{ $t("좌측") }}</option>
											<option value="right">{{ $t("우측") }}</option>
										</select>
									</div>
									<div class="col-md-2">
										<label><trans>정렬</trans></label>
										<select class="form-control" v-model="copySetting.chart.options.legend.align">
											<option value="start">{{ $t("시작") }}</option>
											<option value="center">{{ $t("중앙") }}</option>
											<option value="end">{{ $t("끝") }}</option>
										</select>
									</div>
									<div class="col-md-2">
										<label><trans>폰트 크기</trans></label>
										<valid-input
											:inputType="'number'"
											:inputValue.sync="copySetting.chart.options.chartFontSize"
											:rules="rules.DASHBOARD_WIDGET_FONTSIZE_RULE"
											:errorMessage="validMessage.WIDGET_DECIMAL_AND_FONTSIZE_LENGTH_VALID_MESSAGE"
										>
										</valid-input>
									</div>
									<div class="col-md-3">
										<label><trans>폰트 색상</trans></label>
										<xe-scatch-color-picker
											:color="copySetting.chart.options.legend.labels.fontColor"
											:isOpen="copySetting.chart.colorOpens"
											:inputForm="'hex'"
											@input="copySetting.chart.options.legend.labels.fontColor = $event.hex8"
										>
										</xe-scatch-color-picker>
									</div>
									<div class="col-md-2">
										<label>&nbsp;</label>
										<div class="checkbox checkbox-css">
											<input type="checkbox" :id="'legend_display' + copySetting.id" v-model="copySetting.chart.options.legend.display" />
											<label :for="'legend_display' + copySetting.id"><trans>화면표시</trans></label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- //범례 설정 -->

						<hr />
					</div>

					<div class="tab-pane fade" :class="{ 'active show': tabs.processing }">
						<div class="row form-group">
							<div class="col-md-12">
								<label
									><b><trans>후처리</trans></b></label
								>
								<valid-textarea
									:inputType="'text'"
									:inputValue.sync="copySetting.addition.afterAction"
									:classList="'form-control'"
									:rows="5"
									:NonStatus="true"
									:rules="rules.DASHBOARD_WIDGET_POSTPROCESS_RULE"
								>
								</valid-textarea>
							</div>
						</div>
						<label
							><b><trans>취합방법</trans></b></label
						>
						<div class="row form-group">
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'piechart_afterBind_Last' + copySetting.id" value="Last" v-model="copySetting.addition.afterBind" />
									<label :for="'piechart_afterBind_Last' + copySetting.id">{{$t('마지막(Last)')}}</label>
								</div>
							</div>
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'piechart_afterBind_Min' + copySetting.id" value="Min" v-model="copySetting.addition.afterBind" />
									<label :for="'piechart_afterBind_Min' + copySetting.id">{{$t('최소(Min)')}}</label>
								</div>
							</div>
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'piechart_afterBind_Max' + copySetting.id" value="Max" v-model="copySetting.addition.afterBind" />
									<label :for="'piechart_afterBind_Max' + copySetting.id">{{$t('최대(Max)')}}</label>
								</div>
							</div>
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'piechart_afterBind_Sum' + copySetting.id" value="Sum" v-model="copySetting.addition.afterBind" />
									<label :for="'piechart_afterBind_Sum' + copySetting.id">{{$t('합계(Sum)')}}</label>
								</div>
							</div>
							<div class="col-md-2">
								<div class="radio radio-css">
									<input type="radio" :id="'piechart_afterBind_Avg' + copySetting.id" value="Avg" v-model="copySetting.addition.afterBind" />
									<label :for="'piechart_afterBind_Avg' + copySetting.id">{{$t('평균(Avg)')}}</label>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" :class="{ 'active show': tabs.point }">
						<div v-for="(series, i) in copySetting.chart.series" :key="i">
							<div class="row form-group">
								<div class="col-md-6">
									<label><trans>표시명</trans> {{ i + 1 }}</label>
									<xe-scatch-color-picker :color="series.dataColor" :isOpen="copySetting.chart.colorOpens" :inputForm="'label'" :text="series.label" @input="linePicker($event, series)">
									</xe-scatch-color-picker>
								</div>
								<div class="col-md-5">
									<label><trans>관제점</trans></label>
									<div :style="{ fontWeight: 'normal' }">
										<tree-select
											class="col-12 pr-0 pl-0"
											v-model="series.point"
											:options="searchPointInfoOptions"
											:disable-branch-nodes="true"											
											:open-on-focus="true"
											:placeholder="$t(controlMessages.POINT_TREE_PLACEHOLDER_MESSAGE)"
											:noChildrenText="$t(controlMessages.POINT_TREE_NOCHILDERN_MESSAGE)"
											:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
										/>
									</div>
								</div>
								<div class="col-md-1">
									<label>&nbsp;</label>
									<b-dropdown text="" variant="default" toggle-class="" class="pull-left" style="margin-top: 25px">
										<b-dropdown-item @click="toggleCompare(i)"
											><i class="fa" :class="{ 'fa-check': series.compare }"></i>
											<trans>비교</trans>
										</b-dropdown-item>
										<b-dropdown-item @click="deleteSeries(i)">
											<trans>삭제</trans>
										</b-dropdown-item>
									</b-dropdown>
								</div>
							</div>
							<legend></legend>
						</div>
						<div class="row form-group m-0">
							<button class="btn btn-white m-auto" @click="insertSeries()">
								<i class="fa fa-plus width-100"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-md-12 p-0">
				<div slot="modal-footer" class="w-100">
					<button class="btn btn-primary m-r-5 pull-right" @click="onModalOK()">
						<trans>확인</trans>
					</button>
					<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()">
						<trans>취소</trans>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@src/api/backEndApi";
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	import xeScatchColorPicker from "../common/xeScatchColorPicker.vue";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	export default {
		extends: xeBaseSetting,
		data() {
			return {
				tabs: {
					general: true,
					processing: false,
					point: false,
					chartOptions: false,
				},
				searchPoint: null,
				searchPointInfoOptions: [],
				rules,
				validMessage,
				popupMessages,
				controlMessages
			};
		},
		components: {
			xeScatchColorPicker,
		},
		mounted() {
			this.getSearchPoint();
		},
		methods: {
			linePicker(e, series) {
				if (typeof e === "string") {
					series.label = e;
				} else {
					series.dataColor = e.hex8;
				}
			},
			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.point = false;
					this.tabs.processing = false;
					this.tabs.chartOptions = false;
				} else if (tab == "processing") {
					this.tabs.general = false;
					this.tabs.point = false;
					this.tabs.processing = true;
					this.tabs.chartOptions = false;
				} else if (tab == "point") {
					this.tabs.general = false;
					this.tabs.point = true;
					this.tabs.processing = false;
					this.tabs.chartOptions = false;
				} else if (tab == "chartOptions") {
					this.tabs.general = false;
					this.tabs.point = false;
					this.tabs.processing = false;
					this.tabs.chartOptions = true;
				}
			},
			insertSeries() {
				this.copySetting.chart.series.push({
					label: "Default",
					point: "",
					dataColor: "#348fe2",
					compare: false,
				});
			},
			deleteSeries(idx) {
				this.copySetting.chart.series.splice(idx, 1);
			},
			toggleCompare(idx) {
				this.copySetting.chart.series[idx].compare = !this.copySetting.chart.series[idx].compare;
			},
			getSearchPoint() {
				this.searchPointInfoOptions = [];

				backEndApi.pointInfo.getpointByGroup().then(({ data }) => {
					this.searchPoint = data;

					this.searchPoint.map((point) => {
						const pointList = point.pointList;
						const groupObject = {
							id: point.groupCode,
							label: point.groupCodeName,
							children: [],
						};

						for (let i = 0; i < pointList.length; i++) {
							groupObject.children.push({
								id: pointList[i].ptAddr,
								label: pointList[i].ptName,
							});
						}

						this.searchPointInfoOptions.push(groupObject);
					});

					this.searchPointInfoOptions.sort((a, b) => {
						return a.label.localeCompare(b.label);
					});

					this.searchPointInfoOptions.unshift({
						id: "",
						label: "전체",
					});
				});
			},
		},
	};
</script>
@src/views/widget/v1/common/xeBaseSetting.js