
<template>
  <div class="xeUsageChart heightMAX" :style="{ overflow: 'hidden' }">
    <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div class="kbet-custom-reset heightMAX">
        <div class="rounded kbet-widget-energy v2 heightMAX" :style="{ display: 'block', minHeight: '100px' }">
          <!-- ,  backgroundColor: this.widget.card.backgroundColor -->
          <p class="title rounded-top">{{ $t(widget.title) }}</p>
          <div class="energy-usage-area heightMAX" :style="{ minHeight: '100px', display: 'flex', justifyContent: 'center', height: 'calc(100% - 80px)', paddingTop: `20px`}">
            <div :style="{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}" >
              <dl v-for="(useData, idx) in usageData" :key="`usageArea${idx}`" :class="`${useData.divCd}-area`" class="usage-area halfheightMAX" 
                :style="{display: 'flex', margin:'5px 0 5px 0', minHeight:'40px', alignItems: 'center', }" >
              <!-- paddingTop: `${usageData.length >= 4 ? 0 : (40 / Number(usageData.length))}px` -->
              <!-- marginTop: `${usageData.length >= 4 ? idx === 0 ? '10' : (-(3 * Number(usageData.length))) : 0}px` -->
              <!-- {{ `${usageData.length >= 4 ? idx === 0 ? '10' : (-(2 * Number(usageData.length))) : (40 / Number(usageData.length))}px` }} -->
                <dt class="col-2" :style="{ margin: '0px', width: 'fit-content', textAlign: 'right', paddingBottom: '11px' }">
                  <!-- <i class="icon-img" :style="{ marginRight: '6px' }">
                    <img v-if="useData.srcPath" :src="useData.srcPath" alt="" width="64" height="64" />
                    <img v-else src="" alt="" width="64" height="64" />
                  </i> -->
                  {{ useData.divCdName }}
                  <!-- 도시가스 -->
                </dt>
                <dd class="heightMAX col-7" :style="{ width: 'calc(100% - 165px)', marginRight: '10px' }">
                  <div class="graph-area" style="height: 100%;">
                    <div class="graph-bar" style="height: 100%; min-height: 40px">
                      <!-- <usage-chart :usageData="useData" :graphColor="'#38E54D'" :style="{ height: '100%'}"></usage-chart> -->
                      <usage-chart :usageData="useData" :graphColor="itemColors[useData.divCd]" :usageMaxValue="usageMaxValue" :style="{ height: '100%'}"></usage-chart>
                    </div>
                  </div>
                </dd>

                <dt class="col-3" :style="{ margin: '0px', width: 'fit-content', color: '#0f0f0f', paddingBottom: '11px' }" :ref="useData.divCd.toLowerCase()">
                  <span class="elec-amount">{{ useData.currentConsumpt }}</span>
                  <!-- <strong>2342.2</strong> -->

                  <span class="unit-text"> kWh</span>
                </dt>
              </dl>

              <!-- <dl class="usage-area electricity-area halfheightMAX" :style="{ minHeight: '50px', height: '50px', display: 'flex' }">
                <dt :style="{ margin: '0px', width: 'fit-content' }">
                  <i class="icon-img" :style="{ marginRight: '6px' }">
                    <img src="assets/xems/common/widget/usageChart/icon_energy_elec.png" alt="" width="64" height="64" />
                  </i>
                  전기
                </dt>
                <dd class="heightMAX" :style="{ width: 'calc(100% - 165px)', marginRight: '10px' }">
                  <div class="graph-area" style="height: 100%; padding-bottom: 14px;">
                    <div class="graph-bar" style="height: 100%; min-height: 30px">
                      <usage-chart ref="myChartA" id="myChartA" :usageData="usageData.elecData" :style="{ height: '100%' }"></usage-chart>
                    </div>
                  </div>
                </dd>
                <dt :style="{ margin: '0px', width: 'fit-content', color: '#0f0f0f' }" ref="elec">
                  <strong>{{ usageData.elecData.currentConsumpt }}</strong>
                  <span :style="{ fontSize: '14px' }"> kWh</span>
                </dt>
              </dl> -->

              <!-- <dl class="usage-area gas-area halfheightMAX" :style="{ minHeight: '50px', height: '50px', display: 'flex' }">
                <dt :style="{ margin: '0px', width: 'fit-content' }">
                  <i class="icon-img" :style="{ marginRight: '6px' }">
                    <img src="assets/xems/common/widget/usageChart/icon_energy_gas.png" alt="" width="64" height="64" />
                  </i>
                  가스
                </dt>
                <dd class="heightMAX" :style="{ width: 'calc(100% - 165px)', marginRight: '10px' }">
                  <div class="graph-area" style="height: 100%; padding-bottom: 14px;">
                    <div class="graph-bar" style="height: 100%; min-height: 30px">
                      <usage-chart id="myChartB" :usageData="usageData.gasData" :style="{ height: '100%' }"> </usage-chart>
                    </div>
                  </div>
                </dd>
                <dt :style="{ margin: '0px', width: 'fit-content', color: '#0f0f0f' }" ref="gas">
                  <strong>{{ usageData.gasData.currentConsumpt }}</strong>
                  <span :style="{ fontSize: '14px' }"> Nm³</span>
                </dt>
              </dl> -->
            </div>
          </div>
          <p class="comparison" :style="{ fontSize: '12px' }">
            <strong>{{$t('동시간대비교')}} :</strong>
            <span class="comparison-point yesterday">
              <i class="arrow-point"><span class="hidden">어제 포인트 아이콘</span></i
              >{{$t('전일')}}
            </span>
            <span class="comparison-point last-year">
              <i class="arrow-point"><span class="hidden">작년 포인트 아이콘</span></i
              >{{$t('전년')}}
            </span>
          </p>
        </div>
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" :title="`${$t('설정')} - ${$t('오늘의 에너지 사용량')}`" body-class="p-t-0 p-b-0" hide-footer>
      <usage-chart-setting :initSetting="usageSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import UsageChart from "@components/vue-chartjs/Charts/UsageChart.vue";
import UsageChartSetting from "@views/widget/v1/setting/xeUsageSetting.vue";

export default {
  name: "xeUsageChart",
  extends: xeBaseWidget,
  components: {
    UsageChart,
    UsageChartSetting,
  },
  data() {
    return {
      imgBnW: localStorage.contentInverse,
      itemColors: {
        'Elec': '#FD841F',
        'Gas': '#FD841F',
        'Lpg': '#FD841F',
        'Lng': '#FD841F',
        'LocalHeat': '#FD841F',
      },
      usageData: [],
      usageMaxValue: 0,
      // usageData: {
      //   elecData: {
      //     regDt: "",
      //     currentConsumpt: 0,
      //     currentConsumptcDay: 0,
      //     prevConsumpt: 0,
      //     prevConsumptDay: 0,
      //     lastConsumpt: 0,
      //     lastConsumptDay: 0,
      //   },
      //   gasData: {
      //     regDt: "",
      //     currentConsumpt: 0,
      //     currentConsumptcDay: 0,
      //     prevConsumpt: 0,
      //     prevConsumptDay: 0,
      //     lastConsumpt: 0,
      //     lastConsumptDay: 0,
      //   },
      // },

      chartJs: {
        data: {},
        options: {},
      },
      viewMode: "Chart", // 'Chart', 'Grid'
      chartLegend: null,
      themeColor: global.xe.parseBool(localStorage.contentInverse)
        ? require("@components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").dark
        : require("@components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").light,
    };
  },
  computed: {
    usageSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    master() {
      return this.$store.getters.master;
    },
  },
  watch: {
    "localStorage.contentInverse": function() {},
  },
  created() {
    if (!this.widget.bgIcon) {
      this.widget.bgIcon = "material sort";
    }
    if (!this.widget.moved) {
      this.widget.moved = false;
    }

    if (!this.widget.zoneIdx) {
      this.widget.zoneIdx = 1;
    }

    if (!this.widget.objects) {
      this.widget.objects = {
        main: {
          label: "",
          addition: {
            afterAction: "",
            afterBind: "",
            isPercentage: true,
            fixed: 0,
          },
          tail: "",
          point: "",
          value: 0,
          self: { type: 0, sTi: "", eTi: "" },
        },
        bottom: {
          label: "",
          addition: {
            afterAction: "",
            afterBind: "",
            isPercentage: false,
            fixed: 0,
          },
          tail: "",
          point: "",
          value: 0,
          self: { type: 0, sTi: "", eTi: "" },
        },
        series: { backgroundColor: "" },
      };
    }
    if (!this.widget.card) {
      this.widget.card = {
        colorOpens: false,
        titleColor: "#000000FF",
        titleFontSize: 14,
        backgroundColor: "#3F3F3F",
        fontColor: "#000000",
        style: {
          main: {
            fontSize: "30",
            fontWeight: "600",
            fontColor: "#000000FF",
            unitSize: "15",
            unitColor: "#000000FF",
            iconColor: "#FFFFFFFF",
            textAlign: "center",
          },
          bottom: {
            fontSize: "15",
            fontWeight: "400",
            fontColor: "#000000FF",
            unitSize: "15",
            unitColor: "#000000FF",
            textAlign: "left",
          },
        },
      };
    }
    if (!this.widget.title) {
      this.widget.title = "오늘의 에너지 사용량";
    }
  },
  mounted() {
    let lines = document.getElementsByClassName("xeUsageChart");

    for (let i = 0; i < lines.length; i++) {
      lines[i].parentNode.style.height = "100%";
    }
  },

  methods: {
    initSetting() {
      this.isPanelExpand = false;
    },
    // virtual
    applyData(data) {
      this.setData(data);
      this.data = data;
    },
    setData(data) {
      this.usageData = [];
      for (let i = 0; i < data.length; i++) {
        this.usageData.push(data[i]);

        if (i == 0) {
          this.usageData[i]["srcPath"] = "assets/xems/common/widget/usageChart/icon_energy_elec.png";
        } else if (i == 1) {
          this.usageData[i]["srcPath"] = "assets/xems/common/widget/usageChart/icon_energy_gas.png";
        }
      }

      this.usageMaxValue = 0;
      let valueList = [];
      for(let i = 0; i < this.usageData.length; i++){
        const keys = Object.keys(this.usageData[i]);

        keys.forEach(key => {
          valueList.push(this.usageData[i][key]);
        })
      }

      valueList = valueList.filter(value => typeof value === 'number');
      this.usageMaxValue = Math.max(...valueList);

      if(this.usageMaxValue < 100) this.usageMaxValue = 100;


      // return {
      //   elecData: {
      //     regDt: data[0].regDt,
      //     currentConsumpt: data[0].currentConsumpt,
      //     currentConsumptcDay: data[0].currentConsumptcDay,
      //     prevConsumpt: data[0].prevConsumpt,
      //     prevConsumptDay: data[0].prevConsumptDay,
      //     lastConsumpt: data[0].lastConsumpt,
      //     lastConsumptDay: data[0].lastConsumptDay,
      //   },
      //   gasData: {
      //     regDt: data[1].regDt,
      //     currentConsumpt: data[1].currentConsumpt,
      //     currentConsumptcDay: data[1].currentConsumptcDay,
      //     prevConsumpt: data[1].prevConsumpt,
      //     prevConsumptDay: data[1].prevConsumptDay,
      //     lastConsumpt: data[1].lastConsumpt,
      //     lastConsumptDay: data[1].lastConsumptDay,
      //   },
      // };
    },
  },
};
</script>

<style scoped>
.arrow-up {
  width: 20px;
  height: 18px;
  margin-right: 3px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid black;
}

.innerIconName {
  display: flex;
  align-content: center;
  text-align: center;
}
</style>
