const { isEmpty } = global.xe;

export default {
  // 배열입력 -> 배열반환
  onAfterAction(ptAddr, ptVals, afterAction) {
    if (isEmpty(ptVals)) {
      return ptVals;
    }
    if (isEmpty(afterAction)) {
      return ptVals;
    }

    let value = ptVals;

    try {
      let script = "";
      script += "let rtn = null;\n";
      script += "try{\n";
      script += "\t rtn = (" + afterAction + ");\n";
      script += "}catch(err){\n\tconsole.warn('afterAction script run error ', err); \n}\n";
      script += "finally{ \nreturn rtn;\n }";

      let fn = new Function("ptAddr", "value", script);
      let rtn = fn(ptAddr, value);

      // 배열처리
      if (!Array.isArray(rtn)) {
        rtn = [rtn];
      }
      // 배열반환
      return rtn;
    } catch (err) {
      //console.warn("onAfterAction ptVals", ptVals);
      //console.warn("onAfterAction script error=", script, err);
      return value;
    }
  },
  onAfterAction2(datasets, logs, afterAction) {
    if (isEmpty(afterAction)) return logs;

    // 오브젝트 처리
    let fnObj = this.logsToObj(datasets, logs);

    try {
      let script = "";
      script += "let rtn = null;\n";
      script += "try{\n";

      script += "\t  Object.entries(fnObj).forEach(([key, value], index) => { \n";
      script += "\t    if (index > 1) { \n";
      script += "\t       for(let i = 0; i < fnObj[`v${index - 1}`].length; i++){ \n";
      script += "\t          if(value[i] === null){ \n";
      script += "\t            value[i] = 'null'\n";
      script += "\t          } \n";
      script += "\t       }\n";
      script += "\t      this[`v${index - 1}`] = value;\n";
      script += "\t    }\n";
      script += "\t  });\n";

      script += "\t" + afterAction + ";\n";

      script += "\t  Object.entries(fnObj).forEach(([key, value], index) => { \n";
      script += "\t    \n";
      script += "\t    if (index > 1) { \n";
      script += "\t       for(let i = 0; i < fnObj[`v${index - 1}`].length; i++){ \n";
      script += "\t          if(isNaN(fnObj[`v${index - 1}`][i])){ \n";
      script += "\t             fnObj[`v${index - 1}`][i] = null\n";
      script += "\t          } else {\n";
      script += "\t             fnObj[`v${index - 1}`][i] = this[`v${index - 1}`][i]\n";
      script += "\t          }\n";
      script += "\t       }\n";
      script += "\t    }\n";
      script += "\t  });\n";

      script += "\t \n";
      script += "}catch(err){\n\tconsole.warn('afterAction script run error ', err); \n}\n";
      script += "finally{ \nreturn fnObj;\n }";

      let fn = new Function("fnObj", script);

      let rtn = fn(fnObj);

      // 처리된 obj 다시 logs 로 변환및 바인딩
      return this.objToLogs(datasets, logs, rtn);
    } catch (err) {
      console.warn(err);
      return logs;
    }
  },
  // 배열입력 -> 값반환
  onAfterBind(ptAddr, ptVals, afterBind = "Last") {
    // switch(widget.type){
    //   case "xeChartLine":
    //   case "xeChartScatter":
    //   case "xeChartHorizontalBar":
    //     {
    //       // afterBind 처리 없음
    //     } break;

    //   case "xeGridTable":
    //   case "xePivotTable":
    //     {
    //       ptVals = utils.onAfterBind(ptAddr, ptVals, widget.addition.afterBind);
    //     } break;

    //   case "xeChartPie":
    //   case "xeChartGauge":
    //   case "xeChartRadar":
    //   case "xeChartDonut":
    //   case "xeRankCard":
    //     {
    //       ptVals = utils.onAfterBind(ptAddr, ptVals, widget.addition.afterBind);
    //     } break;

    //   case "xeEnergyCard":
    //   case "xeGoalCard":
    //   case "xeSimpleCard":
    //     {
    //       ptVals = utils.onAfterBind(ptAddr, ptVals, widget.addition.afterBind);
    //     } break;
    // }

    // 후처리함수 :  파이,게이지,레이더등 단일값을 반환한다.
    if (typeof ptVals === "number") {
      return ptVals;
    }
    if (ptVals === undefined || ptVals === null) {
      console.warn(`후처리 ptVals값이 '${ptVals}' 입니다. 기본값 0을 반환합니다.`);
      return 0;
    }

    if (!Array.isArray(ptVals)) {
      console.warn(`후처리 ptVals값이 배열형식이 아닙니다.`, typeof ptVals, ptVals);
      // throw `ptVals값이 배열형식이 아닙니다.`;
      return 0;
    }
    if (ptVals.length == 0) {
      //console.warn(`후처리 ptVals값이 배열에 크기가 0입니다.`, typeof ptVals, ptVals);
      return 0;
    }

    let arr = ptVals.filter((v) => !isEmpty(v));
    if (arr.length == 0) return 0;

    let val = 0;

    switch (afterBind) {
      case "First":
        {
          val = arr.first();
        }
        break;
      case "Last":
        {
          val = arr.last();
        }
        break;
      case "Min":
        {
          val = arr.min();
        }
        break;
      case "Max":
        {
          val = arr.max();
        }
        break;
      case "Sum":
        {
          val = arr.sum();
        }
        break;
      case "Avg":
        {
          val = arr.avg();
        }
        break;
      default:
        {
          val = arr.last();
        }
        break;
      //default: { console.warn("후처리 동작이 정의되지 않은 방식입니다. => ", afterBind); } break;
    }
    //if(isEmpty(val)) console.warn(`후처리 결과값이 null입니다.`, val);
    // let test = [];
    // test.push(arr.first());
    // test.push(arr.last());
    // test.push(arr.min());
    // test.push(arr.max());
    // test.push(arr.sum());
    // test.push(arr.avg());


    return val || 0;
  },
  // 배열입력 -> 배열반환
  // 값  입력 -> 값반환
  fixedValue(data, fixed) {
    if (isEmpty(data)) {
      return data;
    }

    if (Array.isArray(data)) {
      // 배열 입력이면 배열반환
      return data.map((val) => {
        // [ 1, null, 5] 이런식의 데이터도 허용임..
        // 따라서, null도 사용해야 함. filter하지 말것..
        if (!isEmpty(val)) return parseFloat(val.toFixed(fixed));
      });
    } else {
      // 값 입력이면 값반환
      return parseFloat(data.toFixed(fixed));
    }
  },
  // logs를 obj로 변경
  // logs = [{ points: { '00001':[], '00002':[] }  }, { points: { '00001':[], '00002':[] }  }]

  //let obj = {};

  //obj.v1 = logs[0].points['00001'];
  //obj.v2 = logs[1].points['00001'];
  logsToObj(datasets, logs) {
    let obj = { info: {} };

    // 날짜 데이터 바인딩
    obj["vb"] = logs[0].rows;
    if (logs.length > 1) obj["vc"] = logs[1].rows;

    // logs의 포인트별 배열값을 오브젝트에 할당하여 넘긴다.
    datasets.map((dataset, idx) => {
      let log = dataset.compare ? logs[1] : logs[0];
      let ptVals = [];

      if (log && log.hasOwnProperty("points")) ptVals = log.points[dataset.point] || [];

      // v1, v2, v3 ...에 조회 데이터 바인딩
      obj[`v${idx + 1}`] = ptVals;
      // 추후 원래 Obj로 돌아가기 위한 키 + 관제점 저장
      obj.info[`v${idx + 1}`] = dataset.point;
    });

    return obj;
  },
  objToLogs(datasets, logs, obj) {
    // 설정된 datasets 기본으로 조회로그에 데이터 치환하기
    datasets.map((dataset, idx) => {
      let log = dataset.compare ? logs[1] : logs[0];
      if (log && log.hasOwnProperty("points")) log.points[dataset.point] = obj[`v${idx + 1}`];
    });

    return logs;
  },
  makeLabels(timeType) {
    let now = new Date();
    let labels = [];

    if (timeType == 1) labels = [now.addYear(-1).getFullYear() + "Y", now.getFullYear() + "Y"]; // 년
    if (timeType == 2)
      labels = [...Array(13).keys()]
        .filter((v) => v !== 0)
        .map((v) => {
          return v + "M";
        }); // 월
    if (timeType == 3)
      labels = [...Array(31).keys()]
        .filter((v) => v !== 0)
        .map((v) => {
          return v + "D";
        }); // 일
    if (timeType == 4)
      labels = [...Array(24).keys()].map((v) => {
        return v + "H";
      }); // 시
    if (timeType == 5) {
      // 15분
      for (let i = 1; i < 25; i++) {
        if (i == 1) labels.push(["0m", now.getDate() + "D"]);
        else labels.push("0m");
        labels.push("15m");
        labels.push("30m");
        labels.push("45m");
      }
    }

    return labels;
  },
};
