<template>
  <div class="xeGoalCard heightMAX">
    <panel
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-10 overflow-hidden chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <div v-if="isShow" class="card border-0 bg-white text-dark heightMAX gridCenter">
        <div class="card-body p-t-0 p-b-0">
          <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop('main', ...arguments)">
            <div class="d-flex align-items-center mb-1">
              <span class="text-dark xe-stats-number">{{ mainLabel }} {{ mainVal }}{{ $t(main.tail) }}</span>
            </div>
          </drop>
          <div class="m-l-2">
            <span v-if="differencialValGoal > 0.0"
              ><span class="increase"><i class="fa fa-caret-up"></i> {{ differencialValGoal }} % </span> compare to {{ goalLabel }}</span
            >
            <span v-if="differencialValGoal < 0.0"
              ><span class="decrease"><i class="fa fa-caret-down"></i> {{ differencialValGoal }} % </span> compare to {{ goalLabel }}
            </span>
            <span v-if="differencialValGoal == 0.0"
              ><span class="text-grey-darker"> {{ differencialValGoal }} % </span> compare to {{ goalLabel }}
            </span>
          </div>

          <div class="m-l-2 m-b-15">
            <span v-if="differencialValLast > 0.0"
              ><span class="increase"><i class="fa fa-caret-up"></i> {{ differencialValLast }} % </span> compare to {{ bottomLabel }}</span
            >
            <span v-if="differencialValLast < 0.0"
              ><span class="decrease"><i class="fa fa-caret-down"></i> {{ differencialValLast }} % </span> compare to {{ bottomLabel }}
            </span>
            <span v-if="differencialValLast == 0.0"
              ><span class="text-grey-darker"> {{ differencialValLast }} % </span> compare to {{ bottomLabel }}
            </span>
          </div>

          <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop('goal', ...arguments)">
            <div class="d-flex mb-2">
              <div class="d-flex align-items-center"><i class="fa fa-circle text-red f-s-8 mr-2"></i> {{ $t(goal.label) }}</div>
              <div class="d-flex align-items-center ml-auto">
                <div class="text-right pl-2 f-w-600">{{ goalVal }}{{ $t(goal.tail) }}</div>
              </div>
            </div>
          </drop>

          <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop('bottom', ...arguments)">
            <div class="d-flex mb-2">
              <div class="d-flex align-items-center"><i class="fa fa-circle text-warning f-s-8 mr-2"></i> {{ $t(bottom.label) }}</div>
              <div class="d-flex align-items-center ml-auto">
                <div class="text-right pl-2 f-w-600">{{ bottomVal }}{{ $t(bottom.tail) }}</div>
              </div>
            </div>
          </drop>
        </div>
      </div>
    </panel>

    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div v-if="isShow" class="card border-0 bg-white text-dark m-b-20 heightMAX gridCenter" >
        <div class="card-body">
          <div class="mb-4 text-dark">
            <b class="mb-3">{{ $t(widget.title) }}</b>
          </div>
          <div class="d-flex align-items-center mb-1">
            <span class="text-dark xe-stats-number">{{ mainLabel }}{{ mainVal }}{{ $t(main.tail) }}</span>
          </div>

          <div class="m-l-2">
            <span v-if="differencialValGoal > 0.0">
              {{ goalLabel }} {{$t('대비')}} <span class="increase"><i class="fa fa-caret-up"></i> {{ differencialValGoal }} % </span></span
            >
            <span v-if="differencialValGoal < 0.0">
              {{ goalLabel }} {{$t('대비')}} <span class="decrease"><i class="fa fa-caret-down"></i> {{ differencialValGoal }} % </span>
            </span>
            <span v-if="differencialValGoal == 0.0">
              {{ goalLabel }} {{$t('대비')}} <span class="text-grey-darker"> {{ differencialValGoal }} % </span></span
            >
          </div>

          <div class="m-l-2 m-b-15">
            <span v-if="differencialValLast > 0.0">
              {{ bottomLabel }} {{$t('대비')}} <span class="increase"><i class="fa fa-caret-up"></i> {{ differencialValLast }} % </span></span
            >
            <span v-if="differencialValLast < 0.0">
              {{ bottomLabel }} {{$t('대비')}} <span class="decrease"><i class="fa fa-caret-down"></i> {{ differencialValLast }} % </span></span
            >
            <span v-if="differencialValLast == 0.0">
              {{ bottomLabel }} {{$t('대비')}} <span class="text-grey-darker"> {{ differencialValLast }} % </span></span
            >
          </div>

          <div class="d-flex mb-2">
            <div class="d-flex align-items-center"><i class="fa fa-circle text-red f-s-8 mr-2"></i> {{ $t(goal.label) }}</div>
            <div class="d-flex align-items-center ml-auto">
              <div class="text-right pl-2 f-w-600">{{ goalVal }}{{ $t(goal.tail) }}</div>
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="d-flex align-items-center"><i class="fa fa-circle text-warning f-s-8 mr-2"></i> {{ $t(bottom.label) }}</div>
            <div class="d-flex align-items-center ml-auto">
              <div class="text-right pl-2 f-w-600">{{ bottomVal }}{{ $t(bottom.tail) }}</div>
            </div>
          </div>
        </div>
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" title="설정 - GoalCard" body-class="p-t-0 p-b-0" hide-footer>
      <goal-card-setting :initSetting="goalCardSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<style scoped>
.xe-stats-number {
  font-size: 24px;
  font-weight: 700;
}
.widget-stats .stats-icon.stats-icon-lg {
  /* 아이콘이 너무 희미해서 */
  color: #999;
  text-shadow: 3px 7px rgba(0, 0, 0, 0.25);
}

.widget.widget-stats {
  /* padding: 20px; 값이 너무 커서 줄임 */
  padding: 15px 20px 15px 20px !important;
}
.widget-stats .stats-title {
  text-transform: uppercase;
  /* 15px 값이 너무 커서 줄임 */
  margin-bottom: 2px !important;
}
.widget-stats .stats-number {
  /* 15px 값이 너무 커서 줄임 */
  margin-bottom: 5px !important;
}
.increase {
  color: #ff1b30;
}
.decrease {
  color: #0f9e3e;
}

.card-body{
  overflow: auto;
}

.gridCenter{
  display: grid;
  align-content: center;
}
</style>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import goalCardSetting from "@src/views/widget/v1/setting/xeGoalCardSetting.vue";
import utils from "./xeBaseUtils.js";

export default {
  name: "xeGoalCard",
  extends: xeBaseWidget,
  components: {
    goalCardSetting,
  },
  data() {
    return {
      goal: { value: 0 },
      main: { value: 0 },
      bottom: { value: 0 },
      sparkTemperature: {
        data: [],
        length: 12,
        lineStyle: {
          stroke: "#1e87ff",
          strokeWidth: 2,
        },
      },
    };
  },
  computed: {
    goalLabel() {
      return this.widget.objects.goal.label;
    },
    mainLabel() {
      return this.widget.objects.main.label;
    },
    bottomLabel() {
      return this.widget.objects.bottom.label;
    },
    mainVal() {
      return this.comma(this.main.value, this.main.addition.fixed);
    },
    goalVal() {
      return this.comma(this.goal.value, this.goal.addition.fixed);
    },
    bottomVal() {
      return this.comma(this.bottom.value, this.bottom.addition.fixed);
    },
    differencialValGoal() {
      return this.main.value.differencial(this.goal.value, this.goal.addition.fixed);
    },
    differencialValLast() {
      return this.main.value.differencial(this.bottom.value, this.bottom.addition.fixed);
    },
    goalCardSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  created() {
    if (!this.widget.objects) {
      this.widget.objects = {
        goal: { label: "", addition: { afterAction: "", afterBind: "", fixed: 0 }, tail: "", point: "", value: 0, self: { type: 0, sTi: "", eTi: "" } },
        main: { label: "", addition: { afterAction: "", afterBind: "", fixed: 0 }, tail: "", point: "", value: 0, self: { type: 0, sTi: "", eTi: "" } },
        bottom: { label: "", addition: { afterAction: "", afterBind: "", fixed: 0 }, tail: "", point: "", value: 0, self: { type: 0, sTi: "", eTi: "" } },
      };
    }
  },
  mounted(){
    let goals = document.getElementsByClassName("xeGoalCard");

    for(let i = 0; i < goals.length; i++){
      goals[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      if (!this.widget.objects) throw "object is empty";
      if (!this.widget.objects.goal) throw "goal object is empty";
      if (!this.widget.objects.main) throw "main object is empty";

      this.goal = Object.assign(this.widget.objects.goal, { value: 0 });
      this.main = Object.assign(this.widget.objects.main, { value: 0 });
      this.bottom = Object.assign(this.widget.objects.bottom, { value: 0 });
    },
    applyData(logs) {
      if (!this.isEmpty(this.goal.self.sTi)) {
        this.bindCard(this.getCond(logs, Object.assign({ point: this.goal.point }, this.goal.self)), this.goal);
      } else {
        this.bindCard(logs[0], this.goal);
      }

      if (!this.isEmpty(this.main.self.sTi)) {
        this.bindCard(this.getCond(logs, Object.assign({ point: this.main.point }, this.main.self)), this.main);
      } else {
        this.bindCard(logs[0], this.main);
      }

      if (!this.isEmpty(this.bottom.self.sTi)) {
        this.bindCard(this.getCond(logs, Object.assign({ point: this.bottom.point }, this.bottom.self)), this.bottom);
      } else {
        this.bindCard(logs[0], this.bottom);
      }
    },
    getCond(logs, cond) {
      let rtnLogs = null;

      // 일자형식 스크립트 실행
      function runTi(type, ti) {
        let fn = new Function("now", "baseline", "compare", "type", "return " + ti);
        let dt = fn(new Date(), type);

        return dt.normalize(type);
      }

      rtnLogs = logs.filter((log) => log.type == cond.type && log.sDt == runTi(cond.type, cond.sTi) && log.eDt == runTi(cond.type, cond.eTi) && log.points[cond.point]);

      return rtnLogs;
    },
    bindCard(log, card) {
      let ptVals = [];

      if (Array.isArray(log)) ptVals = log[0].points[card.point];
      else ptVals = log.points[card.point];

      ptVals = utils.onAfterAction(card.point, ptVals, card.addition.afterAction); // 배열반환

      let val = utils.onAfterBind(card.point, ptVals, card.addition ? card.addition.afterBind : null); // 값반환

      this.sparkTemperature.data.push(val);

      card.value = val;
    },
    //dnd
    handleDropPoint(label, arg) {
      // console.log("arg", arg);
      if (label == "goal") {
        this.widget.objects.goal = { label: "Target", addition: { afterAction: "", afterBind: "Last", fixed: 0 }, tail: "kWh", point: arg.item.ptAddr, value: 0 };
      } else if (label == "main") {
        this.widget.objects.main = { label: "Current", addition: { afterAction: "", afterBind: "Last", fixed: 0 }, tail: "kWh", point: arg.item.ptAddr, value: 0 };
      } else {
        this.widget.objects.bottom = { label: "Last Month", addition: { afterAction: "", afterBind: "Last", fixed: 0 }, tail: "kWh", point: arg.item.ptAddr, value: 0 };
      }
    },
  },
};
</script>
