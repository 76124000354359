var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xcGridTable heightMAX"},[_c('vue-good-table',{attrs:{"columns":_vm.gridTable.columns,"rows":_vm.gridTable.rows,"lineNumbers":_vm.isLineNumber,"search-options":{ enabled: false, placeholder: 'Search this table' },"pagination-options":{
      enabled: _vm.isPagination,
      position: 'bottom',
      perPage: 100,
      perPageDropdown: [100, 500, 1000],
      rowsPerPageLabel: 'Rows',
    },"sort-options":{ enabled: false },"selectOptions":{
      enabled: false,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
    },"max-height":_vm.maxHeight,"fixed-header":true,"styleClass":"vgt-table striped condensed xe-fixed-header xe-grid-tbl heightMAX","groupOptions":{
      enabled: _vm.isGroup,
    }},scopedSlots:_vm._u([{key:"table-header-row",fn:function(props){return [_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]}},{key:"table-row",fn:function(props){return [_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]}}])},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" 조회된 결과가 없습니다. ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }