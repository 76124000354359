
<template>
  <horizontal-bar-mix-chart ref="chart" class="myChart" :chartData="newOptions.data" :options="newOptions.options" > </horizontal-bar-mix-chart>
</template>

<script>
import HorizontalBarMixChart from "./HorizontalBarMixChart";

export default {
  name: "StackChartComponent",
  props: ["usageData", "graphColor", "usageMaxValue"],
  components: {
    HorizontalBarMixChart,
  },
  data() {
    return {
      tmpChartData: {},
      convertYesterdayData: 0,
      convertLastYearData: 0,
      maxData: 0,
      lineHeight: 11,
    };
  },
  
  mounted() {},
  updated() {
    // console.log(this.usageData);
    // console.log(this.usageData.prevConsumptDay);
    // console.log(this.usageData.currentConsumpt);
    // this.$nextTick(() => {
      //   let tmps = this.$refs.chart._data._chart.data.datasets[5]._meta;
      //   let result = [];
      //   for (let i in tmps) {
        //     result.push([i, tmps[i]]);
        //   }
        //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //   this.lineHeight = result[0][1].data[0]._model.height;
        //   console.log(this.lineHeight);
        // });
      },
      computed: {
        newOptions() {
          return {
            data: {
              datasets: [
              {
                type: "bubble",
                pointStyle: "dash",
                label: "Data",
                backgroundColor: "#f55667",
                borderColor: "#f55667",
                borderWidth: this.lineHeight * 2,
                radius: 1,
                yAxisID: "right",
                fill: true,
                data: [{y: 50, x: this.usageData.prevConsumpt}],
              },
              {
                type: "bubble",
                pointStyle: "triangle",
                label: "Data",
                backgroundColor: "#f55667",
                borderColor: "#f55667",
                borderWidth: this.lineHeight / 5,
                radius: 2,
                yAxisID: "right",
                fill: true,
                data: [{y: 3, x: this.usageData.prevConsumpt}],
              },
              {
                type: "bubble",
                pointStyle: "dash",
                label: "Data",
                backgroundColor: "#3dc1fe",
                borderColor: "#3dc1fe",
                borderWidth: this.lineHeight * 2,
                radius: 1,
                yAxisID: "right",
                fill: true,
                data: [{y: 50,x: this.usageData.lastConsumpt}],
              },
              {
                type: "bubble",
                pointStyle: "triangle",
                label: "Data",
                backgroundColor: "#3dc1fe",
                borderColor: "#3dc1fe",
                borderWidth: this.lineHeight / 5,
                radius: 2,
                yAxisID: "right",
                fill: true,
                data: [{ y: 3,x: this.usageData.lastConsumpt}],
              },
              {
                type: "horizontalBar",
                label: "Data",
                // backgroundColor: "#91f0dd",
                // borderColor: "#91f0dd",
                backgroundColor: this.graphColor,
                borderColor: this.graphColor,
                // backgroundColor: global.xe.parseBool(localStorage.contentInverse) ? "#EFEBE9" : `#${this.graphColor}`,
                // borderColor: global.xe.parseBool(localStorage.contentInverse) ? "#EFEBE9" : `#${this.graphColor}`,
                yAxisID: "left",
                fill: true,
                data: [this.usageData.currentConsumpt],
                
              },
              {
                type: "horizontalBar",
                label: "Data",
                backgroundColor: "#e8e8e8",
                borderColor: "#555",
                yAxisID: "left",
                fill: true,
                data: [this.usageMaxValue],
              },
              ],
            },
            
            options: {
              responsive: true,
              maintainAspectRatio: false,
              
              hover: {
                mode: null,
              },
              legend: {
                position: "bottom",
                display: false,
              },
              tooltips: {
                enabled: false,
              },
              scales: {
                yAxes: [
                {
                  stacked: true,
                  id: "left",
                  ticks: {
                    fontColor: "#000000",
                    fontStyle: "bold",
                    beginAtZero: true,
                    padding: 15,
                    //display: false - remove this and commenting to display: false
                  },
                  gridLines: {
                    drawTicks: false,
                    display: false,
                    color: "transparent",
                    zeroLineColor: "transparent",
                  },
                },
                
                {
                  id: "right",
                  ticks: {
                    display: false,
                    suggestedMin: 0,
                    suggestedMax: 100,
                    fontColor: "#000000",
                    fontStyle: "bold",
                    beginAtZero: true,
                    padding: 15,
                    //display: false - remove this and commenting to display: false
                  },
                  gridLines: {
                    drawTicks: false,
                    display: false,
                    color: "transparent",
                    zeroLineColor: "transparent",
                  },
                },
                ],
                xAxes: [
                {
                  stacked: false,
                  gridLines: {
                    display: false,
                    color: "transparent",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    display: false,
                    padding: 15,
                    max: this.usageMaxValue,
                    beginAtZero: true,
                    fontColor: "#000000",
                    fontStyle: "bold",
                    //display: false - remove this and commenting to display: false
                  },
                },
                ],
              },
            },
          };
        },
      },
      methods: {
      },
    };
  </script>
  
  <style></style>
  