<template>
  <div class="xeProgressCard heightMAX">
    <!-- 화면영역 start -->
    <!-- <div
      class="heightMAX p-t-10 p-b-10 p-l-25 p-r-25 rounded"
      :style="{ backgroundColor: this.widget.card.backgroundColor }"
    > -->
    <div class="kbet-custom-reset heightMAX">
      <div class="rounded kbet-widget-valueCard-v2 heightMAX" :style="{ backgroundColor: this.themeColor.card.itemColors[this.widget.index] }">
        <drop
          @dragover="handleBodyDragOver(...arguments)"
          @drop="handleBodyDrop('main', ...arguments)"
          >
          <i
              class="bg-icon"
              :class="[widget.bgIcon.includes('material')? 'material-icons': widget.bgIcon,]"
              :title="widget.bgIcon"
              >
              {{widget.bgIcon.includes("material") ? widget.bgIcon.replace("material ", "") : ""}}
          </i>
          <p class="title">
            <span>{{ $t(widget.title) }}</span>
          </p>
          <div class="value-usage">
            <strong>{{ $t(mainValue) }}<span>&nbsp;{{ $t(main.tail) }}</span></strong>
          </div>
          
          <div class="value-graph progress stats-progress rounded-corner h-2">
            <div
              class="f-s-12 f-w-700"
              v-bind:style="{
                width: main.value.percentage(bottom.value) + '%',
                backgroundColor: this.widget.card.style.progress.color,
              }"
            >
            </div>

          </div>
          <drop
            @dragover="handleBodyDragOver(...arguments)"
            @drop="handleBodyDrop('bottom', ...arguments)"
          >
            <div class="value-contrast">
              <strong>{{differencialVal2}} %</strong>
              <span>/ {{ $t(bottom.label)}} {{ $t(bottomValue) }} [{{ $t(bottom.tail) }}] </span>
            </div>
          </drop>
        </drop>
      </div>
    </div>

    <b-modal
      ref="modalSetting"
      size="lg"
      title="설정 - 작업진행상태 카드"
      body-class="p-t-0 p-b-0
      "
      hide-footer
    >
      <progress-card-setting
        :initSetting="progressCardSetting"
        @on-modal-cancel="onModalCancel"
        @on-modal-ok="onModalOK"
      />
    </b-modal>
  </div>
</template>

<style scoped></style>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import progressCardSetting from "./setting/xeCompareCardSetting.vue";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";

export default {
  name: "xeProgressCard",
  extends: xeBaseWidget,
  components: {
    progressCardSetting,
  },
  data() {
    return {
      main: { value: 0, addition: { fixed: 1 } },
      bottom: { value: 0, addition: { fixed: 1 } },
      themeColor : global.xe.parseBool(localStorage.contentInverse) ? require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark : require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light,
    };
  },
  computed: {
    hasSelfCond() {
      if (this.main.self || this.bottom.self) return true;
      return false;
    },
    progressCardSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    isPercentage() {
      return this.widget.objects.main.addition.isPercentage;
    },
    differencialVal() {
      return this.main.value.differencial(
        this.bottom.value,
        this.main.addition.fixed
      );
    },
    differencialVal2() {
      let bValue = this.bottom.value;
      let mValue = this.main.value;
      let returnValue = 0;
      if (bValue == 0 || mValue == 0) {
        returnValue = 0;
      } else {
        returnValue = mValue.percentage(bValue)
        //returnValue = (((mValue - bValue)/bValue) * 100).toFixed(this.main.addition.fixed);
      }

      return returnValue;
    },
    mainValue() {
      return this.comma(this.main.value, this.main.addition.fixed);
    },
    bottomValue() {
      return this.comma(this.bottom.value, this.bottom.addition.fixed);
    },
  },
  created() {
    if (!this.widget.style) {
      this.widget.style = "";
    }
    if (!this.widget.bgIcon) {
      this.widget.bgIcon = "";
    }
    if (!this.widget.objects) {
      this.widget.objects = {
        main: {
          label: "",
          addition: {
            afterAction: "",
            afterBind: "",
            isPercentage: true,
            fixed: 0,
          },
          tail: "",
          point: "",
          value: 0,
          self: { type: 0, sTi: "", eTi: "" },
        },
        bottom: {
          label: "",
          addition: {
            afterAction: "",
            afterBind: "",
            isPercentage: false,
            fixed: 0,
          },
          tail: "",
          point: "",
          value: 0,
          self: { type: 0, sTi: "", eTi: "" },
        },
      };
    }
    if (!this.widget.card) {
      this.widget.card = {
        colorOpens: false,
        titleColor: "#FFFFFFFF",
        titleFontSize: 14,
        backgroundColor: "#474f6c",
        style: {
          main: {
            fontSize: "40",
            fontWeight: "600",
            fontColor: "#FFFFFFFF",
            unitSize: "15",
            unitColor: "#FFFFFFFF",
            iconColor: "#FFFFFFFF",
            textAlign: "right",
          },
          bottom: {
            fontSize: "15",
            fontWeight: "400",
            fontColor: "#7d8293",
            unitSize: "15",
            unitColor: "#7d8293",
            textAlign: "right",
          },
          progress: {
            patternYn: "",
            animationYn: "",
            color: "#03adad",
            fontSize: "",
            fontWeight: "",
            height: "",
          },
        },
      };
    }
  },
  mounted() {
    let energys = document.getElementsByClassName("xeProgressCard");

    for (let i = 0; i < energys.length; i++) {
      energys[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      if (!this.widget.objects) throw "object is empty";
      if (!this.widget.objects.main) throw "main object is empty";

      this.main = Object.assign(this.widget.objects.main, { value: 0 });
      this.bottom = Object.assign({}, this.widget.objects.bottom, { value: 0 });
    },
    applyData(logs) {
      if (!this.isEmpty(this.main.self.sTi)) {
        this.bindCard(
          this.getCond(
            logs,
            Object.assign({ point: this.main.point }, this.main.self)
          ),
          this.main
        );
      } else {
        this.bindCard(logs[0], this.main);
      }

      if (!this.isEmpty(this.bottom.self.sTi)) {
        this.bindCard(
          this.getCond(
            logs,
            Object.assign({ point: this.bottom.point }, this.bottom.self)
          ),
          this.bottom
        );
      } else {
        this.bindCard(logs[0], this.bottom);
      }
    },
    getCond(logs, cond) {
      let rtnLogs = null;

      // 일자형식 스크립트 실행
      function runTi(type, ti) {
        let fn = new Function(
          "now",
          "baseline",
          "compare",
          "type",
          "return " + ti
        );
        let dt = fn(new Date(), type);

        return dt.normalize(type);
      }

      rtnLogs = logs.filter(
        (log) =>
          log.type == cond.type &&
          log.sDt == runTi(cond.type, cond.sTi) &&
          log.eDt == runTi(cond.type, cond.eTi) &&
          log.points[cond.point]
      );

      return rtnLogs;
    },
    bindCard(log, card) {
      let ptVals = [];

      if (Array.isArray(log)) ptVals = log[0].points[card.point];
      else ptVals = log.points[card.point];

      ptVals = utils.onAfterAction(
        card.point,
        ptVals,
        card.addition.afterAction
      ); // 배열반환

      let val = utils.onAfterBind(
        card.point,
        ptVals,
        card.addition ? card.addition.afterBind : null
      ); // 값반환

      card.value = val;
    },

    //dnd
    handleDropPoint(label, arg) {
      if (label == "main") {
        this.widget.title = arg.item.title;
        this.widget.objects.main = {
          label: "",
          addition: { afterAction: "", afterBind: "Last", isPercentage: false },
          tail: "",
          point: arg.item.ptAddr,
          value: 0,
        };

        // "main"  : { "label":"", "afterBind": "Last", "tail": "kWh", "point": "084000001_0000_0000_VLI_00000001" },
      } else {
        // bottom
        this.widget.title = arg.item.title;
        this.widget.objects.bottom = {
          label: "",
          addition: { afterAction: "", afterBind: "Last", isPercentage: false },
          tail: "",
          point: arg.item.ptAddr,
          value: 0,
        };
      }
    },
  },
};
</script>
