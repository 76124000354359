<template>
  <div class="xeDoughnut heightMAX rounded" style="overflow: hidden;">
      <div class="heightMAX" style="padding: 0">
        <drop
          @dragover="handleBodyDragOver(...arguments)"
          @drop="handleBodyDrop(...arguments)"
          class="heightMAX kbet-custom-reset"
        >
          <div class="rounded kbet-widget-energy heightMAX" style="display: block;">
            <p class="title">{{ $t(widget.title) }}</p>
            <div class="chartHeightMax2">
                <div v-if="isShow" class="chartHeightMax2">
                   <doughnut-chart
                    ref="chart"
                    class="chartHeightMax2"
                    :data="chartJs.data"
                    :options="chartJs.options"
                  ></doughnut-chart>
                </div>
              </div>
          </div>
        </drop>
      </div>

      <b-modal
        ref="modalSetting"
        size="lg"
        :title="`${$t('설정')} - Doughnut ${$t('차트')}`"
        bodyClass="p-t-0 p-b-0"
        hide-footer
      >
        <chart-doughnut-setting
          :initSetting="chartSetting"
          :data="chartJs.data"
          @on-modal-cancel="onModalCancel"
          @on-modal-ok="onModalOK"
        />
      </b-modal>
  </div>
</template>

<style scoped>
.chartHeightMax2 {
    height: calc(100% - 5px);
}
</style>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import DoughnutChart from "@components/vue-chartjs/Charts/DoughnutChart.js";
import chartDoughnutSetting from "./setting/xeDoughnutSetting.vue";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";
// import "chartjs-plugin-datalabels";

// TODO: 데이터가 없을때 뜨기도하고 안뜨기도 함.....

export default {
  name: "xeDoughnut",
  extends: xeBaseWidget,
  components: {
    DoughnutChart,
    chartDoughnutSetting,
  },
  data() {
    return {
      chartJs: {
        data: {},
        options: {},
      },
      themeColor : global.xe.parseBool(localStorage.contentInverse) ? require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark : require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light,
    };
  },
  computed: {
    chartSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    master() {
      return this.$store.getters.master;
    },
  },
  created() {
    //초기값
    if (!this.widget.chart) {
      this.widget.chart = {
        colorOpens: false,
        backgroundColor: "#ffffff",
        series: [
          {
            // label: "Default",
            // point: "",
            label: "Default1",
            point: "0201_NLI_001F02W",
            dataColor: "#1dd1a1",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default2",
            point: "0201_NLI_001F01W",
            dataColor: "#22a6b3",
            // dataColor: "#A6DBA8",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default3",
            point: "0201_NLI_001F06W",
            dataColor: "#686de0",
            // dataColor: "#65B5F4",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default4",
            point: "0201_NLI_001F02W",
            dataColor: "#8753de",
            // dataColor: "#5873AF",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default5",
            point: "0201_NLI_001F01W",
            dataColor: "#f0932b",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default6",
            point: "0201_NLI_001F06W",
            dataColor: "#6c757d",
            compare: false,
          },
        ],
        options: {
          legend: {
            display: false,
            position: "bottom",
            align: "center",
            labels: {
              boxWidth: 10,
              fontSize: 12,
              fontStyle: "normal",
              fontColor: "#111111",
            },
          },
          fontColor: "#000000",
          chartFontSize: 14,
          titleFontSize: 14,
          unit: "",
          isPercentage: false,
        },
      };
    }
    if (!this.widget.addition)
      this.widget.addition = {
        afterAction: "",
        afterBind: "",
        fixed: 0,
      };
    if (!this.widget.self)
      this.widget.self = {
        baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
        compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
      };
  },
  mounted() {
    let pies = document.getElementsByClassName("xeDoughnut");

    for (let i = 0; i < pies.length; i++) {
      pies[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.chartJs.options = this.createChartOptions();
      this.chartJs.data = this.createChartData();

      // this.$el.style.backgroundColor = (this.master.labelTheme=='black'? '#3f3f3f' : '#fff')
      // // this.$el.style.backgroundColor = this.widget.chart.backgroundColor;

      // this.setDataColor();
      this.setTheme();
    },
    setTheme(){
      this.$el.style.backgroundColor = this.themeColor.chart.pie.backgroundColor;
    },
    setDataColor(){
      this.chartJs.data.datasets[0].backgroundColor.forEach((element, index) => {
        element = this.themeColor.chart.itemColors[index];
      });
    },
    // 차트 데이터 기본값 설정
    createChartData() {
      let points = this.widget.chart.series.map((v) => {
        return { label: v.label, point: v.point, compare: v.compare };
      });

      let backgroundColor = this.widget.chart.series
        .filter((v) => !v.compare || this.isCompare)
        .map((v,i) => {
          v,i;
          // return this.themeColor.chart.itemColors[i];
          return v.dataColor;
        });

      let isPercentage = this.widget.chart.options.isPercentage;

      let labels = points
        ? points.map((item) => {
            return item.label;
          })
        : ["Default"];

      let data = points.map(() => {
        return (100.0 / points.length).toFixed(2);
      });

      return {
        labels: labels,
        points: points,
        isPercentage: isPercentage,
        datasets: [
          {
            backgroundColor: backgroundColor,
            borderWidth: 0,
            data: data,
          },
        ],
      };
    },
    // 차트 기본 옵션값 설정
    createChartOptions() {
      let options = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 60,
        radiusBackground: { color: "#d1d1d1" },
        plugins: {
          datalabels: {
            display: "auto",
            color: this.themeColor.chart.pie.fontColor,
            anchor: "end",
            clamp: true,
            font: {
              size: 11,
            },
            padding: 10,
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: "bold",
                },
              },
              value: {
                color: "green",
              },
            },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
          },
        },
        legend: {
          display: false,
          position: this.widget.chart.options.legend.position,
          align: this.widget.chart.options.legend.align,
          labels: {
            usePointStyle: true,
            boxWidth: this.widget.chart.options.legend.labels.boxWidth,
            fontSize: Number(this.widget.chart.options.legend.labels.fontSize),
            fontColor: this.themeColor.chart.pie.fontColor,
          },
        },
        elements: { point: { radius: 0.3 } },
        layout: {
          padding: {
            left: 60,
            right: 60,
            top: 40,
            bottom: 40,
          },
        },
        title: { display: false },
        tooltips: {
          mode: "index",
          intersect: true,
          callbacks: {
            label: (tooltipItem, data) => {
              let totalSum = data.datasets[tooltipItem.datasetIndex].data.sum();
              let label = data.labels[tooltipItem.index];
              let currentValue =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              let currentPer = null;

              if (data.isPercentage) {
                if (totalSum == 0) {
                  currentPer = (
                    100.0 / data.datasets[tooltipItem.datasetIndex].data.length
                  ).toFixed(this.widget.addition.fixed);
                } else {
                  currentPer = (currentValue / totalSum) * 100.0;
                }

                currentPer = this.comma(currentPer, this.widget.addition.fixed);

                currentValue = this.comma(
                  currentValue,
                  this.widget.addition.fixed
                );

                return (
                  `${label}: ${currentPer}%, ${currentValue}` +
                  this.widget.chart.options.unit
                );
              } else {
                return (
                  `${label}: ${currentValue}` + this.widget.chart.options.unit
                );
              }
            },
          },
        },
      };

      // TODO: chart의 기본속성을 그대로 사용할 수 있도록 하는 기능임. 예전에 사용하다가 사용하지 않고 있음.. 사용해야 함.
      //   if (this.widget.chart) return this.merge(defaultV, this.widget.chart);

      return options;
    },
    // 차트 데이터 바인딩
    applyData(widgetLogs) {
      // 데이터 선별 작업 진행
      let chartData = this.createChartData();

      // 기존 로그 복사하여 데이터 변형 작업 진행
      let copyLogs = JSON.parse(JSON.stringify(widgetLogs));
      // afterAction 처리
      copyLogs = utils.onAfterAction2(
        chartData.points,
        copyLogs,
        this.widget.addition.afterAction
      );

      // 데이터 초기화
      chartData.datasets[0].data = chartData.points.map((item) => {
        let log = copyLogs[item.compare ? 1 : 0];

        // 조회해서 가지고 온 포인트값의 배열
        let ptVals = log && log.points ? log.points[item.point] : [];

        // 후처리 방식에 따라 데이터 바꿔서 차트 데이터에 바인딩
        let val = utils.onAfterBind(
          item.point,
          ptVals,
          this.widget.addition.afterBind
        ); // 값반환

        val = utils.fixedValue(val, this.widget.addition.fixed);

        return val;
      });

      for (let i = 0; i < this.chartJs.data.datasets.length; i++) {
        //NOTE - Test용 코드 시작
        // let randoms = [];
        // for (let j = 0; j < this.chartJs.data.datasets[i].data.length; j++) {
        //   randoms.push(Math.floor(Math.random() * 100));
        // }
        // this.chartJs.data.datasets[i].data = randoms;
        //NOTE - Test용 코드 끝

        this.chartJs.data.datasets[i].data = chartData.datasets[i].data;
      }

      this.$refs.chart._data._chart.update();
    },
    // dnd
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "label": "습구온도", "point": "084000001_0000_0000_VLI_00000001"}

        points.map((point) => {
          if (
            that.widget.chart.series.filter((v) => v.point === point.ptAddr)
              .length == 0
          ) {
            // 중복 체크
            that.widget.chart.series.push({
              label: point.title,
              point: point.ptAddr,
              backgroundColor: that.getRandomColor(),
            });
          }
        });
      }

      let defaultObj = {
        label: "Default",
        point: "",
        backgroundColor: "#348fe2",
        compare: false,
      };

      if (
        JSON.stringify(this.widget.chart.series.slice(0, 1)) ==
        JSON.stringify([defaultObj])
      )
        this.widget.chart.series = [];

      append(
        transfer.item.type === this.CODE.Drag.PointGroup
          ? transfer.item.points
          : [transfer.item]
      );
    },
  },
};
</script>
