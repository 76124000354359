<template>
  <div class="xeWeatherWidget heightMAX">
    <panel
      :variant="panelVariant"
      bodyClass="p-l-30 p-r-30 p-t-10 p-b-0 chartHeightMax"
      hideExpand="false"
      hideReload="false"
      :hideCollapse="isEditMode ? 'true' : 'false'"
      :hideRemove="isEditMode ? 'false' : 'true'"
      @panel-remove="onPanelRemove"
      @panel-expand="expandWeather"
      @panel-collapse="collapseWeather"
      @panel-reload="refreshWeather"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <div class="panel-title">
          {{ currentCity.cityName }}
          <b-dropdown text="" variant="link">
            <b-dropdown-item v-for="(city, i) in citys" :key="i" @click="changeCity(city)">{{ city.cityName }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template slot="button">
        <span class="pull-right m-r-10">
          {{ mainWeather.forecastDt ? mainWeather.forecastDt.format("MM/dd HH a/p") : "" }}
        </span>
        <button class="btn btn-xs btn-icon btn-circle btn-indigo" @click="$router.push(`/operation/weather`)" v-if="!isMobile"><i class="fa fa-search"></i></button>
      </template>

      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="row">
            <div class="col-5 offset-1 text-center">
              <i class="xe-weather-icon-big" :class="'owm-' + mainWeather.weatherIcon"></i>
            </div>
            <div class="col-6">
              <div :class="[isMobile ? 'xe-weather-main-temp-mobile' : 'xe-weather-main-temp']">{{ mainWeather.temp }} ℃</div>
              <div class="text-black">{{ mainWeather.weatherDesc }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-t-10 weatherHeight">
        <div class="col-lg-12 heightMAX">
          <div class="table-responsive heightMAX">
            <table class="table table-valign-middle heightMAX">
              <thead>
                <tr>
                  <th>Source</th>
                  <th>Now</th>
                  <th v-if="!isMobile">Trend</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label class="label label-danger"> {{ $t("기온") }} </label>
                  </td>
                  <td>
                    {{ mainWeather.tempMin }}℃ / {{ mainWeather.tempMax }}℃ <span class="text-success"><i class="fa fa-arrow-up"></i></span>
                  </td>
                  <td v-if="!isMobile">
                    <sparkline height="23">
                      <sparklineLine :data="sparkTemperature.data" :limit="sparkTemperature.data.length" :styles="sparkTemperature.lineStyle" />
                    </sparkline>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label class="label label-success"> {{ $t("비올확율") }} </label>
                  </td>
                  <td>{{ mainWeather.rain || "0" }} %</td>
                  <td v-if="!isMobile">
                    <sparkline height="23">
                      <sparklineLine :data="sparkRain.data" :limit="sparkRain.data.length" :styles="sparkRain.lineStyle" />
                    </sparkline>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label class="label label-warning"> {{ $t("풍속") }} </label>
                  </td>
                  <td>{{ mainWeather.windSpeed }} m/s</td>
                  <td v-if="!isMobile">
                    <sparkline height="23">
                      <sparklineLine :data="sparkWindSpeed.data" :limit="sparkWindSpeed.data.length" :styles="sparkWindSpeed.lineStyle" />
                    </sparkline>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label class="label label-primary"> {{ $t("습도") }} </label>
                  </td>
                  <td>{{ mainWeather.humidity }} %</td>
                  <td v-if="!isMobile">
                    <sparkline height="23">
                      <sparklineLine :data="sparkHumidity.data" :limit="sparkHumidity.data.length" :styles="sparkHumidity.lineStyle" />
                    </sparkline>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>

<style scoped>
/* 일기예보 큰 아이콘 */
.xe-weather-icon-big {
  line-height: 1.4;
  font-size: 70px;
  display: inline-block;
  font-family: "weathericons";
  font-style: normal;
  font-weight: normal;

  /* line-height: 1; */
}
/* 일기예보 오른쪽 요약정보 table에 기본 테두리 제거 */
.xe-weather-summary > tbody > tr > td {
  border-top: 0px;
  padding: 5px 10px;
}
/* 일기예보 큰 기온표시 폰트  */
.xe-weather-main-temp {
  font-size: 42px;
  font-weight: 200;
}

.xe-weather-main-temp-mobile {
  font-size: 7.5vw;
  font-weight: 200;
  padding-top: 1.6vh;
}

.weatherHeight{
  height: calc(100% - 75px);
}

/* 모바일용 icon크기 */
@media screen and (max-width: 768px) {
  .xe-weather-icon-big {
    line-height: 1.4;
    font-size: 16.66vw;
    display: inline-block;
    font-family: "weathericons";
    font-style: normal;
    font-weight: lighter;
  }
}
</style>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import backEndApi from "@api/backEndApi.js";
import Resource from "@plugins/Resource.js";

// TODO: mainWeather가 null이되는 경우가 있음...
// TODO: 날자를 선택하여 조회하는 기능이 없음. 만들어야 함.. sDt, eDT로 조회할 수 있는 서비스함수는 존재함..
// TODO: 기본 사이즈 너무 큼..

export default {
  name: "xeWeatherWidget",
  props: ["showGrid", "widget", "cellSize", "on-remove-widget"],
  extends: xeBaseWidget,
  data() {
    return {
      currentCity: {}, // Resouce.js에서 일반도시명으로 변경 함...
      mainWeather: {
        forecastDt: new Date().format("MM-dd HH") + ":00",
        temp: 0,
        humidity: 0,
        windSpeed: 0,
      },

      sparkTemperature: {
        data: [],
        length: 12,
        lineStyle: {
          stroke: "#ff1b30",
          strokeWidth: 2,
        },
      },
      sparkRain: {
        data: [],
        length: 12,
        lineStyle: {
          stroke: "#04c5c5",
          strokeWidth: 2,
        },
      },
      sparkWindSpeed: {
        data: [],
        length: 12,
        lineStyle: {
          stroke: "#ff9700",
          strokeWidth: 2,
        },
      },
      sparkHumidity: {
        data: [],
        length: 12,
        lineStyle: {
          stroke: "#0078ff",
          strokeWidth: 2,
        },
      },

      resource: Resource,
      forecastDt: new Date().format("yyyyMMdd"),
    };
  },
  mounted(){
    let weathers = document.getElementsByClassName("xeWeatherWidget");

    for(let i = 0; i < weathers.length; i++){
      weathers[i].parentNode.style.height = "100%";
    }
  },
  computed: {
    localCity() {
      return localStorage && localStorage.currentCity ? JSON.parse(localStorage.currentCity) : "";
    },
    master() {
      return this.$store.getters.master;
    },
    citys() {
      return this.master.cityCodes || [];
    },
    masteCityCode() {
      if (this.master && Array.isArray(this.master.cityCode) && this.master.cityCode.length > 0) {
        return this.master.cityCode[0];
      }

      return this.master ? this.master.cityCode : "";
    },
    fixedHeight() {
      // 41은 패널해더의 높이...  5는 dndgrid 마진값

      if (!this.isEmpty(this.widget)) {
        let margin = 0;
        if (this.cellSize && !this.isEmpty(this.cellSize.margin)) margin = this.cellSize.margin;

        let panelHeaderHeight = this.widget.panel && this.widget.panel.display ? 41 : 0; // 41은 패널헤더가 표실될때의 높이값

        return this.widget.position.h * (this.cellSize.h + margin) - margin - panelHeaderHeight;
      } else return null;
    },
  },
  watch: {
    "$store.getters.master.masterCode": function() {
      // 마스터코드 늦게 들어올때 여기서 리로딩
      this.bindWeather();
    },
  },
  methods: {
    // 부모위젯에서 호출됨..
    initSetting() {
      try {
        if (!this.master) return;
        if (!this.master.masterCode) return;

        //  localStorage에 currentCity가 있을 경우 해당 도시를 보여줄 도시로 선택한다.
        if (this.localCity) {
          let city = this.citys.find((v) => v.cityCode == this.localCity.cityCode);
          if (city) this.currentCity = city;
        }
        // 설정이 없을 경우 보여줄 첫번째 빌딩 도시를 선택한다.
        else if (this.citys.length) {
          let city = this.citys.find((v) => v.cityCode == this.masteCityCode);
          if (city) this.currentCity = city;
        }

        this.changeCity(this.currentCity);
      } catch (err) {
        console.error(err);
      }
    },
    //데이터 테이블에 시간볍로 날씨 불러오기
    bindWeather() {
      try {
        if (!localStorage.accessToken) return;

        if (this.isEmpty(this.currentCity)) return;
        if (this.isEmpty(this.currentCity.countryCode)) return;
        if (this.isEmpty(this.currentCity.cityCode)) return;

        this.gridWeather = Object.assign({}, this.gridWeather, { rows: [] });
        this.mainWeather = {};

        let sDt = this.forecastDt.format("yyyyMMdd0000");
        let eDt = this.forecastDt.format("yyyyMMdd2359");
        backEndApi.weather.searchWeatherByPeriod(this.currentCity.countryCode, this.currentCity.cityCode, sDt, eDt).then(({ data }) => {
          if (this.$err(data)) return;

          let weathers = data;
          weathers = weathers.sort((a, b) => {
            return a.forecastDt.parseDate().getTime() > b.forecastDt.parseDate().getTime() ? 1 : -1;
          });

          if (Array.isArray(weathers)) {
            let now = new Date().format("yyyyMMddHH") + "00"; // forecastDt는 DB상에 "201906010000" 분까지 들어있음..

            // 초기화 추가
            this.gridWeather.rows = [];
            this.sparkTemperature.data = [];
            this.sparkRain.data = [];
            this.sparkWindSpeed.data = [];
            this.sparkHumidity.data = [];

            weathers.forEach((weather) => {
              // 현재시간에 해당하는 예보를 찾아서 보여줌..
              if (weather.forecastDt < now) {
                // now에 가장 가까운데이터가 마지막으로 들어감..
                //console.log("searchWeatherByPeriod", weather);
                this.mainWeather = weather;
              }
              // 화면에 보여줄 문자열 형식으로 변환합니다.
              //weather.forecastDt = item.forecastDt.format("MM-dd HH") + ':00';

              this.gridWeather.rows.push(weather);

              this.sparkTemperature.data.push(weather.temp);
              this.sparkRain.data.push(weather.rain);
              this.sparkWindSpeed.data.push(weather.windSpeed);

              this.sparkHumidity.data.push(weather.humidity);
            });

            if (!this.mainWeather.forecastDt && weathers.first()) {
              this.mainWeather = weathers.first();
              // console.log("mainWeather", this.mainWeather);
            }
          }
        });
      } catch (err) {
        console.error(err);
      }
    },
    //선택된 도시 바꾸기
    changeCity(city) {
      try {
        this.currentCity = city;
        localStorage.currentCity = JSON.stringify(this.currentCity); // 사용자선택 저장..
        this.bindWeather();
      } catch (err) {
        console.log(err);
      }
    },
    refreshWeather() {
      this.bindWeather();
    },
    expandWeather() {},
    collapseWeather() {},
    onPanelRemove() {
      this.isDelete = true;
      this.$emit("on-remove-widget");
    },
  },
};
</script>
