<template>
	<div>
			<div class="row form-group m-t-10">
				<div class="col-md-12">
					<a href="javascript:;" class="xe-tab-underline" v-on:click.prevent="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }"> <trans>일반</trans> </a>
					<a href="javascript:;" class="xe-tab-underline" v-on:click.prevent="toggleTabs('main')" :class="{ 'xe-tab-line-orange': tabs.main }"> <trans>메인정보</trans> </a>
					<a href="javascript:;" class="xe-tab-underline" v-on:click.prevent="toggleTabs('sub1')" :class="{ 'xe-tab-line-orange': tabs.sub1 }"> <trans>목표정보</trans> </a>
					<a href="javascript:;" class="xe-tab-underline" v-on:click.prevent="toggleTabs('sub2')" :class="{ 'xe-tab-line-orange': tabs.sub2 }"> <trans>하단정보</trans> </a>
				</div>
			</div>

			<div class="row form-group">
				<div class="col-md-12">
					<div class="tab-content m-0 p-0">
						<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.general }">
							<div class="row form-group">
								<div class="col-10">
									<label> <trans>제목</trans> </label>
									<input type="text" class="form-control" v-model="copySetting.title" />
								</div>
								<div class="col-2">
									<label> <trans>패널</trans> </label>
									<div class="checkbox checkbox-css">
										<input type="checkbox" :id="'energycard_panel'" v-model="copySetting.panel.display" />
										<label :for="'goalcard_panel'">{{ copySetting.panel.display ? "ON" : "OFF" }}</label>
									</div>
								</div>
							</div>
							<hr />
							<div class="row">
								<div class="col-12">
									<h5><trans>조회기간 덮어쓰기</trans></h5>
								</div>
							</div>
							<label
								><b><trans>메인정보</trans></b></label
							>
							<xe-modal-self :copySetting="copySetting.objects.main" :isCompare="false" />
							<label
								><b><trans>목표정보</trans></b></label
							>
							<xe-modal-self :copySetting="copySetting.objects.goal" :isCompare="false" />
							<label
								><b><trans>하단정보</trans></b></label
							>
							<xe-modal-self :copySetting="copySetting.objects.bottom" :isCompare="false" />
						</div>

						<div class="tab-pane fade" :class="{ 'active show': tabs.main }">
							<div class="row form-group">
								<div class="col-md-2">
									<label><trans>표시명</trans> </label>
									<valid-input :inputType="'text'" :inputValue.sync="copySetting.objects.main.label" :rules="rules.DASHBOARD_WIDGET_VIEW_NAME_RULE" :NonStatus="true" :classList="'form-control'">
									</valid-input>
								</div>
								<div class="col-md-2">
									<label><trans>단위</trans></label>
									<valid-input :inputType="'text'" :inputValue.sync="copySetting.objects.main.tail" :rules="rules.DASHBOARD_WIDGET_UNIT_RULE" :NonStatus="true" :classList="'form-control'">
									</valid-input>
								</div>
								<div class="col-md-6">
									<label><trans>관제점</trans></label>
									<input type="text" class="form-control" v-model="copySetting.objects.main.point" />
								</div>
								<div class="col-md-2">
									<label><trans>소수점자리</trans></label>
									<valid-input
										:inputType="'number'"
										:inputValue.sync="copySetting.objects.main.addition.fixed"
										:classList="'form-control'"
										:NonStatus="false"
										:errorMessage="validMessage.WIDGET_DECIMAL_AND_FONTSIZE_LENGTH_VALID_MESSAGE"
										:rules="rules.DASHBOARD_WIDGET_DECIMAL_RULE"
									>
									</valid-input>
								</div>
							</div>

							<div class="row form-group">
								<div class="col-md-12">
									<label
										><b><trans>후처리</trans></b></label
									>
									<valid-textarea
										:inputType="'text'"
										:inputValue.sync="copySetting.objects.main.addition.afterAction"
										:classList="'form-control'"
										:rows="5"
										:NonStatus="true"
										:rules="rules.DASHBOARD_WIDGET_POSTPROCESS_RULE"
									>
									</valid-textarea>
								</div>
							</div>
							<label
								><b><trans>취합방법</trans></b></label
							>
							<div class="row form-group">
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_main_afterBind_Last' + copySetting.id" value="Last" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'goalcard_main_afterBind_Last' + copySetting.id">{{$t('마지막(Last)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_main_afterBind_Min' + copySetting.id" value="Min" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'goalcard_main_afterBind_Min' + copySetting.id">{{$t('최소(Min)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_main_afterBind_Max' + copySetting.id" value="Max" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'goalcard_main_afterBind_Max' + copySetting.id">{{$t('최대(Max)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_main_afterBind_Sum' + copySetting.id" value="Sum" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'goalcard_main_afterBind_Sum' + copySetting.id">{{$t('합계(Sum)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_main_afterBind_Avg' + copySetting.id" value="Avg" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'goalcard_main_afterBind_Avg' + copySetting.id">{{$t('평균(Avg)')}}</label>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane fade" :class="{ 'active show': tabs.sub1 }">
							<div class="row form-group">
								<div class="col-md-2">
									<label><trans>표시명</trans> </label>
									<valid-input :inputType="'text'" :inputValue.sync="copySetting.objects.goal.label" :rules="rules.DASHBOARD_WIDGET_VIEW_NAME_RULE" :NonStatus="true" :classList="'form-control'">
									</valid-input>
								</div>
								<div class="col-md-2">
									<label><trans>단위</trans></label>
									<valid-input :inputType="'text'" :inputValue.sync="copySetting.objects.goal.tail" :rules="rules.DASHBOARD_WIDGET_UNIT_RULE" :NonStatus="true" :classList="'form-control'">
									</valid-input>
								</div>
								<div class="col-md-6">
									<label><trans>관제점</trans></label>
									<input type="text" class="form-control" v-model="copySetting.objects.goal.point" />
								</div>
								<div class="col-md-2">
									<label> <trans>소수점자리</trans> </label>
									<valid-input
										:inputType="'number'"
										:inputValue.sync="copySetting.objects.goal.addition.fixed"
										:classList="'form-control'"
										:NonStatus="false"
										:errorMessage="validMessage.WIDGET_DECIMAL_AND_FONTSIZE_LENGTH_VALID_MESSAGE"
										:rules="rules.DASHBOARD_WIDGET_DECIMAL_RULE"
									>
									</valid-input>
								</div>
							</div>

							<div class="row form-group">
								<div class="col-md-12">
									<label
										><b><trans>후처리</trans></b></label
									>
									<valid-textarea
										:inputType="'text'"
										:inputValue.sync="copySetting.objects.goal.addition.afterAction"
										:classList="'form-control'"
										:rows="5"
										:NonStatus="true"
										:rules="rules.DASHBOARD_WIDGET_POSTPROCESS_RULE"
									>
									</valid-textarea>
								</div>
							</div>
							<label
								><b><trans>취합방법</trans></b></label
							>
							<div class="row form-group">
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_goal_afterBind_Last' + copySetting.id" value="Last" v-model="copySetting.objects.goal.addition.afterBind" />
										<label :for="'goalcard_goal_afterBind_Last' + copySetting.id">{{$t('마지막(Last)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_goal_afterBind_Min' + copySetting.id" value="Min" v-model="copySetting.objects.goal.addition.afterBind" />
										<label :for="'goalcard_goal_afterBind_Min' + copySetting.id">{{$t('최소(Min)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_goal_afterBind_Max' + copySetting.id" value="Max" v-model="copySetting.objects.goal.addition.afterBind" />
										<label :for="'goalcard_goal_afterBind_Max' + copySetting.id">{{$t('최대(Max)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_goal_afterBind_Sum' + copySetting.id" value="Sum" v-model="copySetting.objects.goal.addition.afterBind" />
										<label :for="'goalcard_goal_afterBind_Sum' + copySetting.id">{{$t('합계(Sum)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_goal_afterBind_Avg' + copySetting.id" value="Avg" v-model="copySetting.objects.goal.addition.afterBind" />
										<label :for="'goalcard_goal_afterBind_Avg' + copySetting.id">{{$t('평균(Avg)')}}</label>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane fade" :class="{ 'active show': tabs.sub2 }">
							<div class="row form-group">
								<div class="col-md-2">
									<label><trans>표시명</trans></label>
									<valid-input
										:inputType="'text'"
										:inputValue.sync="copySetting.objects.bottom.label"
										:rules="rules.DASHBOARD_WIDGET_VIEW_NAME_RULE"
										:NonStatus="true"
										:classList="'form-control'"
									>
									</valid-input>
								</div>
								<div class="col-md-2">
									<label><trans>단위</trans></label>
									<valid-input :inputType="'text'" :inputValue.sync="copySetting.objects.bottom.tail" :rules="rules.DASHBOARD_WIDGET_UNIT_RULE" :NonStatus="true" :classList="'form-control'">
									</valid-input>
								</div>
								<div class="col-md-6">
									<label><trans>관제점</trans></label>
									<input type="text" class="form-control" v-model="copySetting.objects.bottom.point" />
								</div>
								<div class="col-md-2">
									<label><trans>소수점자리</trans></label>
									<valid-input
										:inputType="'number'"
										:inputValue.sync="copySetting.objects.bottom.addition.fixed"
										:classList="'form-control'"
										:NonStatus="false"
										:errorMessage="validMessage.WIDGET_DECIMAL_AND_FONTSIZE_LENGTH_VALID_MESSAGE"
										:rules="rules.DASHBOARD_WIDGET_DECIMAL_RULE"
									>
									</valid-input>
								</div>
							</div>

							<div class="row form-group">
								<div class="col-md-12">
									<label
										><b><trans>후처리</trans></b></label
									>
									<valid-textarea
										:inputType="'text'"
										:inputValue.sync="copySetting.objects.bottom.addition.afterAction"
										:classList="'form-control'"
										:rows="5"
										:NonStatus="true"
										:rules="rules.DASHBOARD_WIDGET_POSTPROCESS_RULE"
									>
									</valid-textarea>
								</div>
							</div>
							<label
								><b><trans>취합방법</trans></b></label
							>
							<div class="row form-group">
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_bottom_afterBind_Last' + copySetting.id" value="Last" v-model="copySetting.objects.bottom.addition.afterBind" />
										<label :for="'goalcard_bottom_afterBind_Last' + copySetting.id">{{$t('마지막(Last)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_bottom_afterBind_Min' + copySetting.id" value="Min" v-model="copySetting.objects.bottom.addition.afterBind" />
										<label :for="'goalcard_bottom_afterBind_Min' + copySetting.id">{{$t('최소(Min)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_bottom_afterBind_Max' + copySetting.id" value="Max" v-model="copySetting.objects.bottom.addition.afterBind" />
										<label :for="'goalcard_bottom_afterBind_Max' + copySetting.id">{{$t('최대(Max)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_bottom_afterBind_Sum' + copySetting.id" value="Sum" v-model="copySetting.objects.bottom.addition.afterBind" />
										<label :for="'goalcard_bottom_afterBind_Sum' + copySetting.id">{{$t('합계(Sum)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'goalcard_bottom_afterBind_Avg' + copySetting.id" value="Avg" v-model="copySetting.objects.bottom.addition.afterBind" />
										<label :for="'goalcard_bottom_afterBind_Avg' + copySetting.id">{{$t('평균(Avg)')}}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-12 p-0">
					<div slot="modal-footer" class="w-100">
						<button class="btn btn-primary m-r-5 pull-right" @click="onModalOK()"  ><trans>확인</trans></button>
						<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()"><trans>취소</trans></button>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";

	export default {
		extends: xeBaseSetting,
		data() {
			return {
				tabs: {
					general: true,
					main: false,
					sub1: false,
					sub2: false,
				},
				rules,
				validMessage
			};
		},
		methods: {
			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.main = false;
					this.tabs.sub1 = false;
					this.tabs.sub2 = false;
				} else if (tab == "main") {
					this.tabs.general = false;
					this.tabs.main = true;
					this.tabs.sub1 = false;
					this.tabs.sub2 = false;
				} else if (tab == "sub1") {
					this.tabs.general = false;
					this.tabs.main = false;
					this.tabs.sub1 = true;
					this.tabs.sub2 = false;
				} else if (tab == "sub2") {
					this.tabs.general = false;
					this.tabs.main = false;
					this.tabs.sub1 = false;
					this.tabs.sub2 = true;
				}
			},
		},
	};
</script>
