<template>
	<div>
		<div class="form-group row m-t-10">
			<div class="col-md-12">
				<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }"><trans>일반</trans></a>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md-12">
				<div class="tab-content">
					<!-- 일반(탭) -->
					<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.general }">
						<br />
						<div class="form-group row">
							<div class="col-md-12">
								<label
									><h6><trans>항목명</trans></h6></label
								>
								<valid-input :inputType="'text'" :inputValue.sync="copySetting.title" :rules="rules.DASHBOARD_WIDGET_ITEM_NAME_RULE" :classList="'form-control'" :NonStatus="true"> </valid-input>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-md-12">
								<label>
									<h6>
										<trans>관제점</trans>
									</h6>
								</label>

								<div :style="{ fontWeight: 'normal' }">
									<tree-select
										class="col-12 pr-0 pl-0"
										v-model="copySetting.ptaddr"
										:options="searchPointInfoOptions"
										:disable-branch-nodes="true"										
										:open-on-focus="true"
										:placeholder="$t(controlMessages.POINT_TREE_PLACEHOLDER_MESSAGE)"
										:noChildrenText="$t(controlMessages.POINT_TREE_NOCHILDERN_MESSAGE)"
										:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md-12 p-0">
				<div slot="modal-footer" class="w-100">
					<button type="button" class="btn btn-primary m-r-5 pull-right" @click="onModalOK()">
						<trans>확인</trans>
					</button>
					<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()">
						<trans>취소</trans>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	export default {
		extends: xeBaseSetting,
		components: {},
		data() {
			return {
				tabs: {
					general: true,
					card: false,
					main: false,
					Sub: false,
				},
				// define the default value
				zoneIdx: null,
				// define options
				options: null,
				searchPoint: null,
				searchPointInfoOptions: [],
				rules,
				popupMessages,
				controlMessages
			};
		},
		mounted() {
			this.getZoneListHierarchi();
			this.getSearchPoint();
		},
		methods: {
			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.card = false;
					this.tabs.main = false;
				} else if (tab == "card") {
					this.tabs.general = false;
					this.tabs.card = true;
					this.tabs.main = false;
				} else if (tab == "main") {
					this.tabs.general = false;
					this.tabs.card = false;
					this.tabs.main = true;
				}
			},
			onChangeColor(idx, color) {
				this.copySetting.objects.series.backgroundColor = color;
			},
			onChangeIcon(value) {
				if (!this.isEmpty(value)) this.copySetting.bgIcon = value.icon;
				else this.copySetting.bgIcon = "";
			},
			getZoneListHierarchi() {
				backEndApi.zoneInfo.getZoneListHierarchi().then(({ data }) => {
					this.options = [];

					let temp = [];
					temp.push(data);
					this.options = this.setZoneTreeOption(temp);
				});
			},
			setZoneTreeOption(resultData) {
				resultData.forEach((data) => {
					data.id = data.zoneIdx;
					data.label = data.zoneName;

					if (data["children"]) {
						return this.setZoneTreeOption(data["children"]);
					} else {
						return data;
					}
				});

				return resultData;
			},
			getSearchPoint() {
				this.searchPointInfoOptions = [];

				backEndApi.pointInfo.getpointByGroup().then(({ data }) => {
					this.searchPoint = data;

					this.searchPoint.map((point) => {
						const pointList = point.pointList;
						const groupObject = {
							id: point.groupCode,
							label: point.groupCodeName,
							children: [],
						};

						for (let i = 0; i < pointList.length; i++) {
							groupObject.children.push({
								id: pointList[i].ptAddr,
								label: pointList[i].ptName,
							});
						}

						this.searchPointInfoOptions.push(groupObject);
					});

					this.searchPointInfoOptions.sort((a, b) => {
						return a.label.localeCompare(b.label);
					});

					this.searchPointInfoOptions.unshift({
						id: "",
						label: "전체",
					});
				});
			},
		},
	};
</script>
@src/views/widget/v1/common/xeBaseSetting.js