<template>
  <div class="card-box align-middle" :style="[{ height: height + 'px' }, { margin: margin }]" style="min-height: 10px">
    <div
      class="card-rank f-w-600"
      :style="[
        { padding: padding }, 
        { 'font-size': fontSize * 0.2 + 'px' }, 
        { 'line-height': fontSize * 0.4 + 'px' }, 
        { 'background-color': card.backgroundColor || '#fff' }, 
        { width: card.compare ? 15 + '%' : 30 + '%' }
      ]"
      style="height:100%;"
    >
      {{ card.rank }} <sup> {{ rankUpper }} </sup>
    </div>

    <div
      class="card-label f-w-700"
      :style="[
        { padding: padding }, 
        { 'font-size': fontSize * 0.4 + 'px' }, 
        { 'background-color': card.backgroundColor || '#fff' }, 
        { width: card.compare ? 42.5 + '%' : 70 + '%' }
      ]"
      v-b-tooltip.hover
      :title="cardValue"
    >
      {{ $t(card.label) }}
    </div>

    <div 
      v-if="card.compare" 
      class="card-compare f-w-700" 
      :style="[
        { padding: padding }, 
        { 'font-size': fontSize * 0.4 + 'px' }, 
        { 'background-color': '#bec6ce' }, 
        { width: 42.5 + '%' }
      ]" 
      v-b-tooltip.hover :title="compareValue"
    >
      {{ $t(card.compare.label) }}
    </div>
  </div>
</template>

<style scoped>
.card-box {
  width: 100%;
  text-align: center;
}
.card-rank {
  display: inline-block;
  text-align: left;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
}
/* .card-rank:hover {
  font-size: 24px;
} */

.card-label {
  display: inline-block;
  text-align: left;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
}
/* .card-label:hover {
  font-size: 100px;
} */

.card-compare {
  display: inline-block;
  text-align: left;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
}
</style>

<script>
export default {
  name: "xcRankCard",
  props: ["title", "card", "css", "inverseMode", "last"],
  data() {
    return {};
  },
  computed: {
    height() {
      return this.css.height;
    },
    padding() {
      return `${this.css.padding}px ${this.css.padding}px ${this.css.padding}px ${this.css.padding}px`;
    },
    margin() {
      // last가 true이면 마지막 카드의 마진을 0으로 변경
      if (this.last) return `0px 0px 0px 0px`;
      else return `0px ${this.css.margin}px ${this.css.margin}px 0px`;
    },
    fontSize() {
      return this.height - this.css.margin;
    },
    rankUpper() {
      return ["st", "nd", "rd"][this.card.rank - 1] || "th";
    },
    cardValue() {
      return this.comma(this.card.value || 0, this.card.fixed);
    },
    compareValue() {
      return 'Compare: ' + this.comma(this.card.compare.value || 0, this.card.fixed);
    },
  },
  methods: {
    initSetting() {},
  },
};
</script>
