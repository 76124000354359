import VueChartJS from 'vue-chartjs'

export default {
  extends: VueChartJS.Scatter,
  mixins: [VueChartJS.mixins.reactiveProp],
  props: ['chartData', 'options'],
  beforeDestroy() {
    if(this.$data._chart.destroy) this.$data._chart.destroy(); // where this.chart is a reference to an instance of a ChartJS object
  },
  mounted () {
    //this.init();
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    init(){
      //console.log("VueChartJS.Scatter-------------------", this.chartData," ----------------- ", this.options);
    }
  }
}