<template>
	<div ref="colorpicker">
		<div id="colorpicker">
			<div class="picker">
				<valid-input
					v-if="inputForm === 'hex'"
					:inputType="'text'"
					:inputValue.sync="inputColor"
					:rules="rules.DASHBOARD_WIDGET_VIEW_NAME_RULE"
					:NonStatus="true"
					:classList="'form-control fg-color'"
					@focus="showPicker"
				>
				</valid-input>

				<valid-input
					v-else-if="inputForm === 'label'"
					:inputType="'text'"
					:inputValue.sync="inputVal"
					:rules="rules.DASHBOARD_WIDGET_VIEW_NAME_RULE"
					:NonStatus="true"
					:classList="'form-control'"
					@focus="showPicker"
				>
				</valid-input>
				<div class="colorPadding">
					<button
						class="fg-color-sample form-control"
						v-on:click="toogle"
						v-bind:style="{
							background: inputColor,
						}"
					></button>
				</div>
			</div>
		</div>
		
		<sketch-picker class="sketch-picker" v-show="updateOpen" v-model="inputColor"></sketch-picker>
	</div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";

	import { Sketch } from "vue-color";

	export default {
		props: ["color", "isOpen", "inputForm", "text"],
		components: {
			"sketch-picker": Sketch,
		},
		computed: {
			inputVal: {
				get() {
					return this.text;
				},
				set(val) {
					this.$emit("input", val);
				},
			},
			inputColor: {
				get() {
					return this.color;
				},
				set(val) {
          console.log(val);
					this.$emit("input", val);
				},
			},
		},
		data() {
			return {
				colors: {
					hex: this.inputColor,
					source: "hex",
				},
				updateValue: "",
				updateOpen: this.opens,
				rules
			};
		},
    mounted(){
      console.log(this.colors, this.inputColor);
    },
		methods: {
			toogle() {
				document.addEventListener("click", this.documentClick);
				this.updateOpen = !this.updateOpen;
			},
			hidePicker() {
				document.addEventListener("click", this.documentClick);
				this.updateOpen = false;
			},
			showPicker() {
				document.addEventListener("click", this.documentClick);
				this.updateOpen = true;
			},
			documentClick(e) {
				var el = this.$refs.colorpicker,
					target = e.target;
				if (el !== target && !el.contains(target)) {
					this.hidePicker();
				}
			},
		},
		destroyed() {
			document.removeEventListener("click", this.documentClick);
			this.updateOpen = false;
		},
	};
</script>

<style scoped>
	.colorPadding {
		background-color: #d5dbe0;
		padding: 7px;
		display: grid;
		align-content: center;
		border-radius: 3px;
	}
	.fg-color {
		flex: 1;
	}

	.fg-color-sample {
		display: inline-block;
		padding: 10px;
		height: 0;
	}

	.picker {
		display: flex;
	}

  .picker span{
    width: 100%;
  }

	.sketch-picker {
		position: absolute;
		z-index: 9;
	}
</style>
