<template>
	<div :style="docStyle" :class="['xe-dashboard ' + (isFullScreen ? ' m-auto m-l-40 m-r-40 m-t-20' : '')]">
		<!-- 에디트모드 툴박스 -->
		<vvo v-slot="v" ref="dashboardVal" style="width: 450px;">
			<div class="row m-r-1 m-l-1">
				<div class="col-lg-12 bg-white m-b-20 p-10" v-if="isEditMode">
					<div class="btn-group">
						<button class="btn btn-white" @click="onValidate(v, onSave)" v-b-tooltip.hover :title="$t('대시보드 저장')">
							<!-- <button class="btn btn-white" @click="v.invalid === false ? onSave() : alertPopupError(v.errors, v.fields)" v-b-tooltip :title="$t('대시보드 저장')"> -->
							<i class="fa fa-save"></i> {{ $t("저장") }}
						</button>
						<button class="btn btn-white" @click="openSaveAsPopup()" v-b-tooltip.hover :title="$t('대시보드 다른 이름으로 저장')"><i class="fa fa-copy"></i> {{ $t("다른 이름으로 저장") }}</button>
						<button class="btn btn-white" @click="openAnalysisModal()" v-b-tooltip.hover :title="$t('대시보드 추가')"><i class="fa fa-plus"></i> {{ $t("추가") }}</button>
						<button class="btn btn-white" @click="onToggleEdit()"><i class="fa fa-window-close"></i> {{ $t("닫기") }}</button>
					</div>
				</div>
			</div>
			<!-- 타이틀 및 서브페이지 탭 -->
			<div class="row">
				<!-- 타이틀 -->
				<div class="col-lg-4">
					<div class="pull-left" :class="{ 'w-100': isEditMode }">
						<template v-if="isEditMode">
							<valid-textarea
								:vid="'대시보드 제목'"
								:inputType="'text'"
								:inputValue.sync="currentPage.title"
								:classList="'xe-wg-input page-header'"
								:rules="rules.DASHBOARD_MOTIFY_TITLE_RULE"
								:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
								style="min-height: 30px;"
							></valid-textarea>

							<!-- <textarea
              type="text"
              class="xe-wg-input page-header"
              maxlength="100"
              placeholder="content here"
              style="min-height: 30px;"
              v-model="currentPage.title"
              v-on:keyup.enter="
                $event.target.nextElementSibling
                  ? $event.target.nextElementSibling.focus()
                  : null
              "
              v-on:keydown.up="
                $event.target.previousElementSibling
                  ? $event.target.previousElementSibling.focus()
                  : null
              "
              v-on:keydown.down="
                $event.target.nextElementSibling
                  ? $event.target.nextElementSibling.focus()
                  : null
              "
            ></textarea> -->
						</template>
						<template v-else>
							<h1 class="page-header font-weight-bold">
								<i class="fa" :class="[currentPage.icon]"></i>
								{{ $t(currentPage.title) }}
							</h1>
						</template>
					</div>
					<div v-if="!isMobile">
						<b-dropdown text="" variant="default" toggle-class="xe-btn-transparent" v-if="!isEditMode && showDocOption">
							<b-dropdown-item @click="onRefresh()">
								<i class="fa m-r-5"></i> <i class="fa fa-redo m-r-5"></i>
								{{ $t("새로고침") }}
							</b-dropdown-item>
							
              <b-dropdown-item v-xe-pm.E @click="onToggleEdit()">
								<i class="fa m-r-5" :class="{ 'fa-check': isEditMode }"></i>
								<i class="fa fa-pencil-alt m-r-5"></i> {{ $t("대시보드 편집") }}
							</b-dropdown-item>

							<!-- 20210204주석
            <b-dropdown-item @click="onToggleFullScreen()">
              <i
                class="fa m-r-5"
                :class="{ 'fa-check': pageOptions.pageEmpty }"
              ></i>
              <i class="fa fa-expand m-r-5"></i> {{ $t("전체화면") }}
            </b-dropdown-item> -->
						</b-dropdown>
					</div>
				</div>
				<!-- 서브페이지 탭 -->
				<div class="col-lg-8">
					<ul class="nav pull-right p-r-30" v-if="pageCount > 1 || isEditMode">
						<a href="javascript:;" v-for="(page, i) in viewSet.pages" :key="i" class="xe-tab-underline" :class="{ 'xe-tab-line-blue': tabIdx == i }">
							<i v-on:click.prevent="toggleTabs(i)" :class="page.icon ? page.icon : 'fa fa-question'"></i>
							<span class="d-none d-md-inline" v-on:click.prevent="toggleTabs(i)">
								{{ $t(page.title) }}
							</span>
							<i class="fa fa-pencil-alt m-l-5" v-b-tooltip.hover :title="$t('대시보드 편집')" @click="openEditPage(page, i)" v-if="isEditMode"></i>
						</a>
						<li class="nav-item" v-if="isEditMode">
							<button v-if="isEditMode" class="btn btn-white" v-b-tooltip.hover :title="$t('대시보드 추가')" @click="onAddNewPage('xeWidgetPageV2')">
								<i class="fa fa-plus"></i>
							</button>
						</li>
					</ul>
				</div>
			</div>

			<!-- 위젯페이지 -->
			<div class="tab-content p-0" v-if="isShow">
				<div v-for="(page, i) in viewSet.pages" :key="i" class="tab-pane fade" :class="{ 'active show': tabIdx == i }" @keydown.esc="onFullScreen(false)">
					<xe-widget-page-v2 ref="widgetV2" v-if="tabIdx == i && page.type == 'xeWidgetPageV2'" :page="page" />
				</div>
			</div>

			<!-- 다른 이름으로 저장 팝업 -->
			<vvo v-slot="w">
				<b-modal ref="saveAsPopup" body-class="p-20" cancel-variant="default" :title="$t('다른 이름으로 저장')">
					<div class="row form-group">
						<div class="col-md-12">
							<label><trans>저장할 새 대시보드 제목</trans></label>

							<valid-input
								:vid="'새 대시보드 제목'"
								:inputType="'text'"
								:inputValue.sync="saveAsViewCode"
								:classList="'form-control'"
								:rules="rules.DASHBOARD_MOTIFY_TITLE_RULE"
								:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
							></valid-input>
						</div>
					</div>
					<template #modal-footer="{ ok, cancel, hide }">
						<b-button size="sm" variant="danger" @click="cancel()">
							취소
						</b-button>
						<!-- Emulate built in modal footer ok and cancel button actions -->
						<b-button size="sm" variant="primary" @click="onValidate(w, onSaveAs)">
							저장
						</b-button>
					</template>
				</b-modal>
			</vvo>
			<!-- 대시보드 추가 팝업 -->
			<b-modal ref="analysisModal" :hide-footer="true" :title="$t('대시보드 추가')">
				<analysis-detail @save-done="onAnalysisSaveDone"></analysis-detail>
			</b-modal>

			<!-- 페이지탭 속성 팝업 -->
			<b-modal ref="editPagePopup" cancel-variant="default" hide-footer :title="$t('대시보드 상세')">
				<div v-if="changePage">
					<vvo v-slot="t">
						<div class="row form-group">
							<div class="col-md-12">
								<label> <trans>제목</trans> </label>
								<valid-input
									:vid="'제목'"
									:inputType="'text'"
									:inputValue.sync="changePage.title"
									:rules="rules.DASHBOARD_NEW_TITLE_RULE"
									:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
								></valid-input>
							</div>
						</div>
						<div class="row form-group">
							<div class="col-md-12">
								<label> <trans>아이콘</trans> </label>
								<icon-select :current="changePage.icon" @on-change="onChangeIcon" />
							</div>
						</div>
						<div class="row form-group">
							<div class="col-md-6">
								<label> <trans>시간유형</trans> </label>
								<select class="form-control" v-model="changePage.search.timeType">
									<option :value="5">{{$t('분')}}</option>
									<option :value="4">{{$t('시')}}</option>
									<option :value="3">{{$t('일')}}</option>
									<option :value="2">{{$t('월')}}</option>
									<option :value="1">{{$t('년')}}</option>
								</select>
							</div>
							<div class="col-md-6">
								<label>&nbsp;</label>
								<div class="checkbox checkbox-css">
									<input type="checkbox" :id="'editPagesPopup_isLoadSearch'" v-model="changePage.search.isLoadSearch" />
									<label :for="'editPagesPopup_isLoadSearch'">
										<trans>화면 로드시 조회</trans>
									</label>
								</div>
							</div>
						</div>
						<div class="row form-group">
							<div class="col-md-6">
								<div class="checkbox checkbox-css">
									<input type="checkbox" :id="'editPagesPopup_display'" v-model="changePage.search.display" />
									<label :for="'editPagesPopup_display'">
										<trans>조회상자 사용</trans>
									</label>
								</div>
							</div>
							<div class="col-md-6">
								<div class="checkbox checkbox-css">
									<input type="checkbox" :id="'editPagesPopup_isCompare'" v-model="changePage.search.isCompare" />
									<label :for="'editPagesPopup_isCompare'">
										<trans>비교조회모드 사용</trans>
									</label>
								</div>
							</div>
						</div>
						<div slot="modal-footer" class="w-100">
							<button class="btn btn-sm pull-right btn-primary" @click="onValidate(t, onUpdatePage)"><i class="fa fa-save"></i> <trans>확인</trans></button>
							<button class="btn btn-sm pull-left btn-danger" @click="onRemovePage(changePage, changeIdx)"><i class="fa fa-times"></i> <trans>삭제</trans></button>
						</div>
					</vvo>
				</div>
			</b-modal>
		</vvo>
	</div>
</template>

<style scoped>

@media (max-width: 1450px) {
	.xe-dashboard {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.page-header {
		width: 300px;
	}
}
	/* tab-content 배경색 */
	.panel {
		background-color: rgba(0, 0, 0, 0);
	}
	/* dnd */
	.xe-drop-me {
		min-height: 50px;
		background-color: whitesmoke;
	}
	[draggable] {
		cursor: pointer;
		-moz-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		user-select: none;
	}
	li {
		color: black;
	}
	.dragging {
		opacity: 0.8;
		color: #6894d1;
	}
	.drag-over {
		outline: 1px solid red;
	}
	.drag-enter {
		color: #c93742;
	}
	/* 동작하지 않음.. */
	.dropdown-menu {
		left: -52px !important;
	}
</style>

<script>
	//import PageOptions from "../../config/PageOptions.vue";
	import backEndApi from "@api/backEndApi.js";
	import xeWidgetPageV2 from "./WidgetPageV2.vue";
	import AnalysisDetail from "@src/views/sys/analysisView/visual/AnalysisDetail.vue";
	import AppErrorHandler from "@src/appErrorHandler";

	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {
			xeWidgetPageV2,
			AnalysisDetail,
		},
		props: ["initViewCode"],
		data() {
			return {
				//pageOptions: PageOptions,
				showDocOption: true, //
				isShow: true,
				viewCode: this.$route.params.viewCode,
				viewSet: {},
				originPage: {},
				tabIdx: 0,
				saveAsViewCode: "",
				newViewCode: "",

				changePage: null,
				changeIdx: -1,

				//to: null,

				widget_property_box_width: 300,
				rules,
				validMessage,
				popupMessages,
			};
		},
		created() {
			console.log(this);
		},
		computed: {
			isEditMode() {
				return this.$store.getters.isEditMode;
			},
			isFullScreen() {
				return this.pageOptions.pageEmpty;
			},
			docStyle() {
				// 수정모드때 perperty-bar 300px 만큼..
				if (this.isEditMode)
					return {
						width: "100%",
					};

				return this.isFullScreen && this.currentPage.width ? { " width": this.currentPage.width } : "{}";
			},
			pageCount() {
				if (this.viewSet && this.viewSet.pages) return this.viewSet.pages.length;
				return 0;
			},
			currentPage() {
				if (this.viewSet && this.viewSet.pages && this.viewSet.pages.length > this.tabIdx) {
					return this.viewSet.pages[this.tabIdx];
				}
				return {};
			},
		},
		async mounted() {
			if (this.initViewCode) this.viewCode = this.initViewCode;
			await this.initSetting();
		},
		beforeRouteLeave(to, from, next) {
			this.pageOptions.pageEmpty = false;
			//clearInterval(this.to);
			next();
		},
		beforeDestroy() {
			//clearInterval(this.to);
		},
		watch: {
			"$route.params.viewCode": async function(newV) {
				if (this.initViewCode) return;
				this.viewCode = newV;
				await this.initSetting();
			},
		},
		methods: {
			async initSetting() {
				if (this.isShow == false) return;
				this.isShow = false; // 진행중

				this.tabIdx = 0;
				this.saveAsViewCode = "";
				this.newViewCode = "";

				// TODO: 일반화면 메뉴에서 로고를 눌렀을때 대시보드이 표시되지 않음..
				// this.$route.params.viewCode     "/" 경로만 표시됨..

				if (this.isEmpty(this.viewCode)) {
					console.warn("viewCode is empty..", this.$route);
					return;
				}

				// 현재 페이지의 viewCode와 동작중인 viewCode가 같이 않으면 리턴한다.
				if (this.viewCode !== this.$route.params.viewCode) {
					return;
				}

				await this._initViewCode();
			},
			async _initViewCode() {
				// console.log(this.viewCode);
				// template 미리 보기 작업 해야함

				// backEndApi.visual.getViewSet(this.viewCode).then((res) => {
				// 	console.log(res);

				// 	if (this.$err(res.data)) return;

				// 	let data = res.data;
				// 	if (!data.hasOwnProperty("pages") || data.pages.length <= 0) {
				// 		return;
				// 	}

				// 	data.pages.forEach((page, i) => {
				// 		// cellSize 고정 - 화면크기에 따라 변경되도록 수정되어, 기본값 10으로 고정됨...
				// 		page.cellSize = { w: 10, h: 10, margin: 0 };

				// 		if (page.widgets) {
				// 			page.widgets.forEach((widget, j) => {
				// 				widget.i = widget.id = `W${(i + 1).pad(3)}-${(j + 1).pad(3)}`;

				// 				if (this.isEmpty(widget.position)) {
				// 					widget.position = { x: (j % 4) * 10, y: j * 2, w: 20, h: 20 };
				// 				}
				// 			});
				// 		}
				// 	});

				// 	this.viewSet = data;

				// 	/** 저장 혹은 닫기 시 데이터 복원 등을 위해서 변수 추가 */
				// 	this.originPage = JSON.stringify(data);
				// 	this.isShow = true;
				// });

				/*           
				↓↓↓↓↓
			*/

				try {
					const res = await backEndApi.visual.getViewSet(this.viewCode);

					let data = res.data;
					if (!data.hasOwnProperty("pages") || data.pages.length <= 0) {
						return;
					}

					data.pages.forEach((page, i) => {
						// cellSize 고정 - 화면크기에 따라 변경되도록 수정되어, 기본값 10으로 고정됨...
						page.cellSize = { w: 10, h: 10, margin: 0 };

						if (page.widgets) {
							page.widgets.forEach((widget, j) => {
								widget.i = widget.id = `W${(i + 1).pad(3)}-${(j + 1).pad(3)}`;

								if (this.isEmpty(widget.position)) {
									widget.position = { x: (j % 4) * 10, y: j * 2, w: 20, h: 20 };
								}
							});
						}
					});

					this.viewSet = data;

					/** 저장 혹은 닫기 시 데이터 복원 등을 위해서 변수 추가 */
					this.originPage = JSON.stringify(data);
					this.isShow = true;
				} catch (err) {
					new AppErrorHandler(err).printErrorLog(true);
				}
			},
			// 페이지 전체 save
			async onSave() {
				// this.alertQuestion(popupMessages.DASHBOARD_SAVE_POPUP_MESSAGE).then((result) => {
				// 	if (!result.value) return;

				// 	let param = { viewCode: this.viewCode, option: this.viewSet };

				// 	// console.log(param);

				// 	backEndApi.visual.saveViewSet(param).then(({ data }) => {
				// 		if (this.$err(data)) return;

				// 		this.alertNoti(`${popupMessages.DASHBOARD_SAVE_SUCCESS_POPUP_MESSAGE}`);

				//     this.originPage = JSON.stringify(this.viewSet);
				// 	});
				// });

				/*           
				↓↓↓↓↓
			*/

				try {
					const result = await this.alertQuestion(popupMessages.DASHBOARD_SAVE_POPUP_MESSAGE);
					if (!result.value) return;

					let param = { viewCode: this.viewCode, option: this.viewSet };

					await backEndApi.visual.saveViewSet(param);

					await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
					this.originPage = JSON.stringify(this.viewSet);

					this.onRefresh();
				} catch (err) {
					new AppErrorHandler(err).printErrorLog(true);

					return;
				}
			},
			openSaveAsPopup() {
				this.$refs.saveAsPopup.show();
			},
			// 새로운 대시보드 추가
			openAnalysisModal() {
				this.$refs.analysisModal.show();
			},
			onAnalysisSaveDone(newViewCode) {
				this.$refs.analysisModal.hide();

				// 페이지 새로 생성시 해당 페이지로 이동
				this.$router.push({ path: `/visual/analysis/${newViewCode}` });
			},
			// 페이지 전체 save as
			async onSaveAs() {
				// this.alertQuestion(popupMessages.DASHBOARD_SAVE_POPUP_MESSAGE).then((result) => {
				// 	if (!result.value) return;

				// 	let param = {
				// 		viewCode: this.saveAsViewCode,
				// 		option: JSON.parse(JSON.stringify(this.viewSet)),
				// 	};

				// 	backEndApi.visual.saveAsViewSet(param).then(({ data }) => {
				// 		if (this.$err(data)) {
				// 			// TODO: 어떤 오류인지 알지 못하는데.. 오류메세지를 어떻게???
				// 			// this.notiBottom("이미 존재하는 파일입니다. 다시 시도해주세요.");
				// 			return;
				// 		}

				// 		this.$nextTick(() => {
				// 			this.alertNoti(`${popupMessages.DASHBOARD_SAVE_SUCCESS_POPUP_MESSAGE}`);
				// 			this.originPage = JSON.stringify(this.viewSet);
				// 		});

				// 		this.$router.push({
				// 			path: `/visual/analysis/${this.saveAsViewCode}`,
				// 		});
				// 	});
				// });

				/*           
				↓↓↓↓↓
			*/

				try {
					const result = await this.alertQuestion(popupMessages.DASHBOARD_SAVE_POPUP_MESSAGE);
					if (!result.value) return;

					let param = {
						viewCode: this.saveAsViewCode,
						option: JSON.parse(JSON.stringify(this.viewSet)),
					};

					await backEndApi.visual.saveAsViewSet(param);

					this.$nextTick(async () => {
						await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.originPage = JSON.stringify(this.viewSet);
					});

					this.$router.push({
						path: `/visual/analysis/${this.saveAsViewCode}`,
					});
				} catch (err) {
					new AppErrorHandler(err).printErrorLog(true);
				}
			},
			// tab
			toggleTabs(idx) {
				this.tabIdx = idx;
			},
			openEditPage(page, i) {
				this.changePage = Object.assign({}, page);
				this.changeIdx = i;
				this.$refs.editPagePopup.show(); // 팝업띄우기..
			},
			onAddNewPage(pageType) {
				let newPage = {
					type: pageType, // "xeWidgetPageV2",
					title: this.$t("빈 제목"),
					icon: "fa fa-chart-bar",
					search: {
						isCompare: false,
						timeType: 3,
						isLoadSearch: false,
						display: true,
					},
					widgets: [],
				};

				this.viewSet.pages.push(newPage);
			},
			// Page 속성 수정팝업
			onChangeIcon(value) {
				this.changePage.icon = null;
				if (!this.isEmpty(value)) this.changePage.icon = value.icon;
				// 페이지속성 팝업에서 아이콘을 수정하였을때
			},
			onUpdatePage() {
				let cur = this.viewSet.pages[this.changeIdx];

				cur.title = this.changePage.title;
				cur.icon = this.changePage.icon;
				cur.search = {
					isCompare: this.changePage.search.isCompare,
					timeType: this.changePage.search.timeType,
					isLoadSearch: this.changePage.search.isLoadSearch,
					display: this.changePage.search.display,
				};

				this.$refs.editPagePopup.hide();
			},
			onRemovePage(page, pageIdx) {
        console.log(page, pageIdx, this.viewSet.pages);


        if(this.viewSet.pages.length <= 1){
          this.alertDanger(popupMessages.DASHBOARD_DELETE_LESS_THAN_ONE_MESSAGE);
          return
        }

				this.alertConfirmWarning(popupMessages.DASHBOARD_DELETE_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					this.viewSet.pages.splice(pageIdx, 1);
					this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					this.$refs.editPagePopup.hide();
				});
			},
			// 추가기능 버튼
			onRefresh() {
				console.log(this.$refs);

				if (this.$refs.widget && this.$refs.widget[this.tabIdx]) this.$refs.widget[this.tabIdx].onRefresh();

				if (this.$refs.widgetV2 && this.$refs.widgetV2[this.tabIdx]) {
					setTimeout(() => {
						this.$refs.widgetV2[this.tabIdx].onRefresh();
					}, 500);
				}

				if (this.$refs.widgetV3 && this.$refs.widgetV3[this.tabIdx]) {
					this.$refs.widgetV3[this.tabIdx].onRefresh();
				}
			},
			onToggleEdit() {
				// console.log(this.viewSet., this.originPage);
				// console.log(this.viewSet.pages[0].widgets[9].chart, JSON.parse(this.originPage).pages[0].widgets[9].chart);
				let is = !this.isEditMode;
				this.$store.commit("UPDATE_EDIT_MODE", is);
				this.$store.commit("UPDATE_TOOLBAR", is);
				this.viewSet = JSON.parse(this.originPage);
				this.onRefresh();
			},
			onToggleFullScreen() {
				this.pageOptions.pageEmpty = !this.pageOptions.pageEmpty;
			},
			onFullScreen(isFull) {
				this.pageOptions.pageEmpty = isFull;
			},
			onPropertyBoxWidth(w) {
				this.widget_property_box_width = w;
			},
		},
	};
</script>
