<template>
  <div class="xeChartRadar heightMAX">
    <panel
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="inverseMode ? 'inverse' : panelVariant"
      :bodyClass="'p-5 chartHeightMax ' + inverseBg"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div v-if="isShow" class="heightMAX">
          <radar-chart :chartData="radarChart.data" :options="radarChart.options" class="heightMAX" :style="{ height: chartHeight }"></radar-chart>
        </div>
      </drop>
    </panel>
    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div v-if="isShow" class="m-b-20 heightMAX" :class="[inverseBg]">
        <h4>{{ $t(widget.title) }}</h4>
        <radar-chart :chartData="radarChart.data" :options="radarChart.options" class="height-sm heightMAX"></radar-chart>
      </div>
    </drop>
    <b-modal ref="modalSetting" size="lg" title="설정 - RadarChart" bodyClass="p-t-0 p-b-0" hide-footer>
      <chart-radar-setting :initSetting="chartRadarSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>

  </div>
</template>

<style scoped></style>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import RadarChart from "@src/components/vue-chartjs/RadarChart.js";
import chartRadarSetting from "@src/views/widget/v1/setting/xeChartRadarSetting.vue";
import utils from "./xeBaseUtils.js";

// TODO: backgroundColor 를 pointColor로 개명하고, 설정에 추가...
// "type": "xeChartRadar",
// "title": "(Unit : kWh)",
// "panel": { "display": true },
// "id": "W001-003",
// "hidden": false,
// "pinned": false,
// "position": { "x": 29, "y": 0, "w": 28, "h": 28 },
// "series": [
//   {
//     "label": "LED Lighting",
//     "point": "WEAT_NLI_NINTEMP",
//     "backgroundColor": "#348fe2",  <-----------------  점의 색상임
//     "fill": false
//   },

export default {
  name: "xeChartRadar",
  extends: xeBaseWidget,
  components: {
    RadarChart,
    chartRadarSetting,
  },
  data() {
    return {
      radarChart: {
        type: "radar",
        data: {},
        options: {},
      },
    };
  },
  computed: {
    chartRadarSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  created() {
    // 초기값 설정
    if (!this.widget.series) {
      this.widget.series = [
        // { label: "Default1", point: "", backgroundColor: "#348fe2", "fill": true },
        // { label: "Default2", point: "", backgroundColor: "#348fe2", "fill": true },
        // { label: "Default3", point: "", backgroundColor: "#348fe2", "fill": true }
        { label: "Default1", point: "" },
        { label: "Default2", point: "" },
        { label: "Default3", point: "" },
      ];
    }
    if (!this.widget.yAxes) this.widget.yAxes = [{ label: "default", backgroundColor: "rgba(135,83,222,.3)", compare: false }];
    if (!this.widget.addition) this.widget.addition = { afterAction: "", afterBind: "", isPercentage: true, legend: { display: true, position: "bottom" }, fixed: 0 };
    if (!this.widget.self) this.widget.self = { baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" }, compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" } };
  },
  mounted(){
    let raders = document.getElementsByClassName("xeChartRadar");

    for(let i = 0; i < raders.length; i++){
      raders[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.radarChart.options = this.createChartOptions();
      this.radarChart.data = {
        labels: this.defaultLabels(),
        datasets: this.defaultDatasets(),
      };
    },
    defaultLabels() {
      return this.widget.series.map((item) => {
        return item.label;
      });
    },
    // 차트 데이터 기본값 설정
    defaultDatasets() {
      let isPercentage = this.widget.addition.isPercentage ? this.widget.addition.isPercentage : false;
      let points = this.widget.series.map((v) => {
        return v.point;
      });

      return this.widget.yAxes
        .filter((v) => !v.compare || this.isCompare)
        .map((item) => {
          return {
            label: item.label,
            backgroundColor: item.backgroundColor, //선의 채우기색
            pointRadius: 2,
            borderWidth: 2,
            data: [],
            points: points,
            compare: item.compare,
            isPercentage: isPercentage,
          };
        });
    },
    // 차트 기본 옵션 설정
    createChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: this.widget.addition.legend.display, position: this.widget.addition.legend.position, labels: { usePointStyle: true, boxWidth: 6 } },
        tooltips: {
          mode: "index",
          intersect: true,
          callbacks: {
            title: function() {
              return "";
            },
            label: (tooltipItem, data) => {
              let label = data.labels[tooltipItem.index];
              let currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              currentValue = this.comma(currentValue, this.widget.addition.fixed);

              if (!data.isPercentage) return `${label}: ${currentValue}`;
              return `${label}: ${currentValue}%`;
            },
          },
        },
        scale: {
          ticks: {
            display: false,
          },
        },
      };
    },
    // 차트 데이터 바인딩
    createDatasets(logs) {
      let datasets = this.defaultDatasets();

      // datasets 그대로 못씀 afterAction에서 사용될 형태로 재생성
      let actionObj = datasets[0].points.map((v) => {
        return {
          point: v,
          compare: datasets[0].compare,
        };
      });

      // 기존 로그 복사하여 데이터 변형 작업 진행
      let copyLogs = JSON.parse(JSON.stringify(logs));
      // afterAction 처리
      copyLogs = utils.onAfterAction2(actionObj, copyLogs, this.widget.addition.afterAction);

      return datasets.map((dataset) => {
        let log = dataset.compare ? copyLogs[1] : copyLogs[0];

        if (!log) {
          console.warn("log not found.");
          return;
        }

        dataset.points.forEach((point) => {
          let ptVals = log.points[point] || [];

          let value = utils.onAfterBind(point, ptVals, this.widget.addition.afterBind);

          value = utils.fixedValue(value, this.widget.addition.fixed);

          dataset.data.push(value);
        });
        // dataset.data = ptVals;
        return dataset;
      });
    },
    // virtual
    applyData(widgetLogs) {
      this.isShow = false;
      this.radarChart.data.datasets = this.createDatasets(widgetLogs);
      // this.widgetLogs = widgetLogs;

      this.$nextTick(() => {
        this.isShow = true;
      });
      // console.log("this.radarChart.data.datasets", this.radarChart.data.datasets)
    },
    // 드래그
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "label": "냉난방", "point": "084000001_0000_0000_VLI_00000001", "backgroundColor": "#348fe2", "fill": false },

        points.map((point) => {
          if (that.widget.series.filter((v) => v.point === point.ptAddr).length == 0) {
            // 중복 체크
            //that.widget.series.push({ label: point.title, point: point.ptAddr, backgroundColor: that.getRandomColor(), fill: true });
            that.widget.series.push({ label: point.title, point: point.ptAddr });
          }
        });
      }

      //let defaultObj = { label: "Default1", point: "", backgroundColor: "#348fe2", fill: true };
      let defaultObj = { label: "Default1", point: "" };

      if (JSON.stringify(this.widget.series.slice(0, 1)) == JSON.stringify([defaultObj])) this.widget.series = [];
      append(transfer.item.type === this.CODE.Drag.PointGroup ? transfer.item.points : [transfer.item]);
    },
  },
};
</script>
