import VueChartJS from "vue-chartjs";

export default {
  extends: VueChartJS.Bar,
  mixins: [VueChartJS.mixins.reactiveProp],
  props: ['chartData', 'options'],
  computed:{
  },
  watch: {
    chartData: function(newData){
      //console.log("MaxChart watch-------------------", this.chartData," ----------------- ", this.options);
      
      // if(this.$data._chart && this.$data._chart.destroy) this.$data._chart.destroy();
      // this.renderChart(this.chartData, this.options)

      // 깜빡임 없는 live 업데이트
      // https://stackoverflow.com/questions/43728332/vue-chart-js-chart-is-not-updating-when-data-is-changing
      this.$data._chart.data.datasets[0].data = newData;
      this.$data._chart.update();
    },
    // "chartData.datasets": function(){
    //   console.log("chartData.datasets watch");
    //   this.renderChart(this.chartData, this.options)
    // },
    // "chartData.labels": function(){
    //   this.renderChart(this.chartData, this.options)
    // },
  },
  beforeDestroy() {
    if(this.$data._chart && this.$data._chart.destroy) this.$data._chart.destroy(); // where this.chart is a reference to an instance of a ChartJS object
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    render(){
      this.renderChart(this.chartData, this.options);
    },
  }
}