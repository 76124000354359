var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-box align-middle",staticStyle:{"min-height":"10px"},style:([{ height: _vm.height + 'px' }, { margin: _vm.margin }])},[_c('div',{staticClass:"card-rank f-w-600",staticStyle:{"height":"100%"},style:([
      { padding: _vm.padding }, 
      { 'font-size': _vm.fontSize * 0.2 + 'px' }, 
      { 'line-height': _vm.fontSize * 0.4 + 'px' }, 
      { 'background-color': _vm.card.backgroundColor || '#fff' }, 
      { width: _vm.card.compare ? 15 + '%' : 30 + '%' }
    ])},[_vm._v(" "+_vm._s(_vm.card.rank)+" "),_c('sup',[_vm._v(" "+_vm._s(_vm.rankUpper)+" ")])]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"card-label f-w-700",style:([
      { padding: _vm.padding }, 
      { 'font-size': _vm.fontSize * 0.4 + 'px' }, 
      { 'background-color': _vm.card.backgroundColor || '#fff' }, 
      { width: _vm.card.compare ? 42.5 + '%' : 70 + '%' }
    ]),attrs:{"title":_vm.cardValue}},[_vm._v(" "+_vm._s(_vm.$t(_vm.card.label))+" ")]),(_vm.card.compare)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"card-compare f-w-700",style:([
      { padding: _vm.padding }, 
      { 'font-size': _vm.fontSize * 0.4 + 'px' }, 
      { 'background-color': '#bec6ce' }, 
      { width: 42.5 + '%' }
    ]),attrs:{"title":_vm.compareValue}},[_vm._v(" "+_vm._s(_vm.$t(_vm.card.compare.label))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }