<template>
	<div>
			<div class="row form-group m-t-10">
				<div class="col-md-12">
					<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }">
						<trans>일반</trans>
					</a>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-12">
					<div class="tab-content">
						<div class="tab-pane fade" :class="{ 'active show': tabs.general }">
							<label>&nbsp;</label>
							<div class="row form-group">
								<div class="col-12">
									<label>
										<h6>
											<i class="fa fa-check"></i>
											<trans>항목명</trans>
										</h6>
									</label>

									<div class="row form-group align-items-center">
										<div class="col-md-12">
											<valid-input :inputType="'text'" :inputValue.sync="copySetting.title" :rules="rules.DASHBOARD_WIDGET_ITEM_NAME_RULE" :classList="'form-control'" :NonStatus="true">
											</valid-input>
										</div>
									</div>
								</div>
							</div>
							<!-- 아이콘 스타일 -->
							<div class="row form-group">
								<div class="col-12">
									<label>
										<h6>
											<i class="fa fa-check"></i>
											<trans>아이콘</trans>
										</h6>
									</label>

									<div class="row form-group align-items-center">
										<div class="col-md-12">
											<div class="row form-group align-items-center">
												<div class="col-md-8">
													<icon-select :current="copySetting.bgIcon" @on-change="onChangeIcon" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 아이콘 스타일 -->
							<hr />
							<xe-modal-self :copySetting="copySetting" :isCompare="true" />
						</div>
					</div>
				</div>
			</div>

			<div class="row form-group">
				<div class="col-md-12 p-0">
					<div slot="modal-footer" class="w-100">
						<button class="btn btn-primary m-r-5 pull-right" @click="onModalOK()"  >
							<trans>확인</trans>
						</button>
						<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()">
							<trans>취소</trans>
						</button>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	import * as rules from "@src/consts/ruleConsts.js";

	export default {
		extends: xeBaseSetting,
		data() {
			return {
				tabs: {
					general: true,
				},
				rules
			};
		},
		components: {},
		methods: {
			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.point = false;
					this.tabs.processing = false;
					this.tabs.chartOptions = false;
				}
			},

			onChangeIcon(value) {
				if (!this.isEmpty(value)) this.copySetting.bgIcon = value.icon;
				else this.copySetting.bgIcon = "";
			},
		},
	};
</script>
@src/views/widget/v1/common/xeBaseSetting.js