<template>
  <!-- class="heightMAX" -->
  <div class="xeCompareCard heightMAX">
    <drop
      @dragover="handleBodyDragOver(...arguments)"
      @drop="handleBodyDrop(...arguments)"
      class="heightMAX"
    >
      <div class="kbet-custom-reset heightMAX">
        <!-- <div
          class="rounded kbet-widget-valueCard-v1 heightMAX compareCardStyligV1"
          :style="{ backgroundColor: this.themeColor.card.itemColors[this.widget.index] }"
        > -->
        <div
            class="rounded kbet-widget-valueCard-v1 heightMAX compareCardStyligV1"
            :style="{ backgroundColor: this.widget.card.backgroundColor }"
          >
          <!-- 
            style="background-color: #58d4d2;" 
            <i class="material-icons bg-icon rotate-reversal">electrical_services</i> 
          -->
          <i
            class="bg-icon"
            :class="[widget.bgIcon.includes('material')? 'material-icons': widget.bgIcon,]"
            :title="widget.bgIcon"
          >
            {{widget.bgIcon.includes("material") ? widget.bgIcon.replace("material ", "") : ""}}
          </i>
          <p
            class="title"
            :style="{fontSize: '14px'}"
          >{{ $t(widget.title) }}</p>
          <div class="value-usage">
            <strong :style="{fontSize: '25px'}">{{ mainVal }}<span>{{ $t(main.tail) }}</span></strong>
          </div>
          <dl
            class="value-contrast"
            :style="{ fontSize: '11px'}"
          >
            <dt>{{ $t(bottom.label) }}  </dt>
            <dd
              class="contrast-up"
              v-if="differencialVal2 > 0.0"
            >
              <strong>+ {{differencialVal2}} %</strong>
            </dd>
            <dd
              class="contrast-down"
              v-if="differencialVal2 < 0.0"
            >
              <strong>{{differencialVal2}} %</strong>
            </dd>
            <dd
              class="contrast-same"
              v-if="differencialVal2 == 0.0 || !differencialVal2"
            >
              <strong>{{$t('변동없음')}}</strong>
            </dd>
          </dl>
        </div>
      </div>
    </drop>

    <b-modal
      ref="modalSetting"
      size="lg"
      :title="`${$t('설정')} - ${$t('데이터 카드')}`"
      body-class="p-t-0 p-b-0"
      hide-footer
    >
      <compare-card-setting
        :initSetting="compareCardSettings"
        @on-modal-cancel="onModalCancel"
        @on-modal-ok="onModalOK"
      />
    </b-modal>
  </div>
</template>
        <!-- <div class="heightMAX">
          <div style="textAlign:right;">
            <span
              class=""
              :style="{
                fontSize: this.widget.card.titleFontSize + 'px',
                color: this.widget.card.titleColor,
              }"
              >{{ $t(widget.title) }}</span
            >
          </div>
          <div class="m-t-10 m-b-10">
            <span class="stats-number" :class="[inverseText]" :style="{textAlign:this.widget.card.style.main.textAlign}">
              <p class="">
                <span
                  :style="{
                    fontSize: this.widget.card.style.main.fontSize + 'px',
                    fontWeight: this.widget.card.style.main.fontWeight,
                    color: this.widget.card.style.main.fontColor,
                  }"
                  >{{ mainVal }}
                </span>
                <span
                  v-if="main.tail"
                  class="m-l-5"
                  :style="{
                    fontSize: this.widget.card.style.main.unitSize + 'px',
                    color: this.widget.card.style.main.unitColor,
                  }"
                  >
                  {{ main.tail }}
                </span>
                <span class="increase m-l-5" v-if="differencialVal2 > 0.0">
                  &nbsp;<i class="fa fa-caret-up text-red-lighter"></i>
                </span>
                <span class="decrease m-l-5" v-if="differencialVal2 < 0.0">
                  &nbsp;<i class="fa fa-caret-down text-blue-lighter"></i>
                </span>
                <span class="m-l-5" v-if="differencialVal2 == 0.0 || !differencialVal2">
                  &nbsp;<i class="fa fa-minus text-dark"></i>
                </span>
              </p>
            </span>
            <hr>
          </div>
          <div :style="{
                fontSize: this.widget.card.style.bottom.fontSize + 'px',
                textAlign:this.widget.card.style.bottom.textAlign,
                color: this.widget.card.style.bottom.fontColor,
              }">
            <span>
              {{gubun}} &nbsp;
              <span :style="{fontWeight:this.widget.card.style.bottom.fontWeight}">{{ differencialVal2 }} %&nbsp;</span>
              <span class="increase" v-if="differencialVal2 > 0.0">증가</span>
              <span class="decrease" v-if="differencialVal2 < 0.0">감소</span>
              <span class="" v-if="differencialVal2 == 0.0 || !differencialVal2">변화 없음</span>
            </span>
          </div>
        </div>
      </div> -->
      
  



<script>
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";
import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import compareCardSetting from "./setting/xeIodCardSetting.vue";

export default {
  name: "xeCompareCard",
  extends: xeBaseWidget,
  components: {
    compareCardSetting,
  },
  data() {
    return {
      main: { value: 0, addition: { fixed: 1 } },
      bottom: { value: 0, addition: { fixed: 1 } },
      themeColor : global.xe.parseBool(localStorage.contentInverse) ? require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark : require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light,
    };
  },

  computed: {
    hasSelfCond() {
      if (this.main.self || this.bottom.self) return true;
      return false;
    },
    isPercentage() {
      return this.widget.objects.main.addition.isPercentage;
    },
    compareCardSettings() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    differencialVal() {
      return this.main.value.differencial(
        this.bottom.value,
        this.main.addition.fixed
      );
    },
    differencialVal2() {
      let bValue = this.bottom.value;
      let mValue = this.main.value;
      let returnValue = 0;

      if (bValue == 0 || mValue == 0) {
        returnValue = 0;
      } else {
        //1) (비교대상:금년 - 기준:작년)/기준*100
        returnValue = (((mValue - bValue) / bValue) * 100).toFixed(
          this.main.addition.fixed
        );
        //2) 비교대상:금년/기준:작년*100-100
        //returnValue = ((bValue/mValue)*100).toFixed(this.main.addition.fixed)
      }

      return returnValue;
    },
    gubun() {
      let str = "";

      if (
        this.widget.objects.main.self.sTi.includes("sDate(0)") &&
        this.widget.objects.main.self.eTi.includes("eDate(0)") &&
        this.widget.objects.bottom.self.sTi.includes("sDate(-1)") &&
        this.widget.objects.bottom.self.eTi.includes("eDate(-1)")
      ) {
        str = "전일 대비";
      } else if (
        this.widget.objects.main.self.sTi.includes("sMonth(0)") &&
        this.widget.objects.main.self.eTi.includes("eMonth(0)") &&
        this.widget.objects.bottom.self.sTi.includes("sMonth(-1)") &&
        this.widget.objects.bottom.self.eTi.includes("eMonth(-1)")
      ) {
        str = "전월 대비";
      } else if (
        this.widget.objects.main.self.sTi.includes("sYear(0)") &&
        this.widget.objects.main.self.eTi.includes("eYear(0)") &&
        this.widget.objects.bottom.self.sTi.includes("sYear(-1)") &&
        this.widget.objects.bottom.self.eTi.includes("eYear(-1)")
      ) {
        str = "전년 대비";
      }

      return str;
    },
    mainVal() {
      return this.comma(this.main.value, this.main.addition.fixed);
      // return this.main.value;
    },
  },
  created() {
    if (!this.widget.bgIcon) {
      this.widget.bgIcon = "";
    }
    if (!this.widget.moved) {
      this.widget.moved = false;
    }
    if (!this.widget.objects) {
      this.widget.objects = {
        main: {
          label: "",
          addition: {
            afterAction: "",
            afterBind: "",
            isPercentage: true,
            fixed: 0,
          },
          tail: "",
          point: "",
          value: 0,
          self: { type: 0, sTi: "", eTi: "" },
        },
        bottom: {
          label: "",
          addition: {
            afterAction: "",
            afterBind: "",
            isPercentage: false,
            fixed: 0,
          },
          tail: "",
          point: "",
          value: 0,
          self: { type: 0, sTi: "", eTi: "" },
        },
        series: { backgroundColor: "" },
      };
    }
    if (!this.widget.card) {
      this.widget.card = {
        colorOpens: false,
        titleColor: "#000000FF",
        titleFontSize: 14,
        backgroundColor: "#58d4d2",
        style: {
          main: {
            fontSize: "30",
            fontWeight: "600",
            fontColor: "#000000FF",
            unitSize: "15",
            unitColor: "#000000FF",
            iconColor: "#FFFFFFFF",
            textAlign: "center",
          },
          bottom: {
            fontSize: "15",
            fontWeight: "400",
            fontColor: "#000000FF",
            unitSize: "15",
            unitColor: "#000000FF",
            textAlign: "left",
          },
        },
      };
    }
  },
  mounted() {


    let lodCard = document.getElementsByClassName("xeCompareCard");

    for (let i = 0; i < lodCard.length; i++) {
      lodCard[i].parentNode.style.height = "100%";
    }

  },
  methods: {
    initSetting() {
      this.isPanelExpand = false;

      if (!this.widget.objects) throw "object is empty";
      if (!this.widget.objects.main) throw "main object is empty";
      if (!this.widget.objects.series) throw "series object is empty";

      this.main = Object.assign(this.widget.objects.main, { value: 0 });
      this.bottom = Object.assign({}, this.widget.objects.bottom, { value: 0 });
      this.series = Object.assign(this.widget.objects.series, { value: "" });

      
    },
    applyData(logs) {
      if (!this.isEmpty(this.main)) {
        this.bindCard(
          this.getCond(
            logs,
            Object.assign({ point: this.main.point }, this.main.self)
          ),
          this.main
        );
      } else {
        this.bindCard(logs[0], this.main);
      }

      if (!this.isEmpty(this.bottom.self.sTi)) {
        this.bindCard(
          this.getCond(
            logs,
            Object.assign({ point: this.bottom.point }, this.bottom.self)
          ),
          this.bottom
        );
      } else {
        this.bindCard(logs[0], this.bottom);
      }
    },
    getCond(logs, cond) {
      let rtnLogs = null;

      // 일자형식 스크립트 실행
      function runTi(type, ti) {
        let fn = new Function(
          "now",
          "baseline",
          "compare",
          "type",
          "return " + ti
        );
        let dt = fn(new Date(), type);

        return dt.normalize(type);
      }

      rtnLogs = logs.filter(
        (log) =>
          log.type == cond.type &&
          log.sDt == runTi(cond.type, cond.sTi) &&
          log.eDt == runTi(cond.type, cond.eTi) &&
          log.points[cond.point]
      );

      return rtnLogs;
    },
    bindCard(log, card) {
      let ptVals = [];

      if (Array.isArray(log)) ptVals = log[0].points[card.point];
      else ptVals = log.points[card.point];

      ptVals = utils.onAfterAction(
        card.point,
        ptVals,
        card.addition.afterAction
      ); // 배열반환

      let val = utils.onAfterBind(
        card.point,
        ptVals,
        card.addition ? card.addition.afterBind : null
      ); // 값반환

      card.value = val;
    },
    handleDropPoint(label, arg) {
      if (label == "main") {
        this.widget.title = arg.item.title;
        this.widget.objects.main = {
          label: "",
          addition: { afterAction: "", afterBind: "Last", isPercentage: false },
          tail: "",
          point: arg.item.ptAddr,
          value: 0,
        };

        // "main"  : { "label":"", "afterBind": "Last", "tail": "kWh", "point": "084000001_0000_0000_VLI_00000001" },
      } else {
        // bottom
        this.widget.title = arg.item.title;
        this.widget.objects.bottom = {
          label: "",
          addition: { afterAction: "", afterBind: "Last", isPercentage: false },
          tail: "",
          point: arg.item.ptAddr,
          value: 0,
        };
      }
    },

    // 드래그
    // handleDropPoint(transfer) {
    //   if (this.$err(transfer)) return;

    //   this.widget.title = transfer.item.title;
    //   this.widget.objects.main = { afterBind: "Last", tail: "", point: transfer.item.ptAddr, value: 0 };
    // },
  },
};
</script>


<style scoped>
/* .xe-na {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.xe-vetical-title {
  font-size: 13px;
  font-weight: 600;
}

.xe-nomal-title {
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}

.xe-vetical-value {
  font-size: 28px;
  font-weight: 700;
}

.xe-nomal-value {
  position: absolute;
  bottom: 20px;
  right: 0;
  font-size: 24px;
  font-weight: 700;
  padding-right: 10px;
}

.stats-title {
  text-transform: uppercase;
  margin: 10px 0;
  position: absolute;
  right: 0;
  font-size: 13px;
  font-weight: 600;
}

.stats-value {
  position: absolute;
  bottom: 0px;
  font-size: 13px;
  font-weight: 500;
  left: 0;
  padding-left: 20px;
}

.stats-bottom {
  position: absolute;
  bottom: 0px;
}

.widget-stats .stats-info h4,
.widget.widget-stats .stats-info h4 {
  font-size: 12px;
  margin: 0px 0;
  color: rgb(0, 0, 0);
}

.widget-stats-new-simple,
.widget.widget-stats-new-simple {
  position: relative;
  color: rgb(0, 0, 0);
  padding: 15px 0 0 15px;
  border-radius: 4px;
}

.widget-stats .stats-number,
.widget.widget-stats .stats-number {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 600;
}

.widget.widget-stats-new-simple .stats-info h3 {
  margin-bottom: 15px;
  text-align: right;
  margin-right: 15px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .xe-nomal-title {
    top: 10px;
  }
  .xe-nomal-title i {
    padding-left: 10px;
  }
  .xe-nomal-value {
    bottom: 25px;
    font-size: 5.5vw;
  }
  .xe-nomal-value span {
    padding-right: 10px;
  }
} */
</style>