import Vue from "vue";
import { Drag, Drop } from "vue-drag-drop";

export default {
	components: {
		Drag,
		Drop,
	},
	props: ["widget", "searchOpt", "searchBoxCond", "searchLogs", "cellSize", "contextMenu", "inverseMode", "on-handle-title-drop", "on-change-widget", "on-remove-widget"],
	data() {
		return {
			isShow: false,
			isPanelExpand: false,
			isDelete: false,

			// 일반위젯   self는 this.widget.self.baseline this.widget.self.compare
			// 카드계열만 self가 다름 (xeSimpleCard, xeEnergyCard, xeGoalCard)

			widgetLogs: [], // 디버깅용 임시 데이터

			debug: {},
		};
	},
	computed: {
		isEditMode() {
			return this.$store.getters.isEditMode;
		},
		isCompare() {
			return this.searchOpt ? this.searchOpt.isCompare : false; // 비교 조건 확인
		},
		panelHeaderHeight() {
			let panelHeaderHeight = this.widget.panel && this.widget.panel.display ? 40 : 0; // 40은 패널헤더가 표실될때의 높이값
			return panelHeaderHeight;
		},
		fixedHeight() {
			// 41은 패널해더의 높이...  5는 dndgrid 마진값
			let margin = 0;
			if (!this.isEmpty(this.cellSize.margin)) margin = this.cellSize.margin;

			// let panelHeaderHeight = this.isEditMode || (this.widget.panel && this.widget.panel.display) ? 41 : 0; // 41은 패널헤더가 표실될때의 높이값

			return this.widget.position.h * (this.cellSize.h + margin) - margin - this.panelHeaderHeight;
		},
		// panel 내부에 p-5를 했기때문에 chart size를 줄여줌.
		chartHeight() {
			return this.fixedHeight - 10 + "px";
		},
		inverseBg() {
			return this.inverseMode ? "bg-black" : "bg-white";
		},
		inverseText() {
			return this.inverseMode ? "text-white" : "text-black";
		},
	},
	watch: {
		searchBoxCond: function() {
			this.initSetting();
		},
		searchLogs: function() {
			this.startBind();
		},
		"contextMenu.isShowSetting": function() {
			if (this.contextMenu.widget.id == this.widget.id) this.showSettingModal();
		},
	},
	mounted() {
		this.initWidget();
	},
	methods: {
		// 초기화 ///////////////////////////////////////////////////////////////////////////
		initWidget() {
			this.isShow = true;
			this.isPanelExpand = false;
			// this.initSetting();
		},
		// virtual - initSetting
		initSetting() {
			// console.error(`${this.$options.name} initSetting 함수를 구현하지 않았습니다.`);
		},
		// 바인딩 ///////////////////////////////////////////////////////////////////////////
		startBind() {
			try {
				// console.log("BaseWidget startBind", this.searchLogs);

				// 위젯의 조회 조건
				let widgetConds = this.getWidgetConds(this.searchBoxCond);

				// 조회조건에 맞는 조회결과만 찾기
				let widgetLogs = this.searchLogs
					.map((log) => {
						let cond = widgetConds.find((cond) => log.type == cond.type && log.sDt == cond.sDt && log.eDt == cond.eDt);
						if (cond) return log;
					})
					.filter((v) => v);
				if (this.isEmpty(widgetLogs)) return;
				if (Array.isArray(widgetLogs) && widgetLogs.length < 1) return;

				// 데이터 바인딩
				this.widgetLogs.range(widgetLogs);
				this.applyData(widgetLogs);

				this.isShow = false;
				Vue.nextTick(() => {
					this.isShow = true;
				});
			} catch (err) {
				console.error(`${this.$options.name} startBind error`, err);
			}
		},
		// virtual - bindData
		applyData() {},
		bindData() {
			// newLogs
			console.error(`${this.$options.name} bindData 함수를 구현하지 않았습니다.`);
		},
		// 패널 ///////////////////////////////////////////////////////////////////////////////////////
		onPanelReload() {
			// TODO: emit('on-search'); 해야할지, 아니면 자기자신만 리로드 하는 방법은??? selfSerch해야 함.
		},
		onPanelSetting() {
			this.showSettingModal();
		},
		onPanelRemove() {
			this.isDelete = true;
			this.$emit("on-remove-widget");
		},
		onPanelExpand() {
			this.isPanelExpand = !this.isPanelExpand;
			this.isShow = false;
			Vue.nextTick(() => {
				this.isShow = true;
			});
		},
		// 드래그 ///////////////////////////////////////////////////////////////////////////////////////
		handleTitleDragOver(transfer, dragEvent) {
			if (!this.isEditMode) return;
			if (transfer.item.type === this.CODE.Drag.Point || transfer.item.type === this.CODE.Drag.PointGroup) dragEvent.dataTransfer.dropEffect = "none";
			else dragEvent.dataTransfer.dropEffect = "copy";
		},
		handleBodyDragOver(transfer, dragEvent) {
			if (!this.isEditMode) return;
			if (transfer.item.type === this.CODE.Drag.Point || transfer.item.type === this.CODE.Drag.PointGroup) dragEvent.dataTransfer.dropEffect = "copy";
			// move
			else dragEvent.dataTransfer.dropEffect = "none";
		},
		handleTitleDrop(transfer) {
			if (!this.isEditMode) return;
			//console.log("BaseWidget handleTitleDrop transfer", transfer, this.widget);
			this.$emit("on-handle-title-drop", transfer, this.widget);
		},
		handleBodyDrop(transfer, dragEvent) {
			if (!this.isEditMode) return;

			//console.log("handleBodyDrop transfer=", transfer, ", dragEvent=", dragEvent);

			this.handleDropPoint(transfer, dragEvent);

			this.initSetting();

			this.isShow = false;
			Vue.nextTick(() => {
				this.isShow = true;
			});
		},
		// virtual - handleDropPoint
		handleDropPoint() {
			// (transfer)
			console.error(`${this.$options.name} handleDropPoint 함수를 구현하지 않았습니다.`);
		},
		// virtual - showSettingModal
		showSettingModal() {
			//console.log("modal show");
			console.log(this.$refs.modalSetting);
			if (this.$refs.modalSetting) {
				this.$refs.modalSetting.show();
			}
		},
		onModalOK(changeSetting) {
			console.log(changeSetting);
			this.$refs.modalSetting.hide();
			this.$emit("on-change-widget", changeSetting);

			Vue.nextTick(() => {
				if (this.initSetting) this.initSetting();
			});
		},
		onModalCancel() {
			this.$refs.modalSetting.hide();
		},
		// 위젯별 조회 조건 배열 생성 ///////////////////////////////////////////////////////////////////////////////////////
		getWidgetConds(searchBoxCond) {
			const that = this;

			let searchBox = searchBoxCond;

			// 위젯별 관제점 목록
			function collectPointList(widget, isCompare) {
				let periodPoints = [];
				let comparePoints = [];

				// series구조에 따른 타입분류
				if (widget.type == "xeBar" || widget.type == "xeCompareChart" || widget.type == "xeChartLine" || widget.type == "xeChartPie" || widget.type == "xeChartGauge" || widget.type == "xeLine" || widget.type == "xeArea" || widget.type == "xePie") {
					periodPoints = periodPoints.concat(
						widget.chart.series
							.filter((v) => !v.compare || v.compare == false)
							.map((v) => v.point)
							.filter((v) => v)
					);
					comparePoints = comparePoints.concat(
						widget.chart.series
							.filter((v) => v.compare)
							.map((v) => v.point)
							.filter((v) => v)
					);

					// console.log("getwidgetsconds ================================>", periodPoints, comparePoints);
				} else if (widget.type == "xeEnergyCard" || widget.type == "xeGoalCard" || widget.type == "xeSimpleCard" || widget.type == "xeCompareCard" || widget.type == "xeProgressCard") {
					let obj = widget.objects;
					if (obj) {
						if (obj.main && obj.main.point) periodPoints.push(obj.main.point);
						if (obj.bottom && obj.bottom.point) periodPoints.push(obj.bottom.point);
						if (obj.goal && obj.goal.point) periodPoints.push(obj.goal.point);
					}
				} else if (widget.type == "xeHorizontalBar" || widget.type == "xeChartScatter" || widget.type == "xeChartHorizontalBar") {
					periodPoints = periodPoints.concat(
						widget.chart.series
							.filter((v) => !v.compare || v.compare == false)
							.map((v) => v.point)
							.filter((v) => v)
					);
					comparePoints = comparePoints.concat(
						widget.chart.series
							.filter((v) => v.compare)
							.map((v) => v.point)
							.filter((v) => v)
					);
					periodPoints = periodPoints.concat(widget.addition.xAxes.point ? [widget.addition.xAxes.point] : []);
				} else if (widget.type == "xeGridTable" || widget.type == "xePivotTable") {
					periodPoints = periodPoints.concat(widget.columns.map((v) => v.point).filter((v) => v));
					periodPoints = periodPoints.concat(
						widget.columns
							.filter((v) => !v.compare || v.compare == false)
							.map((v) => v.point)
							.filter((v) => v)
					);
					comparePoints = comparePoints.concat(
						widget.columns
							.filter((v) => v.compare)
							.map((v) => v.point)
							.filter((v) => v)
					);
				} else if (widget.type == "xeChartDonut" || widget.type == "xeRankCard" || widget.type == "xeChartRadar" || widget.type == "xeDoughnut") {
					periodPoints = periodPoints.concat(widget.chart.series.map((v) => v.point).filter((v) => v));
					periodPoints = periodPoints.concat(widget.chart.series.map((v) => v.maxPt).filter((v) => v));
					if (isCompare) {
						comparePoints = comparePoints.concat(widget.chart.series.map((v) => v.point).filter((v) => v));
						comparePoints = comparePoints.concat(widget.chart.series.map((v) => v.maxPt).filter((v) => v));
					}
				}

				return {
					periodPoints: periodPoints.filter((v) => v != "DATE_AND_TIME").unique(),
					comparePoints: comparePoints.filter((v) => v != "DATE_AND_TIME").unique(),
				};
			}
			// 일자형식 스크립트 실행
			function runTi(ti, type, searchBox) {
				// console.log(ti, type, searchBox)
				if (ti) {
					let fn = new Function("now", "baseline", "compare", "type", "return " + ti);
					let dt = fn(new Date(), searchBox.period, searchBox.compare, type);

					return dt.normalize(type);

					// return searh
				}
			}
			// 카드형식 condition 생성
			function makeCardCond(obj, searchBox) {
				if (that.isEmpty(obj)) return;
				if (that.isEmpty(obj.point)) return;

				if (obj.self && obj.self.sTi && obj.self.eTi) {
					// self 조건인 경우

					return {
						_desc: "self",
						type: obj.self.type,
						sDt: runTi(obj.self.sTi, obj.self.type, searchBox),
						eDt: runTi(obj.self.eTi, obj.self.type, searchBox),
						points: [obj.point],
					};
				} else {
					// 일반 조건인 경우 - period일자만 사용됨..
					return {
						_desc: "normal",
						type: searchBox.type,
						sDt: searchBox.period.sDt.normalize(searchBox.type),
						eDt: searchBox.period.eDt.normalize(searchBox.type),
						points: [obj.point],
					};
				}
			}

			// function parse(str) {
			//   if (!/^(\d){8}$/.test(str)) return "invalid date";
			//   var y = str.substr(0, 4),
			//     m = str.substr(4, 2),
			//     d = str.substr(6, 2);
			//   return new Date(y, m, d);
			// }

			function makeNormalCond(obj, searchBox, defaultDt, pts) {
				// console.log(obj, searchBox, defaultDt, pts)
				if (that.isEmpty(obj)) return;
				if (that.isEmpty(pts)) return;
				if (pts.length == 0) return;

				if (obj && obj.type && obj.sTi && obj.eTi) {
					// self 조건인 경우

					return {
						_desc: "self",
						type: obj.type,
						sDt: runTi(obj.sTi, obj.type, searchBox),
						eDt: runTi(obj.eTi, obj.type, searchBox),
						points: pts,
					};
				} else {
					// 일반 조건인 경우
					return {
						_desc: "normal",
						type: searchBox.type,
						sDt: defaultDt.sDt.normalize(searchBox.type),
						eDt: defaultDt.eDt.normalize(searchBox.type),
						points: pts,
					};
				}
			}

			// widget별
			//console.log("getSearchConds widget", widget.type);

			let widget = this.widget;
			let conds = [];

			// self구조에 따른 타입분류= 카드인 경우와 아닌경우만 있음.
			if (widget.type == "xeEnergyCard" || widget.type == "xeGoalCard" || widget.type == "xeSimpleCard" || widget.type == "xeCompareCard" || widget.type == "xeProgressCard") {
				// 카드인경우
				if (widget.objects) {
					conds.push(makeCardCond(widget.objects.main, searchBox));
					conds.push(makeCardCond(widget.objects.goal, searchBox));
					conds.push(makeCardCond(widget.objects.bottom, searchBox));
				}
			} else {
				// 카드가 아닌 경우
				let pts = collectPointList(widget, searchBox.isCompare);

				conds.push(makeNormalCond(widget.self ? widget.self.baseline : null, searchBox, searchBox.period, pts.periodPoints));

				if (searchBox.isCompare) {
					conds.push(makeNormalCond(widget.self ? widget.self.compare : null, searchBox, searchBox.compare, pts.comparePoints));
				}
			}

			return conds.filter((v) => v).map((cond) => Object.assign({ _wg: widget.type }, cond));
		},
	},
};
