<template>
  <div>
    <panel
      v-if="(widget.panel && widget.panel.display) || isEditMode"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-10"
      hideExpand="false"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      @resize="_onPanelReSize"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)">
        <div v-if="isShow" class="text-center">
          <img :src="src" class="cctv-box" @error="onError" />
        </div>
      </drop>
    </panel>

    <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)">
      <div v-if="isShow" class="text-center">
        <img :src="src" class="cctv-box" @error="onError" />
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" body-class="p-20" cancel-variant="default" title="Settings">
      <div class="col-lg-12">
        <input type="text" class="form-control no-rounded-corner bg-silver" v-model="widget.title" />
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.cctv-box {
  width: 100%;
  height: 100%;
}
</style>

<script>
import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";

export default {
  name: "xeCctv",
  extends: xeBaseWidget,
  data() {
    return {
      isShow: false,

      //rtsp: "rtsp://192.168.10.143:port/profile1/media.smp", // cctv
      src: "http://192.168.10.144:8080/58fb55e26ebb76693279e24e57d7881a/mjpeg/wfzQH0D9c8/cctv1" // shinobi
    };
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      this.isShow = true;
    },
    onError(evt) {
      //console.log("image onerror");
      this.isShow = false;

      evt.target.src = "/assets/xems/common/product/noListBg.png";
      evt.target.style.width = "91px";
      evt.target.style.height = "81px";
    },
    onPanelSetting() {
      this.$refs.modalSetting.show();
    },
    onTitleDrop(data) {
      this.$emit("onTitleDrop", data, this.widget);
    },
    onWidgetDrop(data) {
      console.log("onWidgetDrop", data);
    }
  }
};
</script>
