<template>
  <div class="xeChartGauge heightMAX">
    <panel
      ref="panel"
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-0 chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div v-if="isShow" class="heightMAX">
          <WPieChart :dataset="dataSet" responsive :width="gaugeWidth" :height="fixedHeight">
            <WGauge 
              datakey="first" 
              :startAngle="90" 
              :endAngle="450" 
              :styles="{ position: 'relative', background: '#eee' }" 
              :radius="[(gaugeRadius - gaugeThick)-margin, gaugeRadius-margin]" 
              :animation="false" 
              :color="widget.style.color"
            >
              <div class="WPieContent heightMAX" :style="{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100%' }">
                <span :style="{ margin: 'auto', textAlign: 'center', color: '#888' }">
                  <span v-for="(series, i) in widget.series" :key="i">
                    <span v-for="(label, j) in series.labels" :key="j" style="display:block;">
                      <span :style="label.style">{{ gaugeLabelText($t(label.text)) }} </span>
                    </span>
                  </span>
                </span>
              </div>
            </WGauge>
          </WPieChart>
        </div>
      </drop>
    </panel>
    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="bg-white heightMAX">
      <div v-if="isShow" class="m-b-20 heightMAX" :class="[inverseBg]">
        <WPieChart :dataset="dataSet" responsive :width="gaugeWidth" :height="gaugeHeight">
          <WGauge 
            datakey="first" 
            :startAngle="90" 
            :endAngle="450" 
            :styles="{ position: 'relative', background: '#eee' }" 
            :radius="[(gaugeRadius - gaugeThick)-margin, gaugeRadius-margin]" 
            :animation="false" 
            :color="widget.style.color"
          >
            <div class="WPieContent heightMAX" :style="{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100%' }">
              <span :style="{ margin: 'auto', textAlign: 'center', color: '#888' }">
                <span v-for="(series, i) in widget.series" :key="i">
                  <span v-for="(label, j) in series.labels" :key="j" style="display:block;">
                    <span :style="label.style">{{ gaugeLabelText($t(label.text)) }} </span>
                  </span>
                </span>
              </span>
            </div>
          </WGauge>
        </WPieChart>
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" :title="`${$t('설정')} - Gauge Chart`" bodyClass="p-t-0 p-b-0" hide-footer>
      <chart-gauge-setting :initSetting="chartGaugeSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<script>
import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";
import chartGaugeSetting from "@src/views/widget/v1/setting/xeChartGaugeSetting.vue";

export default {
  name: "xeChartGauge",
  extends: xeBaseWidget,
  components: {
    chartGaugeSetting
  },
  data() {
    return {
      dataSet: [],
      gaugeVals: {}
    };
  },
  computed: {
    gaugeWidth() {
      return this.widget.position.w * this.cellSize.w;
    },
    gaugeHeight() {
      return this.widget.position.h * this.cellSize.h;
    },
    gaugeRadius() {
      return this.gaugeWidth / 2.3;
    },
    gaugeThick() {
      return this.gaugeWidth * 0.07;
    },
    chartGaugeSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    gaugeVal() {
      return this.dataSet.reduce((acc, item) => acc + item.first, 0);
    },
    gaugeLabelText() {
      return labelText => {
        labelText = labelText.replace("{org}", this.gaugeVals.org ? this.gaugeVals.org : 0);
        labelText = labelText.replace("{val}", this.gaugeVals.val ? this.gaugeVals.val : 0);
        labelText = labelText.replace("{max}", this.gaugeVals.max ? this.gaugeVals.max : 0);
        return labelText;
      };
    },
    margin(){
      return this.widget.style.margin || 0
    }
  },
  created() {
    // 초기값 설정
    if (!this.widget.style)    this.widget.style    = { color: "" };
    if (!this.widget.series)   this.widget.series   = [{ labels: [{ text: "Default", style: "" }], point: "", maxPt: "", maxVal: null, compare: false }];
    if (!this.widget.addition) this.widget.addition = { afterAction: "", afterBind: "", fixed: 0 };
    if (!this.widget.self)     this.widget.self     = { baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" }, compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" } };
  },
  mounted(){
    let gauges = document.getElementsByClassName("xeChartGauge ");

    for(let i = 0; i < gauges.length; i++){
      gauges[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.dataSet.push({
        name: this.widget.title,
        first: 0,
        series: this.widget.series
      });
    },
    // 차트 데이터 바인딩
    applyData(logs) {
      let that = this;

      // afterAction 및 afterBind
      function onAfter(ptAddr, logs) {
        let log = {};
        
        // 일자형식 스크립트 실행
        function runTi(type, ti) {
          let fn = new Function("now", "baseline", "compare", "type", "return " + ti);
          let dt = fn(new Date(), type);

          return dt.normalize(type);
        }

        if(!that.isEmpty(that.widget.self.baseline.sDt)){

          let cond = that.widget.self.baseline;

          log = logs.filter(log => log.type == cond.type && log.sDt == runTi(cond.type, cond.sTi) && log.eDt == runTi(cond.type, cond.eTi));
        }
        else{
          log = logs[0]
        }
        
        let vals = ptAddr ? log.points[ptAddr] : [] || undefined;
        vals = utils.onAfterAction(ptAddr, vals, that.widget.addition.afterAction); // 배열반환
        return utils.onAfterBind(ptAddr, vals, that.widget.addition.afterBind); // 값반환
      }

      function onCalcMax(ptAddr, logs, maxPt, maxVal, fixed) {
        let org = onAfter(ptAddr, logs);
        let max = maxPt ? onAfter(maxPt, logs) : maxVal || 0;
        let val = ""; // 게이지가 차오르는 cka

        if (max !== 0) {
          // max가 있을경우 계산해서 퍼센트값을 보여줌
          val = ((org / max) * 100.0).toFixed(fixed);
        } else {
          // max가 없을경우 원본값을 보여줌
          org = org.toFixed(fixed);
          val = org;
        }
        return { val, org, max };
      }

      this.dataSet[0].series.forEach(series => {
        this.gaugeVals = {};
        this.gaugeVals = onCalcMax(series.point, logs, series.maxPt, series.maxVal, that.widget.addition.fixed);

        this.dataSet[0].first = Number(this.gaugeVals.val);
      });
    },
    //dnd
    handleDropPoint(transfer) {
      if (transfer.item.type === this.CODE.Drag.Point){

        this.widget.series = [];

        this.widget.series = [{ labels: [{ text: transfer.item.title, style: "font-size:20px; font-weight:600;" }], point: transfer.item.ptAddr, maxPt: "", maxVal: null, compare: false }];
      }
    }
  }
};
</script>

<style scoped>
.WPieChart{
  height: 100%;
}
</style>