<template>
  <div class="xeGoalUsageChart heightMAX">
    <!-- #4da8ef" -->
    <div class="kbet-custom-reset heightMAX">
      <div class="rounded kbet-widget-mixedchart-v1 kbet-widget-energy heightMAX" :style="{ display: 'block' }">
        <!-- :style="{background: this.widget.chart.backgroundColor, display: 'block'}" -->
        <!-- #2D353CFF 검 / #ffffffff 백-->

        <p class="title rounded-top">
          {{ $t(widget.title) }}
        </p>

        <div class="d-flex flex-row heightMAX energy-usage-area " style="padding:0px 20px; height: calc(100% - 100px);">
          <div class="col-4" style="align-self: center; text-align: center; width: 100%">
            <ul class="goal-usage  m-r-10">
              <li>
                <strong class="m-r-4" style="color:#3f3f3f">&nbsp;{{ this.GridData.sumUseConsumpt | numberWithCommas }}&nbsp;</strong>
                <span> kWh</span>
              </li>
              <li>
                <strong>{{$t('월 목표량')}} : </strong>
                <span>{{ this.GridData.totTargetConsumpt | numberWithCommas }} kWh</span>
              </li>
              <!-- <li>
                <strong>사용률 : </strong>
                <span>{{this.GridData.totUseRate}}%</span>
              </li> -->
            </ul>
          </div>
          <div class="col-8 m-t-10" :style="{ height: '100%' }">
            <xe-line ref="chart" type="line" class="chartHeightMax" :data="chartData.data" :options="chartData.options"> </xe-line>
          </div>
        </div>

        <div class="table-goal-contrast">
          <table>
            <!-- table-striped  -->
            <colgroup>
              <col width="23.5%" />
              <col width="28%" />
              <col width="28%" />
              <col width="21.5%" />
            </colgroup>
            <thead>
              <tr class="heightMAX">
                <th>#</th>
                <th>{{$t('월 목표량')}}</th>
                <th>{{$t('누적 소비량')}}</th>
                <th>{{$t('목표 대비')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{$t('전체 소비량')}} (kWh)</td>
                <td>{{ this.GridData.totTargetConsumpt | numberWithCommas }}</td>
                <td>{{ this.GridData.sumUseConsumpt | numberWithCommas }}</td>
                <td>{{ this.GridData.totUseRate | numberWithCommas }} %</td>
              </tr>
              <!-- 공간의 낭비를 줄이는 차원에서 임시로 삭제함 by badblock.2022.06.09-->
              <!--
                <tr>
                  <td>전기</td>
                  <td>{{this.GridData.elecTargetConsumpt | numberWithCommas}}</td>
                  <td>{{this.GridData.elecConsumpt | numberWithCommas}}</td>
                  <td>{{this.GridData.elecUseRate | numberWithCommas}} %</td>
                </tr>
                <tr>
                  <td>가스</td>
                  <td>{{this.GridData.gasTargetConsumpt | numberWithCommas}}</td>
                  <td>{{this.GridData.gasConsumpt | numberWithCommas}}</td>
                  <td>{{this.GridData.gasUseRate | numberWithCommas}} %</td>
                </tr>
              -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal ref="modalSetting" size="lg" :title="`${$t('설정')} - ${$t('목표대비 에너지 사용량')}`" body-class="p-t-0 p-b-0" hide-footer>
      <xe-goal-usage-setting :initSetting="goalUsageSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import xeLine from "../../../components/vue-chartjs/Charts/LineChart.js";
import xeGoalUsageSetting from "./setting/xeGoalUsageSetting.vue";

export default {
  name: "xeGoalUsageChart",
  extends: xeBaseWidget,
  components: {
    xeLine,
    xeGoalUsageSetting,
  },
  data() {
    return {
      Chartdata: [{}],
      GridData: {
        elecConsumpt: "",
        elecTargetConsumpt: "",
        elecUseRate: "",
        gasConsumpt: "",
        gasTargetConsumpt: "",
        gasUseRate: "",
        sumUseConsumpt: "",
        totTargetConsumpt: "",
        totUseRat: "",
      },

      datasets: [
        {
          // sumUseConsumpt
          data: [],
          yAxisID: "Left_yAxis",
          pointRadius: 3, //포인트 속성 숨기기 20210216
          LineTension: 0.1, //꺽은선 그래프
          borderColor: global.xe.parseBool(localStorage.contentInverse)
            ? require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").dark.widget.goalUsageChart.borderColor[0]
            : require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").light.widget.goalUsageChart.borderColor[0], //차트 색상
          fill: true,
        },
        {
          // totTargetConsumpt
          data: [],
          yAxisID: "Left_yAxis",
          pointRadius: 0, //포인트 속성 숨기기 20210216
          LineTension: 0, //꺽은선 그래프
          borderDash: [2, 2],
          borderColor: global.xe.parseBool(localStorage.contentInverse)
            ? require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").dark.widget.goalUsageChart.borderColor[1]
            : require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").light.widget.goalUsageChart.borderColor[1], //차트 색상
          fill: false,
        },
      ],

      chartData: {
        data: {},
        options: {},
      },
      themeColor: global.xe.parseBool(localStorage.contentInverse)
        ? require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").dark
        : require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").light,
    };
  },
  computed: {
    goalUsageSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    master() {
      return this.$store.getters.master;
    },
  },
  created() {
    if (!this.widget.bgIcon) {
      this.widget.bgIcon = "material timeline";
    }
    if (!this.widget.addition) {
      this.widget.addition = {
        afterAction: "",
        fixed: 0,
      };
    }
    if (!this.widget.self) {
      this.widget.self = {
        baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
        compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
      };
    }
    if (!this.widget.chart) {
      this.widget.chart = {
        colorOpens: false,
        // backgroundColor: "#474F6CFF",3F3F3FFF
        backgroundColor: "#3F3F3FFF",
        gridColor: "#E1E1E133",
        fontColor: "#FFFFFFFF",

        series: [
          {
            type: "line",
            label: {
              enabled: false,
            },
            point: "",
            position: "Left",
            fill: false,
            compare: false,
            dataColor: "#00AAAA",
          },
        ],
        options: {
          stacked: true,
          fill: false,
          fixed: 0,
          legend: {
            display: false,
            position: "top",
            align: "end",
            labels: {
              boxWidth: 10,
              fontSize: 14,
              fontStyle: "normal",
              fontColor: "#000000",
            },
          },
          yAxes: {
            left: {
              display: true,
              label: "Unit1",
              min: 0,
              max: 0,
              unit: "",
              fontSize: 12,
              fontColor: "#000000",
            },
            right: {
              display: true,
              label: "Unit2",
              min: 0,
              max: 0,
              unit: "",
              fontSize: 12,
              fontColor: "#000000",
            },
          },
        },
      };
      this.chartData.options = this.createChartOptions();
      this.chartData.data = {
        labels: null,
        datasets: null,
      };
    }
    if (!this.widget.title) {
      this.widget.title = "당월 목표대비 에너지 사용량";
    }
  },
  mounted() {
    let goal = document.getElementsByClassName("xeGoalUsageChart");

    for (let i = 0; i < goal.length; i++) {
      goal[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.isPanelExpand = false;
      this.chartData.data = {
        datasets: null,
        labels: null,
      };
      // this.chartData.options = this.createChartOptions();
      // this.chartData.data = {
      //   datasets: this.datasets,
      //   labels: this.monthDays(),
      //   //labels: this.defaultLabels(3),
      // };
    },
    applyData(widgetLogs) {
      this.ChartData = widgetLogs.detail; // 차트 데이터
      this.GridData = this.setGirdData(widgetLogs.summary); // 그리드 데이터
      let goalValue = this.GridData.totTargetConsumpt; //목표사용량
      let detailTotConsumpt = widgetLogs.detail; //일별사용데이터
      let dailyData = [];

      dailyData.push(0);
      detailTotConsumpt.map((value) => {
        dailyData.push(value.sumUseConsumpt);
      });

      //데이터셋 초기화
      this.datasets[0].data.clear();
      this.datasets[1].data.clear();
      this.datasets[0].data = dailyData;

      // 당월 일수 계산
      var start = new Date();
      start.setMonth(start.getMonth() + 1);
      start.setTime(start.getTime() - 1);
      var lastDay = new Date(start.getYear(), start.getMonth(), "");
      lastDay = lastDay.getDate();

      for (let i = 0; i <= lastDay; i++) {
        if (i == 0) {
          this.datasets[1].data.push(0);
        } else if (i === lastDay) {
          this.datasets[1].data.push(goalValue);
        } else {
          this.datasets[1].data.push(null);
        }
      }

      this.chartData.options = this.createChartOptions();

      if (this.chartData.data.datasets.length == 0) {
        this.chartData.data = {
          datasets: this.datasets,
          labels: this.monthDays(),
        };
      } else {
        this.chartData.data.labels = this.monthDays();
        for (let i = 0; i < this.chartData.data.datasets.length; i++) {
          this.chartData.data.datasets[i].data = this.datasets[i].data;
        }
      }

      this.$refs.chart._data._chart.update();

      //20210216
      // this.chartData.data = {
      //   labels: this.createLabels(widgetLogs),
      //   datasets: this.createDatasets(widgetLogs),
      // };
      //this.widgetLogs = widgetLogs;
    },
    setGirdData(data) {
      let options = {};

      for (let i = 0; i < data.length; i++) {
        if (data[i].divCd === "Tot") {
          options["sumUseConsumpt"] = data[i].useConsumpt;
          options["totTargetConsumpt"] = data[i].targetConsumpt;
          options["totUseRate"] = data[i].useRate;
        }
      }

      return options;

      // return {
      // elecConsumpt: data[0].useConsumpt,
      // elecTargetConsumpt: data[0].targetConsumpt,
      // elecUseRate: data[0].useRate,
      // gasConsumpt: data[1].useConsump,
      // gasTargetConsumpt: data[1].targetConsumpt,
      // gasUseRate: data[1].useRate,
      //   sumUseConsumpt: data[1].useConsumpt,
      //   totTargetConsumpt: data[1].targetConsumpt,
      //   totUseRate: data[1].useRate,
      // };
    },
    getRandom() {
      let data = [];

      for (let i = 0; i < 30; i++) {
        data.push(Math.floor(Math.random() * 200));
      }
      return data;
    },
    monthDays() {
      let defaultLabels = [];

      var start = new Date();
      start.setMonth(start.getMonth() + 1);
      start.setTime(start.getTime() - 1);
      var lastDay = new Date(start.getYear(), start.getMonth(), "");
      lastDay = lastDay.getDate();

      for (let i = 0; i <= lastDay; i++) {
        defaultLabels.push(i);
      }

      return defaultLabels;
    },
    handleDropPoint(label, arg) {
      this.widget.title = arg.item.title;
    },
    defaultLabels(timeType) {
      let now = new Date();
      let labels = [];

      if (timeType == 1) labels = [now.addYear(-1).getFullYear() + "Y", now.getFullYear() + "Y"]; // 년
      if (timeType == 2)
        labels = [...Array(13).keys()]
          .filter((v) => v !== 0)
          .map((v) => {
            return v + "M";
          }); // 월
      if (timeType == 3)
        labels = [...Array(31).keys()]
          .filter((v) => v !== 0)
          .map((v) => {
            return v + "D";
          }); // 일
      if (timeType == 4)
        labels = [...Array(24).keys()].map((v) => {
          return v + "H";
        }); // 시
      if (timeType == 5) {
        // 15분
        for (let i = 1; i < 25; i++) {
          if (i == 1) labels.push(["0m", now.getDate() + "D"]);
          else labels.push("0m");
          labels.push("15m");
          labels.push("30m");
          labels.push("45m");
        }
      }

      return labels;
    },
    defaultDatasets() {
      return this.widget.chart.series
        .filter((item) => !item.compare || this.isCompare)
        .map((item) => {
          return {
            backgroundColor: item.dataColor,
            borderColor: item.dataColor,
            fill: false,
            label: item.label, // ''
            pointBorderColor: item.dataColor,
            pointBackgroundColor: "#fff",
            showLine: true,
            LineTension: 0,
            // xAxisID:
            //   item.position === "Left"? "xAxes_up": item.position === "Right"? "xAxes_down": null,
            yAxisID: item.position === "Left" ? "Left_yAxis" : item.position === "Right" ? "Right_yAxis" : null,
            point: item.point,
            compare: item.compare ? item.compare : false,
          };
        });
    },
    createLabels(logs) {
      let labels = [];

      if (logs.length === 1) {
        // baseline 조회시
        labels = logs[0].dates;
      } else if (logs.length === 2) {
        // compare 조회시
        let base = logs[0];
        let comp = logs[1];

        // 구간조회이기 때문에, 일자의 갯수가 base와 comp가 서로 다를 수 있다.
        // 그래서, 일자 기간을 더 길게 조회한 쪽을 기준으로 for문을 돌아준다.

        if (base.rows.length >= comp.rows.length) {
          labels = base.dates.map((dt, i) => {
            return [dt[1] + " " + dt[0], comp.dates[i] ? comp.dates[i][1] + " " + comp.dates[i][0] : ""];
          });
        } else {
          labels = comp.dates.map((dt, i) => {
            return [dt[1] + " " + dt[0], base.dates[i] ? base.dates[i][1] + " " + base.dates[i][0] : ""];
          });
        }
      }

      return labels;
    },

    createChartOptions() {
      let options = {
        responsive: true,
        spanGaps: true, //공백있어도 그래프가 보
        maintainAspectRatio: false,
        animations: {
          duration: 1000,
          easing: "easeOutQuart",
          onProgress: null,
          onComplete: null,
        },
        hoverMode: "index",
        legend: {
          display: false, //this.widget.chart.options.legend.display
          // position: this.widget.chart.options.legend.position,
          // align: this.widget.chart.options.legend.align,
          fullWidth: true,
          fill: "#000000",
          labels: {
            usePointStyle: true,
            fillStyle: "#242342",
            backgroundColor: "#234132",
            // boxWidth: this.widget.chart.options.legend.labels.boxWidth,
            // fontColor: this.widget.chart.options.legend.labels.fontColor,
            // fontSize: this.widget.chart.options.legend.labels.fontSize,
            fontFamily: "'Helvetica Neue'",
          },
        },
        title: {
          display: false,
          position: "top",
          text: this.widget.title,
          fontSize: this.widget.chart.options.titleFontSize,
          fontColor: this.widget.chart.options.fontColor,
        },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: -5 } },
        hover: {
          mode: "nearest",
          intersect: true,
          animationDuration: 400,
        },
        tooltips: {
          mode: "index",
          responsive: false,
          intersect: false,

          callbacks: {
            label: (tooltipItems, data) => {
              let value = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];

              value = this.comma(value, this.widget.addition.fixed);

              let label = data.datasets[tooltipItems.datasetIndex].label || "";

              if (data.datasets[tooltipItems.datasetIndex].yAxisID == "Left_yAxis") {
                value = String(value) + this.widget.chart.options.yAxes.left.unit;
              } else if (data.datasets[tooltipItems.datasetIndex].yAxisID == "Right_yAxis") {
                value = String(value) + this.widget.chart.options.yAxes.right.unit;
              } else {
                value = String(value);
              }

              if (label) {
                label += ": ";
              }

              return label + value;
            },
            footer: (tooltipItems) => {
              let sumLeft = 0;
              let sumRight = 0;
              let stringLeft = "";
              let stringRight = "";
              let returnString = "";

              for (let i = 0; i < tooltipItems.length; i++) {
                if (tooltipItems[i].yAxisID === "Left_yAxis") {
                  sumLeft += tooltipItems[i].yLabel;
                  stringLeft = "Sum(" + this.widget.chart.options.yAxes.left.unit + "): ";
                } else if (tooltipItems[i].yAxisID === "Right_yAxis") {
                  sumRight += tooltipItems[i].yLabel;
                  stringRight = "Sum(" + this.widget.chart.options.yAxes.right.unit + "): ";
                }
              }

              if (stringLeft !== "") {
                returnString = stringLeft + String(sumLeft);
              }

              if (stringRight !== "") {
                if (returnString.length !== 0) {
                  returnString = returnString + "\n" + stringRight + String(sumRight);
                } else {
                  returnString = stringRight + String(sumRight);
                }
              }

              return returnString;
            },
          },
        },
        elements: {
          point: {
            // radius: 3, // 라인차트에 점크기 기본값 3
          },
        },
        scales: {
          xAxes: [
            {
              id: "xAxes_up",
              display: true,
              stacked: this.widget.chart.options.stacked,
              ticks: {
                display: true, //lable 값 안보이게 처리
                beginAtZero: true,
                autoSkip: true,
                maxTicksLimit: 8,
                maxRotation: 0,
                fontColor: this.master.labelTheme == "black" ? "#8A8A8A" : "#727a7f", //this.widget.chart.gridColor,
                fontSize: this.widget.chart.chartFontSize,
                stepSize: 5,
              },
              gridLines: {
                display: true,
                zeroLineColor: this.master.labelTheme == "black" ? "#E1E1E133" : "#f7f7f7", //this.widget.chart.gridColor, //0 line 색상
                color: this.themeColor.chart.line.gridLineColor,
                drawOnChartArea: true, //그리드라인 여부
              },
            },
          ],
          yAxes: [
            {
              display: true,
              //position: "left",
              id: "Left_yAxis",
              color: "blue",
              scaleLabel: {
                display: false,
              },
              ticks: {
                display: false, //lable 값 안보이게 처리
                labels: ["abc", "bdc"],
                suggestedMin: this.widget.chart.options.yAxes.left.min,
                suggestedMax: this.widget.chart.options.yAxes.left.max,
                beginAtZero: true,
                fontColor: this.master.labelTheme == "black" ? "#E1E1E133" : "#727a7f", //this.widget.chart.fontColor,
              },
              stacked: false,
              gridLines: {
                //y축 라인 표시 x
                display: true,
                zeroLineColor: this.master.labelTheme == "black" ? "#E1E1E133" : "#f7f7f7", //this.widget.chart.gridColor, //0 line 색상
                color: this.themeColor.chart.line.gridLineColor,
                drawOnChartArea: true, // 그리드 라인 여부
              },
            },
            {
              display: false,
              //display: this.widget.chart.options.yAxes.right.display,
              id: "Right_yAxis",
              position: "right",
              scaleLabel: {
                display: false,
                // labelString:
                //   this.widget.chart.options.yAxes.right.label +
                //   " (" +
                //   this.widget.chart.options.yAxes.right.unit +
                //   ")",
                //fontColor: this.inverseMode ? "white" : "black",

                fontSize: this.widget.chart.chartFontSize,
              },
              ticks: {
                display: false, //lable 값 안보이게 처리
                suggestedMin: this.widget.chart.options.yAxes.right.min,
                suggestedMax: this.widget.chart.options.yAxes.right.max,
                beginAtZero: true,
                fontColor: this.widget.chart.gridColor,
                // fontSize: this.widget.chart.chartFontSize,
                // userCallback: (value) => {
                //   value = this.comma(value, this.widget.addition.fixed);
                //   return value;
                // },
              },
              stacked: this.widget.chart.options.stacked,
              // gridLines: {
              //   color: this.widget.chart.gridColor,
              //   drawOnChartArea: true,
              // },
            },
          ],
        },
      };

      return options;
    },
  },
};
</script>

<style scoped>
/* p,span,strong,th,td */
/* .mode-bl {
  color: #fff;
  background-color: #212529;
} */
/* .mode-wh ,p,i,span,strong,th,td,li{
  color:#212529;
  background-color: #fff;
} */

.kbet-widget-mixedchart-v1 {
  display: grid;
  grid-template-rows: 40px auto 97px;
  -ms-grid-rows: 40px auto 97px;
}

.kbet-widget-mixedchart-v1 .value-usage {
  align-self: center;
  -ms-grid-row-align: center;
  margin: 0 5.5% 0 5px;
}

.kbet-widget-mixedchart-v1 .value-usage strong {
  display: inline-block;
  font-size: 42px;
  font-weight: 700;
}

.kbet-widget-mixedchart-v1 .value-usage span {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  vertical-align: top;
  margin: 31px 0 0 3px;
}
.kbet-widget-mixedchart-v1 .bottom {
  position: relative;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  height: 43px;
  font-size: 15px;
  margin: 0 5px;
}
.height60 {
  height: 60%;
}
.height50 {
  height: 50%;
}
.height40 {
  height: 40%;
}

.kbet-widget-mixedchart-v1 .title {
  font-size: 14px;
  font-weight: 800;
  line-height: 40px;
  padding: 0 20px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.2);
}

.kbet-widget-mixedchart-v1 .title i {
  font-size: 24px;
  margin-right: 10px;
}

.kbet-widget-mixedchart-v1 {
  grid-template-rows: 40px auto 97px;
  -ms-grid-rows: 40px auto 97px;
  /* min-height: 300px; */
}

.kbet-widget-mixedchart-v1 .energy-usage-area {
  align-self: center;
  padding: 0px 20px;
  /* padding: 15px 20px; */
}

.kbet-widget-mixedchart-v1 .energy-usage-area .goal-usage {
  display: inline-block;
  margin-right: -4px;
  /* width: 115px; */
  text-align: right;
}

.kbet-widget-mixedchart-v1 .energy-usage-area .goal-usage li:first-child strong {
  display: inline-block;
  font-size: 19px;
  letter-spacing: -0.5px;
}

.kbet-widget-mixedchart-v1 .energy-usage-area .goal-usage li:first-child span {
  display: inline-block;
  font-size: 16px;
  vertical-align: bottom;
  padding-bottom: 2px;
}

.kbet-widget-mixedchart-v1 .energy-usage-area .goal-usage li:last-child {
  font-size: 10px;
  font-weight: 400;
  /* color: rgba(225, 225, 225, 0.5); */
}

.kbet-widget-mixedchart-v1 .energy-usage-area .graph-area {
  display: inline-block;
  /* background: url(../assets/css/default/images/graph-test-img.png); */
  width: calc(100% - 130px);
  min-height: 133px;
  margin-left: 15px;
}

/* table */
.kbet-widget-mixedchart-v1 .table-goal-contrast {
  padding: 0 10px;
}

.kbet-widget-mixedchart-v1 .table-goal-contrast table {
  width: 100%;
  /* color: #fff; */
  font-size: 12px;
}

.kbet-widget-mixedchart-v1 .table-goal-contrast table th,
.kbet-widget-mixedchart-v1 .table-goal-contrast table td {
  text-align: right;
}

.kbet-widget-mixedchart-v1 .table-goal-contrast table tr td:first-child,
.kbet-widget-mixedchart-v1 .table-goal-contrast table tr th:first-child {
  padding-right: 0px;
  text-align: center;
}

.kbet-widget-mixedchart-v1 .table-goal-contrast table tr td:not(:first-child),
.kbet-widget-mixedchart-v1 .table-goal-contrast table tr th:not(:first-child) {
  padding-right: 10px;
}

.kbet-widget-mixedchart-v1 .table-goal-contrast table th {
  height: 21px;
  font-weight: 700;
  background-color: rgba(225, 225, 225, 0.05);
  border-top: 1px solid rgba(225, 225, 225, 0.3);
  border-bottom: 1px solid rgba(225, 225, 225, 0.3);
}

.kbet-widget-mixedchart-v1 .table-goal-contrast table td {
  height: 20px;
}

.kbet-widget-mixedchart-v1 .table-goal-contrast table tr:first-child td {
  height: 21px;
}

.kbet-widget-mixedchart-v1 .table-goal-contrast table tr:nth-child(odd) td {
  background-color: rgba(225, 225, 225, 0.15);
}

.kbet-widget-mixedchart-v1 .table-goal-contrast table tr:nth-child(even) td {
  background-color: rgba(225, 225, 225, 0.05);
}
</style>
