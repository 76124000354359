<template>
  <div>
    <div class="row form-group bg-silver-dark p-0 m-0 m-b-15" v-if="!isMobile">
      <div :class="[conditions.isCompare ? 'col-md-4' : 'col-md-5']">
        <!-- 빈공간 -->
      </div>
      <!-- 시간버튼 -->
      <div class="col-md-3">
        <div class="btn-group pull-right">
          <button v-show="isCompareHidden(5)" class="btn btn-white" :class="{ active: conditions.type === 5 }" @click="conditions.type = 5">{{$t('분')}}</button>
          <button v-show="isCompareHidden(4)" class="btn btn-white" :class="{ active: conditions.type === 4 }" @click="conditions.type = 4">{{$t('시간')}}</button>
          <button v-show="isCompareHidden(3)" class="btn btn-white" :class="{ active: conditions.type === 3 }" @click="conditions.type = 3">{{$t('일')}}</button>
          <button v-show="isCompareHidden(2)" class="btn btn-white" :class="{ active: conditions.type === 2 }" @click="conditions.type = 2">{{$t('월')}}</button>
          <button v-show="isCompareHidden(1)" class="btn btn-white" :class="{ active: conditions.type === 1 }" @click="conditions.type = 1">{{$t('년')}}</button>
        </div>
      </div>
      <!-- 기본조회 -->
      <div v-show="!conditions.isCompare" class="col-md-3">
        <div class="row">
          <div class="col-md-2">
            <!-- 빈공간 -->
          </div>
          <div class="col-md-5">
            <!-- 기존 v-model로 작업하면 컨트롤이 값을 변경해서 직전 시간의 초기값을 알지 못함 -->
            <date-picker :value="conditions.period.sDt" :config="configs.period.start" placeholder="Start Date" @input="onChangePicker($event, 'period', 'sDt')"></date-picker>
          </div>
          <div class="col-md-5">
            <date-picker :value="conditions.period.eDt" :config="configs.period.end" placeholder="End Date" @input="onChangePicker($event, 'period', 'eDt')"></date-picker>
          </div>
        </div>
      </div>
      <!-- 비교조회 -->
      <div v-show="conditions.isCompare" class="col-md-4">
        <div class="row form-group">
          <div class="col-md-2 m-t-5">
            <b class="f-s-11">  <trans>베이스라인</trans> </b>
          </div>
          <div class="col-md-5">
            <date-picker :value="conditions.period.sDt" :config="configs.period.start" placeholder="Start Date" @input="onChangePicker($event, 'period', 'sDt')"></date-picker>
          </div>
          <div class="col-md-5">
            <date-picker :value="conditions.period.eDt" :config="configs.period.end" placeholder="End Date" @input="onChangePicker($event, 'period', 'eDt')"></date-picker>
          </div>
        </div>
        <div class="row form-group compare">
          <div class="col-md-2 m-t-5">
            <b class="text-orange f-s-11"> <trans>비교</trans> </b>
          </div>
          <div class="col-md-5">
            <date-picker
              :value="conditions.compare.sDt"
              :config="configs.compare.start"
              placeholder="Start Date"
              :readonly="isMaintainRatio"
              @change="onChangePicker($event, 'compare', 'sDt')"
              @input="onChangePicker($event, 'compare', 'sDt')"
            ></date-picker>
          </div>
          <div class="col-md-5">
            <date-picker
              :value="conditions.compare.eDt"
              :config="configs.compare.end"
              placeholder="End Date"
              :readonly="isMaintainRatio"
              @change="onChangePicker($event, 'compare', 'eDt')"
              @input="onChangePicker($event, 'compare', 'eDt')"
            ></date-picker>
          </div>
        </div>
      </div>
      <!-- 조회버튼 + 옵션 -->
      <div class="col-md-1">
        <b-dropdown split :text="$t('조회')" :disabled="!isReady" split-variant="primary" variant="primary" @click="onSearch()" right>
          <b-dropdown-item @click="onToggleCompare"> <i v-if="conditions.isCompare" class="fa fa-check"></i> <trans>비교</trans> </b-dropdown-item>
          <b-dropdown-item v-if="conditions.isCompare" @click="onToggleMaintainRatio"> <i v-if="isMaintainRatio" class="fa fa-check"></i> 
            <trans> 1년전과 비교 </trans>
           </b-dropdown-item>
          <!-- <b-dropdown-item v-if="conditions.isCompare" @click="onToggleAdjustWeek" class="hide"> <i v-if="isAdjustWeek" class="fa fa-check"></i> 
            <trans>  요일 보정  Adjust Week</trans>
          </b-dropdown-item> -->
          <!-- <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="onDownloadCsv"> <trans>다운로드</trans> </b-dropdown-item> -->
        </b-dropdown>
      </div>
    </div>
    <div v-else>
      <div class="row form-group">
        <div class="col-8">
          <div class="btn-group">
            <button v-show="isCompareHidden(5)" class="btn btn-white btn-xs" :class="{ active: conditions.type === 5 }" @click="conditions.type = 5">Min</button>
            <button v-show="isCompareHidden(4)" class="btn btn-white btn-xs" :class="{ active: conditions.type === 4 }" @click="conditions.type = 4">Hour</button>
            <button v-show="isCompareHidden(3)" class="btn btn-white btn-xs" :class="{ active: conditions.type === 3 }" @click="conditions.type = 3">Day</button>
            <button v-show="isCompareHidden(2)" class="btn btn-white btn-xs" :class="{ active: conditions.type === 2 }" @click="conditions.type = 2">Month</button>
            <button v-show="isCompareHidden(1)" class="btn btn-white btn-xs" :class="{ active: conditions.type === 1 }" @click="conditions.type = 1">Year</button>
          </div>
        </div>
        <div class="col-4">
          <b-dropdown class="pull-right" size="xs" split :text="$t('조회')" :disabled="!isReady" split-variant="primary" variant="primary" @click="onSearch()" right>
            <b-dropdown-item @click="onToggleView"> <i v-if="isViewMore" class="fa fa-check"></i> 
              <trans>더 보기 </trans>
            </b-dropdown-item>
            <b-dropdown-item @click="onToggleEditDate"> <i v-if="isEditDate" class="fa fa-check"></i>
              <trans>날짜 편집 </trans>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="row form-group" v-if="isViewMore">
        <div class="col-6">
          <!-- 기존 v-model로 작업하면 컨트롤이 값을 변경해서 직전 시간의 초기값을 알지 못함 -->
          <date-picker :readonly="isViewMore && !isEditDate" :value="conditions.period.sDt" :config="configs.period.start" :placeholder="$t('시작일')" @input="onChangePicker($event, 'period', 'sDt')"></date-picker>
        </div>
        <div class="col-6">
          <date-picker :readonly="isViewMore && !isEditDate" :value="conditions.period.eDt" :config="configs.period.end" :placeholder="$t('종료일')" @input="onChangePicker($event, 'period', 'eDt')"></date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.compare {
  margin-top: -5px;
  margin-bottom: 0px;
}
</style>

<docs>
TODO: This is the documentation for SearchBox.
TODO: 두번째줄..
</docs>

<script>
import Vue from "vue";

export default {
  components: {},
  props: ["searchOpt", "isSearchable", "search", "download-csv"],
  data() {
    return {
      isShow: true,
      isMaintainRatio: true,
      //isAdjustWeek: false,
      isReady: true,

      // search condition
      conditions: {
        isCompare: false,
        type: 4,
        period: {
          sDt: new Date().format("yyyy-MM-dd 00:00").parseDate(),
          eDt: new Date().format("yyyy-MM-dd 23:59").parseDate(),
          points: {},
        },
        compare: {
          sDt: new Date()
            .addYear(-1)
            .format("yyyy-MM-dd 00:00")
            .parseDate(),
          eDt: new Date()
            .addYear(-1)
            .format("yyyy-MM-dd 23:59")
            .parseDate(),
          points: {},
        },
      },

      // datetime rande picker config
      configs: {
        period: {
          start: {
            format: "YYYY-MM-DD HH:mm",
            useCurrent: false,
            showClear: true,
            showClose: true,
            minDate: new Date(2010, 1, 1), // TODO : 시간테이블 최소값 바인딩
            maxDate: false,
          },
          end: {
            format: "YYYY-MM-DD HH:mm",
            useCurrent: false,
            showClear: true,
            showClose: true,
            minDate: new Date(2010, 1, 1),
          },
        },
        compare: {
          start: {
            format: "YYYY-MM-DD HH:mm",
            useCurrent: false,
            showClear: true,
            showClose: true,
            minDate: new Date(2010, 1, 1), // TODO : 시간테이블 최소값 바인딩
            maxDate: false,
          },
          end: {
            format: "YYYY-MM-DD HH:mm",
            useCurrent: false,
            showClear: true,
            showClose: true,
            minDate: new Date(2010, 1, 1),
          },
        },
      },

      conditionByType: {
        "5": { period: { sDt: null, eDt: null }, compare: { sDt: null, eDt: null } },
        "4": { period: { sDt: null, eDt: null }, compare: { sDt: null, eDt: null } },
        "3": { period: { sDt: null, eDt: null }, compare: { sDt: null, eDt: null } },
        "2": { period: { sDt: null, eDt: null }, compare: { sDt: null, eDt: null } },
        "1": { period: { sDt: null, eDt: null }, compare: { sDt: null, eDt: null } },
      },
      isViewMore: false,
      isEditDate: false,

      to_refresh: null,
      to_reload: null,
    };
  },
  computed: {},
  watch: {
    searchOpt: function() {
      this.initSetting();
    },
    isSearchable: function(newV) {
      // 조회 가능 상태
      this.isReady = newV;
    },
    "conditions.type": function() {
      this.setDatePicker();
    },
    "conditions.period.sDt": function(newV) {
      this.$set(this.configs.period.end, "minDate", newV.parseDate() || null);

      // compare 동기화 모드이면..
      if (this.isMaintainRatio) {
        this.conditions.compare.sDt = newV.parseDate().addYear(-1);
      }

      // 시작일자가 종료일자보다 크면, 시작일자로 동일하게...
      if (this.conditions.period.sDt.parseDate() > this.conditions.period.eDt.parseDate()) {
        this.conditions.period.eDt = this.conditions.period.sDt.parseDate();
      }
    },
    "conditions.period.eDt": function(newV) {
      //compare 동기화 모드이면..
      if (this.isMaintainRatio) {
        this.conditions.compare.eDt = newV.parseDate().addYear(-1);
      }
    },
    "conditions.compare.sDt": function() {
      // 시작일자가 종료일자보다 크면, 시작일자로 동일하게...
      if (this.conditions.compare.sDt.parseDate() > this.conditions.compare.eDt.parseDate()) {
        this.conditions.compare.eDt = this.conditions.compare.sDt.parseDate();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.to_refresh);
    clearInterval(this.to_reload);
    next();
  },
  beforeDestroy() {
    clearInterval(this.to_refresh);
    clearInterval(this.to_reload);
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    isCompareHidden(curType) {
      // 비교 조건시 분,일 단계는 숨긴다.
      if (this.conditions.isCompare) {
        //return curType <= 3;
        return curType <= 6; // 임시..
      }

      return true;
    },
    initSetting() {
      this.$set(this.configs.period.end, "minDate", this.conditions.period.sDt || null);
      this.conditions.isCompare = this.searchOpt.isCompare;
      this.conditions.type = this.searchOpt.timeType;

      // 조회 타입별 초기값 설정
      Object.keys(this.conditionByType).forEach((timeType) => {
        this.conditionByType[timeType].period = Object.assign({}, this.makeCondition(timeType));
        this.conditionByType[timeType].compare = Object.assign({}, this.makeCondition(timeType));
      });

      this.setDatePicker(() => {
        // 시작시 조회
        if (this.searchOpt.isLoadSearch) {
          this.onSearch();
        }

        // 자동 재조회
        if (this.searchOpt.repeatMin) {
          // console.log("SearchBox Auto Reload : ", this.searchOpt.repeatMin, " Min");
          
          clearInterval(this.to_refresh);
          this.to_refresh = setInterval(() => {
            this.onSearch();
          }, parseInt(this.searchOpt.repeatMin) * 60 * 1000);
        }

        // 자동 리로드 - 예약된 시간에 ...
        if (this.searchOpt.reloadTime) {
          
          clearInterval(this.to_reload);
          this.to_reload = setInterval(() => {
            let ti = new Date().format("HH:mm");

            // TODO: 이상하게 2번 호출되고 있음... 테스트 제대로 되지 않았음.
            // console.log("reloadTime ", this.to, this.searchOpt.reloadTime, ti, (ti == this.searchOpt.reloadTime));

            if (ti === this.searchOpt.reloadTime) {
              window.location.reload(true);
            }
          }, 60000); // 1분에 한번씩
        }
      });
    },
    // timeType 변경에 따라 생성되는 condition의 sDt, eDt 초기화 내용
    // key : sDt or eDt or null
    makeCondition(timeType, key) {
      let rtnObj = { sDt: null, eDt: null };
      let now = new Date();

      switch (timeType) {
        case 1:
          rtnObj.sDt = now
            .addYear(-3)
            .format("yyyy")
            .parseDate();
          rtnObj.eDt = now
            .addYear(0)
            .format("yyyy")
            .parseDate();
          break;
        case 2:
          rtnObj.sDt = now.format("yyyy-01").parseDate();
          rtnObj.eDt = now.format("yyyy-12").parseDate();
          break;
        case 3:
          rtnObj.sDt = now.format("yyyy-MM-01").parseDate();
          rtnObj.eDt = now.format("yyyy-MM-" + now.lastDay()).parseDate();
          break;
        case 4:
          rtnObj.sDt = now.format("yyyy-MM-dd 00").parseDate();
          rtnObj.eDt = now.format("yyyy-MM-dd 23").parseDate();
          break;
        case 5:
          rtnObj.sDt = now.format("yyyy-MM-dd 00:00").parseDate();
          rtnObj.eDt = now.format("yyyy-MM-dd 23:59").parseDate();
          break;
      }

      // key: sDt or eDt, key를 null로 넘기면 둘다 리턴 아니면 맞는 키만 리턴
      if (this.isEmpty(key) || !rtnObj.hasOwnProperty(key)) return rtnObj;
      else return rtnObj[key];
    },
    // 일자조건에 따라 날짜를 초기화 한다.
    setDatePicker(callback) {
      function formatDatePicker(timeType) {
        switch (timeType) {
          case 1:
            return "YYYY";
          case 2:
            return "YYYY-MM";
          case 3:
            return "YYYY-MM-DD";
          case 4:
            return "YYYY-MM-DD HH";
          case 5:
            return "YYYY-MM-DD HH:mm";
          default:
            return "YYYY-MM-DD";
        }
      }

      // DatePicker의 포맷이 변경되고 나서, 날짜가 변경되어야 한다.
      let viewFormat = formatDatePicker(this.conditions.type);
      this.configs.period.start.format = viewFormat;
      this.configs.period.end.format = viewFormat;
      this.configs.compare.start.format = viewFormat;
      this.configs.compare.end.format = viewFormat;

      if (this.conditionByType[this.conditions.type].period.sDt == null) this.conditions.period.sDt = this.makeCondition(this.conditions.type).sDt;
      else this.conditions.period.sDt = this.conditionByType[this.conditions.type].period.sDt.parseDate();

      if (this.conditionByType[this.conditions.type].period.eDt == null) this.conditions.period.eDt = this.makeCondition(this.conditions.type).eDt;
      else this.conditions.period.eDt = this.conditionByType[this.conditions.type].period.eDt.parseDate();

      Vue.nextTick(() => {
        // 대시보드 모드에서는 월선택시 1~12달로 펼쳐지고, 사용자 사용시에는 선택된 일자를 유지시켜준다.
        if (!this.searchOpt.display) {
          let { period } = this.conditions;
          period.sDt = this.makeCondition(this.conditions.type).sDt;
          period.eDt = this.makeCondition(this.conditions.type).eDt;
          // console.log('setDatePicker period', period);
        }

        if (callback) callback();
      });
    },
    // 조회버튼
    onSearch() {
      this.$emit("search");
    },
    onDownloadCsv() {
      this.$emit("download-csv");
    },
    onToggleCompare() {
      // 비교모드를 반전한다.
      this.conditions.isCompare = !this.conditions.isCompare;
    },
    onToggleMaintainRatio() {
      // isMaintainRatio를 토글한다. true로 변경하면, 비교일자가 1년전으로 자동으로 맞춰진다.
      this.isMaintainRatio = !this.isMaintainRatio;
    },
    // onToggleAdjustWeek() {
    //   // isAdjustWeek가 true이면, 비교일자의 요일이 현재일자의 요일과 동일하게 맞춰진다.
    //   this.isAdjustWeek = !this.isAdjustWeek; // TODO : 비교일자 - 주차별 맞추기 기능 구현해야 함.
    // },
    // 날짜 변경시 타입에 따라 기존값 보관
    // type : period or compare, key : sDt or eDt
    onChangePicker(event, type, key) {
      let preDt = this.conditionByType[this.conditions.type][type][key] || this.makeCondition(this.conditions.type, key).format("yyyy-MM-dd HH:mm:ss");
      let newDt = event + preDt.substr(event.length, preDt.length);
      this.conditionByType[this.conditions.type][type][key] = newDt;
      this.conditions[type][key] = newDt.parseDate();
    },
    onToggleView() {
      this.isViewMore = !this.isViewMore;
    },
    onToggleEditDate() {
      this.isEditDate = !this.isEditDate;
    },
  },
};
</script>
