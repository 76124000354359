<!-- https://github.com/recogizer/gauge-chart
https://recogizer.github.io/gauge-chart/docs/
 -->
<template>
  <div
    :id="this.gaugeId"
    class="vue-gauge-item"
  ></div>
</template>
<script>
let GaugeChart = require("vue-gauge/assets/bundle");

export default {
  name: "vue-gauge",
  props: ["refid", "options"],
  created() {},
  mounted() {
    this.initPlugin(this.options);
  },
  data() {
    return {
      beforeData: 0,
    };
  },
  methods: {
    initPlugin(options) {
      if (this.gaugeId) {
        let config = {
          hasNeedle: true,
          needleColor: "#f76c57",
          needleUpdateSpeed: 500,
          arcColors: ["#85a3ca", "#d3d3d3"],
          arcDelimiters: [70],
          rangeLabel: ["0", "100"],
          chartWidth: 250,
          needleStartValue: this.beforeData,
        };
        config = { ...config, ...options };

        // Element inside which you want to see the chart
        let element = document.querySelector("#" + this.gaugeId);
        // Drawing and updating the chart
        GaugeChart.gaugeChart(element, config.chartWidth, config).updateNeedle(
          config.needleValue
        );
      }
    },
  },
  computed: {
    gaugeId() {
      if (typeof this.refid != "undefined") {
        return this.refid;
      }
      return "vue-gauge";
    },
  },
  watch: {
    options() {
      let element = document.getElementById(this.refid);
      element.removeChild(element.childNodes[0]);

      this.initPlugin(this.options);

      this.beforeData = this.options.needleValue;

      let svgs = document.getElementsByClassName("vue-gauge-item");

      for (let i = 0; i < svgs.length; i++) {
        if (svgs[i].childNodes[0].className.baseVal !== "svg-box-content") {
          svgs[i].childNodes[0].className.baseVal =
            svgs[i].childNodes[0].className.baseVal + "svg-box-content";
        }
      }

      let svgAttri = document.getElementsByTagName("svg");
      for (let i = 0; i < svgAttri.length; i++) {
        if (svgAttri[i].width !== "100%" && svgAttri[i].height !== "100%") {
          svgAttri[i].removeAttribute("width");
          svgAttri[i].removeAttribute("height");
          svgAttri[i].setAttribute("width", "100%");
          svgAttri[i].setAttribute("height", "100%");
          svgAttri[i].setAttribute("viewBox", "0, 0, 250, 170");
        }
      }
    },
  },
};
</script>
