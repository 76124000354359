<template>
  <div class="xeGridTable heightMAX">
    <panel
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-0 chartHeightMax40"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop
            @dragover="handleTitleDragOver(...arguments)"
            @drop="handleTitleDrop(...arguments)"
          >
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>

      <drop
        @dragover="handleBodyDragOver(...arguments)"
        @drop="handleBodyDrop(...arguments)"
        class="heightMAX"
      >
        <div v-if="isShow" class="heightMAX">
          <xc-grid-table
            :gridTable="gridTable"
            :maxHeight="gridHeight"
            :isGroup="isGridGroup"
            :isPagination="isPagination"
            :isLineNumber="isLineNumber"
          />
        </div>
      </drop>
    </panel>
    <drop
      v-else
      @dragover="handleBodyDragOver(...arguments)"
      @drop="handleBodyDrop(...arguments)"
      class="heightMAX"
    >
      <div v-if="isShow" class="m-b-20 heightMAX" :class="[inverseBg]">
        <h4>{{ $t(widget.title) }}</h4>
        <xc-grid-table
          :gridTable="gridTable"
          :maxHeight="gridHeight"
          :isGroup="isGridGroup"
          :isPagination="isPagination"
          :isLineNumber="isLineNumber"
        />
      </div>
    </drop>
    <div v-if="isShow">
      <b-modal
        ref="modalSetting"
        size="lg"
        :title="`${$t('설정')} - GridTable`"
        body-class="p-t-0 p-b-0"
        hide-footer
      >
        <grid-table-setting
          :initSetting="gridTableSetting"
          @on-modal-cancel="onModalCancel"
          @on-modal-ok="onModalOK"
        />
      </b-modal>
    </div>
  </div>
</template>

<style>
/* Sum, Avg, Min, Max, Last아래 빈데이터 영역을 숨긴다. */
.xe-grid-tbl tbody tr:nth-last-child(1) {
  display: none;
}
/* 보여야 하는 데이터인데, 위에서 숨겨버림. 다시 보이게 해야 함.. */
.xe-grid-tbl tbody:nth-last-child(1) tr {
  display: table-row;
}
</style>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import gridTableSetting from "@src/views/widget/v1/setting/xeGridTableSetting.vue";
import xcGridTable from "@src/views/widget/v1/controls/xcGridTable.vue";
import utils from "./xeBaseUtils.js";

export default {
  name: "xeGridTable",
  extends: xeBaseWidget,
  components: {
    gridTableSetting,
    xcGridTable,
  },
  data() {
    return {
      isGridGroup: false,
      isPagination: false,
      isLineNumber: true,
      gridTable: {
        columns: [],
        rows: [],
      },
    };
  },
  computed: {
    gridTableSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    gridHeight() {
      return this.fixedHeight - (this.isPagination ? 65 : 0) + "px";
    },
  },
  created() {
    if (!this.widget.columns)
      this.widget.columns = [{ label: "Default", point: "", compare: false }];
    if (!this.widget.addition)
      this.widget.addition = { afterAction: "", afterBind: [], fixed: 0 };
    if (!this.widget.self)
      this.widget.self = {
        baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
        compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
      };
  },
  mounted() {
    let grids = document.getElementsByClassName("xeGridTable");

    for (let i = 0; i < grids.length; i++) {
      grids[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.gridTable = {
        columns: this.defaultColumns(),
        rows: [],
      };
    },
    defaultColumns() {
      let postFix = "_C";
      let rtnColumns = [];

      function createColumn(column) {
        return {
          label: column.label, // + " " + column.point,
          field: column.compare ? `${column.point}${postFix}` : column.point, //포인트정보
          type: "text",
          // width: "" + Math.round(100 / that.widget.columns.length + (that.isCompare ? 2 : 1)) + "%",
          tdClass:
            "text-right f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
          compare: column.compare,
        };
      }
      // , sortFn: this.customSortFn
      //  type: "date",
      rtnColumns.push({
        label: "BaseLine",
        field: "Baseline",
        tdClass: "text-right",
        thClass: "text-center text-nowrap",
        compare: false,
        sortable: false,
        // hidden: true
      });
      // BaseLine, Comparison 시간 Sort 함수 추가인자 (기존에 조회되던 시간을 추가함 - YYYYMMDDHHmmss형태)
      // rtnColumns.push({ label: "hiddenBaseLine", field: "hiddenBaseLine", tdClass: "text-right", thClass: "text-center text-nowrap", compare: false, hidden: true });
      // compare가 false인 column만 column에 추가
      rtnColumns = rtnColumns.concat(
        this.widget.columns
          .filter((v) => !v.compare)
          .map((column) => createColumn(column))
      );

      if (this.isCompare) {
        rtnColumns.push({
          label: "Comparison",
          field: "Comparison",
          tdClass: "text-right",
          thClass: "text-center text-nowrap",
          compare: true,
        });
        // BaseLine, Comparison 시간 Sort 함수 추가인자 (기존에 조회되던 시간을 추가함 - YYYYMMDDHHmmss형태)
        // rtnColumns.push({ label: "hiddenComparison", field: "hiddenComparison", tdClass: "text-right", thClass: "text-center text-nowrap", compare: false, hidden: true });
        rtnColumns = rtnColumns.concat(
          this.widget.columns
            .filter((v) => v.compare)
            .map((column) => createColumn(column))
        );
      }

      return rtnColumns;
    },
    applyData(widgetLogs) {
      this.gridTable = {
        columns: this.defaultColumns(),
        rows: this.createRowData(widgetLogs),
      };
      this.widgetLogs = widgetLogs;
    },
    createRowData(logs) {
      let that = this;
      // 그리드 형태의 데이터를 fixed하는 함수
      function fixedValue(rtnRows) {
        let rows = JSON.parse(JSON.stringify(rtnRows));
        rows.forEach(row => {
          // columns이 grid의 column.field
          let columns = Object.keys(row);
          columns.forEach((column) => {
            // && column !== "hiddenBaseline" && column !== "hiddenComparison"
            if (column !== "Baseline" && column !== "Comparison") {
              let value = that.comma(
                utils.fixedValue(row[column], that.widget.addition.fixed),
                that.widget.addition.fixed
              );

              row[column] = value;
              // row[column] =
              //   that.isEmpty(value) || isNaN(value) ? null : value;
            }
          });
        });
        return rows;
      }

      try {
        // afterAction까지 해서 row에 데이터를 바인딩 해온 값
        let rtnRows = this.bindRowData(logs);
        // afterBind가 있을때
        if (JSON.stringify(this.widget.addition.afterBind) != "[]") {
          let groupRows = [];

          this.widget.addition.afterBind.forEach((type) => {
            let afterRow = {
              Baseline: "" + type,
              Comparison: "" + type,
              children: [{}],
            };

            this.widget.columns.forEach((column) => {
              if (this.isCompare && column.compare) {
                let val = utils.onAfterBind(
                  column.field,
                  rtnRows.map((row) => row[column.point + "_C"]),
                  type
                );

                val = parseFloat(val).toFixed(this.widget.addition.fixed);

                afterRow[column.point + "_C"] = val;
              }

              let val = utils.onAfterBind(
                column.field,
                rtnRows.map((row) => row[column.point]),
                type
              );

              // console.log("val column", column, val);

              // val = parseFloat(val).toFixed(this.widget.addition.fixed);
              val = global.xe.comma(val, this.widget.addition.fixed);

              afterRow[column.point] = val;
            });

            groupRows.push(afterRow);
          });

          // children key와 value를 마지막row에 추가
          if (groupRows.length > 0) {
            groupRows[groupRows.length - 1] = Object.assign(
              groupRows[groupRows.length - 1],
              { children: fixedValue(rtnRows) }
            );
          }

          this.isGridGroup = true;
          return groupRows;
        }

        // afterBind가 없을때
        this.isGridGroup = false;

        // tofixed
        return fixedValue(rtnRows);
      } catch (err) {
        console.error(`${this.$options.name} bindData`, err);
      }
    },
    bindRowData(logs) {
      let doubleRows = logs.map((log, i) => {
        let dateTimeName = "Baseline";
        if (i == 1) dateTimeName = "Comparison";

        return log.dates.map((dateTime, j) => {
          if (dateTime.length > 1)
            dateTime = `${dateTime[1] || ""} ${dateTime[0] || ""}`;

          //Points
          let row = {};
          row[dateTimeName] = dateTime;

          Object.keys(log.points).map((point) => {
            log.points[point] = utils.onAfterAction(
              point,
              log.points[point],
              this.widget.addition.afterAction
            ); // 배열반환
            log.points[point] = utils.fixedValue(
              log.points[point],
              this.widget.addition.fixed
            );
            row[i == 1 ? point + "_C" : point] = log.points[point][j];
          });
          return row;
        });
      });

      // BaseLine 조회일 경우
      if (doubleRows.length <= 1) {
        // console.log("xeGridTable bindGridData BaseLine 조회일 경우 :", doubleRows);
        return doubleRows[0] ? doubleRows[0] : [];
      }

      // 두개의 배열길이 다른경우, 더 긴 배열에 길이만큼 for문이 돌아서 row가 생성되어야 함.
      let baseRows = doubleRows[0];
      let compareRows = doubleRows[1];

      if (baseRows.length > compareRows.length) {
        return baseRows.map((row, idx) => {
          return Object.assign(row, compareRows[idx]);
        });
      }

      return compareRows.map((row, idx) => {
        return Object.assign(row, baseRows[idx]);
      });
    },
    // BaseLine, Comparison 시간 Sort 함수 추가
    // customSortFn(x, y, col, rowX, rowY) {
    //   let target = "hidden" + col.field;
    //   // console.log('rowX[target] < rowY[target]', target, rowX.hasOwnProperty('hiddenBaseline'), rowX.hiddenBaseline, rowX[target]);
    //   return rowX[target] > rowY[target] ? -1 : rowX[target] < rowY[target] ? 1 : 0;
    // }
  },
};
</script>
