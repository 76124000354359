<template>
	<div>
		<!-- 조회기간 설정 -->
		<div class="row form-group">
			<div class="col-md-2">
				<label
					><b><trans>베이스라인</trans></b></label
				>
			</div>
			<div class="col-md-10">
				<div class="row form-group align-items-center">
					<div class="col-md-2">
						<label>
							<trans>시간 유형</trans>
						</label>
						<select class="form-control" v-model="selfBaseline.type">
							<option :value="6">{{$t('분')}}</option>
							<option :value="5">{{$t('15분')}}</option>
							<option :value="4">{{$t('시')}}</option>
							<option :value="3">{{$t('일')}}</option>
							<option :value="2">{{$t('월')}}</option>
							<option :value="1">{{$t('년')}}</option>
						</select>
					</div>
					<div class="col-md-5">
						<label>
							<trans>시작일</trans>
						</label>
						<div class="input-group">
							<valid-input
								:inputType="'text'"
								:inputValue.sync="selfBaseline.sTi"
								:placeholder="$t('시작일을 입력하세요.')"
								:rules="rules.DASHBOARD_WIDGET_BASELINE_START_RULE"
								:NonStatus="true"
							></valid-input>

							<div class="input-group-append">
								<b-dropdown variant="default" right>
									<template v-for="(s, i) in selfStart">
										<b-dropdown-divider v-if="s.type === 'divider'" :key="i"></b-dropdown-divider>
										<b-dropdown-item :key="i" v-else-if="s.type !== 'divider'" @click="writeSelfScript(selfBaseline, 'sTi', s.script)">
											{{ s.type }}
										</b-dropdown-item>
									</template>
								</b-dropdown>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<label>
							<trans>종료일</trans>
						</label>
						<div class="input-group">
							<valid-input
								:inputType="'text'"
								:inputValue.sync="selfBaseline.eTi"
								:placeholder="$t('종료일을 입력하세요.')"
								:rules="rules.DASHBOARD_WIDGET_BASELINE_END_RULE"
								:NonStatus="true"
							></valid-input>

							<div class="input-group-append">
								<b-dropdown variant="default" right>
									<template v-for="(s, i) in selfEnd">
										<b-dropdown-divider v-if="s.type == 'divider'" :key="i"></b-dropdown-divider>
										<b-dropdown-item :key="i" v-else-if="s.type !== 'divider'" @click="writeSelfScript(selfBaseline, 'eTi', s.script)">
											{{ s.type }}
										</b-dropdown-item>
									</template>
								</b-dropdown>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row form-group" v-if="isCompare">
			<!-- v-if="isCompare" -->
			<div class="col-md-2">
				<label
					><b>
						<trans>비교</trans>
					</b></label
				>
			</div>
			<div class="col-md-10">
				<div class="row form-group align-items-center">
					<div class="col-md-2">
						<label>
							<trans>시간 유형</trans>
						</label>
						<select class="form-control" v-model="selfCompare.type">
							<option :value="6"> {{ $t("분") }} </option>
							<option :value="5"> {{ $t("15분") }} </option>
							<option :value="4"> {{ $t("시") }} </option>
							<option :value="3"> {{ $t("일") }} </option>
							<option :value="2"> {{ $t("월") }} </option>
							<option :value="1"> {{ $t("년") }} </option>
						</select>
					</div>
					<div class="col-md-5">
						<label>
							<trans>시작일</trans>
						</label>
						<div class="input-group">
							<valid-input
								:inputType="'text'"
								:inputValue.sync="selfCompare.sTi"
								:placeholder="$t('시작일을 입력하세요.')"
								:rules="rules.DASHBOARD_WIDGET_BASELINE_START_RULE"
								:NonStatus="true"
							></valid-input>

							<div class="input-group-append">
								<b-dropdown variant="default" right>
									<template v-for="(s, i) in selfStart">
										<b-dropdown-divider v-if="s.type == 'divider'" :key="i"></b-dropdown-divider>
										<b-dropdown-item :key="i" v-else-if="s.type !== 'divider'" @click="writeSelfScript(selfCompare, 'sTi', s.script)">
											{{ s.type }}
										</b-dropdown-item>
									</template>
								</b-dropdown>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<label>
							<trans>종료일</trans>
						</label>
						<div class="input-group">
							<valid-input
								:inputType="'text'"
								:inputValue.sync="selfCompare.eTi"
								:placeholder="$t('종료일을 입력하세요.')"
								:rules="rules.DASHBOARD_WIDGET_BASELINE_END_RULE"
								:NonStatus="true"
							></valid-input>

							<div class="input-group-append">
								<b-dropdown variant="default" right>
									<template v-for="(s, i) in selfEnd">
										<b-dropdown-divider v-if="s.type == 'divider'" :key="i"></b-dropdown-divider>
										<b-dropdown-item :key="i" v-else-if="s.type !== 'divider'" @click="writeSelfScript(selfCompare, 'eTi', s.script)">
											{{ s.type }}
										</b-dropdown-item>
									</template>
								</b-dropdown>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- //조회기간 설정 -->
	</div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";

	export default {
		props: ["copySetting", "isCompare"],
		data() {
			return {
				arrSelf: [
					{ type: "now", script: "now" },
					{ type: "baseline", script: "baseline.%DT%" },
					{ type: "compare", script: "compare.%DT%" },
					{ type: "divider" },
					{ type: "oneDay", script: "now.addDay(0)" },
					{ type: "oneMonth", script: "now.addMonth(0)" },
					{ type: "oneYear", script: "now.addYear(0)" },
					{ type: "divider" },
				],
				arrSelfStart: [
					{ type: "oneDayFormat", script: "now.sDate(0)" },
					{ type: "oneMonthFormat", script: "now.sMonth(0)" },
					{ type: "oneYearFormat", script: "now.sYear(0)" },
				],
				arrSelfEnd: [
					{ type: "oneDayFormat", script: "now.eDate(0)" },
					{ type: "oneMonthFormat", script: "now.eMonth(0)" },
					{ type: "oneYearFormat", script: "now.eYear(0)" },
				],
				rules
			};
		},
		computed: {
			selfBaseline() {
				console.log("selfBaseline : " + JSON.stringify(this.copySetting.self.baseline));
				// 카드류
				if (!this.copySetting.self.baseline) return this.copySetting.self;
				// 나머지 차트
				else return this.copySetting.self.baseline;
			},
			selfCompare() {
				return this.copySetting.self.compare;
			},
			selfStart() {
				let rtn = this.arrSelf.concat(this.arrSelfStart);
				return rtn;
			},
			selfEnd() {
				let rtn = this.arrSelf.concat(this.arrSelfEnd);
				return rtn;
			},
		},
		methods: {
			writeSelfScript(which, time, script) {
				if (time == "sTi") which.sTi = script.replace("%DT%", "sDt");
				if (time == "eTi") which.eTi = script.replace("%DT%", "eDt");
			},
		},
	};
</script>

<style scoped>
	.input-group span {
		flex: 1;
	}
</style>
