<template>
  <div class="xeTodoWidget heightMAX">
    <!-- noBody="true"  -->
    <panel 
      ref="panel"
      class="heightMAX displayGrid"
      v-if="widget.panel && widget.panel.display" 
      :title="$t(widget.title)"
      :variant="inverseMode ? 'inverse' : panelVariant"
      :bodyClass="'p-5 chartHeightMax ' + inverseBg"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      :fixedHeight="fixedHeight"

    >
      <template slot="header">
        <h4 class="panel-title">
          {{ $t(widget.title) }}
        </h4>
      </template>
      <template slot="outsideBody">
        <vue-event-calendar class="heightMAX overflows" :events="todoEvents" @day-changed="handleDayChanged" @month-changed="handleMonthChanged">
          <!-- <template scope="props">
            <div v-for="(event, index) in props.showEvents" class="event-item">
              {{event}}
            </div>
          </template> -->
        </vue-event-calendar>
      </template>
    </panel>

    <div v-else class="bg-white heightMAX">
      <vue-event-calendar class="heightMAX overflows" :events="todoEvents" @day-changed="handleDayChanged" @month-changed="handleMonthChanged">
        <!-- <template scope="props">
          <div v-for="(event, index) in props.showEvents" class="event-item">
            {{event}}
          </div>
        </template> -->
      </vue-event-calendar>
    </div>
  </div>
</template>

<style scoped>
.displayGrid{
  display: grid;
}

.overflows{
  overflow: auto;
}
</style>

<script>
import xeBaseWidget from "./xeBaseWidget.js";

export default {
  name: "xeTodoWidget",
  props: ["widget", "cellSize", "on-remove-widget"],
  extends: xeBaseWidget,
  data() {
    return {

      todoEvents: [{
        date: '2020/12/16', // Required
        title: 'Foo' // Required
      }, {
        date: '2020/12/16',
        title: 'Bar',
        desc: 'description',
        customClass: 'disabled highlight' // Custom classes to an calendar cell
      }]

    };
  },
  mounted(){
    let calendars = document.getElementsByClassName("xeTodoWidget");

    for(let i = 0; i < calendars.length; i++){
      calendars[i].parentNode.style.height = "100%";
    }
  },
  computed: {
    fixedHeight() {
      // 41은 패널해더의 높이...  5는 dndgrid 마진값
      let margin = 0;
      if (!this.isEmpty(this.cellSize.margin)) margin = this.cellSize.margin;

      let panelHeaderHeight = this.isEditMode || (this.widget.panel && this.widget.panel.display) ? 41 : 0; // 41은 패널헤더가 표실될때의 높이값

      return this.widget.position.h * (this.cellSize.h + margin) - margin - panelHeaderHeight;
    },
  },
  methods: {
    // 부모위젯에서 호출됨..
    initSetting() {
    },


  }
};
</script>
