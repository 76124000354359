<template>
  <div class="xeLine heightMAX rounded" style="overflow: hidden;">
      <div class="heightMAX" style="padding: 0">
        <!-- <div class="jvectormap-container " style="background-color: transparent; position:relative"> -->
        <drop
          @dragover="handleBodyDragOver(...arguments)"
          @drop="handleBodyDrop(...arguments)"
          class="heightMAX kbet-custom-reset"
        >
          <div class="rounded kbet-widget-energy heightMAX" style="display: block;">
            <p class="title">{{ $t(widget.title)}}</p>
            
            <div class="chartHeightMax">
              <!-- <div class="chartHeightMax"> -->
                <div class="chartHeightMax p-5">
                  <line-chart
                    v-show="viewMode === 'Chart'"
                    class="chartHeightMax"
                    :data="chartJs.data"
                    :options="chartJs.options"
                  ></line-chart>
                </div>
              </div>
            <!-- </div> -->
          </div>
          <!--// kbet-card-info -->
        </drop>
      </div>

      <b-modal
        ref="modalSetting"
        size="lg"
        :title="`${$t('설정')} - Line ${$t('차트')}`"
        bodyClass="p-t-0 p-b-0"
        hide-footer
      >
        <chart-line-setting
          :initSetting="chartSetting"
          :data="chartJs.data"
          @on-modal-cancel="onModalCancel"
          @on-modal-ok="onModalOK"
        />
      </b-modal>
  </div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import LineChart from "../../../components/vue-chartjs/Charts/LineChart.js";
import chartLineSetting from "./setting/xeLineSetting.vue";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";

export default {
  name: "xeLine",
  extends: xeBaseWidget,
  components: {
    LineChart,
    chartLineSetting,
  },
  data() {
    return {
      chartJs: {
        data: {},
        options: {},
      },
      themeColor : global.xe.parseBool(localStorage.contentInverse) ? require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark : require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light,
      viewMode: "Chart", // 'Chart', 'Grid'
    };
  },
  computed: {
    chartSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },

  created() {
    // 초기값 설정
    if (!this.widget.addition) {
      this.widget.addition = {
        afterAction: "",
        fixed: 0,
      };
    }
    if (!this.widget.self) {
      this.widget.self = {
        baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
        compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
      };
    }

    if (!this.widget.chart)
      this.widget.chart = {
        colorOpens: false,
        backgroundColor: "#ffffff",
        gridColor: "#0000001F",
        fontColor: "#000000",
        chartFontSize: 14,
        titleFontSize: 14,
        series: [
          {
            type: "line",
            // label: "Default",
            // point: "",
            label: "Default1",
            point: "0201_NLI_001F02W",
            position: "Left",
            fill: false,
            compare: false,
            dataColor: "#00acac",
            // dataColor: "#00AAAA",
          },
          {
            type: "line",
            // label: "Default",
            // point: "",
            label: "Default2",
            point: "0201_NLI_001F01W",
            position: "Left",
            fill: false,
            compare: false,
            dataColor: "#348fe2",
            // dataColor: "#679FEA",
          },
          {
            type: "line",
            // label: "Default",
            // point: "",
            label: "Default3",
            point: "0201_NLI_001F06W",
            position: "Left",
            fill: false,
            compare: false,
            dataColor: "#8753de",
            //dataColor: "#486EB0",
          },
        ],
        options: {
          stacked: true,
          fill: false,
          fixed: 0,
          legend: {
            display: true,
            position: "top",
            align: "end",
            labels: {
              boxWidth: 10,
              fontSize: 14,
              fontStyle: "normal",
              fontColor: "#000000",
            },
          },
          yAxes: {
            left: {
              label: "Unit1",
              min: 0,
              max: 0,
              display: true,
              unit: "",
              fontSize: 12,
              fontColor: "#000000",
            },
            right: {
              label: "Unit2",
              min: 0,
              max: 0,
              display: false,
              unit: "",
              fontSize: 12,
              fontColor: "#000000",
            },
          },
        },
      };
  },
  mounted() {
    let lines = document.getElementsByClassName("xeLine");

    for (let i = 0; i < lines.length; i++) {
      lines[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    //make Test Random Number
    initSetting() {
      this.chartJs.options = this.createChartOptions();
      this.chartJs.data = {
        labels: this.defaultLabels(this.searchBoxCond.type),
        datasets: this.defaultDatasets(),
      };
      // this.$el.style.backgroundColor = this.widget.chart.backgroundColor;
      this.setDataColor();
      this.setTheme();
    },
    setTheme(){
     
      this.$el.style.backgroundColor = this.themeColor.chart.line.backgroundColor;
    },
    setDataColor(){
      this.chartJs.data.datasets.forEach((element, index) => {
        element.backgroundColor = this.themeColor.chart.itemColors[index];
        element.borderColor = this.themeColor.chart.itemColors[index];

        if(element.compare){
          element.backgroundColor += "77";
          element.borderColor += "77";
        }
        
      });
    },
    defaultLabels(timeType) {
      let now = new Date();
      let labels = [];

      if (timeType == 1)
        labels = [now.addYear(-1).getFullYear() + "Y", now.getFullYear() + "Y"]; // 년
      if (timeType == 2)
        labels = [...Array(13).keys()]
          .filter((v) => v !== 0)
          .map((v) => {
            return v + "M";
          }); // 월
      if (timeType == 3)
        labels = [...Array(31).keys()]
          .filter((v) => v !== 0)
          .map((v) => {
            return v + "D";
          }); // 일
      if (timeType == 4)
        labels = [...Array(24).keys()].map((v) => {
          return v + "H";
        }); // 시
      if (timeType == 5) {
        // 15분
        for (let i = 1; i < 25; i++) {
          if (i == 1) labels.push(["0m", now.getDate() + "D"]);
          else labels.push("0m");
          labels.push("15m");
          labels.push("30m");
          labels.push("45m");
        }
      }

      return labels;
    },
    createLabels(logs) {
      let labels = [];
      let y,
        m,
        d,
        h,
        M = "";

      for (let i = 0; i < logs.length; i++) {
        let firstRows = logs[i].rows[0];

        for (let j = 0; j < logs[i].rows.length; j++) {
          let str = logs[i].rows[j];
          if (/^(\d){4}$/.test(str)) {
            y = str.substr(0, 4);
            // logs[i].rows[j] = y + "-" + m + "-" + d;
            logs[i].rows[j] = y + "년";
          } else if (/^(\d){6}$/.test(str)) {
            y = str.substr(0, 4);
            m = str.substr(4, 2);
            // logs[i].rows[j] = y + "-" + m + "-" + d;
            logs[i].rows[j] = m + "월";
          } else if (/^(\d){8}$/.test(str)) {
            y = str.substr(0, 4);
            m = str.substr(4, 2);
            d = str.substr(6, 2);
            if (Number(firstRows.substr(4, 2)) === Number(m)) {
              logs[i].rows[j] = d + "일";
            } else {
              logs[i].rows.splice(j, logs[i].rows.length - j);
              break;
            }
            // logs[i].rows[j] = y + "-" + m + "-" + d;
          } else if (/^(\d){10}$/.test(str)) {
            y = str.substr(0, 4);
            m = str.substr(4, 2);
            d = str.substr(6, 2);
            h = str.substr(8, 2);
            // logs[i].rows[j] = y + "-" + m + "-" + d + " " + h;\
            logs[i].rows[j] = h + "시";
          } else if (/^(\d){12}$/.test(str)) {
            y = str.substr(0, 4);
            m = str.substr(4, 2);
            d = str.substr(6, 2);
            h = str.substr(8, 2);
            M = str.substr(10, 2);
            // logs[i].rows[j] = y + "-" + m + "-" + d + " " + h + ":" + M;
            logs[i].rows[j] = h + ":" + M;
          }
        }
      }

      if (logs.length === 1) {
        // baseline 조회시
        labels = logs[0].rows;
      } else if (logs.length === 2) {
        // compare 조회시
        let base = logs[0];
        let comp = logs[1];

        // 구간조회이기 때문에, 일자의 갯수가 base와 comp가 서로 다를 수 있다.
        // 그래서, 일자 기간을 더 길게 조회한 쪽을 기준으로 for문을 돌아준다.

        if (base.rows.length >= comp.rows.length) {
          labels = base.rows.map((dt) => {
            return [
              dt,
              // comp.dates[i] ? comp.dates[i][1] + " " + comp.dates[i][0] : "",
            ];
          });
        } else {
          labels = comp.rows.map((dt) => {
            return [
              dt,
              // base.dates[i] ? base.dates[i][1] + " " + base.dates[i][0] : "",
            ];
          });
        }
      }

      return labels;
    },
    defaultDatasets() {
      return this.widget.chart.series
        .filter((item) => !item.compare || this.isCompare)
        .map((item) => {
          return {
            backgroundColor: item.dataColor,
            borderColor: item.dataColor,
            fill: false,
            label: item.label, // ''
            pointBorderColor: item.dataColor,
            pointBackgroundColor: "#fff",
            showLine: true,
            yAxisID:
              item.position === "Left"
                ? "Left_yAxis"
                : item.position === "Right"
                ? "Right_yAxis"
                : null,
            point: item.point,
            compare: item.compare ? item.compare : false,
          };
        });
    },
    createDatasets(logs) {
      try {
        let datasets = this.defaultDatasets();

        // 기존 로그 복사하여 데이터 변형 작업 진행
        // let tmpLogs = JSON.stringify(logs);
        let copyLogs = JSON.parse(JSON.stringify(logs));

        // afterAction 처리
        copyLogs = utils.onAfterAction2(
          datasets,
          copyLogs,
          this.widget.addition.afterAction
        );

        // 기존 로직 중 fixedValue살림

        return datasets.map((dataset) => {
          let log = dataset.compare ? copyLogs[1] : copyLogs[0];
          let ptVals = log && log.points ? log.points[dataset.point] : [];

          dataset.data = ptVals;

          return dataset;
        });
      } catch (e) {
        console.log(e);
      }
    },
    createChartOptions() {
      let options = {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
          duration: 1000,
          easing: "easeOutQuart",
          onProgress: null,
          onComplete: null,
        },
        hoverMode: "index",
        legend: {
          display: this.widget.chart.options.legend.display,
          position: this.widget.chart.options.legend.position,
          align: this.widget.chart.options.legend.align,
          fullWidth: true,
          fill: "#000000",
          labels: {
            usePointStyle: true,
            fillStyle: "#242342",
            backgroundColor: "#234132",
            boxWidth: this.widget.chart.options.legend.labels.boxWidth,
            fontSize: this.widget.chart.options.legend.labels.fontSize,
            fontColor: this.themeColor.chart.bar.fontColor,
            fontFamily: "'Helvetica Neue'",
          },
        },
        title: {
          display: false,
          position: "top",
          text: global.xe.$t(this.widget.title),
          fontSize: this.widget.chart.options.titleFontSize,
          fontColor: this.widget.chart.options.fontColor,
        },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: -5 } },
        hover: {
          mode: "nearest",
          intersect: true,
          animationDuration: 400,
        },
        tooltips: {
          mode: "index",
          responsive: false,
          intersect: false,

          callbacks: {
            label: (tooltipItems, data) => {
              let value =
                data.datasets[tooltipItems.datasetIndex].data[
                  tooltipItems.index
                ];

              value = this.comma(value, this.widget.addition.fixed);

              let label = data.datasets[tooltipItems.datasetIndex].label || "";

              if (
                data.datasets[tooltipItems.datasetIndex].yAxisID == "Left_yAxis"
              ) {
                value =
                  String(value) + this.widget.chart.options.yAxes.left.unit;
              } else if (
                data.datasets[tooltipItems.datasetIndex].yAxisID ==
                "Right_yAxis"
              ) {
                value =
                  String(value) + this.widget.chart.options.yAxes.right.unit;
              } else {
                value = String(value);
              }

              if (label) {
                label += ": ";
              }

              return label + value;

              //   console.log(data);

              //   data.datasets.forEach((dataset) => {
              //     sum += dataset.data[tooltipItems.datasetIndex];
              //   });

              //   return "Sum: " + sum;

              // let label = data.datasets[tooltipItem.datasetIndex].label || '';

              // if (label) { label += ': ' }
              // if(!data.datasets[tooltipItem.datasetIndex].compare){ label += tooltipItem.yLabel; }

              // return label;
            },
            footer: (tooltipItems) => {
              let sumLeft = 0;
              let sumRight = 0;
              let stringLeft = "";
              let stringRight = "";
              let returnString = "";

              for (let i = 0; i < tooltipItems.length; i++) {
                if (tooltipItems[i].yAxisID === "Left_yAxis") {
                  sumLeft += tooltipItems[i].yLabel;
                  stringLeft =
                    "Sum(" + this.widget.chart.options.yAxes.left.unit + "): ";
                } else if (tooltipItems[i].yAxisID === "Right_yAxis") {
                  sumRight += tooltipItems[i].yLabel;
                  stringRight =
                    "Sum(" + this.widget.chart.options.yAxes.right.unit + "): ";
                }
              }

              if (stringLeft !== "") {
                returnString = stringLeft + String(sumLeft);
              }

              if (stringRight !== "") {
                if (returnString.length !== 0) {
                  returnString =
                    returnString + "\n" + stringRight + String(sumRight);
                } else {
                  returnString = stringRight + String(sumRight);
                }
              }

              return returnString;
            },
          },
        },
        elements: {
          point: {
            // radius: 3, // 라인차트에 점크기 기본값 3
          },
        },
        scales: {
          xAxes: [
            {
              stacked: this.widget.chart.options.stacked,
              ticks: {
                autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 20,
                fontColor: this.themeColor.chart.bar.fontColor,
                fontSize: this.widget.chart.chartFontSize,
              },
              gridLines: {
                color: this.themeColor.chart.bar.gridLineColor,
                drawOnChartArea: true,
              },
            },
          ],
          yAxes: [
            {
              display: this.widget.chart.options.yAxes.left.display,

              position: "left",
              id: "Left_yAxis",
              color: "#ffffff",
              scaleLabel: {
                display: true,
                labelString:
                  this.widget.chart.options.yAxes.left.label +
                  this.widget.chart.options.yAxes.left.unit,
                fontColor: this.themeColor.chart.bar.fontColor,
                fontSize: this.widget.chart.chartFontSize,
              },
              ticks: {
                autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 20,
                suggestedMin: this.widget.chart.options.yAxes.left.min,
                suggestedMax: this.widget.chart.options.yAxes.left.max,
                beginAtZero: true,
                fontColor: this.themeColor.chart.bar.fontColor,
                fontSize: this.widget.chart.chartFontSize,
                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
              stacked: this.widget.chart.options.stacked,
              gridLines: {
                color: this.themeColor.chart.bar.gridLineColor,
                drawOnChartArea: true,
              },
            },
            {
              display: this.widget.chart.options.yAxes.right.display,
              position: "right",
              id: "Right_yAxis",
              scaleLabel: {
                display: true,
                labelString:
                  this.widget.chart.options.yAxes.right.label +
                  this.widget.chart.options.yAxes.right.unit,
                fontColor: this.themeColor.chart.bar.fontColor,

                fontSize: this.widget.chart.chartFontSize,
              },
              ticks: {
                autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 20,
                suggestedMin: this.widget.chart.options.yAxes.right.min,
                suggestedMax: this.widget.chart.options.yAxes.right.max,
                beginAtZero: true,
                fontColor: this.themeColor.chart.bar.fontColor,
                fontSize: this.widget.chart.chartFontSize,
                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
              stacked: this.widget.chart.options.stacked,
              gridLines: {
                color: this.themeColor.chart.bar.gridLineColor,
                drawOnChartArea: true,
              },
            },
          ],
        },
      };

      return options;
    },
    // virtual
    applyData(widgetLogs) {
      this.chartJs.data = {
        labels: this.createLabels(widgetLogs),
        datasets: this.createDatasets(widgetLogs),
      };
      // this.widget.chart.datasets = this.chartJs.data.datasets;
      this.widgetLogs = widgetLogs;
    },
    //dnd - virtual
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "type": "bar", "label": "입구온도", "point": "084000001_0000_0000_VLI_00000001", "position": "Left", "backgroundColor": "#5499C7", "fill": false}

        points.map((point) => {
          if (
            that.widget.chart.series.filter((v) => v.point === point.ptAddr)
              .length == 0
          ) {
            // 중복 체크
            that.widget.chart.series.push({
              type: "spline",
              label: point.title,
              point: point.ptAddr,
              position: "Left",
              backgroundColor: that.getRandomColor(),
              fill: false,
            });
          }
        });
      }

      let defaultObj = {
        type: "spline",
        label: "Default",
        point: "",
        position: "Left",
        backgroundColor: "rgba(52, 143, 226, 0.3)",
        fill: false,
        compare: false,
      };

      if (
        JSON.stringify(this.widget.chart.series.slice(0, 1)) ==
        JSON.stringify([defaultObj])
      )
        this.widget.chart.series = [];

      append(
        transfer.item.type === this.CODE.Drag.PointGroup
          ? transfer.item.points
          : [transfer.item]
      );
    },
  },
  hexToRGBA(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  },
};
</script>
