<template>
	<div>
		<vvo v-slot="{ passes }">
			<div class="row form-group">
				<div class="col-8">
					<label class="m-auto">
						<trans>분석템플릿을 선택하세요.</trans>
					</label>
				</div>
				<!-- <div class="col-2">
          <button class="btn btn-sm btn-default" :class="{'active': mode.isList}" @click="changeMode('list')">
            <i class="fa fa-list"></i> List
          </button>
        </div>
        <div class="col-2">
          <button class="btn btn-sm btn-default" :class="{'active': mode.isTile}" @click="changeMode('tile')">
            <i class="fa fa-th-large"></i> Tile
          </button>
        </div> -->
			</div>
			<div class="row form-group">
				<div class="col-12" v-if="mode.isList">
					<div v-if="Array.isArray(templates) && templates.length > 0">
						<div class="radio radio-css" v-for="(template, i) in templates" :key="i">
							<input type="radio" :id="'template' + i" v-model="newAnalysis.templateCode" :value="template.name" />
							<label :for="'template' + i"> {{ $t(template.name) }}</label>
						</div>
					</div>
					<div v-else>
						<label>
							<trans>저장된 분석템플릿이 없습니다.</trans>
						</label>
					</div>
				</div>
				<div v-else class="col-3">
					<div v-for="(template, i) in templates" :key="i">
						<div class="xe-Tile" :class="{ 'bg-grey': newAnalysis.templateCode == template.name }" @click="selectTemplate(template.name)">{{ template.name }}</div>
					</div>
				</div>
			</div>

			<hr />
			<div class="row form-group">
				<div class="col-md-12">
					<label>
						<trans>대시보드 제목</trans>
					</label>
					<valid-input
						:inputType="'text'"
						:inputValue.sync="newAnalysis.viewCode"
						:rules="rules.DASHBOARD_NEW_TITLE_RULE"
						:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
					></valid-input>

					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="View Code">
						<input type="text" class="form-control" :class="classes" v-model="newAnalysis.viewCode" />
						<span class="invalid-feedback">{{ errors[0] }}</span>
					</vvp> -->
				</div>
			</div>

			<div slot="modal-footer" class="w-100">
				<button class="btn btn-sm btn-primary m-r-5 pull-right" @click="onSaveAnalysis"><i class="fa fa-save"></i> {{ $t("저장") }}</button>
			</div>
		</vvo>
	</div>
</template>

<style scoped>
	.xe-Tile {
		background-color: white;
		text-align: center;
		border: 1px solid black;
		height: 50px;
		line-height: 50px;
		border-radius: 10px;
		cursor: pointer;
	}
</style>

<script>
	import backEndApi from "@api/backEndApi.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import AppErrorHandler from "@src/appErrorHandler";
	import { StatusCodes } from "http-status-codes";

	export default {
		props: [],
		data() {
			return {
				newAnalysis: {
					templateCode: null,
				},
				templates: [],
				curTemplate: {},
				mode: {
					isList: true,
					isTile: false,
				},
				rules,
				validMessage,
			};
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			async initSetting() {
				try {
					let result = await backEndApi.visual.searchVisualTemplate();

					// let testError =new error ('안됨');
					// testError.statusCode = '401';

					// throw testError;
					// throw new Error('TEST').;
					if (this.$err(result.data)) return;

					this.templates = result.data;
					this.newAnalysis.templateCode = this.templates[0].name;
				} catch (e) {
					new AppErrorHandler(e)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.UNAUTHORIZED, popupMessages.DASHBOARD_ANALYSIS_TEMPLATE_API_ERROR_POPUP_MESSAGE, this.alertDanger)
						.errHandling();
				}
			},
			// changeMode(mode) {
			//   if (mode == "list") {
			//     this.mode.isList = true;
			//     this.mode.isTile = false;
			//   } else {
			//     this.mode.isList = false;
			//     this.mode.isTile = true;
			//   }
			// },
			selectTemplate(template) {
				this.newAnalysis.templateCode = template;
			},
			async onSaveAnalysis() {
				if (this.isEmpty(this.newAnalysis.viewCode)) {
					this.alertNoti(popupMessages.DASHBOARD_TITLE_ERROR_VALID_POPUP_MESSAGE);
					return;
				}

				if (this.isEmpty(this.newAnalysis.templateCode)) {
					this.alertNoti(popupMessages.DASHBOARD_ANALYSIS_TEMPLATE_VALID_POPUP_MESSAGE);
					return;
				}

				let isOk = await this.alertQuestion(popupMessages.DASHBOARD_SAVE_POPUP_MESSAGE);
				if (!isOk.value) return;

				let param = {
					templateCode: this.newAnalysis.templateCode,
					viewCode: this.newAnalysis.viewCode,
				};

				try {
					// 템플릿을 선택하여, 대시보드를 생성한다.
					let result = await backEndApi.visual.saveTemplateToVisual(param);
					// 이름이 중복되어 생성에 실패하였습니다
					if (this.$err(result.data)) {
						this.alertNoti(popupMessages.DASHBOARD_DATA_DUP_ERROR_POPUP_MESSAGE); // TODO: 호출전에 Validation 해야 함. 오류도 정확하지 않음..
						return;
					}

					this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE); // 대시보드를을 생성하였습니다
				} catch (e) {
					new AppErrorHandler(e)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createBizHandle(this.ERR_KIND.DUPLICATE_DATA, popupMessages.DASHBOARD_DATA_DUP_ERROR_POPUP_MESSAGE, this.alertDanger)
						.errHandling();
				}
			},
		},
	};
</script>
