<template>
  <div class="xeImage heightMAX">
    <panel
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-r-5 p-l-10 overflow-hidden chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div v-if="isShow" class="text-center heightMAX">
          <img :src="widget.src" :class="[widget.css]" :style="[widget.style]" width="100%" />
        </div>
      </drop>
    </panel>

    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div v-if="isShow" class="m-0 p-0  text-center  overflow-hidden heightMAX" :class="[inverseBg]">
        <h4 v-if="widget.title">{{ $t(widget.title) }}</h4>
        <img :src="widget.src" :class="[widget.css]" :style="[widget.style]" width="100%" :height="imageHeight" />
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" title="설정 - image" bodyClass="p-t-0 p-b-0" hide-footer>
      <image-setting :initSetting="imageSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import imageSetting from "@src/views/widget/v1/setting/xeImageSetting.vue";

export default {
  name: "xeImage",
  extends: xeBaseWidget,
  components: {
    imageSetting,
  },
  data() {
    return {};
  },
  computed: {
    imageSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    imageHeight() {
      return this.fixedHeight + "px";
    },
  },
  created() {},
  mounted(){
    let images = document.getElementsByClassName("xeImage");

    for(let i = 0; i < images.length; i++){
      images[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {},
    //데이터 바인딩
    bindData() {},
    // dnd
    handleDropPoint() {// transfer
      //let that = this;
      // function append(points){
      // 	// { "label": "냉각탑냉각능력(kcal/h)", "point": "084000001_0000_0000_VLI_00000001", "position": "Left", "backgroundColor": "#DAF7A6", "fill": false },
      // 	points.map((point)=>{
      // 		if(that.widget.series.filter(v => v.point === point.ptAddr).length == 0){ // 중복 체크
      // 			that.widget.series.push( { "type": "Line", "label": point.title, "point": point.ptAddr, "position": "Left", "backgroundColor": this.getRandomColor(), "fill": false } );
      // 		}
      // 	});
      // }
      // if(!that.widget.series) that.widget.series = [];
      // append( transfer.item.type === this.CODE.Drag.PointGroup ? transfer.item.points : [transfer.item] );
    },
  },
};
</script>
