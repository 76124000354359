<template>
  <div class="xeHorizontalBar heightMAX rounded" style="overflow: hidden;">
    <div class="heightMAX" style="padding: 0">
      <!-- <div class="jvectormap-container " style="background-color: transparent; position:relative"> -->
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)"
        class="heightMAX kbet-custom-reset">
        <div class="rounded kbet-widget-energy heightMAX" style="display: block;">
          <p class="title">{{ titleSplit }}</p>
          <div class="chartHeightMax">
            <!-- <div class="chartHeightMax"> -->
            <div class="chartHeightMax p-5">
              <horizontal-bar-chart ref="chart" :data="horizonralBar.data" :options="horizonralBar.options"
                class="heightMAX" style="padding-right: '40px'" />
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!--// kbet-card-info -->
      </drop>
    </div>

    <b-modal ref="modalSetting" size="lg" :title="`${$t('설정')} - Horizontal Bar ${$t('차트')}`" bodyClass="p-t-0 p-b-0" hide-footer>
      <chart-horizontal-bar-setting :initSetting="chartSetting" @on-modal-cancel="onModalCancel"
        @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<script>
import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import HorizontalBarChart from "../../../components/vue-chartjs/Charts/HorizontalBarChart.js";
import chartHorizontalBarSetting from "./setting/xeHorizontalBarSetting.vue";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";

export default {
  name: "xeHorizontalBar",
  extends: xeBaseWidget,
  components: {
    HorizontalBarChart,
    chartHorizontalBarSetting,
  },
  data() {
    return {
      horizonralBar: {
        data: {},
        options: {},
      },
      themeColor: global.xe.parseBool(localStorage.contentInverse) ? require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark : require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light
    };
  },
  computed: {
    titleSplit(){
      let result = this.widget.title;
      let splitTitle = "";
      let titleUnit = "";
      if(result.includes("(") || result.includes(")")){
        splitTitle = result.split("(");

        titleUnit = `(${splitTitle[1].split(")")[0]})`

        result = `${global.xe.$t(splitTitle[0])} ${titleUnit}`;
      } else {
        result = global.xe.$t(result);
      }

      return result;

    },
    chartSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    master() {
      return this.$store.getters.master;
    },
  },
  created() {
    if (!this.widget.series)
      this.widget.series = [
        { label: "Default", point: "", backgroundColor: "#348fe2" },
      ];
    if (!this.widget.addition)
      this.widget.addition = {
        xAxes: { label: "", min: 0, max: 0, stacked: false, unit: "" },
        afterAction: "",
        fixed: 0,
      };
    if (!this.widget.self)
      this.widget.self = {
        baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
        compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
      };

    if (!this.widget.chart)
      this.widget.chart = {
        colorOpens: false,
        backgroundColor: "#ffffff",
        gridColor: "#0000001F",
        fontColor: "#000000",

        chartFontSize: 14,
        titleFontSize: 14,
        series: [
          {
            type: "horizontalBar",
            label: "Default1",
            point: "0201_NLI_001F02W",
            position: "Left",
            backgroundColor: "rgba(52, 143, 226, 0.3)",
            compare: false,
            dataColor: "#92D0F0",
          },
        ],
        options: {
          stacked: false,
          fill: false,
          fixed: 0,
          legend: {
            display: false,
            position: "bottom",
            align: "center",
            labels: {
              boxWidth: 10,
              fontSize: 18,
              fontStyle: "normal",
              fontColor: "#000000",
            },
          },
          yAxes: {
            left: {
              label: "",
              min: 0,
              max: 0,
              display: true,
            },
          },
        },
      };
  },
  mounted() {
    let horizontals = document.getElementsByClassName("xeHorizontalBar");

    for (let i = 0; i < horizontals.length; i++) {
      horizontals[i].parentNode.style.height = "100%";
    }
    
  },

  methods: {
    initSetting() {
      this.horizonralBar.options = this.createChartOptions();

      this.horizonralBar.data = {
        labels: this.defaultLabels(),
        datasets: [],
      };
      // this.$el.style.backgroundColor = (this.master.labelTheme=='black'? '#3f3f3f' : '#fff')
      // // this.$el.style.backgroundColor = this.widget.chart.backgroundColor;
      // this.setDataColor();
      this.setTheme();
    },
    setTheme() {

      this.$el.style.backgroundColor = this.themeColor.chart.horizontal.backgroundColor;
    },
    setDataColor() {
      this.horizonralBar.data.datasets.forEach((element, index) => {
        element.backgroundColor = this.themeColor.chart.itemColors[index];
        element.borderColor = this.themeColor.chart.itemColors[index];

        if (element.compare) {
          element.backgroundColor += "77";
          element.borderColor += "77";
        }

      });
    },
    defaultLabels() {
      return this.widget.chart.series.map((item) => {
        return global.xe.$t(item.label);
      });
    },
    //chart data 기본값설정
    defaultDatasets() {
      console.log("defaultDatasets", this.widget.chart.series);
      return this.widget.chart.series.map((item, index) => {
        index;
        return {
          // backgroundColor: this.themeColor.chart.itemColors[index],
          // borderColor: this.themeColor.chart.itemColors[index],
          backgroundColor: item.dataColor,
          borderColor: item.dataColor,
          fill: false,
          label: global.xe.$t(item.label),
          yAxisID: "Left_yAxis",
          point: item.point,
        };
      });
    },
    //차트 상세 옵션 설정
    createChartOptions() {
      let leftAxis = this.widget.chart.options.yAxes
        ? this.widget.chart.options.yAxes.left
          ? this.widget.chart.options.yAxes.left
          : ""
        : "";
      let xAxes =
        this.widget.addition && this.widget.addition.xAxes
          ? this.widget.addition.xAxes
          : "";
      // let rightAxis = this.widget.yAxes ? (this.widget.yAxes.right ? this.widget.yAxes.right : "") : "";

      leftAxis = leftAxis ? leftAxis : { label: "value1", stacked: false };
      // rightAxis = rightAxis ? rightAxis : { label: "value2", min: 0, max: 0, stacked: false, display: false };

      return {
        responsive: true,
        animations: { duration: 1000, easing: "easeOutQuart" }, // 빠른 데이터 전환시 animation 오류 발생 (transition of null)
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: this.widget.chart.options.legend.position,
          align: "center",
          fullWidth: true,
          fill: "#000000",
          labels: {
            usePointStyle: true,
            boxWidth: this.widget.chart.options.legend.labels.boxWidth,
            fontSize: this.widget.chart.options.legend.labels.fontSize,
            fontColor: this.themeColor.chart.horizontal.fontColor,
            fontFamily: "'Helvetica Neue'",
          },
        },
        tooltips: {
          mode: "index",
          intersect: true,
          callbacks: {
            title: function () {
              return "";
            },
            label: (tooltipItems, data) => {
              let label = data.labels[tooltipItems.index];
              let currentValue =
                data.datasets[tooltipItems.datasetIndex].data[
                tooltipItems.index
                ];

              currentValue = this.comma(
                currentValue,
                this.widget.addition.fixed
              );

              if (!data.isPercentage)
                return (
                  `${label}: ${currentValue}` + this.widget.addition.xAxes.unit
                );
              return (
                `${label}: ${currentValue}%` + this.widget.addition.xAxes.unit
              );
            },
          },
        },
        scales: {
          xAxes: [
            {
              position: "bottom",
              scaleLabel: {
                display: false,
                labelString: xAxes.label || "",
              },
              ticks: {
                suggestedMin: xAxes.min,
                suggestedMax: xAxes.max,
                beginAtZero: true,
                fontColor: this.themeColor.chart.horizontal.fontColor,
                fontSize: this.widget.chart.chartFontSize,

                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
              gridLines: {
                color: this.themeColor.chart.horizontal.gridLineColor,
                drawOnChartArea: true,
              },
              stacked: this.widget.chart.options.stacked,
            },
          ],
          yAxes: [
            {
              // display: leftAxis.display,
              position: "left",
              id: "Left_yAxis",
              scaleLabel: {
                display: true,
                labelString: leftAxis.label,
                fontColor: this.themeColor.chart.horizontal.fontColor,
                fontSize: this.widget.chart.options.chartFontSize,
              },
              ticks: {
                suggestedMin: this.widget.chart.options.yAxes.left.min,
                suggestedMax: this.widget.chart.options.yAxes.left.max,
                beginAtZero: true,
                fontColor: this.themeColor.chart.horizontal.fontColor,
                fontSize: this.widget.chart.chartFontSize,
                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
              gridLines: {
                color: this.themeColor.chart.horizontal.gridLineColor,
                drawOnChartArea: true,
                offsetGridLines: false,
              },
              stacked: this.widget.chart.options.stacked,
              mirror: true,
            },
            // {
            //   display: rightAxis.display,
            //   position: "right",
            //   id: "Right_yAxis",
            //   scaleLabel: { display: true, labelString: rightAxis.label, fontColor: "black" },
            //   stacked: rightAxis.stacked,
            //   gridLines: { drawOnChartArea: false }
            // }
          ],
        },
      };
    },
    // virtual
    applyData(widgetLogs) {
      let newData = {
        data: {
          datasets: this.createDatasets(widgetLogs),
        },
      };
      // this.isShow = false;
      

      
      if (this.horizonralBar.data.datasets.length === 0) {
        this.horizonralBar.data.datasets = this.createDatasets(widgetLogs);
      }

      //NOTE - Test용 코드 시작
      // for (let i = 0; i < this.horizonralBar.data.datasets.length; i++) {
      //   let randoms = [];
      //   for (let j = 0; j < 3; j++) {
      //     randoms.push(Math.floor(Math.random() * 10));
      //   }
      //   this.horizonralBar.data.datasets[i].backgroundColor =
      //     newData.data.datasets[i].backgroundColor;

      //   this.horizonralBar.data.datasets[i].data = randoms;
      // }
      //NOTE - Test용 코드 끝

      for (let i = 0; i < newData.data.datasets.length; i++) {
        this.horizonralBar.data.datasets[i].backgroundColor = newData.data.datasets[i].backgroundColor;
        this.horizonralBar.data.datasets[i].data = newData.data.datasets[i].data;
        console.log(this.horizonralBar.data.datasets)
      }

      const labels = this.defaultLabels();
      const datasets = this.horizonralBar.data.datasets[0];

      const combined = labels.map((label, i) => ({
        labels: label,
        data: datasets.data[i],
      }));

      combined.sort((a, b) => a.data - b.data);

      this.horizonralBar.data.labels = combined.map((item) => item.labels);
      this.horizonralBar.data.datasets[0].data = combined.map((item) => item.data);

      this.$refs.chart._data._chart.update();

      this.widgetLogs = widgetLogs;

      // this.$nextTick(() => {
      //   this.isShow = true;
      // });
    },
    //데이터 바인딩
    createDatasets(logs) {
      try {
        let datasets = this.defaultDatasets();
        console.log(datasets)
        // let log = logs[0];

        let datas = [];
        let backgroundColors = [];

        // 기존 로그 복사하여 데이터 변형 작업 진행
        let copyLogs = JSON.parse(JSON.stringify(logs));
        // afterAction 처리
        copyLogs = utils.onAfterAction2(
          datasets,
          copyLogs,
          this.widget.addition.afterAction
        );
        datasets.forEach((dataset) => {
          let ptVals = [];
          let ptVal = null;

          // compare 모드가 없어서 copyLogs[0]으로 고정함
          ptVals = copyLogs[0].points[dataset.point] || [];

          ptVals = this.isEmpty(ptVals) ? 0 : ptVals.sum();

          ptVal = utils.fixedValue(ptVals, this.widget.addition.fixed);
          // console.log(ptVal)
          datas.push(ptVal);
          // datas.sort((a, b) => a - b);
          // console.log(datas)
          backgroundColors.push(dataset.backgroundColor);
        });

        datasets = [
          {
            data: datas,
            backgroundColor: backgroundColors,
            type: "horizontalBar",
            xAxisID: "x-axis-0",
            yAxisID: "Left_yAxis",
          },
        ];
        return datasets;
      } catch (err) {
        console.error(`${this.$options.name} bindChartData`, err);
      }
    },
    // dnd
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "label": "냉각탑냉각능력(kcal/h)", "point": "084000001_0000_0000_VLI_00000001", "position": "Left", "backgroundColor": "#DAF7A6", "fill": false },

        points.map((point) => {
          if (
            that.widget.series.filter((v) => v.point === point.ptAddr).length ==
            0
          ) {
            // 중복 체크
            that.widget.series.push({
              label: point.title,
              point: point.ptAddr,
              position: "Left",
              backgroundColor: that.getRandomColor(),
            });
          }
        });
      }

      let defaultObj = {
        label: "Default",
        point: "",
        position: "Left",
        backgroundColor: "#348fe2",
      };

      // 기본 default이 series의 첫번재 값과 같으면 series를 초기화함
      if (
        JSON.stringify(this.widget.chart.series.slice(0, 1)) ==
        JSON.stringify([defaultObj])
      )
        this.widget.chart.series = [];
      append(
        transfer.item.type === this.CODE.Drag.PointGroup
          ? transfer.item.points
          : [transfer.item]
      );
    },
  },
};
</script>
