<template>
  <!-- class="heightMAX" -->
  <div class="xeSelfRateCard heightMAX">
    <drop
      @dragover="handleBodyDragOver(...arguments)"
      @drop="handleBodyDrop(...arguments)"
      class="heightMAX"
    >
      <div class="kbet-custom-reset heightMAX">
        <div
          class="rounded kbet-widget-valueCard-v1 heightMAX compareCardStyligV1"
          :style="{ backgroundColor: this.themeColor.card.itemColors[this.widget.index] }"
        >
          <i data-v-47b74d40="" title="fas fa-chart-line" class="bg-icon fas fa-chart-line"> </i>
          <p
            class="title"
            :style="{fontSize: '14px'}"
          >{{ $t(widget.title) }}</p>
          <div class="value-usage">
            <strong :style="{fontSize: '25px'}">{{ baseVal }}<span>%</span></strong>
          </div>
          <dl
            class="value-contrast"
            :style="{ fontSize: '12px'}"
          >
            <dt>{{ $t("전월대비") }}  </dt>
            <dd
              class="contrast-up"
              v-if="differenceRate > 0.0"
            >
              <strong>+ {{compareVal}} %</strong>
            </dd>
            <dd
              class="contrast-down"
              v-if="differenceRate < 0.0"
            >
              <strong>{{compareVal}} %</strong>
            </dd>
            <dd
              class="contrast-same"
              v-if="differenceRate == 0.0 || !differenceRate"
            >
              <strong></strong>
            </dd>
          </dl>
        </div>
      </div>
    </drop>

    <b-modal
      ref="modalSetting"
      size="lg"
      :title="`${$t('설정')} - ${$t('에너지 자립률 카드')}`"
      body-class="p-t-0 p-b-0"
      hide-footer
    >
      <self-rate-card-setting
        :initSetting="setting"
        @on-modal-cancel="onModalCancel"
        @on-modal-ok="onModalOK"
      />
  </b-modal>
  </div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import selfRateCardSetting from "./setting/xeSelfRateCardSetting.vue";

export default {
  name: "xeSelfRateCard",
  extends: xeBaseWidget,
  components: {
    selfRateCardSetting
  },
  data() {
    return {
      baseVal :null,
      compareVal: null,
      differenceRate: null,
      themeColor : global.xe.parseBool(localStorage.contentInverse) ? require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark : require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light,
    };
  },
  computed: {
    setting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  created() {
    if (!this.widget.zoneIdx) {
      this.widget.zoneIdx = 1;
    }
  },
  mounted() {
    let selfRateCard = document.getElementsByClassName("xeSelfRateCard");

    for (let i = 0; i < selfRateCard.length; i++) {
      selfRateCard[i].parentNode.style.height = "100%";
    }

  },
  methods: {
    initSetting(){},
    applyData(data) {
      this.baseVal = data.baseVal;
      this.compareVal = data.compareVal;
      this.differenceRate = data.differenceRate;
    },
  }
};
</script>


<style scoped>
/* .xe-na {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.xe-vetical-title {
  font-size: 13px;
  font-weight: 600;
}

.xe-nomal-title {
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}

.xe-vetical-value {
  font-size: 28px;
  font-weight: 700;
}

.xe-nomal-value {
  position: absolute;
  bottom: 20px;
  right: 0;
  font-size: 24px;
  font-weight: 700;
  padding-right: 10px;
}

.stats-title {
  text-transform: uppercase;
  margin: 10px 0;
  position: absolute;
  right: 0;
  font-size: 13px;
  font-weight: 600;
}

.stats-value {
  position: absolute;
  bottom: 0px;
  font-size: 13px;
  font-weight: 500;
  left: 0;
  padding-left: 20px;
}

.stats-bottom {
  position: absolute;
  bottom: 0px;
}

.widget-stats .stats-info h4,
.widget.widget-stats .stats-info h4 {
  font-size: 12px;
  margin: 0px 0;
  color: rgb(0, 0, 0);
}

.widget-stats-new-simple,
.widget.widget-stats-new-simple {
  position: relative;
  color: rgb(0, 0, 0);
  padding: 15px 0 0 15px;
  border-radius: 4px;
}

.widget-stats .stats-number,
.widget.widget-stats .stats-number {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 600;
}

.widget.widget-stats-new-simple .stats-info h3 {
  margin-bottom: 15px;
  text-align: right;
  margin-right: 15px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .xe-nomal-title {
    top: 10px;
  }
  .xe-nomal-title i {
    padding-left: 10px;
  }
  .xe-nomal-value {
    bottom: 25px;
    font-size: 5.5vw;
  }
  .xe-nomal-value span {
    padding-right: 10px;
  }
} */
</style>