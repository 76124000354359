import VueChartJS, { mixins } from "vue-chartjs";

export default {
  extends: VueChartJS.HorizontalBar,
  mixins: [mixins.reactiveProp],
  props: ["chartData", "options"],
  mounted() {
    // console.log('bar mounted', this.options, this.chartData)

    this.renderChart(this.chartData, this.options);
  },
  watch: {
    chartData: function() {
      // this.$refs.canvas.update();
      // this.renderChart(this.chartData, this.options);
      // console.log('bar watch', this.options, this.chartData)
      this.$data._chart.options = this.options;
      this.$data._chart.update();
    },
  },
};
