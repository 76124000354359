<template>
  <div class="xeForecast heightMAX rounded" style="overflow: hidden;">
    <div class="heightMAX">
      <!-- <div class="jvectormap-container " style="background-color: transparent; position:relative"> -->
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX kbet-custom-reset">
        <div class="rounded kbet-widget-energy monitoring heightMAX" style="display: block;">
          <p class="title">{{ $t(widget.title) }}</p>
          <div class="monitoring_wrap">
            <ul class="forecastInfo">
              <li v-for="(data, i) in weatherData" :key="`forcastinfo${i}`" :class="isOnTime === i ? `forcastDark${i}` : `forcast${i}`">
                <dl class="inforecast">
                  <dt :class="isOnTime === i ? `timeDark` : `time`" :style="checkdiscomfort(data)">
                    <p>{{ data.regDt.replace(" ", "").split('시')[0] + $t('시') }}</p>
                    <!-- {{ data.regDt }} -->
                  </dt>
                  <dt :class="isOnTime === i ? `iconDart` : `icon`">
                    <i v-if="data.weatherMain === 'Clouds'" class="wi wi-cloud"></i>
                    <i v-else-if="data.weatherMain === 'Clear'" class="wi wi-day-sunny"></i>
                    <i v-else-if="data.weatherMain === 'Rain'" class="wi wi-rain"></i>
                    <i v-else-if="data.weatherMain === 'Snow'" class="wi wi-snow"></i>
                    <i v-else class="wi wi-day-sunny"></i>
                  </dt>

                  <dt :class="isOnTime === i ? `tempDark` : `temp`">
                    {{ Number(data.temp).toFixed(0) + "°C" }}
                  </dt>

                  <dt :class="isOnTime === i ? `humiDark` : `humi`">
                    {{ data.humidity + "%" }}
                  </dt>
                </dl>
              </li>
            </ul>
          </div>
        </div>
      </drop>
    </div>
  </div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";

export default {
  name: "xeBizInfo",
  props: ["showGrid", "widget", "cellSize", "on-remove-widget"],
  extends: xeBaseWidget,
  components: {},
  created() {},
  mounted() {
    this.isShow = false;
  },
  data() {
    return {
      weatherData: null,
      nowData: null,
      isOnTime: 0,
    };
  },
  computed: {
    setting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  methods: {
    applyData(data) {
      this.weatherData = data;
      this.setDatas();
    },
    checkdiscomfort(data) {
      let style = {};

      if (data.discomfort <= 0) {
        style.backgroundColor = "rgb(210, 210, 210)";
      } else if (data.discomfort === 1) {
        style.backgroundColor = "rgb(195, 168, 168)";
      } else if (data.discomfort === 2) {
        style.backgroundColor = "rgb(197, 89, 87)";
      } else if (data.discomfort >= 3) {
        style.backgroundColor = "rgb(141, 4, 0)";
      } else {
        style.backgroundColor = "rgb(210, 210, 210)";
      }

      return style;
    },
    setDatas() {

      let nowHour = new Date().format("HH");
      let isTimes = this.weatherData.map((v) => parseInt(v.regDt.split("시")[0].trim()));

      isTimes.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });

      for (let i = 1; i < isTimes.length; i++) {

        if (parseInt(nowHour) < isTimes[i]) {
          this.isOnTime = i-1;
          break;
        }
        
      }
    },
  },
};
</script>

<style lang="scss">
.xeForecast {
  .monitoring_wrap {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
  }
  .forecastInfo {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    [class*="forcast"] {
      width: 12%;
    }
    [class*="forcast"] {
      background-color: transparent;
    }
    [class*="forcastDark"] {
      background-color: #c8c8c8;

      .temp {
        font-weight: bold;
      }
      .humi {
        font-weight: bold;
      }
    }
    .inforecast {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .time {
        height: 20%;
        display: table;
        font-weight: bold;
        color: #ffffff;
        p {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
        }
      }
      .timeDark {
        font-weight: bold;
        color: #ffffff;

        height: 20%;
        display: table;
        p {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
        }
      }
      .icon {
      }
      .iconDart {
        color: white;
      }
      .temp {
        font-weight: normal;
        color: #000000;
      }
      .tempDark {
        font-weight: bold;
        color: #000000;
      }
      .humi {
        font-weight: normal;
        color: #000000;
      }
      .humiDark {
        font-weight: bold;
        color: #000000;
      }
    }
  }
}
</style>
