<template>
  <div class="xc-donut-container" :style="[{ width: card.style.width + 'px' }, { height: card.style.height + 'px' }]" >
    <small :class="{ 'text-black': inverseMode, 'text-white': inverseMode }"> </small>

    <div class="percent" :style="[{ width: r * 2 + 10 + 'px' }, { height: r * 2 + 10 + 'px' }]">
      <svg>
        <circle :cx="r" :cy="r" :r="r" :style="[{ 'stroke-width': strokeWidth }, { stroke: inverseMode ? '#191919' : '#efefef' }]"></circle>
        <circle
          :cx="r"
          :cy="r"
          :r="r"
          :style="[{ 'stroke-width': strokeWidth }, { stroke: card.style.color }, { 'stroke-dasharray': '' + stroke + '' }, { 'stroke-dashoffset': 'calc(' + stroke + ' - (' + stroke + ' * ' + card.baseline.val + ') / 100)' }]"
        ></circle>
      </svg>
      <div class="number">
        <h2 :style="{ 'font-size': r * 0.5 + 'px' }">
          {{ baselineVal }}
          <span :style="{ 'font-size': r * 0.4 + 'px' }">%</span>
          <br />
          <span class="text-orange" :style="{ 'font-size': r * 0.4 + 'px' }" v-if="card.isCompare">
            / {{ compareVal }}
            <span class="text-orange" :style="{ 'font-size': r * 0.3 + 'px' }" v-if="card.isCompare">%</span>
          </span>
        </h2>
      </div>
    </div>
  </div>
</template>

<style scoped>
.xc-donut-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
  pointer-events: none;
  z-index: 1;
}
.xc-donut-container {
  position: relative;
  /* width: 250px;
  height: 300px; */
  border-radius: 4px;
  /* background: linear-gradient(0deg, #1b1b1b, #222, #1b1b1b);  일단제외 */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  transition: 0.5s;
}

.percent {
  position: relative;
  /* width: 150px;
	height: 150px; */
  border-radius: 50%;
  box-shadow: inset 0 0 50px #000;
  background: #222;
  z-index: 1;
}
.percent .number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.percent .number h2 {
  color: #777;
  font-weight: 700;
  font-size: 40px; /* 적용 */
  transition: 0.5s;
}

.percent .number h2 span {
  font-size: 24px; /* 적용 */
  color: #777;
  transition: 0.5s;
}

.text {
  position: relative;
  color: #777;
  margin-top: 20px;
  font-weight: 700;
  font-size: 18px; /* 적용 */
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.5s;
}

.xc-donut-container svg {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.xc-donut-container svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  /* stroke: #191919; */
  /*stroke-width: 10;  적용 */
  stroke-linecap: round;
  transform: translate(5px, 5px); /* 미적용 */
}
</style>

<script>
export default {
  name: "xcDonutCard",
  props: ["card", "inverseMode"],
  components: {},
  data() {
    return {

      // card:{
      //   val: 0,
      //   org: 0,
      //   compareVal: 0,
      //   compareOrg: 0
      //   style : {}
      // }
      
    };
  },
  computed: {
    r() {
      console.log(this.card);
      return this.card.style.width / 3.5; // 2.7
    },
    stroke() {
      // return this.r*6.3;
      return this.r * 2 * 3.14;
    },
    strokeWidth() {
      return this.r * 0.13;
    },
    baselineVal(){
      return this.comma(this.card.baseline.val, this.card.fixed)
    },
    compareVal(){
      return this.comma(this.card.compare.val, this.card.fixed)
    }
  },
  watch: {
    inverseMode() {}
  },
  created() {},
  methods: {
    initSetting() {}
  }
};
</script>
