<template>
  <div class="xeHtml heightMAX">
    <panel
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-10 overflow-hidden chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div v-if="isShow" class="heightMAX">
          <xe-html-component ref="rawHtmlComponent" v-if="widget.rawHtml" :is="rawHtmlComponent"></xe-html-component>
          <br />
        </div>
      </drop>
    </panel>

    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div v-if="isShow" class="overflow-hidden m-b-20 heightMAX" :class="[inverseBg]">
        <h4>{{ $t(widget.title) }}</h4>
        <xe-html-component ref="rawHtmlComponent" v-if="widget.rawHtml" :is="rawHtmlComponent"></xe-html-component>
        <br />
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" body-class="p-20" cancel-variant="default" title="설정 - Html">
      <div class="row form-group">
        <div class="col-12">
          <label> <trans>제목</trans> </label>
          <input type="text" class="form-control" v-model="widget.title" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-12">
          <label> <trans>내용</trans> </label>
          <textarea rows="20" class="form-control" v-model="widget.rawHtml"></textarea>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style scoped></style>

<script>
//import Vue from "vue";
import xeBaseWidget from "./xeBaseWidget.js";
// httpVueLoader : https://github.com/FranckFreiburger/http-vue-loader
//import httpVueLoader from 'http-vue-loader';

// TODO: searchLogs 조회결과 또는 실시간값 화면에 바인딩하는 부분 없음.

export default {
  name: "xeHtml",
  extends: xeBaseWidget,
  data() {
    return {};
  },
  mounted(){
    let htmls = document.getElementsByClassName("xeHtml");

    for(let i = 0; i < htmls.length; i++){
      htmls[i].parentNode.style.height = "100%";
    }
  },
  computed: {
    rawHtml() {
      if (!this.widget.rawHtml) return "";

      return (
        "<div>" +
        this.widget.rawHtml
          .replace("\n", "")
          .replace("\t", " ")
          .replace("\t", " ") +
        "</div>"
      );
    },
    rawHtmlComponent() {
      let parent = this; // xeHtml
      return {
        // https://stackoverflow.com/questions/39516731/dynamic-html-elements-in-vue-js
        name: "raw-html-component",
        template: parent.rawHtml,
        data() {
          return {
            searchLogs: parent.searchLogs,
          };
        },
      };
    },
  },
  watch: {
    searchLogs: function() {
      this.$forceUpdate();
    },
  },
  methods: {
    initSetting() {},
    bindData() {
      
    },
    // dnd
    handleDropPoint() {
      this.$forceUpdate();
    },
  },
};
</script>
