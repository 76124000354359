<template>
  <div class="xeChartScatter heightMAX">
    <panel
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-5 chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>
      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div v-if="isShow" class="heightMAX">
          <scatter-chart :chartData="chartJs.data" :options="chartJs.options" class="heightMAX" :style="{ height: chartHeight }"></scatter-chart>
        </div>
      </drop>
    </panel>

    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div v-if="isShow" class="m-b-20 heightMAX" :class="[inverseBg]">
        <h4>{{ $t(widget.title) }}</h4>
        <scatter-chart :chartData="chartJs.data" :options="chartJs.options" class="height-sm heightMAX"></scatter-chart>
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" title="설정 - ScatterChart" bodyClass="p-t-0 p-b-0" hide-footer>
      <chart-scatter-setting :initSetting="chartScatterSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<style scoped></style>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import ScatterChart from "@src/components/vue-chartjs/ScatterChart.js";
import chartScatterSetting from "@src/views/widget/v1/setting/xeChartScatterSetting.vue";
import utils from "./xeBaseUtils.js";

export default {
  name: "xeChartScatter",
  extends: xeBaseWidget,
  components: {
    ScatterChart,
    chartScatterSetting,
  },
  data() {
    return {
      chartJs: {},
    };
  },
  computed: {
    chartScatterSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  created() {
    if (!this.widget.series) this.widget.series = [{ label: "Default", point: "", position: "Left", backgroundColor: "#348fe2", fill: false, compare: false }];
    if (!this.widget.yAxes) this.widget.yAxes = { left: { label: "Unit1", min: 0, max: 0, display: true }, right: { label: "Unit2", min: 0, max: 0, display: true } };
    if (!this.widget.addition) this.widget.addition = { xAxes: { label: "default", point: "" }, afterAction: "", fixed: 0, legend: { display: true, position: "bottom" } };
    if (!this.widget.self) this.widget.self = { baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" }, compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" } };
  },
  mounted(){
    let scatters = document.getElementsByClassName("xeChartScatter");

    for(let i = 0; i < scatters.length; i++){
      scatters[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.chartJs.options = this.createChartOptions();

      this.chartJs.data = {
        datasets: this.defaultDatasets(),
      };
    },
    //차트 상세 옵션 설정
    createChartOptions() {
      let leftAxis = this.widget.yAxes.left || { label: "value1", min: 0, max: 0, display: true };
      let rightAxis = this.widget.yAxes.right || { label: "value2", min: 0, max: 0, display: true };

      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: this.widget.addition.legend.display, position: this.widget.addition.legend.position, labels: { usePointStyle: true, boxWidth: 5, fontSize: 12 } },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let xValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x;
              let yValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;

              xValue = this.comma(xValue, this.widget.addition.fixed);
              yValue = this.comma(yValue, this.widget.addition.fixed);

              let label = data.datasets[tooltipItem.datasetIndex].label || "";

              return `${label}: (x:${xValue}, y:${yValue})`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              position: "bottom",
              type: "linear",
              scaleLabel: {
                display: true,
                labelString: this.widget.addition.xAxes.label || "",
              },
              ticks: {
                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
            },
          ],
          yAxes: [
            {
              type: "linear",
              display: leftAxis.display,
              position: "left",
              id: "Left_yAxis",
              scaleLabel: { display: true, labelString: leftAxis.label, fontColor: "black" },
              ticks: {
                suggestedMin: leftAxis.min,
                suggestedMax: leftAxis.max,
                beginAtZero: true,
                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
            },
            {
              type: "linear",
              display: rightAxis.display,
              position: "right",
              id: "Right_yAxis",
              scaleLabel: { display: true, labelString: rightAxis.label, fontColor: "black" },
              ticks: {
                suggestedMin: rightAxis.min,
                suggestedMax: rightAxis.max,
                beginAtZero: true,
                userCallback: (value) => {
                  value = this.comma(value, this.widget.addition.fixed);
                  return value;
                },
              },
              gridLines: { drawOnChartArea: false },
            },
          ],
        },
      };
    },
    //chart data 기본값설정
    defaultDatasets() {
      return this.widget.series
        .filter((item) => !item.compare || this.isCompare)
        .map((item) => {
          return {
            label: item.label,
            backgroundColor: item.backgroundColor,
            borderColor: item.backgroundColor,
            yAxisID: item.position === "Left" ? "Left_yAxis" : "Right_yAxis",
            fill: item.fill, // == "false" ? false : true, // v1
            point: item.point,
            compare: item.compare ? item.compare : false,
          };
        });
    },
    // virtual
    applyData(widgetLogs) {
      this.isShow = false;
      this.chartJs.data = {
        datasets: this.createDatasets(widgetLogs),
      };
      this.widgetLogs = widgetLogs;

      this.isShow = true;
    },
    createDatasets(logs) {
      let datasets = this.defaultDatasets();
      let xDatas = this.createXData(logs);

      return datasets.map((dataset) => {
        let log = dataset.compare ? logs[1] : logs[0];
        let compareIdx = dataset.compare ? 1 : 0;

        let ptVals = log.points[dataset.point];

        ptVals = utils.onAfterAction(dataset.point, ptVals, this.widget.addition.afterAction); // 배열반환

        xDatas = utils.onAfterAction(this.widget.addition.xAxes.point, xDatas, this.widget.addition.afterAction); // 배열반환

        dataset.data = xDatas.map((curData, i) => {
          let xData = (Array.isArray(curData) ? curData[compareIdx] : curData) || 0;
          let yData = ptVals && ptVals[i] ? ptVals[i] : 0;

          return {
            x: xData,
            y: yData,
          };
        });

        return dataset;
      });
    },
    createXData(logs) {
      let xData = [];
      let xAxes = this.widget.addition.xAxes;
      // "logs": [ {
      //     "type" : 2, "sDt": 202001, "eDt": 202001,
      //     "points": { "WEAT_NLI_NINTEMP": [ 7.25 ], "WEAT_NLI_NINHUMIDITY": [ 42 ] },
      //     "rows": [ "202001" ],
      //     "dates": [ [ "1M", "2020Y" ] ]
      //   } ]

      if (logs.length === 1) {
        // baseline 조회시
        xData = logs[0].points[xAxes.point];
      } else if (logs.length === 2) {
        // compare 조회시
        let base = logs[0];
        let comp = logs[1];

        // 구간조회이기 때문에, 일자의 갯수가 base와 comp가 서로 다를 수 있다.
        // 그래서, 일자 기간을 더 길게 조회한 쪽을 기준으로 for문을 돌아준다.

        if (base.rows.length >= comp.rows.length) {
          xData = base.points[xAxes.point].map((pointValue, idx) => {
            return [pointValue, comp.points[xAxes.point][idx] ? comp.points[xAxes.point][idx] : ""];
          });
        } else {
          xData = comp.points[xAxes.point].map((pointValue) => {
            return [base.points[xAxes.point] ? base.points[xAxes.point] : "", pointValue];
          });
        }
      }

      return xData;
    },
    // dnd
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "label": "냉각탑냉각능력(kcal/h)", "point": "084000001_0000_0000_VLI_00000001", "position": "Left", "backgroundColor": "#DAF7A6", "fill": false },

        points.map((point) => {
          if (that.widget.series.filter((v) => v.point === point.ptAddr).length == 0) {
            // 중복 체크
            that.widget.series.push({ label: point.title, point: point.ptAddr, position: "Left", backgroundColor: that.getRandomColor(), fill: false, compare: false });
          }
        });
      }

      let defaultObj = { label: "Default", point: "", position: "Left", backgroundColor: "#348fe2", fill: false, compare: false };

      if (JSON.stringify(this.widget.series.slice(0, 1)) == JSON.stringify([defaultObj])) this.widget.series = [];
      append(transfer.item.type === this.CODE.Drag.PointGroup ? transfer.item.points : [transfer.item]);
    },
  },
};
</script>
