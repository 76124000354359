<template>
	<div>
0			<div class="row form-group m-t-10">
				<div class="col-md-12">
					<a href="javascript:;" class="xe-tab-underline" v-on:click.prevent="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }"> <trans>일반</trans></a>
					<a href="javascript:;" class="xe-tab-underline" v-on:click.prevent="toggleTabs('point')" :class="{ 'xe-tab-line-orange': tabs.point }"> <trans>관제점</trans></a>
					<a href="javascript:;" class="xe-tab-underline" v-on:click.prevent="toggleTabs('processing')" :class="{ 'xe-tab-line-orange': tabs.processing }"> <trans>데이터처리</trans></a>
				</div>
			</div>

			<div class="row form-group">
				<div class="col-md-12">
					<div class="tab-content m-0 p-0">
						<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.general }">
							<div class="row form-group">
								<div class="col-10">
									<label><trans>제목</trans></label>
									<input type="text" class="form-control" v-model="copySetting.title" />
								</div>
								<div class="col-2">
									<label><trans>패널</trans></label>
									<div class="checkbox checkbox-css">
										<input type="checkbox" :id="'gridTable_panel'" v-model="copySetting.panel.display" />
										<label :for="'gridTable_panel'">{{ copySetting.panel.display ? "ON" : "OFF" }}</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<h5><trans>그리드</trans></h5>
								</div>
							</div>
							<div class="row form-group">
								<div class="col-md-2">
									<label> <trans>소수점자리</trans> </label>
									<valid-input
										:inputType="'number'"
										:inputValue.sync="copySetting.addition.fixed"
										:classList="'form-control'"
										:NonStatus="false"
										:errorMessage="validMessage.WIDGET_DECIMAL_AND_FONTSIZE_LENGTH_VALID_MESSAGE"
										:rules="rules.DASHBOARD_WIDGET_DECIMAL_RULE"
									>
									</valid-input>
								</div>
							</div>
							<hr />
							<div class="row">
								<div class="col-12">
									<h5><trans>조회기간 덮어쓰기</trans></h5>
								</div>
							</div>
							<xe-modal-self :copySetting="copySetting" :isCompare="true" />
						</div>

						<div class="tab-pane fade" :class="{ 'active show': tabs.processing }">
							<div class="row form-group">
								<div class="col-md-12">
									<label
										><b><trans>후처리</trans></b></label
									>
									<valid-textarea
										:inputType="'text'"
										:inputValue.sync="copySetting.addition.afterAction"
										:classList="'form-control'"
										:rows="5"
										:NonStatus="true"
										:rules="rules.DASHBOARD_WIDGET_POSTPROCESS_RULE"
									>
									</valid-textarea>
								</div>
							</div>
							<label
								><b><trans>취합방법</trans></b></label
							>
							<div class="row form-group">
								<div class="col-md-2">
									<div class="checkbox checkbox-css">
										<input type="checkbox" :id="'gridtable_afterBind_Last' + copySetting.id" v-model="copySetting.addition.afterBind" value="Last" />
										<label :for="'gridtable_afterBind_Last' + copySetting.id">{{$t('마지막(Last)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="checkbox checkbox-css">
										<input type="checkbox" :id="'gridtable_afterBind_Min' + copySetting.id" v-model="copySetting.addition.afterBind" value="Min" />
										<label :for="'gridtable_afterBind_Min' + copySetting.id">{{$t('최소(Min)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="checkbox checkbox-css">
										<input type="checkbox" :id="'gridtable_afterBind_Max' + copySetting.id" v-model="copySetting.addition.afterBind" value="Max" />
										<label :for="'gridtable_afterBind_Max' + copySetting.id">{{$t('최대(Max)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="checkbox checkbox-css">
										<input type="checkbox" :id="'gridtable_afterBind_Sum' + copySetting.id" v-model="copySetting.addition.afterBind" value="Sum" />
										<label :for="'gridtable_afterBind_Sum' + copySetting.id">{{$t('합계(Sum)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="checkbox checkbox-css">
										<input type="checkbox" :id="'gridtable_afterBind_Avg' + copySetting.id" v-model="copySetting.addition.afterBind" value="Avg" />
										<label :for="'gridtable_afterBind_Avg' + copySetting.id">{{$t('평균(Avg)')}}</label>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.point }">
							<div v-for="(columns, i) in copySetting.columns" :key="i">
								<div class="row form-group">
									<div class="col-md-5">
										<label><trans>표시명</trans> {{ i + 1 }}</label>
										<valid-input :inputType="'text'" :inputValue.sync="columns.label" :rules="rules.DASHBOARD_WIDGET_VIEW_NAME_RULE" :NonStatus="true" :classList="'form-control'"> </valid-input>
									</div>
									<div class="col-md-6">
										<label><trans>관제점</trans></label>
										<input type="text" class="form-control" v-model="columns.point" />
									</div>
									<div class="col-md-1">
										<label>&nbsp;</label>
										<b-dropdown text="" variant="default" toggle-class="btn-white" class="pull-left" style="margin-top:25px;">
											<b-dropdown-item @click="toggleCompare(i)"><i class="fa" :class="{ 'fa-check': columns.compare }"></i> <trans>비교</trans> </b-dropdown-item>
											<b-dropdown-item @click="deleteColumn(i)"> <trans>삭제</trans> </b-dropdown-item>
										</b-dropdown>
									</div>
								</div>
								<legend></legend>
							</div>
							<div class="row form-group m-0">
								<button class="btn btn-white m-auto" @click="insertColumn()"><i class="fa fa-plus width-100"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-12 p-0">
					<div slot="modal-footer" class="w-100">
						<button class="btn btn-primary m-r-5 pull-right" @click="onModalOK()"  ><trans>확인</trans></button>
						<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()"><trans>취소</trans></button>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";

	export default {
		extends: xeBaseSetting,
		data() {
			return {
				tabs: {
					general: true,
					processing: false,
					point: false,
				},
				rules,
				validMessage
			};
		},
		methods: {
			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.point = false;
					this.tabs.processing = false;
				}
				if (tab == "processing") {
					this.tabs.general = false;
					this.tabs.point = false;
					this.tabs.processing = true;
				} else if (tab == "point") {
					this.tabs.general = false;
					this.tabs.point = true;
					this.tabs.processing = false;
				}
			},
			insertColumn() {
				this.copySetting.columns.push({ label: "", point: "" });
			},
			deleteColumn(idx) {
				this.copySetting.columns.splice(idx, 1);
			},
			toggleCompare(idx) {
				this.copySetting.columns[idx].compare = !this.copySetting.columns[idx].compare;
			},
		},
	};
</script>
