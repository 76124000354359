<template>
	<div class="xeAlarmHistoryWidget heightMAX">
		<panel
			:variant="panelVariant"
			bodyClass="p-r-20 p-l-20 bg-silver chartHeightMax"
			hideExpand="true"
			hideReload="true"
			:hideCollapse="isEditMode ? 'false' : 'true'"
			:hideRemove="isEditMode ? 'false' : 'true'"
			@panel-remove="onPanelRemove"
			@panel-expand="expandAlarmHistoryPanel"
			@panel-collapse="collapseAlarmHistoryPanel"
			@panel-reload="initSetting"
			ref="panel"
			:fixedHeight="fixedHeight"
		>
			<template slot="header">
				<h4 class="panel-title"><trans>알람로그</trans></h4>
				<span v-if="alarmHistory.length > 0" class="label bg-primary cursor-pointer m-r-5">{{ `${alarmHistory.length} alarms` }}</span>
			</template>

			<div v-if="alarmHistory.length > 0" class="chats heightMAX" style="height: 225px;">
				<div class="left heightMAX" v-for="(alarm, idx) in alarmHistory" v-bind:key="idx">
					<span class="date-time"> {{ alarm.regDt.passedTime() }} </span>
					<a href="javascript:;" class="name">
						{{ alarm.alarmGroup }}
						<span class="badge"> {{ alarm.status }} </span>
						<span class="m-l-10">{{ alarm.regDt.format("yyyy-MM-dd hh:mm:ss") }}</span>
					</a>
					<div class="message heightMAX">
						{{ liveAM[alarm.ptAddr] ? liveAM[alarm.ptAddr].ptName : "" }}

						<br />
						{{ alarm.description }}
						<br />
						{{ alarm.solution }}
					</div>
				</div>
			</div>
			<div v-if="alarmHistory.length == 0" class="heightMAX">
				<div class="historyNone heightMAX">
					<span> {{ $t("데이터가 존재하지 않습니다.") }} </span>
				</div>
			</div>
		</panel>
	</div>
</template>

<style scoped>
	.historyNone {
		text-align: center;
		display: grid;
		align-items: center;
	}

	.historyNone span {
		display: block;
		padding: 196px 0 95px 0;
		font-size: 20px;
		color: #000;
		background: url("/assets/xems/common/product/noListBg.png") center 87px no-repeat;
	}
</style>

<script>
	import Vue from "vue";
	import backEndApi from "@api/backEndApi.js";

	export default {
		name: "xeAlarmHistory",
		props: ["widget", "cellSize", "on-remove-widget"],

		data() {
			return {
				// Alarm history
				alarmHistory: [],
				alarmHistoryAreaHeigth: -1,
			};
		},
		computed: {
			isEditMode() {
				return this.$store.getters.isEditMode;
			},
			fixedHeight() {
				// 41은 패널해더의 높이...  5는 dndgrid 마진값
				let margin = 0;
				if (!this.isEmpty(this.cellSize.margin)) margin = this.cellSize.margin;

				let panelHeaderHeight = this.isEditMode || (this.widget.panel && this.widget.panel.display) ? 41 : 0; // 41은 패널헤더가 표실될때의 높이값

				return this.widget.position.h * (this.cellSize.h + margin) - margin - panelHeaderHeight;
			},
		},
		mounted() {
			this.initSetting();

			let alarms = document.getElementsByClassName("xeAlarmHistoryWidget");

			for (let i = 0; i < alarms.length; i++) {
				alarms[i].parentNode.style.height = "100%";
			}
		},
		methods: {
			initSetting() {
				this.refreshAlarmHistoryPanel();
			},
			refreshAlarmHistoryPanel() {
				//console.log("refreshAlarmHistoryPanel");
				backEndApi.notification.searchAlarmNotification(3).then(({ data }) => {
					if (this.$err(data)) return;

					// {
					//     "regDt": "20190128110301",
					//     "alarmGroup": "052000007_0000_0000_V_0000000100",
					//     "point": "052000007_0000_0000_V_0000000100",
					//     "alarmType": "A0",
					//     "value": "0",
					//     "status": "L",
					//     "hold": "1",
					//     "description": "test alarm",
					//     "solution": "test alarm"
					// },

					// 날짜형식 읽기 쉽게 변경해야 함.. 1일 안쪽이면, 몇시간 전인지..  몇분전인지... 읽기 쉽게 변환하기..
					//data.forEach(v => {
					//  v.regDt = v.regDt;
					//});

					this.alarmHistory = data;
				});
			},
			openAlarmHistory() {
				this.$router.push(`/alarm/AlarmList`);
			},
			// 패널 동작
			expandAlarmHistoryPanel(expand) {
				// TODO: max-height를 제어할 필요성이 있나 ???
				if (this.alarmHistoryAreaHeigth === -1) {
					this.alarmHistoryAreaHeigth = document.getElementById("alarmHistoryArea").offsetHeight;
				}

				Vue.nextTick(() => {
					var area = document.getElementById("alarmHistoryArea");

					if (expand) {
						var offsetHeight = document.getElementById("alarmHistoryPanel").offsetHeight;
						area.style.maxHeight = offsetHeight - 60 + "px"; // `calc(100% - ${offsetHeight}px)`;
					} else {
						area.style.maxHeight = this.alarmHistoryAreaHeigth + "px";
					}
				});
			},
			collapseAlarmHistoryPanel() {
				//console.log("collapseAlarmHistoryPanel");
			},
			onPanelRemove() {
				this.isDelete = true;
				this.$emit("on-remove-widget");
			},
		},
	};
</script>
