<template>
  <div>
    <div class="row form-group m-t-10">
      <div class="col-md-12">
        <a href="javascript:;" class="xe-tab-underline xe-tab-line-orange"> <trans>일반</trans></a>
      </div>
    </div>
    <div class="row form-group m-t-10">
      <div class="col-md-6">
        <label><trans>제목</trans></label>
        <input type="text" class="form-control" v-model="copySetting.title" />
      </div>
      <div class="col-md-4">
        <label><trans>파일명</trans></label>
        <input type="text" class="form-control" v-model="copySetting.fileName" />
      </div>
      <div class="col-2">
        <label><trans>패널</trans></label>
        <div class="checkbox checkbox-css">
          <input type="checkbox" :id="'rankcard_panel'" v-model="copySetting.panel.display" />
          <label :for="'rankcard_panel'">{{copySetting.panel.display ? 'ON' : 'OFF'}}</label>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12 p-0">
        <div slot="modal-footer" class="w-100">
          <button class="btn btn-primary m-r-5 pull-right" @click="onModalOK()"><trans>확인</trans></button>
          <button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()"><trans>취소</trans></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";

export default {
  extends: xeBaseSetting,
  data() {
    return {};
  },
  methods: {}
};
</script>
