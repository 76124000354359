import xeModalSelf from "./xeModalSelf.vue";

export default {
  props: ["initSetting"],
  components: {
    xeModalSelf
  },
  data() {
    return {
      copySetting: {},
      isSelfSearch: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.copySetting = JSON.parse(JSON.stringify(this.initSetting));
    this.isSelfSearch = this.copySetting.self ? true : false;
  },
  methods: {
    validFailed(errors){
      let keys = Object.keys(errors);
  
      for(let i = 0; i < keys.length; i++){
        let message = errors[keys[i]][0];

        if(message !== ""){
          this.alertNoti(`${keys[i]}는 ${message}`);
          return;
        }
      }

      
      // console.log(value);
    },

    onModalOK() {
      this.$emit("on-modal-ok", this.copySetting);
    },
    onModalCancel() {
      this.copySetting = JSON.parse(JSON.stringify(this.initSetting));
      this.$emit("on-modal-cancel");
    }
  }
};
