<template>
  <div class="xeEnergyCard heightMAX">
    <panel
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-0 overflow-hidden chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>

      <div v-if="isShow" class="widget widget-stats heightMAX" :style="widget.style">
        <div class="stats-icon stats-icon-lg"><i :class="widget.bgIcon"></i></div>
        <div class="stats-content heightMAX">
          <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop('main', ...arguments)">
            <div class="stats-title ">{{ $t(widget.title) }}</div>
            <div class="stats-number">
              {{ $t(main.label) }} {{ mainValue }} {{ $t(main.tail) }}
              <span v-if="isPercentage && main.value" class="pull-right f-s-20 m-t-5">
                <span class="increase" v-if="differencialVal > 0.0"><i class="fa fa-caret-up"></i> {{ differencialVal }} %</span>
                <span class="decrease" v-if="differencialVal < 0.0"><i class="fa fa-caret-down"></i> {{ differencialVal }} %</span>
                <span class="text-grey-lighter" v-if="differencialVal == 0.0"> {{ differencialVal }} %</span>
              </span>
            </div>
          </drop>
          <div class="stats-progress progress">
            <div class="progress-bar" v-bind:style="{ width: main.value.percentage(bottom.value) + '%' }"></div>
          </div>
          <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop('bottom', ...arguments)">
            <div class="stats-desc">{{ $t(bottom.label) }} {{ bottomValue }} {{ $t(bottom.tail) }}</div>
          </drop>
        </div>
      </div>
    </panel>
    <div v-else class="widget widget-stats heightMAX" :style="widget.style">
      <div class="stats-icon stats-icon-lg"><i :class="widget.bgIcon"></i></div>
      <div class="stats-content heightMAX">
        <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop('main', ...arguments)">
          <div class="m-b-2">{{ $t(widget.title) }}</div>
          <div class="stats-number ">
            {{ $t(main.label) }} {{ mainValue }} {{ $t(main.tail) }}
            <span v-if="isPercentage && main.value" class="pull-right f-s-20 m-t-5">
              <span class="increase" v-if="differencialVal > 0.0"><i class="fa fa-caret-up"></i> {{ differencialVal }} %</span>
              <span class="decrease" v-if="differencialVal < 0.0"><i class="fa fa-caret-down"></i> {{ differencialVal }} %</span>
              <span class="text-grey-lighter" v-if="differencialVal == 0.0"> {{ differencialVal }} %</span>
            </span>
          </div>
        </drop>
        <div class="stats-progress progress bg-silver">
          <div class="progress-bar bg-black" v-bind:style="{ width: main.value.percentage(bottom.value) + '%' }"></div>
        </div>
        <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop('bottom', ...arguments)">
          <div style="margin:-5px 0 -3px;">{{ $t(bottom.label) }} {{ bottomValue }} {{ $t(bottom.tail) }}</div>
        </drop>
      </div>
    </div>

    <b-modal ref="modalSetting" size="lg" title="설정 - EnergyCard" body-class="p-t-0 p-b-0" hide-footer>
      <energy-card-setting :initSetting="energyCardSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import energyCardSetting from "@src/views/widget/v1/setting/xeEnergyCardSetting.vue";
import utils from "./xeBaseUtils.js";

export default {
  name: "xeEnergyCard",
  extends: xeBaseWidget,
  components: {
    energyCardSetting,
  },
  data() {
    return {
      main: { value: 0, addition: { fixed: 1 } },
      bottom: { value: 0, addition: { fixed: 1 } },
    };
  },
  computed: {
    hasSelfCond() {
      if (this.main.self || this.bottom.self) return true;
      return false;
    },
    energyCardSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    isPercentage() {
      return this.widget.objects.main.addition.isPercentage;
    },
    differencialVal() {
      return this.main.value.differencial(this.bottom.value, this.main.addition.fixed);
    },
    mainValue() {
      return this.comma(this.main.value, this.main.addition.fixed);
    },
    bottomValue() {
      return this.comma(this.bottom.value, this.bottom.addition.fixed);
    },
  },
  created() {
    if (!this.widget.style) {
      this.widget.style = "";
    }
    if (!this.widget.bgIcon) {
      this.widget.bgIcon = "";
    }
    if (!this.widget.objects) {
      this.widget.objects = {
        main: { label: "", addition: { afterAction: "", afterBind: "", isPercentage: true, fixed: 0 }, tail: "", point: "", value: 0, self: { type: 0, sTi: "", eTi: "" } },
        bottom: { label: "", addition: { afterAction: "", afterBind: "", isPercentage: false, fixed: 0 }, tail: "", point: "", value: 0, self: { type: 0, sTi: "", eTi: "" } },
      };
    }
  },
  mounted(){
    let energys = document.getElementsByClassName("xeEnergyCard");

    for(let i = 0; i < energys.length; i++){
      energys[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      if (!this.widget.objects) throw "object is empty";
      if (!this.widget.objects.main) throw "main object is empty";

      this.main = Object.assign(this.widget.objects.main, { value: 0 });
      this.bottom = Object.assign({}, this.widget.objects.bottom, { value: 0 });
    },
    applyData(logs) {
      if (!this.isEmpty(this.main.self.sTi)) {
        this.bindCard(this.getCond(logs, Object.assign({ point: this.main.point }, this.main.self)), this.main);
      } else {
        this.bindCard(logs[0], this.main);
      }

      if (!this.isEmpty(this.bottom.self.sTi)) {
        this.bindCard(this.getCond(logs, Object.assign({ point: this.bottom.point }, this.bottom.self)), this.bottom);
      } else {
        this.bindCard(logs[0], this.bottom);
      }
    },
    getCond(logs, cond) {
      let rtnLogs = null;

      // 일자형식 스크립트 실행
      function runTi(type, ti) {
        let fn = new Function("now", "baseline", "compare", "type", "return " + ti);
        let dt = fn(new Date(), type);

        return dt.normalize(type);
      }

      rtnLogs = logs.filter((log) => log.type == cond.type && log.sDt == runTi(cond.type, cond.sTi) && log.eDt == runTi(cond.type, cond.eTi) && log.points[cond.point]);

      return rtnLogs;
    },
    bindCard(log, card) {
      let ptVals = [];

      if (Array.isArray(log)) ptVals = log[0].points[card.point];
      else ptVals = log.points[card.point];

      ptVals = utils.onAfterAction(card.point, ptVals, card.addition.afterAction); // 배열반환

      let val = utils.onAfterBind(card.point, ptVals, card.addition ? card.addition.afterBind : null); // 값반환

      card.value = val;
    },
    //dnd
    handleDropPoint(label, arg) {
      if (label == "main") {
        this.widget.title = arg.item.title;
        this.widget.objects.main = { label: "", addition: { afterAction: "", afterBind: "Last", isPercentage: false }, tail: "", point: arg.item.ptAddr, value: 0 };

        // "main"  : { "label":"", "afterBind": "Last", "tail": "kWh", "point": "084000001_0000_0000_VLI_00000001" },
      } else {
        // bottom
        this.widget.title = arg.item.title;
        this.widget.objects.bottom = { label: "", addition: { afterAction: "", afterBind: "Last", isPercentage: false }, tail: "", point: arg.item.ptAddr, value: 0 };
      }
    },
  },
};
</script>
