<template>
  <div class="xeSimpleCard heightMAX">
    <!-- :variant=" panelVariant" -->
    <panel
      ref="panel"
      v-if="widget.panel && widget.panel.display"
      title=""
      :variant="inverseMode ? 'inverse' : panelVariant"
      bodyClass="p-10 p-t-0"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title" style="height:20px;"></h4>
          </drop>
        </drag>
      </template>
      <div v-if="isShow">
        <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)">
          <div v-if="widget.objects.main.addition.isVertical" class="xe-na" :class="[inverseBg]">
            <div>
              <i :class="widget.bgIcon" class="xe-vetical-title width-full text-center"></i>
              <div class="xe-vetical-title width-full text-center">
                <span :class="[inverseText]">{{ $t(widget.title) }}</span>
              </div>
              <div class="xe-vetical-value m-t-5 width-full text-center">
                <span>{{ mainVal }}&nbsp;{{ $t(main.tail) }}</span>
              </div>
            </div>
          </div>
          <div v-else class="valign-middle" :class="[inverseBg]">
            <div class="xe-nomal-title">
              <i :class="widget.bgIcon">&nbsp;</i>
              <span :class="[inverseText]">{{ $t(widget.title) }}</span>
            </div>
            <div class="xe-nomal-value">
              <span :class="[inverseText]">{{ mainVal }}&nbsp;{{ $t(main.tail) }}</span>
            </div>
          </div>
        </drop>
      </div>
    </panel>

    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="xeSimpleCardDrop heightMAX">
      <div v-if="isShow" :class="[inverseBg]" class="m-b-20 simpleCardDrops heightMAX">
        <div v-if="widget.objects.main.addition.isVertical" class="xe-na heightMAX" :class="[inverseBg]">
          <div class="heightMAX verticalAlign">
            <i :class="widget.bgIcon" class="xe-vetical-title width-full text-center"></i>
            <div class="xe-vetical-title width-full text-center">
              <span :class="[inverseText]">{{ $t(widget.title) }}</span>
            </div>
            <div class="xe-vetical-value m-t-5 width-full text-center">
              <span :class="[inverseText]">{{ mainVal }}&nbsp;{{ $t(main.tail) }}</span>
            </div>
          </div>
        </div>
        <div v-else class="valign-middle heightMAX" :class="[inverseBg]" >
          <div class="xe-nomal-title">
            <i :class="widget.bgIcon">&nbsp;</i>
            <span :class="[inverseText]">{{ $t(widget.title) }}</span>
          </div>
          <div class="xe-nomal-value">
            <span :class="[inverseText]">{{ mainVal }}&nbsp;{{ $t(main.tail) }}</span>
          </div>
        </div>
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" title="설정 - SimpleCard" body-class="p-t-0 p-b-0" hide-footer>
      <simple-card-setting :initSetting="simpleCardSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<style scoped>
.xe-na {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.xe-vetical-title {
  font-size: 13px;
  font-weight: 600;
}

.xe-nomal-title {
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}

.xe-vetical-value {
  font-size: 28px;
  font-weight: 700;
}

.xe-nomal-value {
  position: absolute;
  bottom: 20px;
  right: 0;
  font-size: 24px;
  font-weight: 700;
  padding-right: 10px;
}

.stats-title {
  text-transform: uppercase;
}

.verticalAlign{
  display: grid;
  align-content: center;
}

@media screen and (max-width: 768px) {
  .xe-nomal-title {
    top: 10px;
  }

  .xe-nomal-title i {
    padding-left: 10px;
  }

  .xe-nomal-value {
    bottom: 25px;
    font-size: 5.5vw;
  }

  .xe-nomal-value span {
    padding-right: 10px;
  }
}
</style>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import simpleCardSetting from "@src/views/widget/v1/setting/xeSimpleCardSetting.vue";
import utils from "./xeBaseUtils.js";

export default {
  name: "xeSimpleCard",
  extends: xeBaseWidget,
  components: {
    simpleCardSetting,
  },
  data() {
    return {
      main: { value: 0, addition: { fixed: 1 } },
    };
  },
  computed: {
  
    simpleCardSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    mainVal() {
      return this.comma(this.main.value, this.main.addition.fixed);
      // return this.main.value;
    },
  },
  created() {
    if (!this.widget.objects) {
      this.widget.objects = {
        main: { label: "", addition: { afterAction: "", afterBind: "", fixed: 0, isVertical: false }, tail: "", point: "", value: 0, self: { type: 0, sTi: "", eTi: "" } },
      };
    }
    if (!this.widget.bgIcon) {
      this.widget.bgIcon = "";
    }
    if(!this.widget.moved){
      this.widget.moved = false;
    }
  },
  mounted(){
    let simpleCard = document.getElementsByClassName('xeSimpleCard');

    for(let i = 0; i < simpleCard.length; i++){
      simpleCard[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.isPanelExpand = false;

      if (!this.widget.objects) throw "object is empty";
      if (!this.widget.objects.main) throw "main object is empty";

      this.main = Object.assign(this.widget.objects.main, { value: 0 });
    },
    applyData(logs) {
      if (logs && logs.length) this.bindCard(logs[0], this.main);
    },
    bindCard(log, card) {
      let ptVals = log.points[card.point];

      ptVals = utils.onAfterAction(card.point, ptVals, card.addition ? card.addition.afterAction : null); // 배열반환

      let val = utils.onAfterBind(card.point, ptVals, card.addition ? card.addition.afterBind : null); // 값반환

      val = utils.fixedValue(val, this.main.addition.fixed);

      card.value = val;
    },
    // 드래그
    handleDropPoint(transfer) {
      if (this.$err(transfer)) return;

      this.widget.title = transfer.item.title;
      this.widget.objects.main = { afterBind: "Last", tail: "", point: transfer.item.ptAddr, value: 0 };
    },
  },
};
</script>
