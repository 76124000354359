<template>
	<div>
		<vvo v-slot="{ passes, invalid }">
			<div class="form-group row m-t-10">
				<div class="col-md-12">
					<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }">
						<trans>일반</trans>
					</a>
					<a href="javascript:;" class="xe-tab-underline" v-on:click.prevent="toggleTabs('card')" :class="{ 'xe-tab-line-orange': tabs.card }">
						<trans>카드</trans>
					</a>

					<a href="javascript:;" class="xe-tab-underline" @click.prevent="toggleTabs('main')" :class="{ 'xe-tab-line-orange': tabs.main }">
						<trans>메인정보</trans>
					</a>
				</div>
			</div>

			<div class="form-group row">
				<div class="col-md-12">
					<div class="tab-content">
						<!-- 일반(탭) -->
						<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.general }">
							<br />
							<div class="form-group row">
								<div class="col-md-12">
									<label>
										<h6>
											<trans>항목명</trans>
										</h6>
									</label>
									<valid-input :inputType="'text'" :NonStatus="true" :inputValue.sync="copySetting.title" :rules="rules.DASHBOARD_WIDGET_ITEM_NAME_RULE" :classList="'form-control'"> </valid-input>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-12">
									<label>
										<h6>
											<trans>구역설정</trans>
										</h6>
									</label>
									<tree-select
										v-model="copySetting.zoneIdx"
										:placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
										:noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
										:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
										:multiple="false"
										:options="options"
									/>
								</div>
							</div>
						</div>

						<!-- 카드 스타일(탭) -->
						<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.card }">
							<br />
							<!-- 베경 스타일 -->
							<!-- <div class="row form-group">
							<div class="col-md-2">
								<label>
									<b>
										<trans>배경</trans>
									</b>
								</label>
							</div>
							<div class="col-md-10">
								<div class="row form-group align-items-center">
									<div class="col-md-4">
										<label>
											<trans>배경색</trans>
										</label>
										<xe-scatch-color-picker :color="copySetting.card.backgroundColor" :isOpen="copySetting.card.colorOpens" :inputForm="'hex'" @input="copySetting.card.backgroundColor = $event.hex8">
										</xe-scatch-color-picker>
									</div>
									<div class="col-md-3">
										<label>
											<trans>폰트 색상</trans>
										</label>
										<xe-scatch-color-picker :color="copySetting.card.fontColor" :isOpen="copySetting.card.colorOpens" :inputForm="'hex'" @input="copySetting.card.fontColor = $event.hex8">
										</xe-scatch-color-picker>
									</div>
								</div>
							</div>
						</div> -->
							<!-- 아이콘 스타일 -->
							<div class="row form-group">
								<div class="col-md-2">
									<label>
										<b>
											<trans>아이콘</trans>
										</b>
									</label>
								</div>
								<div class="col-md-10">
									<div class="row form-group align-items-center">
										<div class="col-md-4">
											<label>
												<trans>아이콘</trans>
											</label>
											<icon-select :current="copySetting.bgIcon" @on-change="onChangeIcon" />
										</div>
									</div>
								</div>
							</div>
							<!-- 아이콘 스타일 -->
						</div>
						<!-- 카드 스타일(탭) -->

						<!-- 메인 정보(탭) -->
						<div class="tab-pane fade" v-bind:class="{ 'active show': tabs.main }">
							<br />
							<label>
								<h6>
									<trans>기본정보</trans>
								</h6>
							</label>
							<div class="form-group row">
								<div class="col-md-4">
									<label>
										<trans>표시명</trans>
									</label>
									<input type="text" class="form-control" v-model="copySetting.objects.main.label" />
								</div>
								<div class="col-md-4">
									<label>
										<trans>관제점</trans>
									</label>

									<div :style="{ fontWeight: 'normal' }">
										<tree-select
											class="col-12 pr-0 pl-0"
											v-model="copySetting.objects.main.point"
											:options="searchPointInfoOptions"
											:disable-branch-nodes="true"											
											:open-on-focus="true"
											:placeholder="$t(controlMessages.POINT_TREE_PLACEHOLDER_MESSAGE)"
											:noChildrenText="$t(controlMessages.POINT_TREE_NOCHILDERN_MESSAGE)"
											:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
										/>
									</div>
								</div>
								<div class="col-md-2">
									<label>
										<trans>단위</trans>
									</label>
									<valid-input :inputType="'text'" :inputValue.sync="copySetting.objects.main.tail" :rules="rules.DASHBOARD_WIDGET_UNIT_RULE" :NonStatus="true" :classList="'form-control'">
									</valid-input>
								</div>
								<div class="col-md-2">
									<label>
										<trans>소수점자리</trans>
									</label>
									<valid-input
										:inputType="'number'"
										:inputValue.sync="copySetting.objects.main.addition.fixed"
										:classList="'form-control'"
										:errorMessage="validMessage.WIDGET_DECIMAL_AND_FONTSIZE_LENGTH_VALID_MESSAGE"
										:rules="rules.DASHBOARD_WIDGET_DECIMAL_RULE"
									>
									</valid-input>
								</div>
							</div>
							<br />
							<div class="row">
								<div class="col-12">
									<label>
										<h6>
											<trans>조회기준</trans>
										</h6>
									</label>
									<xe-modal-self :copySetting="copySetting.objects.main" :isCompare="false" />
								</div>
							</div>
							<br />
							<label>
								<h6>
									<trans>취합방법</trans>
								</h6>
							</label>
							<div class="form-group row">
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'simplecard_main_afterBind_Last' + copySetting.id" value="Last" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'simplecard_main_afterBind_Last' + copySetting.id">{{$t('마지막(Last)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'simplecard_main_afterBind_Min' + copySetting.id" value="Min" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'simplecard_main_afterBind_Min' + copySetting.id">{{$t('최소(Min)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'simplecard_main_afterBind_Max' + copySetting.id" value="Max" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'simplecard_main_afterBind_Max' + copySetting.id">{{$t('최대(Max)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'simplecard_main_afterBind_Sum' + copySetting.id" value="Sum" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'simplecard_main_afterBind_Sum' + copySetting.id">{{$t('합계(Sum)')}}</label>
									</div>
								</div>
								<div class="col-md-2">
									<div class="radio radio-css">
										<input type="radio" :id="'simplecard_main_afterBind_Avg' + copySetting.id" value="Avg" v-model="copySetting.objects.main.addition.afterBind" />
										<label :for="'simplecard_main_afterBind_Avg' + copySetting.id">{{$t('평균(Avg)')}}</label>
									</div>
								</div>
							</div>
							<br />
							<div class="form-group row">
								<div class="col-md-12">
									<label>
										<h6>
											<trans>후처리</trans>
										</h6>
									</label>
									<valid-textarea
										:inputType="'text'"
										:inputValue.sync="copySetting.objects.main.addition.afterAction"
										:classList="'form-control'"
										:rows="5"
										:NonStatus="true"
										:rules="rules.DASHBOARD_WIDGET_POSTPROCESS_RULE"
									>
									</valid-textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group row">
				<div class="col-md-12 p-0">
					<div slot="modal-footer" class="w-100">
						<button type="button" class="btn btn-primary m-r-5 pull-right" @click="passes(onModalOK())">
							<trans>확인</trans>
						</button>
						<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()">
							<trans>취소</trans>
						</button>
					</div>
				</div>
			</div>
		</vvo>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	// import xeScatchColorPicker from "../common/xeScatchColorPicker.vue";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	export default {
		extends: xeBaseSetting,
		components: {
			// xeScatchColorPicker,
		},
		data() {
			return {
				tabs: {
					general: true,
					card: false,
					main: false,
					Sub: false,
				},

				// define the default value
				zoneIdx: null,
				// define options
				options: null,
				searchPoint: null,
				searchPointInfoOptions: [],
				rules,
				validMessage,
				popupMessages,
				controlMessages
			};
		},
		mounted() {
			this.getZoneListHierarchi();
			this.getSearchPoint();
		},
		methods: {
			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.card = false;
					this.tabs.main = false;
				} else if (tab == "card") {
					this.tabs.general = false;
					this.tabs.card = true;
					this.tabs.main = false;
				} else if (tab == "main") {
					this.tabs.general = false;
					this.tabs.card = false;
					this.tabs.main = true;
				}
			},
			onChangeColor(idx, color) {
				this.copySetting.objects.series.backgroundColor = color;
			},
			onChangeIcon(value) {
				if (!this.isEmpty(value)) this.copySetting.bgIcon = value.icon;
				else this.copySetting.bgIcon = "";
			},
			getZoneListHierarchi() {
				backEndApi.zoneInfo.getZoneListHierarchi().then(({ data }) => {
					this.options = [];

					let temp = [];
					temp.push(data);
					this.options = this.setZoneTreeOption(temp);
				});
			},
			setZoneTreeOption(resultData) {
				resultData.forEach((data) => {
					data.id = data.zoneIdx;
					data.label = data.zoneName;

					if (data["children"]) {
						return this.setZoneTreeOption(data["children"]);
					} else {
						return data;
					}
				});

				return resultData;
			},
			getSearchPoint() {
				this.searchPointInfoOptions = [];

				backEndApi.pointInfo.getpointByGroup().then(({ data }) => {
					this.searchPoint = data;

					this.searchPoint.map((point) => {
						const pointList = point.pointList;
						const groupObject = {
							id: point.groupCode,
							label: point.groupCodeName,
							children: [],
						};

						for (let i = 0; i < pointList.length; i++) {
							groupObject.children.push({
								id: pointList[i].ptAddr,
								label: pointList[i].ptName,
							});
						}

						this.searchPointInfoOptions.push(groupObject);
					});

					this.searchPointInfoOptions.sort((a, b) => {
						return a.label.localeCompare(b.label);
					});

					this.searchPointInfoOptions.unshift({
						id: "",
						label: "전체",
					});
				});
			},
		},
	};
</script>
@src/views/widget/v1/common/xeBaseSetting.js