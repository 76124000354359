<template>
  <div>
    <div v-if="popupObj.popupType === 'Property'">
      <h4>{{ popupObj.label }}</h4>
      <div class="row form-group">
        <div class="col-lg-4">
          <label>Name </label>
          <input type="text" class="form-control" placeholder="Enter value" v-model="popupObj.propertyName" :readonly="true" />
        </div>
        <div class="col-lg-8">
          <!-- popupObj.valueType === 'digital -->
          <label v-if="popupObj.valueType === 'digital'">&nbsp;</label>
          <div v-if="popupObj.valueType === 'digital'" class="checkbox checkbox-css">
            <input type="checkbox" :id="digitalSwitchId" @change="onApplyProperty(popupProp)" v-model="popupProp.ptVal" :true-value="1" :false-value="0" />
            <label :for="digitalSwitchId">{{ popupProp.ptVal === 1 ? "ON" : "OFF" }}</label>
          </div>

          <!-- popupObj.valueType === 'analog -->
          <div v-else>
            <div class="row">
              <div :class="[!popupProp.writeable ? 'col-lg-12' : '', 'col-lg-4']">
                <label>Input</label>
                <input type="number" class="form-control" placeholder="?" v-model="popupProp.ptVal" :min="popupProp.pt ? popupProp.pt.min : -999999999999.99" :max="popupProp.pt ? popupProp.pt.max : 999999999999.99" :readonly="true" />
              </div>
              <div class="col-lg-5" v-if="popupProp.writeable">
                <label>Output</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter value"
                  v-model="popupProp.wtVal"
                  :min="popupProp.pt ? popupProp.pt.min : -999999999999.99"
                  :max="popupProp.pt ? popupProp.pt.max : 999999999999.99"
                  :readonly="!popupProp.writeable"
                />
              </div>
              <div class="col-lg-3" v-if="popupProp.writeable">
                <label>&nbsp;</label>
                <button class="btn btn-default" @click="onApplyProperty(popupProp)">Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Facility Writeable Popup -->
    <div v-if="popupObj.popupType === 'Facility'">
      <div class="row form-group">
        <div class="col-lg-3">
          <label> 제품유형 </label>
        </div>
        <div class="col-lg-9">
          <label> {{ popupProp.driverType }} v{{ popupProp.version }} </label>
        </div>
      </div>
      <div class="row form-group" v-for="(prop, i) in popupProps" :key="'prop' + i">
        <div class="col-lg-4">
          <input type="text" class="form-control" placeholder="Enter value" v-model="prop.propertyName" :readonly="true" />
          <!-- {{prop.propertyName}} -->
        </div>
        <div class="col-lg-8">
          <div v-if="popupObj.valueType === 'digital'" class="checkbox checkbox-css">
            <input type="checkbox" :id="digitalSwitchIds[i]" @change="onCheckProperty(popupObj, prop)" v-model="prop.ptVal" :true-value="1" :false-value="0" />
            <label :for="digitalSwitchIds[i]">{{ prop.ptVal === 1 ? "ON" : "OFF" }}</label>
          </div>
          <div v-else>
            <div class="row">
              <div :class="[!prop.writeable ? 'col-lg-12' : '', 'col-lg-4']">
                <input type="number" class="form-control" placeholder="?" v-model="prop.ptVal" :min="prop.pt ? prop.pt.min : -999999999999.99" :max="prop.pt ? prop.pt.max : 999999999999.99" :readonly="true" />
                <!-- {{prop.ptVal}} -->
              </div>
              <div class="col-lg-5" v-if="prop.writeable">
                <input type="number" class="form-control" placeholder="Enter value" v-model="prop.wtVal" :min="prop.pt ? prop.pt.min : -999999999999.99" :max="prop.pt ? prop.pt.max : 999999999999.99" :readonly="!popupProp.writeable" />
              </div>
              <div class="col-lg-3" v-if="prop.writeable">
                <button class="btn btn-default" @click="onApplyProperty(prop)">Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import backEndApi from "@api/backEndApi.js";
import * as popupMessages from "@src/consts/popupMessageConsts"

export default {
  name: "xeRealtimeFacilityModal",
  props: ["popupObj", "popupProps"],
  data() {
    return {
      facility: {},
      popupProp: {},
      digitalSwitchId:
        "customSwitch" +
        Math.random()
          .toString(36)
          .substring(10),
      digitalSwitchIds: [],
    };
  },
  watch: {
    liveDate() {
      this.bindLiveReal();
    }
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      this.$store.dispatch("UPDATE_PT_MAP");

      if (this.popupObj.popupType === "Facility") {
        this.digitalSwitchIds = this.getRandomIds("customSwitch", Object.keys(this.popupProps).length);
      }

      this.facility = JSON.parse(JSON.stringify(this.popupObj.facility));
      delete this.facility.properties;

      if(this.popupObj.popupType === 'Property' && this.popupObj.facility.properties.length > 0){
        let rtnProp = this.popupObj.facility.properties[0];

        let liveAM = this.readStorage('liveAM');

        let pt = liveAM[rtnProp.ptAddr];

        this.popupProp = Object.assign(rtnProp, {pt: pt});
      }
      
    },
    bindLiveReal() {
      let liveReal = this.readStorage("liveReal");
      this.popupObj.ptVal = liveReal[this.popupObj.ptAddr].ptVal;

      Object.keys(this.popupProps).map(v => {
        let ptAddr = this.popupProps[v].ptAddr;
        let ptVal = liveReal[ptAddr] ? liveReal[ptAddr].ptVal : "?";
        this.popupProps[v] = Object.assign(this.popupProps[v], { ptVal: ptVal });
      });

      this.popupProp = this.popupProps[this.popupObj.propertyName];
    },
    onApplyProperty(prop){
      this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        let now = (new Date()).normalize(6);

        let output = {
          channelIdx    : this.facility.channelIdx,
          facilityIdx   : this.facility.facilityIdx,
          ptAddr        : prop.outputPtAddr,
          ptVal         : typeof(prop.wtVal) === 'string' ?  parseFloat(prop.wtVal) : prop.wtVal,
          setDt         : now,
          state         : 'init',
          updDt         : now
        };

        backEndApi.outputLog.insertOutputLog(output).then(({ data }) => {
          if (this.$err(data)) return;

          this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
        });
      });
    }
  }
};
</script>
