<template>
  <div class="xeRankCard heightMAX">
    <panel
      v-if="widget.panel && widget.panel.display"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-0 bg-silver chartHeightMax"
      hideExpand="true"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>

      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div :id="rankID" class="heightMAX">
          <template v-for="(card, i) in cards">
            <!-- last는 마지막 card인지 판단 -->
            <xc-rank-card :title="$t(widget.title)" :card="card" :css="css" :inverseMode="inverseMode" :last="i == cards.length - 1 ? true : false" :key="i" />
          </template>
        </div>
      </drop>
    </panel>
    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div :class="[inverseBg]" class="m-b-20 heightMAX">
        <h4 v-if="widget.title">{{ $t(widget.title) }}</h4>
        <div :id="rankID">
          <template v-for="(card, i) in cards">
            <xc-rank-card :title="widget.title" :card="card" :css="css" :inverseMode="inverseMode" :key="i" />
          </template>
        </div>
      </div>
    </drop>

    <b-modal ref="modalSetting" size="lg" :title="`${$t('설정')} - Rank Card`" body-class="p-t-0 p-b-0" hide-footer>
      <rank-card-setting :initSetting="rankCardSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>
  </div>
</template>

<script>
import xeBaseWidget from "./xeBaseWidget.js";
import xcRankCard from "@src/views/widget/v1/controls/xcRankCard.vue";
import rankCardSetting from "@src/views/widget/v1/setting/xeRankCardSetting.vue";
import utils from "./xeBaseUtils.js";

// TODD: 팔렛트색상값이 너무 강함.
// 테마에 alert-primary 연한 색상이   UI General -> alert 박스의 색상을 썼으면 좋겠음.

export default {
  name: "xeRankCard",
  extends: xeBaseWidget,
  components: {
    xcRankCard,
    rankCardSetting,
  },
  data() {
    return {
      // inverseMode: false,

      rankID: this.getRandomId("rank"),

      cards: [
        // { rank:1, value: 75, label: "Food Refrigeration", compare: { rank:1, value: 75, label: "Food Refrigeration" } },
        // { rank:2, value: 90, label: "AHU",                 },
        // { rank:3, value: 25, label: "PAC", 								 }
      ],
      // css: { height: 0, margin: 10, padding: 15 },
    };
  },
  computed: {
    rankCardSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    css() {
      let areaHeight = this.widget.panel.display ? this.fixedHeight + 10 : this.widget.position.h * this.cellSize.h;
      let h = areaHeight / this.cards.length;
      let m = 10;

      return { height: h - m, margin: m, padding: 15 };
    },
  },
  created() {
    // 초기값 설정
    if (!this.widget.series) this.widget.series = [{ label: "Default", point: "", backgroundColor: "#348fe2" }];
    if (!this.widget.addition) this.widget.addition = { afterAction: "", afterBind: "", fixed: 0 };
    if (!this.widget.self) this.widget.self = { baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" }, compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" } };
  },
  mounted(){
    let ranks =document.getElementsByClassName("xeRankCard");

    for(let i = 0; i < ranks.length; i++){
      ranks[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.cards = [];
      this.widget.series.forEach((item, i) => {
        let base = this.merge({ rank: i + 1, value: 0, label: item.label, point: item.point, backgroundColor: item.backgroundColor, fixed: this.widget.addition.fixed });

        if (this.isCompare) {
          if (base.rank == i + 1) base.compare = { value: 0, label: "" };
        }

        this.cards.push(base);
      });
    },
    // 차트 데이터 바인딩
    applyData(logs) {
      const that = this;
      function makeVal(ptAddr, ptVals) {
        return utils.onAfterBind(ptAddr, ptVals, that.widget.addition.afterBind); // 값반환
      }

      // baseline 데이터값 생성

      // 기존 로그 복사하여 데이터 변형 작업 진행
      let copyLogs = JSON.parse(JSON.stringify(logs));
      // afterAction 처리
      copyLogs = utils.onAfterAction2(this.cards, copyLogs, this.widget.addition.afterAction);

      let baseline = copyLogs[0];
      this.cards = this.cards.map((card) => {
        card.value = makeVal(card.point, baseline.points[card.point] || []);
        //card.addition.fixed = this.widget.addition.fixed;
        card.fixed = this.widget.addition.fixed;
        return card;
      });

      // baseline으로 정렬
      this.cards
        .sort((a, b) => b.value - a.value)
        .forEach((card, i) => {
          card.rank = i + 1;
        });

      let compare = logs[1] || logs[0];
      if (this.isCompare && compare) {
        // compare 데이터값 생성
        let compareArr = this.cards.map((card, i) => {
          let compareVal = makeVal(card.point, compare.points[card.point] || []);
          return { rank: i + 1, label: card.label, value: compareVal };
        });

        // compare 값의 랭킹을 기록해 둔다.
        compareArr.sort((a, b) => b.value - a.value).forEach((obj, i) => (obj.rank = i + 1));

        // baseline과 동일한 랭킹에 해당하는 compre 개체를 할당한다.
        this.cards.forEach((card) => {
          let compareObj = compareArr.find((obj) => obj.rank === card.rank);
          if (compareObj) card.compare = compareObj;
        });
      }
    },
    //dnd
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        points.map((point) => {
          if (that.widget.series.filter((v) => v.point === point.ptAddr).length == 0) {
            // 중복 체크
            that.widget.series.push({ label: point.title, point: point.ptAddr, backgroundColor: that.getRandomColor() });
          }
        });
      }

      let defaultObj = { label: "Default", point: "", backgroundColor: "#348fe2" };

      if (JSON.stringify(this.widget.series.slice(0, 1)) == JSON.stringify([defaultObj])) this.widget.series = [];
      append(transfer.item.type === this.CODE.Drag.PointGroup ? transfer.item.points : [transfer.item]);
    },
  },
};
</script>
