<template>
	<div>
			<div class="row form-group m-t-10">
				<div class="col-md-12">
					<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }">
						<trans>일반</trans>
					</a>
					<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('chartOptions')" :class="{ 'xe-tab-line-orange': tabs.chartOptions }">
						<trans>차트 옵션</trans>
					</a>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-12">
					<div class="tab-content">
						<div class="tab-pane fade" :class="{ 'active show': tabs.general }">
							<label>&nbsp;</label>
							<div class="row form-group">
								<div class="col-12">
									<label>
										<h6>
											<i class="fa fa-check"></i>
											<trans>항목명</trans>
										</h6>
									</label>

									<div class="row form-group align-items-center">
										<div class="col-md-6">
											<valid-input :inputType="'text'" :inputValue.sync="copySetting.title" :rules="rules.DASHBOARD_WIDGET_ITEM_NAME_RULE" :classList="'form-control'" :NonStatus="true">
											</valid-input>
										</div>
									</div>
								</div>
							</div>
							<hr />
						</div>

						<div class="tab-pane fade" :class="{ 'active show': tabs.chartOptions }">
							<label>&nbsp;</label>
							<div class="row form-group">
								<div class="col-12">
									<h6>
										<i class="fa fa-check"></i>
										<trans>차트 옵션</trans>
									</h6>
								</div>
							</div>

							<!-- 공통 설정 -->
							<div class="row form-group">
								<div class="col-md-2">
									<label
										><b>
											<trans>공통</trans>
										</b></label
									>
								</div>
								<div class="col-md-10">
									<div class="row form-group align-items-center">
										<div class="col-md-3">
											<label>
												<trans>배경 색상</trans>
											</label>
											<xe-scatch-color-picker
												:color="copySetting.chart.backgroundColor"
												:isOpen="copySetting.chart.colorOpens"
												:inputForm="'hex'"
												@input="copySetting.chart.backgroundColor = $event.hex8"
											>
											</xe-scatch-color-picker>
										</div>
										<div class="col-md-3">
											<label>
												<trans>격자 색상</trans>
											</label>
											<xe-scatch-color-picker :color="copySetting.chart.gridColor" :isOpen="copySetting.chart.colorOpens" :inputForm="'hex'" @input="copySetting.chart.gridColor = $event.hex8">
											</xe-scatch-color-picker>
										</div>
										<div class="col-md-3">
											<label>
												<trans>폰트 색상</trans>
											</label>
											<xe-scatch-color-picker :color="copySetting.chart.fontColor" :isOpen="copySetting.chart.colorOpens" :inputForm="'hex'" @input="copySetting.chart.fontColor = $event.hex8">
											</xe-scatch-color-picker>
										</div>
										<div class="col-md-3">
											<label>
												<trans></trans>
											</label>
										</div>
									</div>
								</div>
							</div>
							<!-- //공통 설정 -->

							<div class="row form-group">
								<div class="col-md-2">
									<label>
										<b><trans>아이콘</trans></b>
									</label>
								</div>
								<div class="col-md-10">
									<div class="row form-group align-items-center">
										<div class="col-md-4">
											<label><trans>아이콘</trans></label>
											<icon-select :current="copySetting.bgIcon" @on-change="onChangeIcon" />
										</div>
									</div>
								</div>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>

			<div class="row form-group">
				<div class="col-md-12 p-0">
					<div slot="modal-footer" class="w-100">
						<button class="btn btn-primary m-r-5 pull-right" @click="onModalOK()"  >
							<trans>확인</trans>
						</button>
						<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()">
							<trans>취소</trans>
						</button>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
	import xeScatchColorPicker from "../common/xeScatchColorPicker.vue";
	import * as rules from "@src/consts/ruleConsts.js";

	export default {
		extends: xeBaseSetting,
		data() {
			return {
				tabs: {
					general: true,
					chartOptions: false,
				},
				rules
			};
		},
		components: {
			xeScatchColorPicker,
		},
		created() {},
		methods: {
			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.chartOptions = false;
				} else if (tab == "chartOptions") {
					this.tabs.general = false;
					this.tabs.chartOptions = true;
				}
			},
			onChangeColor(idx, color) {
				this.copySetting.chart.series[idx].backgroundColor = color;
			},
			onChangeIcon(value) {
				if (!this.isEmpty(value)) this.copySetting.bgIcon = value.icon;
				else this.copySetting.bgIcon = "";
			},
		},
	};
</script>
@src/views/widget/v1/common/xeBaseSetting.js