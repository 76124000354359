<template>
  <div class="xeDataCard heightMAX">
    <div class="kbet-custom-reset heightMAX">
      <div class="rounded kbet-widget-valueCard-v1 heightMAX" style="background-color: #58d4d2;">
        <i class="material-icons bg-icon rotate-reversal">electrical_services</i>
        <p class="title">{{$t('금일 전력 사용량')}}</p>
        <div class="value-usage">
          <strong>12,345<span>kWh</span></strong>
        </div>
        <dl class="value-contrast">
          <dt>{{$t('전일 대비')}}</dt>
          <dd class="contrast-up">
            <strong>+3.2%</strong>
          </dd>
        </dl>
      </div>
    </div>
    <!-- <span>{{masterValue.copy}}</span>
    <div v-for="(cityCode, i) in masterValue.cityCodes" :key="i">
      <div v-if="cardNo==i">
        <span class="" >{{masterValue.cityCodes[i].cityName}}</span> 
        <hr>
        <span class="" >{{masterValue.cityCodes[i].weatherCityId}}</span>
      </div>
    </div> -->
    <!-- 
      <span class="increase m-l-5" v-if="differencialVal2 > 0.0">
        &nbsp;<i class="fa fa-caret-up text-red-lighter"></i>
      </span>
      <span class="decrease m-l-5" v-if="differencialVal2 < 0.0">
        &nbsp;<i class="fa fa-caret-down text-blue-lighter"></i>
      </span>
      <span class="m-l-5" v-if="differencialVal2 == 0.0 || !differencialVal2">
        &nbsp;<i class="fa fa-minus text-dark"></i>
      </span>
        <pre class="">{{masterValue}}</pre> 
   -->
  </div>
</template>

<script>
import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import utils from "@src/views/widget/v1/setting/xeIodCardSetting.vue";
import backEndApi from "@api/backEndApi.js";

//const nMonths = 12;


export default {
  name: "xeDataCard",
  extends: xeBaseWidget,
  props:['cardData', 'cardNo'],
  components: {

},
  data() {
    return {

      goals:[],
      goalTypes: [],
      years: {},
      curGoalType: "",
      curYear: 0,
      masterValue:[],
      targetValues:[],

      data1:[],
      data2:[],
      ptGroups:[],
    };
  },
  computed: {
    // TODO
    // hasSelfCond() {
    //   if (this.main.self || this.bottom.self) return true;
    //   return false;
    // },
    // isPercentage() {
    //   return this.widget.objects.main.addition.isPercentage;
    // },
    // iodCardSetting() {
    //   return JSON.parse(JSON.stringify(this.widget));
    // },
    // differencialVal2() {
    //   let bValue = this.bottom.value;
    //   let mValue = this.main.value;
    //   let returnValue = 0;

    //   if (bValue == 0 || mValue == 0) {
    //     returnValue = 0;
    //   } else {
    //     //1) (비교대상:금년 - 기준:작년)/기준*100
    //     returnValue = (((mValue - bValue) / bValue) * 100).toFixed(
    //       this.main.addition.fixed
    //     );
    //     //2) 비교대상:금년/기준:작년*100-100
    //     //returnValue = ((bValue/mValue)*100).toFixed(this.main.addition.fixed)
    //     // console.log("return value : " + returnValue)
    //   }

    //   return returnValue;
    // },
    // gubun(){
    //   let str= "";

    //   if( this.widget.objects.main.self.sTi.includes('sDate(0)') && 
    //       this.widget.objects.main.self.eTi.includes('eDate(0)') &&
    //       this.widget.objects.bottom.self.sTi.includes('sDate(-1)') &&
    //       this.widget.objects.bottom.self.eTi.includes('eDate(-1)')){
    //     str="전일 대비"
    //   } else if( this.widget.objects.main.self.sTi.includes('sMonth(0)') && 
    //              this.widget.objects.main.self.eTi.includes('eMonth(0)') &&
    //              this.widget.objects.bottom.self.sTi.includes('sMonth(-1)') &&
    //              this.widget.objects.bottom.self.eTi.includes('eMonth(-1)')){
    //     str="전월 대비"
    //   } else if( this.widget.objects.main.self.sTi.includes('sYear(0)') && 
    //              this.widget.objects.main.self.eTi.includes('eYear(0)') &&
    //              this.widget.objects.bottom.self.sTi.includes('sYear(-1)') &&
    //              this.widget.objects.bottom.self.eTi.includes('eYear(-1)')){
    //     str="전년 대비"
    //   }
    //   return str;
    // },
  },
  created() {
    // TODO
    // master: {
    //     getMaster() {
    //       return axios.get(xemsHost() + `/master/getMaster`);
    //     },
    //     updateMaster(master) {
    //       return axios.post(xemsHost() + `/master/updateMaster`, master);
    //     },
    //   },

    backEndApi.master.getMaster().then(({ data }) => {
      // commit("UPDATE_MASTER", data);
      this.masterValue= data
    });
  },
  mounted() {
    let dataCard = document.getElementsByClassName("xeDataCard");

    for (let i = 0; i < dataCard.length; i++) {
      dataCard[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      console.log('initSetting() is called.')
    },
    // TODO
    bindData() {
      //this.workgroups.clear();
    this.ptGroups.clear();
      
    return backEndApi.pointInfo.searchPointGroup().then(({ data }) => {
        if (this.$err(data)) return;
        console.log(data)
        this.ptGroups.range(data.map(v => v.ptGroup));
      });
    },
    applyData(logs) {
      if (!this.isEmpty(this.main.self.sTi)) {
        this.bindCard(
          this.getCond(
            logs,
            Object.assign({ point: this.main.point }, this.main.self)
          ),
          this.main
        );
      } else {
        this.bindCard(logs[0], this.main);
      }

      if (!this.isEmpty(this.bottom.self.sTi)) {
        this.bindCard(
          this.getCond(
            logs,
            Object.assign({ point: this.bottom.point }, this.bottom.self)
          ),
          this.bottom
        );
      } else {
        this.bindCard(logs[0], this.bottom);
      }
    },
    getCond(logs, cond) {
      let rtnLogs = null;

      function runTi(type, ti) {
        let fn = new Function(
          "now",
          "baseline",
          "compare",
          "type",
          "return " + ti
        );
        let dt = fn(new Date(), type);

        return dt.normalize(type);
      }

      rtnLogs = logs.filter(
        (log) =>
          log.type == cond.type &&
          log.sDt == runTi(cond.type, cond.sTi) &&
          log.eDt == runTi(cond.type, cond.eTi) &&
          log.points[cond.point]
      );
      return rtnLogs;
    },
    bindCard(log, card) {
      let ptVals = [];

      if (Array.isArray(log)) ptVals = log[0].points[card.point];
      else ptVals = log.points[card.point];

      ptVals = utils.onAfterAction(
        card.point,
        ptVals,
        card.addition.afterAction
      ); // 배열반환

      let val = utils.onAfterBind(
        card.point,
        ptVals,
        card.addition ? card.addition.afterBind : null
      ); // 값반환

      card.value = val;
    },
    // handleDropPoint(label, arg) {
    //   if (label == "main") {
    //     this.widget.title = arg.item.title;
    //     this.widget.objects.main = {
    //       label: "",
    //       addition: { afterAction: "", afterBind: "Last", isPercentage: false },
    //       tail: "",
    //       point: arg.item.ptAddr,
    //       value: 0,
    //     };
    //   } else {
    //     this.widget.title = arg.item.title;
    //     this.widget.objects.bottom = {
    //       label: "",
    //       addition: { afterAction: "", afterBind: "Last", isPercentage: false },
    //       tail: "",
    //       point: arg.item.ptAddr,
    //       value: 0,
    //     };
    //   }
    // },
    // 드래그
    // handleDropPoint(transfer) {
    //   if (this.$err(transfer)) return;

    //   this.widget.title = transfer.item.title;
    //   this.widget.objects.main = { afterBind: "Last", tail: "", point: transfer.item.ptAddr, value: 0 };
    // },
  },
};
</script>


<style scoped>
.base {
  /* align-content: center; */
  position: absolute;
  display: grid;
}
.stats-title {
  text-transform: uppercase;
  position: absolute;
  margin: 10px 0;
  font-size: 13px;
  font-weight: 600;
  /* TODO */
  /* top: 20px; */
  /* padding-left: 10px; */
}

.stats-value {
  /* margin: 10px 0; */
  position: absolute;
  font-size: 13px;
  font-weight: 500;
  padding-left: 20px;
}

.stats-bottom {
  position: absolute;
  bottom: 0px;
}

</style>