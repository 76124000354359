<template>
	<div class="xeArea heightMAX rounded" style="overflow: hidden;">
		<div class="heightMAX" style="padding: 0">
			<!-- <div class="jvectormap-container " style="background-color: transparent; position:relative"> -->
			<drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX kbet-custom-reset">
				<div class="rounded kbet-widget-energy heightMAX" style="display: block;">
					<p class="title">{{ $t(widget.title) }}</p>
					<div class="chartHeightMax">
						<!-- <div class="chartHeightMax"> -->
						<div class="chartHeightMax p-5">
							<line-chart v-show="viewMode === 'Chart'" ref="chart" class="chartHeightMax" :data="chartJs.data" :options="chartJs.options"></line-chart>
						</div>
					</div>
				</div>
				<!--// kbet-card-info -->
			</drop>
			<!-- </div> -->
		</div>

		<b-modal ref="modalSetting" size="lg" :title="`${$t('설정')} - Area ${$t('차트')}`" bodyClass="p-t-0 p-b-0" hide-footer>
			<chart-line-setting :initSetting="chartSetting" :data="chartJs.data" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
		</b-modal>
	</div>
</template>
<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
	import LineChart from "../../../components/vue-chartjs/LineChart.js";
	import chartLineSetting from "./setting/xeAreaSetting.vue";
	import utils from "@src/views/widget/v1/common/xeBaseUtils.js";

	export default {
		name: "xeArea",
		extends: xeBaseWidget,
		components: {
			LineChart,
			chartLineSetting,
		},
		data() {
			return {
				tmpTestData: {
					data: {},
					options: {},
				},
				chartJs: {
					data: {},
					options: {},
				},
				viewMode: "Chart", // 'Chart', 'Grid'

				// grid
				isGridGroup: false,
				isPagination: false,
				isLineNumber: true,
				gridTable: {
					columns: [],
					rows: [],
				},
				gridSetting: {},
			};
		},
		computed: {
			chartSetting() {
				return JSON.parse(JSON.stringify(this.widget));
			},
		},

		created() {
			// 초기값 설정
			if (!this.widget.addition) {
				this.widget.addition = {
					afterAction: "",
					fixed: 0,
				};
			}
			if (!this.widget.self) {
				this.widget.self = {
					baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
					compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
				};
			}

			if (!this.widget.chart)
				this.widget.chart = {
					colorOpens: false,
					backgroundColor: "#ffffff",
					gridColor: "#0000001F",
					fontColor: "#000000",
					chartFontSize: 14,
					titleFontSize: 14,
					series: [
						{
							type: "line",
							//label: "Default",
							//point: "",
							// test용
							label: "",
							point: "",
							position: "Left",
							backgroundColor: "rgba(52, 143, 226, 0.3)",
							unit: "",
							fill: true,
							compare: false,
							dataColor: "#00acac77",
							// borderColor: "#00acac",
							// dataColor: "#3B67A477",
							// borderColor: "#3B67A4ff",
						},
						{
							type: "line",
							// label: "Default",
							// point: "",
							// test용
							label: "",
							point: "",
							position: "Left",
							backgroundColor: "rgba(52, 143, 226, 0.3)",
							unit: "",
							fill: true,
							compare: false,
							dataColor: "#348fe277",
							// borderColor: "#348fe2",

							// dataColor: "#60E2D777",
							// borderColor: "#60E2D7ff",
						},
					],
					options: {
						stacked: true,
						fill: true,
						fixed: 0,
						legend: {
							display: true,
							position: "top",
							align: "end",
							labels: {
								boxWidth: 10,
								fontSize: 14,
								fontStyle: "normal",
								fontColor: "#000000",
							},
						},
						yAxes: {
							left: {
								label: "Unit1",
								min: 0,
								max: 0,
								display: true,
								unit: "",
								fontSize: 12,
								fontColor: "#000000",
							},
							right: {
								label: "Unit2",
								min: 0,
								max: 0,
								display: false,
								unit: "",
								fontSize: 12,
								fontColor: "#000000",
							},
						},
					},
				};
		},
		mounted() {
			let lines = document.getElementsByClassName("xeArea");

			for (let i = 0; i < lines.length; i++) {
				lines[i].parentNode.style.height = "100%";
			}
		},
		methods: {
			parse(str) {
				var y = "";
				var m = "";
				var d = "";
				var h = "";
				var M = "";
				var s = "";
				if (/^(\d){8}$/.test(str)) {
					y = str.substr(0, 4);
					m = str.substr(4, 2);
					d = str.substr(6, 2);
					return new Date(y, m, d);
				} else if (/^(\d){10}$/.test(str)) {
					y = str.substr(0, 4);
					m = str.substr(4, 2);
					d = str.substr(6, 2);
					h = str.substr(8, 2);
					return new Date(y, m, d, h);
				} else if (/^(\d){12}$/.test(str)) {
					y = str.substr(0, 4);
					m = str.substr(4, 2);
					d = str.substr(6, 2);
					h = str.substr(8, 2);
					M = str.substr(10, 2);
					s = str.substr(12, 20);
					return new Date(y, m, d, h, M, s);
				}
			},
			//make Test Random Number

			getRandomData() {
				let testData = [];
				for (let i = 0; i < 20; i++) {
					testData.push(Math.floor(Math.random() * 100));
				}

				return testData;
			},
			initSetting() {
				this.chartJs.options = this.createChartOptions();
				this.chartJs.data = {
					labels: this.defaultLabels(this.widget.self.baseline.type),
					datasets: this.defaultDatasets(),
				};

				this.$el.style.backgroundColor = this.widget.chart.backgroundColor;
			},
			defaultLabels(timeType) {
				let labels = [];

				if (timeType) return labels;

				// 기본 Label을 생성하지 않고 데이터의 내용만으로 Label을 생성한다.
				/*
      let now = new Date();

      if (timeType == 1)
        labels = [now.addYear(-1).getFullYear() + "Y", now.getFullYear() + "Y"]; // 년
      if (timeType == 2)
        labels = [...Array(13).keys()]
          .filter((v) => v !== 0)
          .map((v) => {
            return v + "M";
          }); // 월
      if (timeType == 3)
        labels = [...Array(31).keys()]
          .filter((v) => v !== 0)
          .map((v) => {
            return v + "D";
          }); // 일
      if (timeType == 4)
        labels = [...Array(24).keys()].map((v) => {
          return v + "H";
        }); // 시
      if (timeType == 5) {
        // 15분
        for (let i = 1; i < 25; i++) {
          if (i == 1) labels.push(["0m", now.getDate() + "D"]);
          else labels.push("0m");
          labels.push("15m");
          labels.push("30m");
          labels.push("45m");
        }
      }
      */

				return labels;
			},
			createLabels(logs) {
				let labels = [];
				let y,
					m,
					d,
					h,
					M = "";

				for (let i = 0; i < logs.length; i++) {
					// let firstRows = logs[i].rows[0];

					for (let j = 0; j < logs[i].rows.length; j++) {
						let str = logs[i].rows[j];
						if (/^(\d){4}$/.test(str)) {
							y = str.substr(0, 4);
							// logs[i].rows[j] = y + "-" + m + "-" + d;
							logs[i].rows[j] = y + global.xe.$t("년");
						} else if (/^(\d){6}$/.test(str)) {
							y = str.substr(0, 4);
							m = str.substr(4, 2);
							// logs[i].rows[j] = y + "-" + m + "-" + d;
							logs[i].rows[j] = global.xe.$t(m + "월");
						} else if (/^(\d){8}$/.test(str)) {
							y = str.substr(0, 4);
							m = str.substr(4, 2);
							d = str.substr(6, 2);
							// if (Number(firstRows.substr(4, 2)) === Number(m)) {
							//   logs[i].rows[j] = d + "일";
							// } else {
							//   logs[i].rows.splice(j, logs[i].rows.length - j);
							//   break;
							// }
							logs[i].rows[j] = global.xe.$t(Number(d) + "일");
							// logs[i].rows[j] = y + "-" + m + "-" + d;
						} else if (/^(\d){10}$/.test(str)) {
							y = str.substr(0, 4);
							m = str.substr(4, 2);
							d = str.substr(6, 2);
							h = str.substr(8, 2);
							// logs[i].rows[j] = y + "-" + m + "-" + d + " " + h;\
							logs[i].rows[j] = h + global.xe.$t("시");
						} else if (/^(\d){12}$/.test(str)) {
							y = str.substr(0, 4);
							m = str.substr(4, 2);
							d = str.substr(6, 2);
							h = str.substr(8, 2);
							M = str.substr(10, 2);
							// logs[i].rows[j] = y + "-" + m + "-" + d + " " + h + ":" + M;
							logs[i].rows[j] = h + ":" + M;
						}
					}
				}

				// console.log('xeArea::logs[0] : ', JSON.stringify(logs[0]));

				if (logs.length === 1) {
					// baseline 조회시
					labels = logs[0].rows;
				} else if (logs.length === 2) {
					// compare 조회시
					let base = logs[0];
					let comp = logs[1];

					// 구간조회이기 때문에, 일자의 갯수가 base와 comp가 서로 다를 수 있다.
					// 그래서, 일자 기간을 더 길게 조회한 쪽을 기준으로 for문을 돌아준다.

					if (base.rows.length >= comp.rows.length) {
						labels = base.rows.map((dt) => {
							return [
								dt,
								// comp.dates[i] ? comp.dates[i][1] + " " + comp.dates[i][0] : "",
							];
						});
					} else {
						labels = comp.rows.map((dt) => {
							return [
								dt,
								// base.dates[i] ? base.dates[i][1] + " " + base.dates[i][0] : "",
							];
						});
					}
				}
				return labels;
			},
			defaultDatasets() {
				return this.widget.chart.series
					.filter((item) => !item.compare || this.isCompare)
					.map((item) => {
						return {
							backgroundColor: item.dataColor,
							borderColor: item.dataColor,
							fill: true,
							label: global.xe.$t(item.label), // ''
							borderWidth: 1.5,
							// pointBorderColor: item.dataColor,
							// pointBackgroundColor: "#fff",
							pointRadius: 0,
							// lineTension: this.widget.chart.options.spline == true ? 0.4 : 0,
							showLine: true,
							borderCapStyle: "butt",
							spanGaps: true,
							yAxisID: item.position === "Left" ? "Left_yAxis" : "Right_yAxis",
							point: item.point,
							unit: item.unit,
							compare: item.compare ? item.compare : false,
							// data: this.getRandomData(),
						};
					});
			},
			createDatasets(logs) {
				let datasets = this.defaultDatasets();

				// 기존 로그 복사하여 데이터 변형 작업 진행
				let copyLogs = JSON.parse(JSON.stringify(logs));

				// afterAction 처리
				copyLogs = utils.onAfterAction2(datasets, copyLogs, this.widget.addition.afterAction);

				// console.log(datasets);

				// 기존 로직 중 fixedValue살림
				return datasets.map((dataset) => {
					let log = dataset.compare ? copyLogs[1] : copyLogs[0];
					let ptVals = log && log.points ? log.points[dataset.point] : [];

					dataset.data = ptVals;

					return dataset;
				});
			},
			createChartOptions() {
				let options = {
					elements: {
						line: {
							fill: "-1",
						},
					},
					responsive: true,
					maintainAspectRatio: false,
					animations: {
						duration: 1000,
						easing: "easeOutQuart",
					},
					hoverMode: "label",
					legend: {
						display: this.widget.chart.options.legend.display,
						position: this.widget.chart.options.legend.position,
						align: this.widget.chart.options.legend.align,
						fullWidth: true,
						labels: {
							usePointStyle: true,
							boxWidth: this.widget.chart.options.legend.labels.boxWidth,
							fontSize: this.widget.chart.options.legend.labels.fontSize,
							fontColor: this.widget.chart.options.legend.labels.fontColor,
              
						},
					},
					title: {
						display: false,
						position: "top",
						text: this.widget.title,
						fontSize: this.widget.chart.options.titleFontSize,
						fontColor: this.widget.chart.options.fontColor,
					},
					layout: { padding: { left: 0, right: 0, top: 0, bottom: -5 } },
					hover: {
						mode: "nearest",
						intersect: false,
						animationDuration: 400,
					},
					tooltips: {
						mode: "index",
						responsive: false,
						intersect: false,
            enabled: false,
						custom: function(tooltipModel) {
							let bodyEmptyCheck = false;

							var tooltipEl = document.getElementById("chartjs-tooltip");

							if (tooltipModel.body) {
								let NOTNULLValue = tooltipModel.body.find((v) => v.lines.find((t) => !t.includes("null")));
								if (!NOTNULLValue) {
									bodyEmptyCheck = true;
								}
							}

							if (bodyEmptyCheck) {
								if (tooltipEl) tooltipEl.remove();
								return;
							}

							// Create element on first render
							if (!tooltipEl) {
								tooltipEl = document.createElement("div");
								tooltipEl.id = "chartjs-tooltip";
								tooltipEl.innerHTML = "<table></table>";
								tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
								tooltipEl.style.borderColor = tooltipModel.borderColor;
								tooltipEl.style.borderWidth = tooltipModel.borderWidth;
								tooltipEl.style.borderRadius = `${tooltipModel.cornerRadius}px`;
								tooltipEl.style.borderStyle = "solid";
								tooltipEl.style.color = "#fff";
								document.body.appendChild(tooltipEl);
							}

							// Hide if no tooltip
							if (tooltipModel.opacity === 0) {
								tooltipEl.style.opacity = 0;
								return;
							}

							// Set caret Position
							tooltipEl.classList.remove("above", "below", "no-transform");
							if (tooltipModel.yAlign) {
								tooltipEl.classList.add(tooltipModel.yAlign);
							} else {
								tooltipEl.classList.add("no-transform");
							}

							function getBody(bodyItem) {
								return bodyItem.lines;
							}

							// Set Text
							if (tooltipModel.body) {
								var titleLines = tooltipModel.title || [];
								var bodyLines = tooltipModel.body.map(getBody);

								var innerHtml = "<thead>";

								titleLines.forEach(function(title) {
									innerHtml += "<tr><th>" + global.xe.$t(title) + "</th></tr>";
								});
								innerHtml += "</thead><tbody>";

								bodyLines.forEach(function(body, i) {
									var colors = tooltipModel.labelColors[i];
									var style = "background:" + colors.backgroundColor;
									style += "; border-color:" + colors.borderColor;
									style += "; border-width: 2px";
									style += "; width: 10px";
									style += "; height: 10px";
									style += "; font-size: 10px";
									style += "; align-self: center";
									style += "; margin-right: 2px";
									var span = '<div style="' + style + '"></div>';
									innerHtml += "<tr><td style='display:flex;'>" + span + body + "</td></tr>";
								});
								innerHtml += "</tbody>";

								var tableRoot = tooltipEl.querySelector("table");
								tableRoot.innerHTML = innerHtml;
							}

							// `this` will be the overall tooltip
							var position = this._chart.canvas.getBoundingClientRect();

							tooltipModel.position = "absolute";
							tooltipModel.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
							tooltipModel.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
							// Display, position, and set styles for font
							tooltipEl.style.opacity = 1;
							tooltipEl.style.position = "absolute";
							tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
							tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
							tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
							tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
							tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
							tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
							tooltipEl.style.pointerEvents = "none";
						},
						callbacks: {
							label: (tooltipItems, data) => {
								let value = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];

								value = this.comma(value, this.widget.addition.fixed);

								let label = global.xe.$t(data.datasets[tooltipItems.datasetIndex].label) || "";

								if (data.datasets[tooltipItems.datasetIndex].yAxisID == "Left_yAxis") {
									value = String(value) + this.widget.chart.options.yAxes.left.unit;
								} else if (data.datasets[tooltipItems.datasetIndex].yAxisID == "Right_yAxis") {
									value = String(value) + this.widget.chart.options.yAxes.right.unit;
								} else {
									value = String(value);
								}

								if (label) {
									label += ": ";
								}
								return label + value;

								//   data.datasets.forEach((dataset) => {
								//     sum += dataset.data[tooltipItems.datasetIndex];
								//   });

								//   return "Sum: " + sum;

								// let label = data.datasets[tooltipItem.datasetIndex].label || '';

								// if (label) { label += ': ' }
								// if(!data.datasets[tooltipItem.datasetIndex].compare){ label += tooltipItem.yLabel; }

								// return label;
							},
							footer: (tooltipItems) => {
								var sum = 0;

								tooltipItems.forEach(function(tooltipItem) {
									sum += tooltipItem.yLabel;
								});
								return "Sum: " + this.comma(sum.toFixed(1), 0);
							},
						},
					},

					scales: {
						xAxes: [
							{
								// stacked: this.widget.chart.options.stacked,
								ticks: {
									autoSkip: true,
									maxRotation: 0,
									autoSkipPadding: 20,
									fontColor: this.widget.chart.fontColor,
									fontSize: this.widget.chart.chartFontSize,
								},
								gridLines: {
									color: this.widget.chart.gridColor,
									drawOnChartArea: true,
									offsetGridLines: false,
								},
							},
						],
						yAxes: [
							{
								display: this.widget.chart.options.yAxes.left.display,

								position: "left",
								id: "Left_yAxis",
								color: "#ffffff",
								scaleLabel: {
									display: true,
									labelString: global.xe.$t(this.widget.chart.options.yAxes.left.label) + this.widget.chart.options.yAxes.left.unit,
									fontColor: this.widget.chart.fontColor,
									fontSize: this.widget.chart.chartFontSize,
								},
								ticks: {
									autoSkip: true,
									maxRotation: 0,
									autoSkipPadding: 20,
									suggestedMin: this.widget.chart.options.yAxes.left.min,
									suggestedMax: this.widget.chart.options.yAxes.left.max,
									beginAtZero: true,
									fontColor: this.widget.chart.fontColor,
									fontSize: this.widget.chart.chartFontSize,
									userCallback: (value) => {
										value = this.comma(value, this.widget.addition.fixed);
										return value;
									},
								},
								stacked: this.widget.chart.options.stacked,
								gridLines: {
									color: this.widget.chart.gridColor,
									drawOnChartArea: true,
									offsetGridLines: false,
								},
							},
							{
								display: this.widget.chart.options.yAxes.right.display,
								position: "right",
								id: "Right_yAxis",
								scaleLabel: {
									display: true,
									labelString: global.xe.$t(this.widget.chart.options.yAxes.right.label) + this.widget.chart.options.yAxes.right.unit,
									fontColor: this.inverseMode ? "white" : "black",
									fontSize: this.widget.chart.chartFontSize,
								},
								ticks: {
									autoSkip: true,
									maxRotation: 0,
									autoSkipPadding: 20,
									suggestedMin: this.widget.chart.options.yAxes.right.min,
									suggestedMax: this.widget.chart.options.yAxes.right.max,
									beginAtZero: true,
									fontColor: this.widget.chart.fontColor,
									fontSize: this.widget.chart.chartFontSize,
									userCallback: (value) => {
										value = this.comma(value, this.widget.addition.fixed);
										return value;
									},
								},
								stacked: this.widget.chart.options.stacked,
								gridLines: {
									color: this.widget.chart.gridColor,
									drawOnChartArea: true,
									offsetGridLines: false,
								},
							},
						],
					},
				};

				return options;
			},
			// virtual
			applyData(widgetLogs) {
				// widgetLogs
				let newDatasets = {
					data: {
						labels: this.createLabels(widgetLogs),
						datasets: this.createDatasets(widgetLogs),
					},
				};

				// this.chartJs.data = {
				//   labels: this.createLabels(widgetLogs),
				//   datasets: this.createDatasets(widgetLogs),
				// };

				//NOTE - Test용 코드 시작

				// if (this.chartJs.data.datasets[0].data.length === 0) {
				//   this.chartJs.data = {
				//     labels: this.createLabels(widgetLogs),
				//     datasets: this.createDatasets(widgetLogs),
				//   };
				// }

				// this.tmpTestData = this.chartJs;

				// for (let i = 0; i < this.tmpTestData.data.datasets[0].data.length; i++) {
				//   if (this.tmpTestData.data.datasets[0].data[i] == null) {
				//     this.tmpTestData.data.datasets[0].data[i] = 80;
				//     break;
				//   }
				// }

				// this.chartJs.data.datasets[0].data = this.tmpTestData.data.datasets[0].data;
				//NOTE - Test용 코드 끝

				// console.log(this.chartJs.data, newDatasets);

				// this.chartJs.data = newDatasets;

				// this.chartjs.data.labels = newDatasets.data.labels;
				// this.chartjs.data.datasets = newDatasets.data.datasets;
				// if(newDatasets)


				this.chartJs.data.labels = newDatasets.data.labels;

				// for (let i = 0; i < newDatasets.data.labels.length; i++) {
				// 	this.chartJs.data.labels[i] = newDatasets.data.labels[i];
				// }

				this.chartJs.data.datasets = newDatasets.data.datasets;

				// for (let i = 0; i < newDatasets.data.datasets.length; i++) {
				//   console.log(this.chartJs.data.datasets[i], newDatasets.data.datasets[i]);
				// 	this.chartJs.data.datasets[i].data = newDatasets.data.datasets[i].data;
				// }

				this.$refs.chart._data._chart.update();

				this.widgetLogs = widgetLogs;
			},

			//dnd - virtual
			handleDropPoint(transfer) {
				let that = this;

				function append(points) {
					// { "type": "bar", "label": "입구온도", "point": "084000001_0000_0000_VLI_00000001", "position": "Left", "backgroundColor": "#5499C7", "fill": false}

					points.map((point) => {
						if (that.widget.chart.series.filter((v) => v.point === point.ptAddr).length == 0) {
							// 중복 체크
							that.widget.chart.series.push({
								type: "spline",
								label: point.title,
								point: point.ptAddr,
								position: "Left",
								unit: "",
								backgroundColor: that.getRandomColor(),
								fill: false,
							});
						}
					});
				}

				let defaultObj = {
					type: "spline",
					label: "Default",
					point: "",
					position: "Left",
					backgroundColor: "rgba(52, 143, 226, 0.3)",
					fill: false,
					compare: false,
				};

				if (JSON.stringify(this.widget.chart.series.slice(0, 1)) == JSON.stringify([defaultObj])) this.widget.chart.series = [];

				append(transfer.item.type === this.CODE.Drag.PointGroup ? transfer.item.points : [transfer.item]);
			},
		},
		hexToRGBA(hex, alpha) {
			var r = parseInt(hex.slice(1, 3), 16),
				g = parseInt(hex.slice(3, 5), 16),
				b = parseInt(hex.slice(5, 7), 16);

			return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
		},
	};
</script>

<style lang="scss" scoped></style>
