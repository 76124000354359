<template>
  <div class="xeUsageChart heightMAX" style="overflow: hidden;">
        <div class="heightMAX">
          <div class="kbet-custom-reset heightMAX">
            <div class="rounded kbet-widget-map heightMAX" style="display: block;">
              <p class="title">{{ $t(widget.title)}}</p>
              <!-- 콘텐츠 영역 -->
              <div class="map_area_array">
                <div class="map_area">
                  <dl class="poi opi_1">
                    <dt>{{seoul8f.zoneName}}</dt>
                    <dd>
                      <ul>
                        <li>
                          <strong>{{$t('자립률')}} : </strong> <span>{{seoul8f.selfRenceRate == '-' ? seoul8f.selfRenceRate : seoul8f.selfRenceRate + ' %'}}</span> 
                        </li>
                        <li>
                          <strong>{{$t('소비량')}} : </strong> <span>{{seoul8f.useTotal == '-' ? seoul8f.useTotal : seoul8f.useTotal + ' kWh'}}</span>
                        </li>
                        <li>
                          <strong>{{$t('생산량')}} : </strong> <span>{{seoul8f.renewTotal == '-' ? seoul8f.renewTotal : seoul8f.renewTotal + ' kWh'}}</span>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl class="poi opi_2">
                    <dt>{{seoul7f.zoneName}}</dt>
                    <dd>
                      <ul>
                        <li>
                          <strong>{{$t('자립률')}} : </strong> <span>{{seoul7f.selfRenceRate == '-' ? seoul7f.selfRenceRate : seoul7f.selfRenceRate + ' %'}}</span> 
                        </li>
                        <li>
                          <strong>{{$t('소비량')}} : </strong> <span>{{seoul7f.useTotal == '-' ? seoul7f.useTotal : seoul7f.useTotal + ' kWh'}}</span>
                        </li>
                        <li>
                          <strong>{{$t('생산량')}} : </strong> <span>{{seoul7f.renewTotal == '-' ? seoul7f.renewTotal : seoul7f.renewTotal + ' kWh'}}</span>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl class="poi opi_3">
                    <dt>{{sejong.zoneName}}</dt>
                    <dd>
                      <ul>
                        <li>
                          <strong>{{$t('자립률')}} : </strong> <span>{{sejong.selfRenceRate == '-' ? sejong.selfRenceRate : sejong.selfRenceRate + ' %'}}</span> 
                        </li>
                        <li>
                          <strong>{{$t('소비량')}} : </strong> <span>{{sejong.useTotal == '-' ? sejong.useTotal : sejong.useTotal + ' kWh'}}</span>
                        </li>
                        <li>
                          <strong>{{$t('생산량')}} : </strong> <span>{{sejong.renewTotal == '-' ? sejong.renewTotal : sejong.renewTotal + ' kWh'}}</span>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl class="poi opi_4">
                    <dt>{{busan.zoneName}}</dt>
                    <dd>
                      <ul>
                        <li>
                          <strong>{{$t('자립률')}} : </strong> <span>{{busan.selfRenceRate == '-' ? busan.selfRenceRate : busan.selfRenceRate + ' %'}}</span> 
                        </li>
                        <li>
                          <strong>{{$t('소비량')}} : </strong> <span>{{busan.useTotal == '-' ? busan.useTotal : busan.useTotal + ' kWh'}}</span>
                        </li>
                        <li>
                          <strong>{{$t('생산량')}} : </strong> <span>{{busan.renewTotal == '-' ? busan.renewTotal : busan.renewTotal + ' kWh'}}</span>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </div>
              <!-- //콘텐츠 영역 -->
            </div>
          </div>
        </div>
        <b-modal
          ref="modalSetting"
          size="sm"
          :title="`${$t('설정')} - ${$t('전국현황 자립률')}`"
          bodyClass="p-t-0 p-b-0"
          hide-footer
        >
          <self-rate-toc-setting
            :initSetting="setting"
            @on-modal-cancel="onModalCancel"
            @on-modal-ok="onModalOK"
          />
        </b-modal>
      </div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import selfRateTocSetting from "./setting/xeSelfRateTocSetting.vue";
// import backEndApi from "@api/backEndApi.js";

export default {
  name: "xeBizInfo",
  props: ["showGrid", "widget", "cellSize", "on-remove-widget"],
  extends: xeBaseWidget,
  components: {
    selfRateTocSetting
  },
  created() {
    if (!this.widget.bgIcon) {
      this.widget.bgIcon = "material flash_on";
    }
  },
  mounted() {
  },
  data() {
    return {
      seoul8f:{
        zoneName: "",
        useTotal: "-",
        renewTotal: "-",
        selfRenceRate: "-",
      },
      seoul7f:{
        zoneName: "",
        useTotal: "-",
        renewTotal: "-",
        selfRenceRate: "-",
      },
      sejong:{
        zoneName: "",
        useTotal: "-",
        renewTotal: "-",
        selfRenceRate: "-",
      },
      busan:{
        zoneName: "부산분원",
        useTotal: "-",
        renewTotal: "-",
        selfRenceRate: "-",
      },
      themeColor : global.xe.parseBool(localStorage.contentInverse) ? require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark : require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light
    };
  },
  computed: {
    setting() {
      return JSON.parse(JSON.stringify(this.widget));
    }
  },
  methods: {
    applyData(data) {
      data.forEach(result => {

        //NaN 채크
        if(isNaN(result.useTotal)) result.useTotal = "-";
        if(isNaN(result.renewTotal)) result.renewTotal = "-";
        if(isNaN(result.selfRenceRate)) result.selfRenceRate = "-";

        //Null 채크 및 공백채크
        if(!result.useTotal) result.useTotal = "-";
        if(!result.renewTotal) result.renewTotal = "-";
        if(!result.selfRenceRate) result.selfRenceRate = "-";

        if(result.zoneName == "기술원 본원"){
          this.seoul8f.zoneName = result.zoneName;
          this.seoul8f.useTotal = result.useTotal;
          this.seoul8f.renewTotal = result.renewTotal;
          this.seoul8f.selfRenceRate = result.selfRenceRate;
        }else if(result.zoneName == "인증센터"){
          this.seoul7f.zoneName = result.zoneName;
          this.seoul7f.useTotal = result.useTotal;
          this.seoul7f.renewTotal = result.renewTotal;
          this.seoul7f.selfRenceRate = result.selfRenceRate;
        }else if(result.zoneName == "세종분원"){
          this.sejong.zoneName = result.zoneName;
          this.sejong.useTotal = result.useTotal;
          this.sejong.renewTotal = result.renewTotal;
          this.sejong.selfRenceRate = result.selfRenceRate;
        }else if(result.zoneName == "부산분원"){
          this.busnan.zoneName = result.zoneName;
          this.busnan.useTotal = result.useTotal;
          this.busnan.renewTotal = result.renewTotal;
          this.busnan.selfRenceRate = result.selfRenceRate;
        }
      });
    }
  },
};
</script>

<style scoped>

</style>
