<template>
	<div>
		<div class="row form-group m-t-10">
			<div class="col-md-12">
				<a href="javascript:;" class="xe-tab-underline" @click="toggleTabs('general')" :class="{ 'xe-tab-line-orange': tabs.general }">
					<trans>일반</trans>
				</a>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-12">
				<div class="tab-content">
					<div class="tab-pane fade" :class="{ 'active show': tabs.general }">
						<label>&nbsp;</label>
						<div class="row form-group">
							<div class="col-12">
								<label>
									<h6>
										<i class="fa fa-check"></i>
										<trans>항목명</trans>
									</h6>
								</label>

								<div class="row form-group align-items-center">
									<div class="col-md-12">
										<valid-input :inputType="'text'" :inputValue.sync="copySetting.title" :rules="rules.DASHBOARD_WIDGET_ITEM_NAME_RULE" :classList="'form-control'" :NonStatus="true">
										</valid-input>
									</div>
								</div>
							</div>
						</div>
						<!-- 아이콘 스타일 -->
						<div class="row form-group">
							<div class="col-12">
								<label>
									<h6>
										<i class="fa fa-check"></i>
										<trans>아이콘</trans>
									</h6>
								</label>

								<div class="row form-group align-items-center">
									<div class="col-md-12">
										<div class="row form-group align-items-center">
											<div class="col-md-8">
												<icon-select :current="copySetting.bgIcon" @on-change="onChangeIcon" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 아이콘 스타일 -->
						<hr />
						<xe-modal-self :copySetting="copySetting" :isCompare="true" />
					</div>
					<div class="row form-group">
						<div class="col-12">
							<label>
								<h6>
									<i class="fa fa-check"></i>
									<trans>구역설정</trans>
								</h6>
							</label>

							<div class="row form-group align-items-center">
								<div class="col-md-12">
									<tree-select
										v-model="copySetting.zoneIdx" 
										:load-options="loadOptions"
										:placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
										:noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
										:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
										:multiple="false"
										:options="options" />
								</div>
							</div>
						</div>
					</div>
					<div class="row form-group">
						<div class="col-12">
							<label>
								<h6>
									<i class="fa fa-check"></i>
									<trans>건물이미지</trans>
								</h6>
							</label>

							<div class="row form-group align-items-center">
								<div class="col-md-12">
									<select class="form-control" v-model="copySetting.bldImage">
										<option value="bg-icon03.png">소형 건물</option>
										<option value="bg-icon02.png">빌딩</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-md-12 p-0">
				<div slot="modal-footer" class="w-100">
					<button class="btn btn-primary m-r-5 pull-right" @click="onModalOK()">
						<trans>확인</trans>
					</button>
					<button class="btn btn-secondary pull-right m-r-10" @click="onModalCancel()">
						<trans>취소</trans>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import xeBaseSetting from "@src/views/widget/v1/common/xeBaseSetting.js";
import * as rules from "@src/consts/ruleConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import * as controlMessages from "@src/consts/controlMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
	extends: xeBaseSetting,
	data() {
		return {
			tabs: {
				general: true,
			},

			// define the default value
			zoneIdx: null,
			// define options
			options: null,
			rules,
			popupMessages,
			controlMessages,
		};
	},
	components: {},
	async mounted() {
		await this.getZoneListHierarchi();
	},
	methods: {
		toggleTabs(tab) {
			if (tab == "general") {
				this.tabs.general = true;
				this.tabs.point = false;
				this.tabs.processing = false;
				this.tabs.chartOptions = false;
			}
		},

		onChangeIcon(value) {
			if (!this.isEmpty(value)) this.copySetting.bgIcon = value.icon;
			else this.copySetting.bgIcon = "";
		},

		async getZoneListHierarchi() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.zoneInfo.getZoneListHierarchi();
				const data = result.data;

				this.options = [];

				let temp = [];
				temp.push(data);
				this.options = this.setZoneTreeOption(temp);

				console.log(this.options);
			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			}
		},
		setZoneTreeOption(resultData) {
			resultData.forEach((data) => {


				data.id = data.zoneIdx;
				data.label = data.zoneName;

				if (data["children"]) {
					return this.setZoneTreeOption(data["children"]);
				} else {
					return data;
				}
			});

			return resultData;
		},
	},
};
</script>
@src/views/widget/v1/common/xeBaseSetting.js