<template>
  <div class="xcGridTable heightMAX">
    <vue-good-table
      :columns="gridTable.columns"
      :rows="gridTable.rows"
      :lineNumbers="isLineNumber"
      :search-options="{ enabled: false, placeholder: 'Search this table' }"
      :pagination-options="{
        enabled: isPagination,
        position: 'bottom',
        perPage: 100,
        perPageDropdown: [100, 500, 1000],
        rowsPerPageLabel: 'Rows',
      }"
      :sort-options="{ enabled: false }"
      :selectOptions="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      }"
      :max-height="maxHeight"
      :fixed-header="true"
      styleClass="vgt-table striped condensed xe-fixed-header xe-grid-tbl heightMAX"
      :groupOptions="{
        enabled: isGroup,
      }"
    >
      <div slot="emptystate" style="text-align: center;">
        조회된 결과가 없습니다.
      </div>
      <template slot="table-header-row" slot-scope="props">
        {{ props.formattedRow[props.column.field] }}
      </template>
      <template slot="table-row" slot-scope="props">
        {{ props.formattedRow[props.column.field] }}
      </template>
    </vue-good-table>
  </div>
</template>

<style scoped>
/* Sum, Avg, Min, Max, Last아래 빈데이터 영역을 숨긴다. */
.xe-grid-tbl tbody tr:nth-last-child(1) {
  display: none;
}
/* 보여야 하는 데이터인데, 위에서 숨겨버림. 다시 보이게 해야 함.. */
.xe-grid-tbl tbody:nth-last-child(1) tr {
  display: table-row;
}
</style>

<script>
export default {
  name: "xcGridTable",
  props: ["gridTable", "maxHeight", "isGroup", "isPagination", "isLineNumber"],
  components: {},
  mounted() {
    let vgt1 = document.getElementsByClassName("vgt-wrap");
    let vgt2 = document.getElementsByClassName("vgt-inner-wrap");
    let vgt3 = document.getElementsByClassName("vgt-responsive");

    for (let i = 0; i < vgt1.length; i++) {
      vgt1[i].style.height = "100%";
      vgt2[i].style.height = "100%";
      vgt3[i].style.height = "100%";
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    initSetting() {},
  },
};
</script>