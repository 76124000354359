<template>
  <div class="xePie heightMAX rounded" style="overflow: hidden;">
      <div class="heightMAX" style="padding: 0">
        <drop
          @dragover="handleBodyDragOver(...arguments)"
          @drop="handleBodyDrop(...arguments)"
          class="heightMAX kbet-custom-reset"
        >
          <div class="rounded kbet-widget-energy heightMAX" style="display: block;">
            <p class="title">{{ $t(widget.title)}}</p>
            <div class="chartHeightMax2">
                <div v-if="isShow" class="chartHeightMax2">
                   <pie-chart
                      class="chartHeightMax"
                      chartType="Pie"
                      :data="chartJs.data"
                      :options="chartJs.options"
                    ></pie-chart>
                </div>
              </div>
          </div>
        </drop>
      </div>

      <b-modal
        ref="modalSetting"
        size="lg"
        title="설정 - Pie 차트"
        bodyClass="p-t-0 p-b-0"
        hide-footer
      >
        <chart-pie-setting
          :initSetting="chartSetting"
          @on-modal-cancel="onModalCancel"
          @on-modal-ok="onModalOK"
        />
      </b-modal>
  </div>
</template>

<style scoped>
.chartHeightMax2 {
    height: calc(100% - 5px);
}
</style>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import PieChart from "../../../components/vue-chartjs/Charts/PieChart.js";
import chartPieSetting from "./setting/xePieSetting";
import utils from "@src/views/widget/v1/common/xeBaseUtils.js";


// TODO: 데이터가 없을때 뜨기도하고 안뜨기도 함.....

export default {
  name: "xePie",
  extends: xeBaseWidget,
  components: {
    PieChart,
    chartPieSetting,
  },
  data() {
    return {
      chartJs: {
        data: {},
        options: {},
      },
      themeColor : global.xe.parseBool(localStorage.contentInverse) ? require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark : require('../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light,

    };
  },
  computed: {
    chartSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
  },
  created() {
    //초기값
    if (!this.widget.chart) {
      this.widget.chart = {
        colorOpens: false,
        backgroundColor: "#ffffff",
        series: [
          {
            // label: "Default",
            // point: "",
            label: "Default1",
            point: "0201_NLI_001F02W",
            dataColor: "#7CDBCB",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default2",
            point: "0201_NLI_001F01W",
            dataColor: "#A6DBA8",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default3",
            point: "0201_NLI_001F06W",
            dataColor: "#65B5F4",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default1",
            point: "0201_NLI_001F02W",
            dataColor: "#5873AF",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default2",
            point: "0201_NLI_001F01W",
            dataColor: "#74A3E5",
            compare: false,
          },
          {
            // label: "Default",
            // point: "",
            label: "Default3",
            point: "0201_NLI_001F06W",
            dataColor: "#7CBEDB",
            compare: false,
          },
        ],
        options: {
          legend: {
            display: false,
            position: "bottom",
            align: "center",
            labels: {
              boxWidth: 10,
              fontSize: 22,
              fontStyle: "normal",
              fontColor: "#666666",
            },
          },
          fontColor: "#000000",
          chartFontSize: 14,
          titleFontSize: 14,
          unit: "",
          isPercentage: false,
        },
      };
    }
    if (!this.widget.addition)
      this.widget.addition = {
        afterAction: "",
        afterBind: "",
        fixed: 0,
      };
    if (!this.widget.self)
      this.widget.self = {
        baseline: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
        compare: { type: this.searchBoxCond.type, sTi: "", eTi: "" },
      };
  },
  mounted() {
    let pies = document.getElementsByClassName("xePie");

    for (let i = 0; i < pies.length; i++) {
      pies[i].parentNode.style.height = "100%";
    }
  },
  methods: {
    initSetting() {
      this.chartJs.options = this.createChartOptions();
      this.chartJs.data = this.createChartData();

      this.$el.style.backgroundColor = this.widget.chart.backgroundColor;
    },
    // 차트 데이터 기본값 설정
    createChartData() {
      let points = this.widget.chart.series.map((v) => {
        return { label: v.label, point: v.point, compare: v.compare };
      });

      let backgroundColor = this.widget.chart.series
        .filter((v) => !v.compare || this.isCompare)
        .map((v,i) => {
          v,i;
          // return this.themeColor.chart.itemColors[i];
          return v.dataColor;
        });

      let isPercentage = this.widget.chart.options.isPercentage;

      let labels = points
        ? points.map((item) => {
            return item.label;
          })
        : ["Default"];

      let data = points.map(() => {
        return (100.0 / points.length).toFixed(2);
      });

      return {
        labels: labels,
        points: points,
        isPercentage: isPercentage,
        datasets: [
          {
            backgroundColor: backgroundColor,
            data: data,
            borderWidth: 0,
          },
        ],
      };
    },
    // 차트 기본 옵션값 설정
    createChartOptions() {
      let options = {
        responsive: true,
        maintainAspectRatio: false,
        chartFontSize: this.widget.chart.options.chartFontSize,
        radiusBackground: { color: "#d1d1d1" },
        plugins: {
          datalabels: {
            display: "auto",
            color: "#949494",
            anchor: "end",
            font: {
              size: 12,
            },
            align: "end",
            padding: 10,
            labels: {
              title: {
                font: {
                  weight: "bold",
                },
              },
              value: {
                color: "green",
              },
            },
            formatter: function(value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
          },
        },
        legend: {
          display: false,
          position: this.widget.chart.options.legend.position,
          align: this.widget.chart.options.legend.align,
          labels: {
            usePointStyle: true,
            boxWidth: this.widget.chart.options.legend.labels.boxWidth,
            fontSize: this.widget.chart.options.chartFontSize,
            fontColor: this.widget.chart.options.legend.labels.fontColor,
          },
        },

        elements: { point: { radius: 0.3 } },
        layout: {
          padding: {
            left: 60,
            right: 60,
            top: 40,
            bottom: 40,
          },
        },
        title: { display: false },
        tooltips: {
          mode: "index",
          intersect: true,
          callbacks: {
            label: (tooltipItem, data) => {
              let totalSum = data.datasets[tooltipItem.datasetIndex].data.sum();
              let label = data.labels[tooltipItem.index];
              let currentValue =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              let currentPer = null;

              if (data.isPercentage) {
                if (totalSum == 0) {
                  currentPer = (
                    100.0 / data.datasets[tooltipItem.datasetIndex].data.length
                  ).toFixed(this.widget.addition.fixed);
                } else {
                  currentPer = (currentValue / totalSum) * 100.0;
                }

                currentPer = this.comma(currentPer, this.widget.addition.fixed);

                currentValue = this.comma(
                  currentValue,
                  this.widget.addition.fixed
                );

                return (
                  `${label}: ${currentPer}%, ${currentValue}` +
                  this.widget.chart.options.unit
                );
              } else {
                return (
                  `${label}: ${currentValue}` + this.widget.chart.options.unit
                );
              }
            },
          },
        },
      };

      // TODO: chart의 기본속성을 그대로 사용할 수 있도록 하는 기능임. 예전에 사용하다가 사용하지 않고 있음.. 사용해야 함.
      // if (this.widget.chart) return this.merge(defaultV, this.widget.chart);

      return options;
    },
    // 차트 데이터 바인딩
    applyData(widgetLogs) {
      // 데이터 선별 작업 진행
      let chartData = this.createChartData();

      // 기존 로그 복사하여 데이터 변형 작업 진행
      let copyLogs = JSON.parse(JSON.stringify(widgetLogs));
      // afterAction 처리
      copyLogs = utils.onAfterAction2(
        chartData.points,
        copyLogs,
        this.widget.addition.afterAction
      );

      // 데이터 초기화
      chartData.datasets[0].data = chartData.points.map((item) => {
        let log = copyLogs[item.compare ? 1 : 0];

        // 조회해서 가지고 온 포인트값의 배열
        let ptVals = log && log.points ? log.points[item.point] : [];

        // 후처리 방식에 따라 데이터 바꿔서 차트 데이터에 바인딩
        let val = utils.onAfterBind(
          item.point,
          ptVals,
          this.widget.addition.afterBind
        ); // 값반환

        val = utils.fixedValue(val, this.widget.addition.fixed);

        return val;
      });

      this.chartJs.data = chartData;
    },
    // dnd
    handleDropPoint(transfer) {
      let that = this;

      function append(points) {
        // { "label": "습구온도", "point": "084000001_0000_0000_VLI_00000001"}

        points.map((point) => {
          if (
            that.widget.series.filter((v) => v.point === point.ptAddr).length ==
            0
          ) {
            // 중복 체크
            that.widget.series.push({
              label: point.title,
              point: point.ptAddr,
              backgroundColor: that.getRandomColor(),
            });
          }
        });
      }

      let defaultObj = {
        label: "Default",
        point: "",
        backgroundColor: "#348fe2",
        compare: false,
      };

      if (
        JSON.stringify(this.widget.chart.series.slice(0, 1)) ==
        JSON.stringify([defaultObj])
      )
        this.widget.chart.series = [];

      append(
        transfer.item.type === this.CODE.Drag.PointGroup
          ? transfer.item.points
          : [transfer.item]
      );
    },
  },
};
</script>
