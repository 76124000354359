<template>
  <div class="xeUsageChart heightMAX rounded" style="overflow: hidden;">
    <div class="heightMAX">
      <!-- <div class="jvectormap-container " style="background-color: transparent; position:relative"> -->
      <drop class="heightMAX kbet-custom-reset" @dragover="handleBodyDragOver(...arguments)"
        @drop="handleBodyDrop(...arguments)">
        <div class="rounded kbet-widget-energy heightMAX" style="display: block;">
          <!-- <p class="title"
            :style="{ backgroundColor: this.themeColor.card.panel.backgroundColor, color: this.themeColor.card.panel.fontColor }">
            <i title="material sort" class="material-icons"> flash_on </i>실시간 에너지 자립률</p> -->
          <div class="usage-animation-area">
            <div class="animation-area">
              <div class="img-area">
                <dl>
                  <dt><img src="/assets/xems/common/svg/icon_kepco.svg" alt=""></dt>
                  <dd>{{ useTotal }} kWh</dd>
                </dl>
                <div class="line">
                  <svg>
                    <line x1="0" y1="0" x2="200" y2="0[" stroke-dasharray="5,5" stroke-width="3" stroke="white" />
                  </svg>
                </div>
              </div>
              <div class="img-area">
                <strong class="rounded">{{ selfRenceRate }}%</strong>
                <dl>
                  <dt><img src="/assets/xems/common/svg/icon_building.svg" alt=""></dt>
                  <dd>{{ bldTotal }} kWh</dd>
                </dl>
              </div>
              <div class="img-area">
                <div class="line">
                  <svg>
                    <line x1="0" y1="0" x2="200" y2="0" stroke-dasharray="5,5" stroke-width="3" stroke="white" />
                  </svg>
                </div>
                <dl>
                  <dt><img src="/assets/xems/common/svg/icon_solar_power.svg" alt=""></dt>
                  <dd>{{ renewTotal }} kWh</dd>
                </dl>
              </div>
            </div>
            <p class="info"><i class="material-icons-outlined">info</i>실시간 자립률은 최근 1시간 자료로 계산됩니다.</p>
          </div>
        </div>
        <!--// kbet-card-info -->
      </drop>
    </div>
  </div>
</template>

<script>
	import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";

export default {
  name: "xeBizInfo",
  props: ["showGrid", "widget", "cellSize", "on-remove-widget"],
  extends: xeBaseWidget,
  components: {
  },
  created() {
    // console.log("xeSelfRate widget created");
  },
  mounted() {
    // console.log("xeSelfRate widget mounted");
  },
  data() {
    return {
      useTotal: null,
      bldTotal: null,
      renewTotal: null,
      selfRenceRate: null,
      themeColor: global.xe.parseBool(localStorage.contentInverse) ? require('@components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').dark
        : require('@components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json').light
    };
  },
  computed: {

  },

  methods: {
    applyData(data) {
      // console.log("xeSelfRate.applyData : " + data);
      this.useTotal = data.useTotal;
      this.bldTotal = data.bldTotal;
      this.renewTotal = data.renewTotal;
      this.selfRenceRate = data.selfRenceRate;
    }
  },
};
</script>

<style scoped>
</style>
